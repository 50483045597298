import React from 'react'
import './privacy-policy.css'
import image from '../../asserts/privacy-policy.jpg'
import sideImage from '../../asserts/main-privacy-image.png'
import { Divider } from 'antd'
const PrivacyPolicy = () => {
  return (
    <>
    <div className='main-prvacy-container'>
       <div className='overly-contaier'>
        <div className='privacy-image-container'>
            <h1>Privacy Policy</h1>
         <img src={image} alt='image'/>
        </div>
       </div>
       <div className='privacy-intro mt-5'>
        <div className='seven'>
         <h3>Introduction</h3>
        </div>
         <p>SHL Film and TV ("we," "us," "our") is committed to protecting the privacy of its staff and
ensuring the security of any information accessed through the SHL Film and TV Staff App
("App"). This App is intended solely for use by our staff members ("users") to access
work-related information and company resources. The App does not collect or store
personal data.</p>
<Divider/>
<div className='row'>
<div className='col-md-6 privacy-intro2'>
<div class="ten">

<h3>1. Information Accessed Through the App</h3>
</div>

<p>The App is used by staff members to access the following types of information:</p>


<li><b>Shift Schedules:</b> View scheduled shifts, working hours, and related details.</li>


<li><b>Employee Records::</b> Access personal employment details, such as job title,
department, and work assignments.</li>



<li><b>Internal Communication:</b> Receive announcements and updates from
management.</li>



<li><b>Company Forms:</b> Access standard company forms, such as leave requests or
expense forms.</li>


<li><b>Company Information:</b> View the SHL Employee Handbook and other company
policies and guidelines.</li>

<Divider/>
<div class="ten">
<h3>2. Data Usage</h3>
</div>
<p>The data accessed through the App is used for:</p>

<li><b>Shift Management:</b> Allowing staff to view their work schedules.</li>


<li><b>Internal Communication:</b> View the SHL Employee Handbook and other company
policies and guidelines.</li>


<li><b>Employee Resources:</b> Providing access to forms, company policies, and essential
documents, such as the SHL Employee Handbook.</li>
</div>
<div className='col-md-6' style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
    <div className='privacy-image-container2'>
<img alt='img' src={sideImage}/>
    </div>
</div>
</div>



<Divider/>
<div class="ten">
<h3>3. Data Security</h3>
</div>

<p>The App does not collect or store any personal data. However, it provides secure access to
information stored on our internal systems. We have implemented secure access protocols to ensure that only authorized users can access the App and the information it
provides.</p>
<Divider/>
<div class="ten">
<h3>4. Third-Party Access</h3>
</div>
<p>No third-party services are involved in the operation of the App. The information accessed
through the App remains within our internal systems and is not shared with any external
parties.</p>


<Divider/>
<div class="ten">
<h3>5. Your Rights</h3>
</div>

<p>As a user, you have the right to:</p>


<li><b>Access Information:</b> View the information available through the App.</li>


<li><b>Report Issues:</b> Notify us of any issues or inaccuracies in the information presented
through the App.</li>

<Divider/>
<div class="ten">
<h3>6. Changes to This Privacy Policy</h3>
</div>

<p>We may update this Privacy Policy from time to time to reflect changes in our practices or
legal requirements. Any significant updates will be communicated within the App, and the
"Effective Date" above will be revised accordingly.</p>
<Divider/>
<div class="ten">
<h3>7. Contact Us</h3>
</div>

<p>If you have any questions or concerns regarding this Privacy Policy or the App, please
contact us at:</p>
<Divider/>

<div className='privacy-footer'>
<p>SHL Film and TV</p>
<p>Email: Parker@shl.london</p>
    
</div>

       </div>
    </div>
    </>
  )
}

export default PrivacyPolicy
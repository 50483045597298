import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import UserManagment from "./pages/UserManagment/UserManagment";
import UsersList from "./pages/UsersList/UsersList";
import Roles from "./pages/Roles/Roles";
import Permissions from "./pages/Permissions/Permissions";
import Holiday from "./pages/Holiday/Holiday.js";
import { HolidayTypes } from "./pages/HolidayTypes/HolidayTypes";
import ViewHolidays from "./pages/Holiday/ViewHolidays";
import Settings from "./pages/Settings/Settings";
import AllHolidays from "./pages/AllHolidays/AllHolidays";
import Equipments from "./pages/Equipments/Equipments";
import Orders from "./pages/Orders/Orders";
import RotaGroup from "./pages/RotaGroup/RotaGroup";
import EquipmentDetails from "./pages/EquipmentDetails/EquipmentDetails";
import RotaCalendar from "./pages/RotaCalendar/RotaCalendar";
import OrderDetail from "./pages/OrderDetail/OrderDetail";
import ClockInOut from "./pages/ClockInOut/ClockInOut";
import ClockDetails from "./pages/ClockInOut/ClockDetails";
import StaffMatrix from "./pages/StaffMatrix/StaffMatrix";
import ViewMatrix from "./pages/StaffMatrix/ViewMatrix";
import Dvla from "./pages/Dvla/Dvla";
import EmailVerify from "./pages/EmailVerify/EmailVerify";
import { Announcement } from "./pages/Announcement/Announcement";
import StaffArea from "./pages/StaffArea/StaffArea";
import ViewArea from "./pages/ViewArea/ViewArea";
import HolidayGroup from "./pages/HolidayGroup/HolidayGroup";
import ViewHolidayGroup from "./pages/ViewHolidayGroup/ViewHolidayGroup";
import GroupHolidays from "./pages/GroupHolidays/GroupHolidays";
import LeaveUsers from "./pages/LeaveUsers/LeaveUsers";
import { PremissionChecker, PremissionChecker2 } from "../services/PremissionChecker";
import { useSelector } from "react-redux";
import AllPendingLeaves from "./pages/AllPendingLeaves/AllPendingLeaves";
import MyDashboard from "./pages/MyDashboard/MyDashboard";
import Departments from "./pages/Departments/Departments";
import Feed from "./pages/Feed/Feed";
import FeedView from "./pages/Feedview/View";
import ViewDvla from "./pages/Dvla/ViewDvla.js";
import ApplyLeave from "./pages/ApplyLeave/ApplyLeave.js";
import ViewHierarchy from "./pages/Roles/ViewHierarchy";
import FlowChart from "./pages/Roles/FlowChart.js";


const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {



  const user = useSelector(state => state.user.user)


  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  const CurrentUser = JSON.parse(localStorage.getItem("userDetails"));



  console.log(user,"this")
  
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={MyDashboard} />
        <ContentRoute role={'admin'} path="/builder" component={BuilderPage} />
        <ContentRoute role={[PremissionChecker(user,'user.list') ? user.role[0].name : '']} path="/users" component={UsersList} />
        {/* <ContentRoute role={[PremissionChecker(user,'role.list') ? user.role[0].name : '']} path="/roles" component={Roles} /> */}
        <ContentRoute role={[PremissionChecker(user,'role.list') ? user.role[0].name : '']} path="/view-hierarchy" component={FlowChart} />
        <ContentRoute role={[PremissionChecker(user,'role.permissions_attach') ? user.role[0].name : '']} path="/permissions/:id" component={Permissions} />
        <ContentRoute role={[PremissionChecker(user,"leave_application.list") ? user.role[0].name : '']} path="/leaveholidays" component={Holiday} />
        <ContentRoute role={[PremissionChecker(user,"leave_application.list") ? user.role[0].name : '']}  path="/view-holiday/:id" component={ViewHolidays} />
        <ContentRoute role={'admin'} path="/allHolidays" component={AllHolidays} />
        <ContentRoute role={'admin'} path="/equipments" component={Equipments} />
        <ContentRoute role={'admin'} path="/manage-orders" component={Orders} />
        <ContentRoute role={[PremissionChecker(user,'rota.list') ? user.role[0].name : '']} path="/rota-groups" component={RotaGroup} />
        <ContentRoute role={[PremissionChecker(user,'holiday_group.list') || PremissionChecker(user,'leave_calendar.show') || PremissionChecker(user,'leave_application.list') ? user.role[0].name : '']}  path="/holidaygroups" component={HolidayGroup} />
        <ContentRoute role={[PremissionChecker(user,'dvla.list') ? user.role[0].name : '']} path="/dvla" component={Dvla} />
        <ContentRoute role={[PremissionChecker(user,'dvla.endorsements') ? user.role[0].name : '']} path="/view-dvla" component={ViewDvla} />
        <ContentRoute role={[PremissionChecker(user,'leave_application.apply_leave_application') ? user.role[0].name : '']} path="/apply-user-leaves/:id" component={ApplyLeave} />
        <ContentRoute role={'admin'} path="/In-Out" component={ClockInOut} />
        <ContentRoute role={'admin'} path="/In-Out-details/:id/:name" component={ClockDetails} />
        <ContentRoute role={'admin'} path="/view-equipment/:id" component={EquipmentDetails} />
        <ContentRoute role={'admin'} path="/view-order/:id" component={OrderDetail} />
        <ContentRoute role={[PremissionChecker(user,'rota.calendar') ? user.role[0].name : '']} path="/rota-calendar/:id" component={RotaCalendar} />
        <ContentRoute role={'admin'} path="/staff-matrix" component={StaffMatrix} />
        <ContentRoute role={'admin'} path="/view-matrix" component={ViewMatrix} />
        <ContentRoute role={[PremissionChecker(user,'announcement.list') || PremissionChecker(user,'feed.list')  ? user.role[0].name : '']} path="/announcement" component={Announcement} />
        <ContentRoute role={'admin'} path="/allpendingleaves" component={AllPendingLeaves} />



        <ContentRoute role={[PremissionChecker(user,'staff_area.list') || PremissionChecker(user,'department.list') ? user.role[0].name : '']} path="/staff-area" component={StaffArea} />
        <ContentRoute role={[PremissionChecker(user,'staff_area.list') ? user.role[0].name : '']}  path="/view-area/:id/:groupname" component={ViewArea} />
        
        
        <ContentRoute role={[PremissionChecker(user,'leave_calendar.show') ? user.role[0].name : '']} path="/group/:id/:groupname" component={ViewHolidayGroup} />
        
        {/* <ContentRoute role={[PremissionChecker(user,'department.list') ? user.role[0].name : '']} path="/department" component={Departments} /> */}
        {/* <ContentRoute role={[PremissionChecker(user,'feed.list') ? user.role[0].name : '']} path="/feed" component={Feed} /> */}
        <ContentRoute role={[user.role[0].name]} path="/feed-view" component={FeedView} />


        <ContentRoute role={[PremissionChecker(user,'leaver.list') ? user.role[0].name : '']} path="/leave-users" component={LeaveUsers} />
        <Route path="/email-verify/:id/:hash" component={EmailVerify} />
        <Route path="/user-managment" component={UserManagment} />
        <ContentRoute role={[PremissionChecker(user,'leave_type.list') ? user.role[0].name : '']} path="/holidaytypes" component={HolidayTypes} />
        <Route path="/leave-settings" component={Settings} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,

} from "react-bootstrap-table2-paginator";
import './styles.css'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../../app/modules/ECommerce/pages/customers/CustomersUIHelpers";
import { Pagination } from '../../../../src/_metronic/_partials/controls'
import { Modal, Spinner } from "react-bootstrap";
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { DatePicker } from 'antd'
import { useCustomersUIContext } from "../../modules/ECommerce/pages/customers/CustomersUIContext";
import { baseUrl } from "../../../services/config";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { getSelectRow } from "../../../_metronic/_helpers";
import GroupingTable from "../Orders/GroupingTable/GroupingTable";
import UpdateLeaveStatus from "../../components/UpdateLeaveStatus/UpdateLeaveStatus";
import { useSelector } from "react-redux";



export default function Holiday() {

  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };

  const authtoken = localStorage.getItem("userToken");
  const { RangePicker } = DatePicker

  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [addModal, setaddModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [actionsLoading, setactionsLoading] = useState(false);
  const [holiday, setHolidays] = useState([])
  const [loading, setloading] = useState(false)
  const [editItem, setEditItem] = useState({})
  const customersUIContext = useCustomersUIContext();
  const [SelectedId, setSelectedId] = useState('')
  const [usernameSearch, setusernameSearch] = useState('')
  const [emailsearch, setemailsearch] = useState('')
  const [status_name, setstatus_name] = useState('')
  const [selectedObj, setSelectedObj] = useState({})
  const [ids, setIds] = useState([])
  const [updateModal, setUpdateModal] = useState(false)

  const user = useSelector(state => state.user.user)


  useEffect(() => {
    getallLeaves()
  }, [])

  const [status, setStatus] = useState([])

  useEffect(() => {
    getRoles()
  }, [])



  const getRoles = () => {
    fetch(`${baseUrl}/asset/leave-application-statuses`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authtoken}`,
      }
    }).then(res => res.json())
      .then((data) => {
        // let arr = []

        // data.payload.forEach(element => {
        //     arr.push({
        //         id: element.id,
        //         label: element.name,
        //         value: element.name
        //     })
        // });
        setStatus(data.payload)
      }).catch(err => {
        console.log(err)
      })
  }
  const history = useHistory()

  const getallLeaves = () => {
    setloading(true)
    fetch(`${baseUrl}/mgmt/leave-applications`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false)
        console.log("brands List", data.payload);
        setHolidays(data.payload)
      })
      .catch((err) => {
        setloading(false)
        console.log(err);
      });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      // sortCaret: sortCaret,


    },
    {
      dataField: "username",
      text: "Username",
      sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "first_name",
      text: "Firstname",
      sort: true,
      // sortCaret: sortCaret,

    },
    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },
    // {
    //   dataField: "employment_start_date",
    //   text: "Employment Start Date",
    //   sort: true,
    // },
    // {
    //   dataField: "leave_applications",
    //   text: "Holiday Start Date",
    //   sort: true,

    // },
    // {
    //   dataField: "leave_applications",
    //   text: "Reason",
    //   sort: true,
    //   formatter: (cell) => {
    //     return <>{cell.map(label => <li>{label.note}</li>)}</>
    //   },

    // },
    // {
    //   dataField: "leave_applications",
    //   text: "Status",
    //   sort: true,
    //   formatter: (cell) => {
    //     return <>{cell.map(label => <li>{label.status}</li>)}</>
    //   },
    // },


    // {
    //   dataField: "approved",
    //   text: "Approve",
    //   formatter: columnFormatters.ApprovedFormatter,
    //   formatExtraData: {

    //     openDeleteCustomerDialog: (id) => {

    //       setdeleteModal(true)
    //       setSelectedId(id)
    //     },
    //   },
    //   classes: "text-center pr-0",
    //   headerClasses: "text-center pr-3",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
    // {
    //     dataField: "reject",
    //     text: "Reject",
    //     formatter: columnFormatters.RejectFormatter,
    //     formatExtraData: {
    //       openDeleteCustomerDialog: (id) => {

    //         setdeleteModal(true)
    //         setSelectedId(id)
    //       },
    //     },
    //     classes: "text-center pr-4",

    //   },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.TriActions,
      formatExtraData: {
        user:user,
        openViewCustomerDialog: (item) => HandleView(item),
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];


  const openEdit = (item) => {
    setEditItem(item)
    seteditModal(true)
    setaddModal(true)
  }
  const HandleView = (item) => {
    console.log("clicked", item)
    history.push(`/view-holiday/${item.id}`, {
      state: { Obj: item }
    })
  }

 



  const saveRole = values => {
    setactionsLoading(true)
    console.log(values.employment_start_date.getDate())
    let d = values.employment_start_date
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2)
    var curr_date = ("0" + (d.getDate())).slice(-2)
    let ddd = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`


    const fdata = {
      username: values.username,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      contact: values.contact,
      address: values.address,
      emergency_contact: values.emergency_contact,
      employment_start_date: ddd,
      role_name: values.role_name
    }


    console.log(fdata)

    // console.log(values)
    let url = editModal ? `admin/users/${selectedObj.id}?_method=PUT` : 'admin/users'

    fetch(`${baseUrl}/${url}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(fdata)
    }).then(res => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setaddModal(false)
          toast.success(data.metadata.message)
          setactionsLoading(false)
          getallLeaves()
        } else if (!data.metadata.success) {

          Object.values(data.metadata.message).map(msg => {
            toast.error(msg)
          })
          setactionsLoading(false)

          // if (Array.isArray(data.metadata.message)) {
          //   Object.values(data.metadata.message).map(msg => {
          //     toast.error(msg)
          //   })
          // } else {
          //   toast.error(data.metadata.message)
          // }


        }

      }).catch(err => {
        console.log(err);
        setactionsLoading(false)

      })
  }




 
  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };

  const updatingQueryParams = (page) => {
    console.log(page);
    console.log("this is chus2");
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  const init_values = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    contact: '',
    address: '',
    emergency_contact: '',
    employment_start_date: '',
    role_name: ''
  }


  console.log(holiday);



  const dataSearch = holiday.filter((item) => {
  console.log(item)
    let text1 = usernameSearch.toLowerCase()
    const topicSearchData = text1 ? item?.username?.toLowerCase().includes(text1) : true;
    let text2 = emailsearch.toLowerCase()
    const appellantSearchData = text2 ? item?.email?.toLowerCase().includes(text2) : true;
    let text3 = status_name.toLowerCase()
    for (let i = 0; i < item?.leave_applications.length; i++) {
      var sectionSearchData = text3 ? item?.leave_applications[i].status?.toLowerCase().includes(text3) : true;
      
    }

    return topicSearchData && appellantSearchData && sectionSearchData
  });


  const dateSearch = (value) => {
    if (value != null) {
      const startDate = value[0].$d
      const endDate = value[1].$d
      var curr_moth = ("0" + (startDate.getMonth() + 1)).slice(-2)
      var curr_date = ("0" + (startDate.getDate())).slice(-2)
      let newStartdate = `${startDate.getFullYear()}-${curr_moth}-${curr_date}`



      var moth = ("0" + (endDate.getMonth() + 1)).slice(-2)
      var date = ("0" + (endDate.getDate())).slice(-2)
      let newendDate = `${endDate.getFullYear()}-${moth}-${date}`


      fetch(`${baseUrl}/mgmt/applied-leave-users/start-date/${newStartdate}/end-date/${newendDate}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }).then((res) => res.json().then((res) => {
        console.log(res)
        setHolidays(res.payload)
      }))
    } else {
      getallLeaves()
    }
  }
  console.log(dataSearch?.length,"this is")
  const options = {
    custom: true,
    totalSize: dataSearch?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Card>

        <>

          <CardHeader title="Holiday Planner">
            <CardHeaderToolbar>
              {/* <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  seteditModal(false)
                  setaddModal(true)
                }}
              >
                New User
              </button> */}
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <div className="form-group row">
              <div className="col-lg-3">
                <small className="form-text text-muted">
                  <b>Search</b> in username
                </small>
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search by username"
                  value={usernameSearch}
                  onChange={(e) => {
                    setusernameSearch(e.target.value);
                  }}
                />

              </div>
              {/* <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search by email"
                  value={emailsearch}
                  onChange={(e) => {
                    setemailsearch(e.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in email
                </small>
              </div> */}
              <div className="col-lg-3">
                <small className="form-text text-muted">
                  <b>Filter</b> by Type
                </small>
                <select
                  className="form-control"
                  placeholder="Filter by Role"
                  name="type"
                  onChange={(e) => {
                    setstatus_name(e.target.value);
                  }}
                  value={status_name}
                >
                  <option value="">All</option>
                  {
                    status.map(statu => (
                      <option key={statu.value} value={statu.name}>{statu.name}</option>
                    ))
                  }
                </select>

              </div>

              <div className="col-lg-3">
                <small className="form-text text-muted">
                  <b>Filter</b> by Start Date and End Date
                </small>
                <RangePicker
                  onChange={(values) => { dateSearch(values) }}
                />
              </div>

            </div>


            {/* <CustomersFilter />
          {customersUIProps.ids.length > 0 && <CustomersGrouping />}
            <CustomersTable /> */}
            {ids.length > 0 &&
              <GroupingTable
                isDel={false}
                list={ids}
                onUpdateStatusDialog={() => setUpdateModal(true)}
              // openDeleteDialog={() => alert('workd')}
              />}


            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <>
                    <Pagination
                      updatingQueryParams={updatingQueryParams}
                      onPaginationSelectChange={onPaginationSelectChange}
                      isLoading={loading}
                      paginationProps={paginationProps}
                    >
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        keyField="id"
                        data={dataSearch}
                        columns={columns}
                        defaultSorted={uiHelpers.defaultSorted}
                        {...paginationTableProps}
                      // filter={filterFactory()}
                      // selectRow={getSelectRow({
                      //   entities: dataSearch,
                      //   ids,
                      //   setIds,
                      // })}
                      ></BootstrapTable>
                    </Pagination>
                    {/* )} */}
                  </>
                );
              }}
            </PaginationProvider>
          </CardBody>
        </>
      </Card>

      <UpdateLeaveStatus
        show={updateModal}
        onHide={() => setUpdateModal(false)}
        list={dataSearch}
        ids={ids}

      />

    </>

  );
}



import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import UserReducer from "./reducers/UserReducer";
import RoleReducer from "./reducers/RoleReducer";
import PermissionsReducer from "./reducers/PermissionsReducer";
import ButtonReducer from "./reducers/ButtonReducer";
import RotaReducer from "./reducers/RotaReducer";
import HolidayReducer from "./reducers/HolidayReducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  user: UserReducer,
  roles: RoleReducer,
  permissions: PermissionsReducer,
  showButton: ButtonReducer,
  holidayGroups: HolidayReducer,
  rotaGroups: RotaReducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}

import React from 'react'

const GroupingTable = ({
    list,
    onUpdateStatusDialog,
    openDeleteDialog,
    isDel

}) => {
    return (
        <div className="form">
            <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
                <div className="col-xl-12">
                    <div className="form-group form-group-inline">
                        <div className="form-label form-label-no-wrap">
                            <label className="font-bold font-danger">
                                <span>
                                    Selected records count:
                                    <b>
                                        {list.length}
                                    </b>
                                </span>
                            </label>
                        </div>
                        <div>
                            {
                                isDel ?

                                    <button
                                        type="button"
                                        className="btn btn-danger font-weight-bolder font-size-sm"
                                        onClick={openDeleteDialog}
                                    >
                                        <i className="fa fa-trash"></i> Delete All
                                    </button>
                                    : null
                            }
                            &nbsp;
                            {/* <button
                                type="button"
                                className="btn btn-light-primary font-weight-bolder font-size-sm"
                            onClick={customersUIProps.openFetchCustomersDialog}
                            >
                                <i className="fa fa-stream"></i> Fetch Selected
                            </button> */}
                            &nbsp;


                            <button
                                type="button"
                                className="btn btn-light-primary font-weight-bolder font-size-sm"
                                onClick={onUpdateStatusDialog}
                            >
                                <i className="fa fa-sync-alt"></i> Update Status
                            </button>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GroupingTable
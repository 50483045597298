import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import "./styles.css";
import * as Yup from "yup";
import {
  Input,
  DatePickerField,
  AntDatePicker,
} from "../../../_metronic/_partials/controls";
import { baseUrl } from "../../../services/config";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import { PremissionChecker } from "../../../services/PremissionChecker";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import toast from "react-hot-toast";
import { Image } from "antd";

//regx

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#187DE4d" : "white",
      color: "#000000",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const colourStylesInvalid = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    borderColor: "red",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#187DE4d" : "white",
      color: "#000000",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

export default function AddUser({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  onChangeRole,
  editItem,
  setStatus,
  status,
  isEdit,
  pattrenOpner,
}) {
  console.log(editItem);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const addinitialValues = {
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    address: "",
    employment_start_date: "",
    emergency_contact: "",
    role_name: "",
    emergency_contact_name: "",
    date_of_birth: "",
    town: "",
    postal_code: "",
    country: "",
    driving_licence_number: "",
    enquiring_companies: "",
    request_purpose: "",
    fair_processing_declaration: "",
    dvla_consent_start_date: "",
    dvla_consent_end_date: "",
    password: "",
    nin: "",

    // status: status ? 1 : 0
  };
  const editinitialValues = {
    username: editItem.username,
    first_name: editItem.first_name,
    last_name: editItem.last_name,
    email: editItem.email,
    contact: editItem.contact,
    address: editItem.address,
    employment_start_date: editItem.employment_start_date,
    date_of_birth: editItem?.dob,
    emergency_contact: editItem.emergency_contact,
    emergency_contact_name: editItem.emergency_contact_name,
    role_name: editItem ? editItem?.role[0]?.id : "",
    town: editItem.town,
    postal_code: editItem.postal_code,
    country: editItem.country,
    driving_licence_number: editItem.driving_licence_number,
    enquiring_companies: editItem.enquiring_companies,
    request_purpose: editItem.request_purpose,
    fair_processing_declaration: editItem.fair_processing_declaration,
    dvla_consent_start_date: editItem.dvla_consent_start_date,
    dvla_consent_end_date: editItem.dvla_consent_end_date,
    nin: editItem.nin,
    // status: status ? 1 : 0
  };

  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);

  const [roles, setRoles] = useState([]);
  // Validation schema
  const CustomerEditSchema = Yup.object().shape({
    username: Yup.string().required("User Name is required"),
    first_name: Yup.string()
      .required("First Name is required")
      .matches(/^[a-zA-Z]+$/, "Only Alphabet Allow"),
    last_name: Yup.string()
      .required("Last Name is required")
      .matches(/^[a-zA-Z]+$/, "Only Alphabet Allow"),
    email: Yup.string()
      .matches(emailRegex, "Must be correct Email")
      .email("Email Address must be correct")
      .required("Email is required"),
    contact: Yup.string().required("Contact number is required"),
    address: Yup.string().required("Address is required"),
    employment_start_date: Yup.date().required("Start date is required"),
    date_of_birth: Yup.date().required("Date of birth is required"),
    emergency_contact: Yup.string().required(
      "Emergency Contact number is required"
    ),
    password: editItem
      ? Yup.string()
          .notRequired("Password is required")
          .nullable()
      : Yup.string().required("Password is required"),
    nin: Yup.string()
      .notRequired("Nin is required")
      .nullable(),
    role_name: Yup.string().required("Role is required"),
    emergency_contact_name: Yup.string().required(
      "Emergency Contact Name is required"
    ),
  });
  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = () => {
    fetch(`${baseUrl}/mgmt/roles`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let arr = [];

        data.payload.forEach((element) => {
          arr.push({
            id: element.id,
            label: element.name,
            value: element.id,
          });
        });
        setRoles(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function generateUsername(name) {
    // Remove any spaces and convert name to lowercase
    const username = name.replace(/\s+/g, "").toLowerCase();
    // Generate random number between 100 and 999 (for 3 digits)
    // or between 10 and 99 (for 2 digits)
    const randomNumber = Math.floor(Math.random() * 900 + 100);
    // Combine username and random number to form final username
    const finalUsername = username + randomNumber.toString();
    return finalUsername;
  }

  // let title =['title','title','title']
  // let title =['sdess','title','title']

  function generateRandomPassword() {
    const length = 8; // Length of the password (excluding "shl")
    const chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+[{]}\\|;:'\",<.>/?"; // Available characters
    let password = "shl"; // Start with "shl"

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }

    return password;
  }

  const sendEmai = () => {
    setSendEmailLoading(true);
    fetch(`${baseUrl}/mgmt/resend-verification-email/${editItem.email}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSendEmailLoading(false);
        if (data.metadata) {
          if (data.metadata.success) {
            toast.success(data.metadata.message);
          } else {
            if (toString.call(data.metadata.message) === "[object Object]") {
              const errorMessageArray = Object.values(
                data.metadata.message
              ).flatMap((messages) => messages);
              errorMessageArray.forEach((message) => toast.error(message));
            } else if (
              toString.call(data.metadata.message) === "[object Array]"
            ) {
              Object.values(data.metadata.message).map((msg) => {
                toast.error(msg);
              });
            } else {
              toast.error(data.metadata.message);
            }
          }
        } else {
          toast.error("Internal server error");
        }
      })
      .catch((err) => {
        setSendEmailLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={editItem != "" ? editinitialValues : addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          // console.log(values)
          saveCustomer(values);
        }}
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          handleBlur,
          values,
          errors,
          touched,
          setFieldError,
          setFieldTouched,
          handleChange,
          setValues,
        }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form onSubmit={handleSubmit} className="form form-label-right">
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-6 col-md-6 col-sm-6 add_user_input">
                    <label>
                      {" "}
                      User Name <span className="text-danger">*</span>
                    </label>
                    <Field
                      value={values.username}
                      name="username"
                      component={Input}
                      placeholder="Enter User Name"
                      maxLength={120}
                      type={"text"}
                    />
                  </div>
                </div>
                <div className="dividerContainer">
                  <div className="addUserDivider"></div>
                </div>
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-6 col-md-6 col-sm-6 add_user_input">
                    <label>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <Field
                      value={values.first_name}
                      name="first_name"
                      component={Input}
                      // onChange={(e) => {
                      //   setValues('first_name', e.target.value)
                      //   console.log(e.target.value)
                      // }
                      // }
                      placeholder="Enter First Name"
                      maxLength={120}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 add_user_input">
                    <label>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <Field
                      value={values.last_name}
                      name="last_name"
                      component={Input}
                      onChange={(e) => {
                        setFieldValue("last_name", e.target.value);
                        setFieldValue(
                          "username",
                          generateUsername(
                            `${values.first_name} ${e.target.value}`
                          )
                        );
                        setFieldValue(
                          "password",
                          `shl-${values.first_name.toLowerCase()}${e.target.value.toLowerCase()}`
                        );
                      }}
                      placeholder="Enter Last Name"
                      maxLength={120}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Date of Birth <span className="text-danger">*</span>
                    </label>
                    <div>
                      <AntDatePicker
                        placeholderText="Select Date Of Birth"
                        name="date_of_birth"
                        label="Date of Birth"
                        className="userDatePicker"
                      />

                      {/* 
                        <KeyboardDatePicker
                          disableToolbar
                          variant="dialog"
                          format="yyyy/MM/dd"
                          margin="normal"
                          id="date-picker-inline"
                          name="date_of_birth"
                          label="Date of birth"
                          onBlur={handleBlur}
                          defaultValue={new Date()}
                          value={values.date_of_birth}
                          onChange={(date) => {
                            const d = moment(date).format('YYYY-MM-DD')
                            setFieldValue('date_of_birth', d)
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        {errors.date_of_birth && touched.date_of_birth && (
                          <span style={{ color: "red", marginTop: ".5rem" }}>
                            {errors.date_of_birth}
                          </span>
                        )} */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 add_user_input">
                    <label>
                      {" "}
                      Email Address <span className="text-danger">*</span>
                    </label>
                    <Field
                      value={values.email}
                      name="email"
                      component={Input}
                      placeholder="Enter Email Address"
                      maxLength={120}
                    />
                  </div>
                </div>

                <div className="dividerContainer">
                  <div className="addUserDivider"></div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6 col-md-6 col-sm-6 add_user_input">
                    <label>
                      Street Address <span className="text-danger">*</span>
                    </label>
                    <Field
                      value={values.address}
                      name="address"
                      component={Input}
                      placeholder="Enter Street Address"
                      // maxLength={120}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 add_user_input">
                    <label>Postal Code</label>
                    <Field
                      value={values.postal_code}
                      name="postal_code"
                      component={Input}
                      placeholder="Enter Postel Code"
                    />
                  </div>
                </div>
                <div className="dividerContainer">
                  <div className="addUserDivider"></div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6 col-md-6 col-sm-6 add_user_input">
                    <label>Town</label>
                    <Field
                      value={values.town}
                      name="town"
                      component={Input}
                      placeholder="Enter Town"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 add_user_input">
                    <label>
                      Contact number <span className="text-danger">*</span>
                    </label>
                    <Field
                      value={values.contact}
                      name="contact"
                      component={Input}
                      type="text"
                      placeholder="Enter Contact number"
                      // maxLength={120}
                    />
                  </div>
                </div>
                <div className="dividerContainer">
                  <div className="addUserDivider"></div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6 col-md-6 col-sm-6 add_user_input">
                    <label>
                      {" "}
                      Emergency Contact Name{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Field
                      value={values.emergency_contact_name}
                      name="emergency_contact_name"
                      component={Input}
                      placeholder="Enter Emergency Contact Name"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 add_user_input">
                    <label>
                      Emergency Contact Number{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Field
                      value={values.emergency_contact}
                      name="emergency_contact"
                      component={Input}
                      type="text"
                      placeholder="Enter Emergency Contact number"
                      // maxLength={120}
                    />
                  </div>
                  {/* {
                    !isEdit &&
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <label>Password</label>
                      <Field
                        value={values.password}
                        readOnly
                        name="password"
                        component={Input}
                  
                        placeholder="Enter Password"
                      />
                    </div>

                  } */}
                </div>
                <div className="dividerContainer">
                  <div className="addUserDivider"></div>
                </div>
                {/* <div className="form-group row">

                </div> */}
                <div className="form-group row">
                  {/* First Name */}

                  <div className="col-lg-6 col-md-6 col-sm-6 ">
                    <label>
                      Employment Start Date{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div>
                      {/* <DatePickerField
                        placeholderText="Select Start Date"
                        name="employment_start_date"
                        label="Date of Birth"
                      /> */}
                      <AntDatePicker
                        placeholderText="Select Date Of Birth"
                        name="employment_start_date"
                        label="Employment start Date"
                        className="userDatePicker"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label>
                      Role <span className="text-danger">*</span>
                    </label>
                    {/* old select */}
                    {/* <select
                                            onChange={(e) => onChangeRole(e.target.value)}
                                            className="form-control"
                                            as="select"
                                            name={'role_name'}
                                        >
                                            <option value={''}>{'Select Role'}</option>

                                            {roles.map((rol) => (
                                                <option key={rol.id} value={rol.name}>{rol.name}</option>
                                            ))}
                                        </select> */}

                    <Select
                      defaultValue={
                        editItem
                          ? {
                              label: editItem.role[0]?.name,
                              value: editItem.role[0]?.id,
                            }
                          : null
                      }
                      name="role_name"
                      options={roles}
                      className="react-select-container addUserSlelct"
                      classNamePrefix="react-select"
                      styles={
                        errors.role_name && touched.role_name
                          ? colourStylesInvalid
                          : colourStyles
                      }
                      onChange={(option) =>
                        setFieldValue("role_name", option.value)
                      } // <=== How to get setFieldValue?
                      onBlur={handleBlur}
                      values={values.role_name}
                    />
                    {errors.role_name && touched.role_name && (
                      <span style={{ color: "red", marginTop: ".5rem" }}>
                        {errors.role_name}
                      </span>
                    )}
                    {/* {errors.role_name && (
                      <div className="text-danger feedback">
                        {errors.role_name}
                      </div>
                    ) } */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 add_user_input">
                    <label>NIN</label>
                    <Field
                      value={values.nin}
                      name="nin"
                      component={Input}
                      type="text"
                      placeholder="Enter NIN"
                      // maxLength={120}
                    />
                  </div>
                  {/* <div className="col-lg-3 col-md-3 col-sm-3">
                      <label>Dvla Check</label>
                      <div className="mt-2">
                        <Switch defaultChecked={editItem?.ttc_check} onChange={(e) => setStatus(e)} />


                      </div>
                    </div> */}
                </div>
                {editItem && editItem.image != null && (
                  <>
                    <div className="addUserDivider mt-7"></div>
                    <div className="row">
                      <div className="col-md-12" style={{ marginTop: "45px" }}>
                        <div>
                          <label>
                            Profile Image
                          </label>
                        </div>
                        <Image
                            width={200}
                          src={editItem?.image?.url}
                          alt="img"
                        />
                      </div>
                    </div>
                  </>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {/* {PremissionChecker(user, "work_pattern.list") ? ( */}
              <div
                className="col-lg-8"
                style={{ position: "absolute", left: "0px" }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={pattrenOpner}
                >
                  Add Work Pattern
                </button>
                {editItem != "" && !editItem.email_verified ? (
                  sendEmailLoading ? (
                    <Spinner
                      style={{ marginLeft: "40px" }}
                      animation="border"
                      color="green"
                    />
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary mx-2"
                      onClick={sendEmai}
                    >
                      Resend Verification Email
                    </button>
                  )
                ) : (
                  ""
                )}
              </div>
              {/* // ) : null} */}

              {actionsLoading ? (
                <Spinner animation="border" />
              ) : (
                <>
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-danger"
                  >
                    Cancel
                  </button>
                  <> </>
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate"
                  >
                    Save
                  </button>
                </>
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

import React, { useCallback, useRef, useState } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  updateEdge,
  MarkerType,
} from 'reactflow';
import './flowChart.css'

import 'reactflow/dist/style.css';
import ColorSelectorNode from './ColorSelectorNode';
import { Divider } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl } from '../../../services/config';
import AddRole from './AddRole';
import toast from 'react-hot-toast';
import { Modal } from 'react-bootstrap';
import CustomEdge from './CustomEdge';
import ConnectionLine from './ConnectionLine.js';
import { PremissionChecker } from '../../../services/PremissionChecker.js';
const nodeTypes = {
  selectorNode: ColorSelectorNode,
};
const edgeTypes = {
  buttonedge: CustomEdge,
};
const initialNodes = [
  { id: '1', position: { x: 0, y: 0 }, data: { label: '1' },type: 'selectorNode' },
  { id: '2', position: { x: 0, y: 100 }, data: { label: '2' },type: 'selectorNode' },
  { id: '3', position: { x: 0, y: 200 }, data: { label: '3' },type: 'selectorNode', },
];
const initialEdges = [{ id: 'e1-15', source: '15', target: '18' }];
function FlowChart() {
  const user = useSelector((state) => state.user.user);

  const authtoken = localStorage.getItem("userToken");
  const {edges:reduxEdges,roles} = useSelector(state => state.roles)
  const {show} = useSelector(state => state.showButton)
  const [nodes, setNodes, onNodesChange] = useNodesState(roles);
  const [edges, setEdges, onEdgesChange] = useEdgesState(reduxEdges);
  const edgeUpdateSuccessful = useRef(true);
  const [addModal, setaddModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editModal, seteditModal] = useState(false);
  const [positionUpdateLoading,setPositionUpdateLoading]=useState(false)
  const [showButton,setShowButton]=useState(false)

  // const onConnect = useCallback(
  //   (params) => setEdges((eds) => addEdge(params, eds)),
  //   [setEdges],
  // );

  useEffect(() => {
    let showButton = false;

    if (roles.length === nodes.length) {
      for (let i = 0; i < nodes.length; i++) {
        if (roles[i].position.x !== nodes[i].position.x || roles[i].position.y !== nodes[i].position.y) {
          showButton = true;
          break;
        }
      }
    }

    dispatch({
      type: "SHOW_BUTTON",
      payload: {
        show: showButton,
      },
    });

  }, [nodes, roles, dispatch]);
  
//   function arraysEqual(arr1, arr2) {
//     if (arr1.length !== arr2.length) {
//       dispatch({
//         type: "SHOW_BUTTON",
//         payload: {
//           show: true,
//         },
//       });
//         return false;
//     }

//     // Normalize the properties to strings
//     const normalizeArray = (array) => {
//         return array.map(obj => {
//             return {
//                 source: String(obj.source),
//                 target: String(obj.target)
//             };
//         }).sort((a, b) => {
//             const aStr = JSON.stringify(a);
//             const bStr = JSON.stringify(b);
//             return aStr.localeCompare(bStr);
//         });
//     };

//     const normalizedArr1 = normalizeArray(arr1);
//     const normalizedArr2 = normalizeArray(arr2);

//     for (let i = 0; i < normalizedArr1.length; i++) {
//         if (JSON.stringify(normalizedArr1[i]) !== JSON.stringify(normalizedArr2[i])) {
//           dispatch({
//             type: "SHOW_BUTTON",
//             payload: {
//               show: true,
//             },
//           });
//             return false;
//         }
//     }
//     dispatch({
//       type: "SHOW_BUTTON",
//       payload: {
//         show: false,
//       },
//     });
//     return true;
// }
  const onConnect = useCallback(
    (params) => {
      dispatch({
        type: "SHOW_BUTTON",
        payload: {
          show: true,
        },
      });
    
      
      setEdges((eds) => {
        // Add the edge
        const updatedEdges = addEdge(params, eds);
  
        // Add the new field to the last edge
        if (updatedEdges.length > 0) {
          const lastIndex = updatedEdges.length - 1;
          updatedEdges[lastIndex] = {
            ...updatedEdges[lastIndex],
            style: { strokeWidth: 2, stroke: '#1e1e2d' },
            type: 'buttonedge',
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: '#1e1e2d',
            }
          };
        }
        // arraysEqual(updatedEdges,reduxEdges)
        return updatedEdges;
      });
    },
    [setEdges]
  );
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);
useEffect(() => {
  setNodes(roles)
  setEdges(reduxEdges)
}, [roles])
useEffect(() => {
  getRoles()
}, [])


  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const dispatch = useDispatch();



 function getRandomLightColor() {
  // Generate random values for red, green, and blue in the range of 128-255
  const r = Math.floor(Math.random() * 128) + 128;
  const g = Math.floor(Math.random() * 128) + 128;
  const b = Math.floor(Math.random() * 128) + 128;
  
  // Convert the RGB values to a hexadecimal color code
  const color = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  
  return color;
}
  const getRoles = async () => {
    await fetch(`${baseUrl}/mgmt/roles-with-positions`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            dispatch({
              type: "SHOW_BUTTON",
              payload: {
                show: false,
              },
            });
            data.payload.forEach((item) => {
              item.id = item.id.toString();
              item.data.color = getRandomLightColor();
              item.data.id = item.id.toString();
              item.data.users.forEach((user) => {
                  user.color = Math.floor(Math.random() * 16777215).toString(16);
              });
          });
            getHierarchy(data.payload)
          }
        }
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };
  const getHierarchy = async (roles) => {
    await fetch(`${baseUrl}/mgmt/roles-hierarchy`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
             console.log(roles,"here is the roles")
             console.log(data.payload,"here is the Positioning")
             data.payload.forEach((item) => {
              item.id = item.id.toString();
              item.style = {strokeWidth:2, stroke: '#1e1e2d',}
              item.type = 'buttonedge'
              item.markerEnd= {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: '#1e1e2d',
              }
            });
             dispatch({
              type: "GET_ROLES",
              payload: {
                edges: data.payload,
                roles: roles,
              },
            });
          }
        }
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };


  
  const saveRole = (values) => {
    setError(null);
    setisLoading(true);
    let url = editModal
      ? `mgmt/roles/${selectedObj.id}?_method=PUT`
      : "mgmt/roles";

    fetch(`${baseUrl}/${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setaddModal(false);
          toast.success(data.metadata.message);
          getRoles();
          setisLoading(false);
        } else if (!data.metadata.success) {
          setError(Object.values(data.metadata.message));
          setisLoading(false);
          // Object.values(data.metadata.message).map(msg => {
          //     toast.error(msg)
          //     setisLoading(false)
          // })
        }
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
      });
  };

  const handleSave=()=>{
    setPositionUpdateLoading(true)
    let url = `mgmt/update-roles-positions`
   const data=[]
   nodes.map((item)=>{
    data.push({
      id:item.id,
      x:item.position.x,
      y:item.position.y
    })
   })
    fetch(`${baseUrl}/${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({positions:data}),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          dispatch({
            type: "SHOW_BUTTON",
            payload: {
              show: false,
            },
          });
          saveEdges()
          // toast.success("Positions Updated Successfully");
        }else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            if(data.metadata.message !=""){
              toast.error(data.metadata.message);
            }
          }
        } else {
          toast.error(`Something went wrong`);
        }
      })
      .catch((err) => {
        console.log(err);
    setPositionUpdateLoading(false)

      });
  }

  const saveEdges=()=>{
    let url = `mgmt/define-parent-children-roles`
   const data=[]
   edges.map((item)=>{
    data.push({
      source:item.source,
      target:item.target
    })
   })
    fetch(`${baseUrl}/${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({hierarchy:data}),
    })
      .then((res) => res.json())
      .then((data) => {
    setPositionUpdateLoading(false)
        if (data.metadata.success) {
          toast.success("Updated Successfully");
        }else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            if(data.metadata.message !=""){
              toast.error(data.metadata.message);
            }
          }
        } else {
          toast.error(`Something went wrong`);
        }
      })
      .catch((err) => {
        console.log(err);
    setPositionUpdateLoading(false)

      });
  }
  const getPermissions = () => {
    fetch(`${baseUrl}/asset/permissions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        data.payload.sort((a, b) => {
          const nameA = a.name.split(".")[0];
          const nameB = b.name.split(".")[0];
          return nameA.localeCompare(nameB);
        });
        convertToIndexArray(data.payload);
        console.log("i think so permission", data.payload);
        dispatch({
          type: "ALL_PERMISSIONS",
          payload: {
            allPermissions: data.payload,
          },
        });
  getRoles()

      })
      .catch((err) => {
      });
  };

  const convertToIndexArray = (array) => {
    const dummy = array.map((item, i) => {
      const newObj = new Object();
      newObj.index = i;
      newObj.name = item.name.split(".")[0];
      return newObj;
    });

    const uniqueArr = dummy.reduce((result, current) => {
      const existingObject = result.find((item) => item.name === current.name);
      if (!existingObject) {
        result.push(current);
      }
      return result;
    }, []);

  };
  console.log(nodes,"here it is")
  console.log(edges,"here it is")
  return (
    <>
   {/* <Signature/> */}
   {/* <Table/> */}
   {/* <Calandar/> */}
   {/* <Demo/> */}
   {/* <Simple/> */}
   {/* <Vin/> */}
   {/* <Pdf/> */}
   {/* <PPT/> */}
{/* <Excel/> */}
<div className='hierarchy-viewer'>
<div className='d-flex justify-content-between' style={{position:"relative"}}>
      <div>
      {/* <Link to={"/roles"}>
              <BsFillArrowLeftCircleFill
                style={{
                  top: "2px",
                  left: "35px",
                  position: "absolute",
                  fontSize: "2.5rem",
                  color: "black",
                  marginRight: "20px",
                  textDecoration: "none",
                }}
              />
            </Link> */}
      </div>
           <div>
           <h1>Roles Hierarchy</h1>
           </div>
           <div>
           {/* {PremissionChecker(user, "role.create") ? ( */}
         {show &&  <button
           disabled={positionUpdateLoading}
                onClick={() => {
                 handleSave()
                }}
                type="button"
                className="btn btn-primary mr-4"
              >
                Save Changes
              </button>}
             {PremissionChecker(user,"role.create") &&  <button
                onClick={() => {
                  seteditModal(false);
                  setaddModal(true);
                }}
                type="button"
                className="btn btn-primary mr-7"
              >
                New Role
              </button>}
           
            {/* // ) : null} */}
           </div>
          </div>
          <Divider/>
   {nodes.length>0 && <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      // onEdgeUpdate={onEdgeUpdate}
      // onEdgeUpdateStart={onEdgeUpdateStart}
      // onEdgeUpdateEnd={onEdgeUpdateEnd}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      // connectionLineComponent={ConnectionLine}
    >
        {/* <Controls /> */}
        {/* <MiniMap /> */}
        <Background variant="" gap={12} size={1} />
      </ReactFlow>
}
    </div>
   



    <Modal show={addModal}>
        <Modal.Header>{editModal ? "Edit Role" : "Add new Role"}</Modal.Header>
        <AddRole
          customer={{
            name: editModal ? selectedObj.name : "",
          }}
          isEdit={editModal ? true : false}
          saveCustomer={(values) => saveRole(values)}
          onHide={() => setaddModal(false)}
          actionsLoading={isLoading}
          errors={error}
        />
      </Modal>
    </>
  );
}

export default FlowChart;

import React from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { baseUrl } from '../../../services/config';
import { toast } from 'react-hot-toast';


const EquipmentImageModal = ({
    object,
    visible,
    hideModal,
    id,
    reloadfunc
}) => {


    const authtoken = localStorage.getItem("userToken");

    const [imgError, setImgError] = useState('')
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');


    const handleChange = (file) => {
        setImgError('')
        setImageUrl(file)
    }



    const uploadImage = () => {

        if (imageUrl == '') {
            setImgError('Please select Image')
        } else {
            setImgError('')
            setLoading(true)
            let formdata = new FormData()

            formdata.append('image', imageUrl)

            fetch(`${baseUrl}/mgmt/equipment/${id}?_method=PUT`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                },
                body: formdata
            }).then(res => res.json())
                .then((data) => {
                    if (data.metadata.success) {
                        console.log(data)
                        setLoading(false)
                        hideModal()
                        reloadfunc()
                        toast.success(data.metadata.message)
                    } else if (!data.metadata.success) {
                        Object.values(data.metadata.message).map((msg) => {
                            toast.error(msg)
                            setLoading(false)
                        })
                    } else {
                        toast.error(`Something went wrong :(`)
                        setLoading(false)

                    }
                }).catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        }

    }


    return (
        <Modal
            show={visible}
            centered
        >
            <Modal.Header>
                <Modal.Title>Upload Image to </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    imageUrl &&
                    <img height={100} width={100} src={URL.createObjectURL(imageUrl)} />
                }
                <>
                    <label
                        htmlFor='fileImage'
                        style={{
                            width: 130,
                            margin: 20,
                            padding: 10,
                            borderRadius: 5,
                            backgroundColor: '#E6E9EC',
                            cursor: 'pointer'
                        }}>
                        Select Image
                        {/* <img style={{ width: 100 }} src={logo} /> */}
                    </label>
                    <input
                        style={{ display: "none" }}
                        type="file"
                        id={`fileImage`}
                        // ref={fileRef}
                        accept="image/png, image/jpeg"
                        onChange={(e) => handleChange(e.target.files[0])}
                    />
                    {imgError && <span className='text-danger'>{imgError}</span>}

                </>
            </Modal.Body>
            <Modal.Footer>
                {
                    loading ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Spinner animation='border' />
                        </div>
                        :
                        <>
                            <button onClick={hideModal} className='btn btn-danger'>Cancel</button>
                            <button onClick={uploadImage} className='btn btn-primary'>Upload</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EquipmentImageModal
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { baseUrl } from '../../../services/config'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls'

const Settings = () => {


    const authtoken = localStorage.getItem("userToken");
    const [addModal, setaddModal] = useState(false)
    const [settingObj, setsettingObj] = useState({})
    const [leaves, setleaves] = useState('')
    const [loading, setloading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        getSettings()
    }, [])


    const getSettings = () => {
        fetch(`${baseUrl}/asset/setting`)
            .then(res => res.json())
            .then(data => {
                setsettingObj(data.payload)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const updateSettings = () => {
        if (leaves == '') {
            setError('Please enter Leaves')
        } else {


            setloading(true)
            const data = {
                allow_leaves: leaves
            }

            fetch(`${baseUrl}/mgmt/setting/update?_method=PUT`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authtoken}`,
                },
                body: JSON.stringify(data)
            })
                .then(res => res.json())
                .then(data => {
                    if (data.metadata.success) {
                        toast.success(data.metadata.message)
                        setaddModal(false)
                        getSettings()
                        setloading(false)
                        setleaves("")
                    }else{
                    toast.error(data.metadata.message)
                        setloading(false)
                    }
                    // setsettingObj(data.payload)
                })
                .catch(err => {
                    toast.success(err)
                    setloading(false)

                })
        }
    }

    const openSetting = () => {
        setaddModal(true)
    }

    return (
        <>
            <Card>
                <CardHeader title="Leave Settings">
                    <CardHeaderToolbar>
                        {/* <button
                            type="button"
                            className="btn btn-primary"

                        >
                            New Type
                        </button> */}
                    </CardHeaderToolbar>
                </CardHeader>

                <CardBody>
                    <table className='table'>
                        <tr>
                            <th>Leaves</th>
                            <th>Actions</th>
                        </tr>
                        <tr>
                            <td>{settingObj.allow_leaves}</td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={openSetting}

                                >
                                    Update
                                </button>
                            </td>
                        </tr>
                    </table>
                </CardBody>
            </Card>
            <Modal
                onHide={() => setaddModal(false)}
                show={addModal}>
                <Modal.Header>
                    <Modal.Title>Update Leaves</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <input
                        className='form-control'
                        placeholder={`Enter Leaves`}
                        defaultValue={settingObj.leaves}

                        onChange={(e) => {
                            setError('')
                            setleaves(e.target.value)
                        }
                        }
                    />
                    {error && <span className='text-danger'>{error}</span>}


                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ?
                            <Spinner color='green' animation='border' />
                            : <>

                                <button
                                    onClick={() => {
                                        setleaves('')
                                        setaddModal(false)}}
                                    className='btn btn-danger'>
                                    Cancel
                                </button>

                                <button
                                    onClick={() => updateSettings()}
                                    className='btn btn-primary'>
                                    Update
                                </button>

                            </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Settings

import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { Switch } from "antd";
import { PremissionChecker } from "../../../../../../../services/PremissionChecker";

export function toggleFormatter(
  cellContent,
  row,
  rowIndex,
  {onStatusChange,user }
) {
  return (
    <>
      {/* <a
        title="Attach Permissions"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => attachPermissions(row)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/General/Attachment1.svg")}
          />
        </span>
      </a> */}
           {PremissionChecker(user,"dvla.status") ? ( <Switch checked={row.status==1 ? true :false} onClick={() => onStatusChange(row)} size="default" /> ) : null}
      {/* <a
        title="Edit customer"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openEditCustomerDialog(row)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </a> */}
      <> </>

      {/* <a
        title="Delete customer"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => openDeleteCustomerDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a> */}
    </>
  );
}

import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import "./todayShifts.css";
import { Avatar } from "@material-ui/core";
const TodayShifts = ({ item,signleHolidays,allHoliDays,removeSeconds}) => {
  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
        <div className="todays_rota_card">
          <>
              <div className="today_rota_card_header">
                <p>{signleHolidays ? 'Holiday' :item.title}</p>
                <p className="todayShiftTime">
                  {!item?.isHoliday && item?.hide_time == "1" ? item?.time : ""}
                </p>
              </div>
            <div className="today_rota_cardBody">
              {item?.users?.map((user) => (
                <div className="todaySubCard">
                  <div className="todayShiftUsers">
                    <Avatar
                      style={{ backgroundColor: `#${user?.color}` }}
                      alt={user?.first_name?.toUpperCase()}
                      src="#"
                    />
                    <p>
                      {user.first_name} {user.last_name}
                    </p>
                  </div>
               {item?.isHoliday &&   <div className="d-flex justify-content-between">
                    <p className="today_shift_title">{user.shift_title}</p>
                    <p className="today_shift_time">{user?.hide_time == "1" ? removeSeconds(user?.start_time) : ""}</p>
                  </div>}
                  <div>
                  </div>
                </div>
              ))}
              {signleHolidays && allHoliDays?.map((user) => (
                <div className="todaySubCard">
                  <div className="todayShiftUsers">
                    <Avatar
                      style={{ backgroundColor: `#${user?.color}` }}
                      alt={user?.first_name?.toUpperCase()}
                      src="#"
                    />
                    <p>
                      {user.first_name} {user.last_name}
                    </p>
                  </div>
                  <div>
                  </div>
                </div>
              ))}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default TodayShifts;

import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import { Divider } from "antd";

const ConfirmModal = ({
  modalVisible,
  hideModal,
  onClickAnyWay,
  title,
  btnText,
  modelTitle,
  loading,
  mainLoading = false,
  hasPublishDraft = ()=>{},
  handleCheck=()=>{}
}) => {
  return (
    <Modal
      style={{ opacity: mainLoading ? 0.3 : 1 }}
      centered
      show={modalVisible}
    >
      <Modal.Header>
        <Modal.Title>{modelTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {title}
        <div>
          {hasPublishDraft() && (
            <div>
                <Divider/>
              <Checkbox
              onChange={(e)=>handleCheck(e.target.checked)}
              style={{padding:0,marginRight:"10px"}}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <label><b>Publish Drafts</b></label>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={hideModal} className="btn btn-danger">
          Cancel
        </button>
        {loading ? (
          <Spinner
            style={{ marginLeft: "20px" }}
            animation="border"
            color="green"
          />
        ) : (
          <button onClick={onClickAnyWay} className="btn btn-primary">
            {btnText}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;

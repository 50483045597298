
const INITIAL_STATE = {
    holidayGroups: [],
};

const HolidayReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'HOLIDAY_GROUPS':
            return {
                ...state,
                holidayGroups: action.payload.holidayGroups,
            };
        default:
            return state;
    }
};

export default HolidayReducer;
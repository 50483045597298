import React from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,

} from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../../app/modules/ECommerce/pages/customers/CustomersUIHelpers";
import { Pagination } from '../../../../src/_metronic/_partials/controls'
import { useCustomersUIContext } from "../../modules/ECommerce/pages/customers/CustomersUIContext";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../../services/config';
import { Spinner } from 'react-bootstrap';
import { geocodeByLatLng } from 'react-google-places-autocomplete'
import { makeStyles } from '@material-ui/core/styles';

import { Card as MuiCard } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';


const ClockDetails = () => {


  const authtoken = localStorage.getItem("userToken");

  const [isLoading, setisLoading] = useState(false)

  const [attendanceList, setAttendanceList] = useState([])

  const params = useParams()


  const { id, name } = params






  const dummy = [
    {
      date: "2022-4-01",
      start: "9:00 Am",
      End: "6:00 Pm",
      hours: "9 hours"
    },
    {
      date: "2022-4-02",
      start: "9:00 Am",
      End: "6:00 Pm",
      hours: "9 hours"
    },
    {
      date: "2022-4-03",
      start: "9:01 Am",
      End: "6:00 Pm",
      hours: "8 hours and 59min"
    },
    {
      date: "2022-4-04",
      start: "9:20 Am",
      End: "6:00 Pm",
      hours: "8 hours and 40min"
    },
    {
      date: "2022-4-05",
      start: "9:00 Am",
      End: "6:00 Pm",
      hours: "9 hours"
    },
    {
      date: "2022-4-06",
      start: "9:30 Am",
      End: "6:00 Pm",
      hours: "9 hours and 30min"
    }
  ]
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  //updating query params for pagination

  const updatingQueryParams = (page) => {
    console.log(page)
    setQueryParamsBase({
      pageNumber: page,
    });
  };


  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  }

  //end   
  const options = {
    custom: true,
    totalSize: dummy.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  const [loading, setLoading] = useState(false)

  const columns = [

    {
      dataField: "date",
      text: "Date",
      sort: true,
      // sortCaret: sortCaret,

    },
    {
      dataField: "start",
      text: "Start Time",
      sort: true,
      // sortCaret: sortCaret,

    },
    {
      dataField: "End",
      text: "Finsh Time",
      sort: true,
      // sortCaret: sortCaret,

    },
    {
      dataField: "hours",
      text: "Total Hours",
      sort: true,
      // sortCaret: sortCaret,

    },


  ];


  useEffect(() => {
    getClockDetails()
  }, [])



  const getClockDetails = () => {
    setisLoading(true)
    fetch(`${baseUrl}/mgmt/users/${id}/shift-attendance`, {
      headers: {
        Authorization: `Bearer ${authtoken}`

      }
    }).then(res => res.json())
      .then((data) => {
        setAttendanceList(data.payload)
        setisLoading(false)

      }).catch(err => {
        console.log(err)
        setisLoading(false)

      })
  }




  const getAddress = (lat, lng) => {
    return new Promise((resolve, reject) => {
      geocodeByLatLng({ lat, lng })
        .then(results => {
          if (results && results.length > 0) {
            resolve(results[0].formatted_address);
          } else {
            reject(new Error('No results found'));
          }
        })
        .catch(error => reject(error));
    });
    // return geocodeByLatLng({ lat, lng })
    //   .then(results => results[0].formatted_address)
    //   .catch(error => console.error('Error', error));
  }








  return (
    <>
      <Card>

        <CardHeader title={name}>
          <CardHeaderToolbar>

          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {isLoading && <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}

          >
            <Spinner animation='border' />
          </div>}

          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }} className='row'>
            {
              attendanceList.map((atendce) => (
                <MuiCard variant='outlined' className='usercard col-md-5 m-1 p-5'>
                  <h2>{atendce.title}</h2>

                  <ul className='pl-5'>
                    {
                      atendce?.attendance.map(list => (
                        <li key={list.id}>{list.action} at {list.time} </li>
                      ))
                    }
                  </ul>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <CalendarTodayIcon style={{ color: 'GrayText' }} />
                    <h5 style={{ color: 'GrayText' }} className='mt-2 ml-2'>{atendce.date}</h5>
                  </div>
                </MuiCard>
              ))
            }

          </div>

        </CardBody>
      </Card>
    </>
  )
}

export default ClockDetails
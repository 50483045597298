import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import { baseUrl } from '../../../services/config'
import dayjs from 'dayjs';
import { Button, message, Space,DatePicker } from 'antd';
const DeleteUser = ({
    HideModal,
    visible,
    id,
    reload,
    url,
    method,
    msg
}) => {
    const authToken = localStorage.getItem("userToken");
    const [loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [date,setDate]=useState(getCurrentDate())
    console.log(url)
    const dateFormat = 'YYYY-MM-DD';
    const warning = (res) => {
        messageApi.open({
          type: 'warning',
          content: `${res}`,
        });
      };
    const cancelSub = (e) => {
        e.preventDefault()
        if(!date){
          return toast.error("Please select the Date")
        }
        setLoading(true)
        fetch(`${baseUrl}/mgmt/delete/user/${id}/employment-end-date/${date}`, {
            method,
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    if(data.metadata.message=="Sorry! you cannot delete this Leave type, its have applications."){
                        warning(data.metadata.message)
                    }else{
                    toast.success(data.metadata.message)
                    }
                    setLoading(false)
                    HideModal()
                    reload()
                } else if (!data.metadata.success) {
                    setLoading(false)
                    toast.error(data.metadata.message)
                } else {
                    setLoading(false)
                    toast.error('Unknown error :(')
                }
            }).catch(err => {
                setLoading(false)
                console.log(err);
            })
    }

    function getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }



    return (
        <>
        {contextHolder}
        <Modal
            centered
            show={visible}>
            <Modal.Header>
                <Modal.Title>Leaver</Modal.Title>
            </Modal.Header>                    
                        <Modal.Body>
                            {
                                msg ? msg : 'Are you sure you want to make Leaver?'
                            }
                            <div className='row mt-4'>
                               <div className='col-md-12'>
                               <div>
                               <label><b>Select leaving date</b></label>  
                               </div>
                               <div>
                                <DatePicker style={{width:"100%"}} defaultValue={dayjs(getCurrentDate(), dateFormat)} format={dateFormat}  onChange={(e,value)=>setDate(value)}/>
                               </div>
                               </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={HideModal}
                                className='btn btn-danger'>
                                Close
                            </button>
                            {
                loading ?
                           <div style={{width:"100px",display:"flex",justifyContent:"center"}}>
                           <Spinner animation='border' variant='dark' />
                           </div>
                            :
                            <button
                                onClick={cancelSub}
                                className='btn btn-primary'>
                                Leave
                            </button>
                            }
                        </Modal.Footer>
                 
            
        </Modal>
        </>
    )
}

export default DeleteUser
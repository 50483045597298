import { Input, Modal } from "antd";
import React from "react";
import { useState } from "react";
import { Select } from "antd";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Table } from "react-bootstrap";

const ViewMatrix = () => {
  let data = [
    {
      name: "First Name",
      type: "String",
    },
    {
      name: "Last Name",
      type: "String",
    },
    {
      name: "Email",
      type: "String",
    },
  ];
  const user = [
    {
      first_name: "dsadsa",
      last_name: "sadsadas",
      email: "sadsa@gmail.com",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [modal1Open, setModal1Open] = useState(false);
  const handleSubmit = () => {
    console.log(...data);
    // setDummy({
    //     name:name,
    //     type:type
    // })
    setDummy((dummy) => [
      ...dummy,
      {
        name: name,
        type: type,
      },
    ]);

    let tempArr = []


    let obj = {
      name: name,
      type: type,
    };

    tempArr.push(obj)
    let newarray = [...userData]

    userData.map((user, index) => {
      user["fieldList"] = [...tempArr];

      newarray[index] = user;
    });

    setUserData(newarray);
    // setUserData((userData) => [
    //   ...userData,
    //   {
    //     name: name,
    //     type: type,
    //   },
    // ]);
  };
  const onChange = (value) => {
    setType(value);
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const [dummy, setDummy] = useState(data);
  const [userData, setUserData] = useState(user);
  console.log(dummy);
  console.log(userData);

  return (
    <>
      <Card>
        <CardHeader title="Group Name">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setModal1Open(true);
              }}
            >
              Add New Field
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table responsive>
            <thead>
              <tr>
                {dummy.length > 0
                  ? dummy.map((item) => {
                      return <th>{item.name}</th>;
                    })
                  : ""}
              </tr>
            </thead>

            <tbody>
              {userData.length > 0
                ? userData.map((item) => (
                    <tr>
                      <td style={{ color: "black" }}>{item.first_name}</td>
                      <td style={{ color: "black" }}>{item.last_name}</td>
                      <td style={{ color: "black" }}>{item.email}</td>
                      {/* <td style={{ color: "black" }}>
                        {item.newField?.type == "input" ? <input /> : null}
                      </td> */}
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Modal
        title={"Add New Field"}
        centered
        open={modal1Open}
        okText={"Add"}
        onOk={() => handleSubmit()}
        confirmLoading={loading}
        onCancel={() => {
          setModal1Open(false);
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <label>Enter Field Name</label>
              <Input
                onChange={(e) => setName(e.target.value)}
                placeholder="Field Name"
              />
            </div>
            <div className="col-md-12 mt-2">
              <label>Select Field Type</label>
              <div>
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="Select field type"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "input",
                      label: "Input",
                    },
                    {
                      value: "datepicker",
                      label: "Datepicker",
                    },
                    {
                      value: "select",
                      label: "Select",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewMatrix;

import React from 'react'
import { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import { baseUrl } from '../../../services/config';
import { Checkbox, Divider } from 'antd';
import { useSelector } from 'react-redux';




const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Apple', 'Pear', 'Orange'];
const defaultCheckedList = ['Apple', 'Orange'];

const AreaFileFolderAssign = ({
    rotaId,
    refresh,
    getassignUrl,
    postassignUrl,
    triggerFunction 
}) => {

    console.log(rotaId, "id")

    const user = useSelector(state => state.user.user)

    const authtoken = localStorage.getItem("userToken");
    const [loading, setloading] = useState(false)
    const [usersList, setUsersList] = useState([])
    const [assignLoading, setAssignLoading] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])

    const [departments, setDepartments] = useState([])

    const [checkallcheckbox, setcheckallcheckbox] = useState(false);
    const [allcampusstudent, setallcampusstudent] = useState([]);




    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);

    const onChange = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };



    const allcheckboxChecked = () => {
        let middlearray = [];
        let studentarray = [];
        if (checkallcheckbox) {
            setcheckallcheckbox(false);
            usersList.map((item, i) => {
                item.checked = false;
                middlearray.push(item);
            });

        } else {
            setcheckallcheckbox(true);
            usersList.map((item, i) => {
                item.checked = true;
                studentarray.push(item.id);
                middlearray.push(item);
            });

        }

        setallcampusstudent([...middlearray]);
    };










    useEffect(() => {
        if (rotaId) {
            getAllUsers()
        }
    }, [])



    const getAllUsers = () => {
        setloading(true)
        fetch(`${baseUrl}/mgmt/users`, {
            method: "GET",
            headers: {
                // Accept: "application/json",
                // "Content-Type": "application/json",
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((res) => res.json())

            .then((data) => {

                // setUsersList(data.payload);
                getAssignedUsers(data.payload, rotaId)
            })
            .catch((err) => {
                setloading(false)
                console.log(err);
            });
    };


    const getAssignedUsers = (array, id) => {
        console.log(id)
        fetch(getassignUrl, {
            method: "GET",
            headers: {
                // Accept: "application/json",
                // "Content-Type": "application/json",
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((res) => res.json())

            .then((data) => {

                console.log(data.payload, "this is yrr")
                let dummy = []
                for (let i = 0; i < data.payload.length; i++) {
                    dummy[i] = data.payload[i].id
                }
                // console.log(dummy,"this is dummy")
                // console.log(array,"yaha per userlist")
                // let arr=[70,21]
                let checkboxes = array;
                array.map((item, index) => {
                    if (dummy.includes(item.id)) {
                        item["checked"] = true;
                        item["selected"] = true;
                        checkboxes[index] = item;
                    } else {
                        item["checked"] = false;
                        item["selected"] = false;
                        checkboxes[index] = item;
                    }
                });
                console.log(checkboxes, "checkbox")
                const trueItemIndexarray = checkboxes.map((item) => {
                    if (item.checked) {
                        return item.id
                    }
                })

                getDepartments(trueItemIndexarray)

                setUsersList(checkboxes)


            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getDepartments = (trueItemIndexarray) => {
        fetch(`${baseUrl}/mgmt/departments`, {
            method: "GET",
            headers: {
                // Accept: "application/json",
                // "Content-Type": "application/json",
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                // setUsersList(data.payload);
                // setDepartments(data.payload)
                let dummy = []
                for (let i = 0; i < data.payload.length; i++) {
                    dummy[i] = data.payload[i].id
                }
                // console.log(dummy,"this is dummy")
                // console.log(array,"yaha per userlist")
                // let arr=[70,21]
                let checkboxes = data.payload;
                // data.payload.map((item, index) => {

                //     item["checked"] = false;
                //     item["selected"] = false;
                //     checkboxes[index] = item;

                // });
                data.payload.map((item, index) => {
                    const result = item.users.every(item => trueItemIndexarray.includes(item.id))
                    if (result) {
                        item["checked"] = true;
                        item["selected"] = true;
                        checkboxes[index] = item;
                    } else {
                        item["checked"] = false;
                        item["selected"] = false;
                        checkboxes[index] = item;
                    }

                });
                console.log(checkboxes, "=====>>>>>>>")
                setDepartments(checkboxes)
                setloading(false)

            })
            .catch((err) => {
                setloading(false)
                console.log(err);
            });
    }
    const assignUsersToGroup = () => {


        let selectedDepartments = departments.filter(deprt => deprt.checked == true)
        console.log(selectedDepartments, "selected")
        const allUsers = selectedDepartments.reduce((acc, obj) => {
            const userList = obj.users || []; // Get the 'users' list from each object
            return acc.concat(userList); // Concatenate the user list to the final list
        }, []);


        let idd2 = allUsers.map(item => item.id)

        let filterdUsers = usersList.filter(item => item.checked == true)
        let ids = filterdUsers.map(item => item.id)
        console.log(ids)


        let newarr = idd2.concat(ids)


        const uniqueArray = [...new Set(newarr)];

        if (uniqueArray.length == 0) {
            toast.error("Please Select At Least 1 User")
            return
        }

        let formData = new FormData()
        for (let i = 0; i < uniqueArray.length; i++) {
            formData.append(`user_ids[${i}]`, uniqueArray[i]);
        }

        fetch(postassignUrl, {
            method: 'POST',
            headers: {

                Authorization: `Bearer ${authtoken}`,
            },
            body: formData
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    // toast.success(data.metadata.message)
        
                    refresh()
                } else if (data.metadata.success) {
                    Object.values(data.metadata.message).map(msg => {
                        // toast.error(msg)

            

                    })
                }
                // else {
                //     toast.error(`At Least One User Is Required`)
                //     setAssignLoading(false)

                // }
            }).catch(err => {
                console.log(err)
                setAssignLoading(false)

            })
    }


    const onChangeText = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };




    const checkBoxClick = (e, index) => {
        setcheckallcheckbox(true);
        let middlearray = [];
        let studentarray = [];
        allcampusstudent.map((item, i) => {
            if (item.id == index) {
                item.checked = !item.checked;
            }
            if (!item.checked) {
                setcheckallcheckbox(false);
            }
            if (item.checked) {
                studentarray.push(item.id);
            }
            middlearray.push(item);
        });

        setallcampusstudent([...middlearray]);
    };


    console.log("usersList", departments)




    const handleCheckboxChange = (itemId) => {
        setUsersList(prevData => {
            const newData = [...prevData];
            const itemIndex = newData.findIndex(item => item.id === itemId);
            newData[itemIndex].checked = !newData[itemIndex].checked;
            const trueItemIndexarray = newData.map((item) => {
                if (item.checked) {
                    return item.id
                }
            })
            departments.map((item, index) => {
                const result = item.users.every(item => trueItemIndexarray.includes(item.id))
                if (result) {
                    item["checked"] = true;
                    item["selected"] = true;
                    departments[index] = item;
                } else {
                    item["checked"] = false;
                    item["selected"] = false;
                    departments[index] = item;
                }

            });
            setDepartments(departments)
            return newData;
        });
    };

    const handleCheckboxChange2 = (itemId) => {
        setDepartments(prevData => {
            const newData = [...prevData];
            const itemIndex = newData.findIndex(item => item.id === itemId);
            newData[itemIndex].checked = !newData[itemIndex].checked;
            const trueItemIndexarray = newData[itemIndex].users.map((item) => {
                return item.id
            })
            if (newData[itemIndex].checked == true) {
                console.log("ya chala????")
                usersList.map((item, index) => {
                    if (trueItemIndexarray.includes(item.id)) {
                        item["checked"] = true;
                        item["selected"] = true;
                        usersList[index] = item;
                    }
                });
                setUsersList(usersList)
            } else {
                usersList.map((item, index) => {
                    if (trueItemIndexarray.includes(item.id)) {
                        item["checked"] = false;
                        item["selected"] = false;
                        usersList[index] = item;
                    }
                });
                setUsersList(usersList)
            }
            return newData;
        });
    };



    const handleCheckAllChange = (event) => {
        setUsersList(prevData => {
            const newData = [...prevData];
            newData.forEach(item => item.checked = event.target.checked);
            return newData;
        });
    };

    console.log(departments, "here it is??/")
    triggerFunction.current = assignUsersToGroup;
    return (
 <>

                {
                    loading ?
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Spinner animation='border' />
                        </div>
                        :
                        <>

                            <div>
                                <p>Departments</p>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',

                                    }}
                                >
                                    {
                                        departments.map(deprt => (
                                            <div style={{ width: '25%' }}>
                                                <Checkbox
                                                    checked={deprt.checked}
                                                    onChange={(event) => handleCheckboxChange2(deprt.id)}
                                                >
                                                    {deprt.name}
                                                </Checkbox>
                                            </div>

                                        ))
                                    }
                                </div>

                            </div>

                            <hr />



                            <Checkbox checked={usersList.every(item => item.checked === true)} onChange={handleCheckAllChange}>Check All</Checkbox>

                            <Divider />
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',

                                }}
                            >
                                {
                                    usersList.map((user, i) => (
                                        <div style={{ width: '25%' }}>

                                            <Checkbox checked={user.checked} onChange={(event) => handleCheckboxChange(user.id)}>{user.username}</Checkbox>
                                        </div>
                                    ))
                                }

                            </div>
                        </>
                }

</>
       
    
         
    )
}

export default AreaFileFolderAssign
import React, { useState } from 'react'
import RotaCalendarMobi from '../../components/RotaCalendarMobi/RotaCalendarMobi'
import CalendarTwo from '../../components/CalendarTwo/CalendarTwo';

import { useParams,useLocation } from 'react-router-dom';
import { baseUrl } from '../../../services/config';
import { useEffect } from 'react';
import RotaCalendarTwo from '../../components/RotaCalendarTwo/RotaCalendarTwo';
import { Spinner } from 'react-bootstrap';


const RotaCalendar = () => {
    const params = useParams()
    const { id } = params
    const location = useLocation();
    let componentId = parseInt(id, 10);

    const authtoken = localStorage.getItem("userToken");

    const [selectedRota, setSelectedRota] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const [groupList, setgroupList] = useState([])
    const [eventList, setEventList] = useState([])
    const [mySelectedRota,setMySelectedRota]=useState({})
    const [usersList, setUsersList] = useState([])
    const [selectUsers, setselectUsers] = useState([])

    useEffect(() => {
        getRotaGroups()
    }, [])






    const getRotaGroups = () => {
        fetch(`${baseUrl}/mgmt/rotas`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            }
        }).then((res) => res.json().then((data) => {
            const filterSelectedRota=data?.payload?.find((item)=>item.id==id)
            setMySelectedRota(filterSelectedRota)
            setgroupList(data.payload)
        })).catch(err => {
        })


    }

    function handleChange(event) {
        const selectedOption = JSON.parse(event.target.value);
        setSelectedRota(selectedOption)
        getRotaCalendar(selectedOption.id)
    }


    const getRotaCalendar = (id, start_date, end_date) => {
        // setIsLoading(true)
        fetch(`${baseUrl}/mgmt/rotas/${id}/start-date/2022-01-01/end-date/2090-03-20/web-calendar`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authtoken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                // const eventsArray = data.payload.flatMap(user => {
                //     const shifts = (user.rota_shifts.length > 0 && user.rota_shifts[0].shifts) || [];
                //     return shifts.map(shift => ({
                //         start: `${shift.date}T${shift.start_time}`,
                //         end: shift.full_day ? null : `${shift.date}T${shift.end_time}`,
                //         title: shift.title,
                //         resource: user.id
                //     }));
                // });
                // const usersArray = data.payload.map(user => ({
                //     id: user.id,
                //     name: `${user.first_name} ${user.last_name}`,
                //     color: user.color
                // }));
                let newShifts = data.payload.shifts

                data.payload.shifts.map((userone, userIndex) => {
                    userone['resource'] = parseInt(userone.user_id)
                    userone['start'] = `${userone.date}T${userone.start_time ? userone.start_time : '00:00:00'}`
                    userone['end'] = `${userone.date}T${userone.end_time ? userone.end_time : '00:00:00'}`
                    userone['isFullDay'] = userone.full_day == "1" ? Boolean(true) : Boolean(false)
                    userone['isGeoFense'] = userone.geo_fence == "1" ? Boolean(true) : Boolean(false)
                    // userone['color'] = `#${getColor()}`

                    // userone['timeShow'] = `${userone.title}`
                    userone['allDay'] = userone.full_day == "1" ? Boolean(true) : Boolean(false)
                    newShifts[userIndex] = userone
                })
                let newUsers = data.payload.users

                data.payload.users.map((userone, userIndex) => {
                    userone['color'] = Math.floor(Math.random() * 16777215).toString(16);
                    userone['name'] = `${userone.first_name} ${userone.last_name}`
                    newUsers[userIndex] = userone
                })
                         let tempArray=[]
                data.payload.users.map((item, index) => {
                    tempArray.push({
                      label: item.first_name,
                      value: item.id,
                    });
                  });
                  setselectUsers(tempArray)

                setEventList(newShifts)
                setUsersList(newUsers)
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)

            })

    }



    return (
        <div>
             <RotaCalendarMobi rotaId={id} rota={mySelectedRota}/>
            <hr />
            <div className='col-md-4'>
                <label>Select Rota</label>
                <select
                    className='form-control'
                    onChange={handleChange}
                >
                    <option value={''}>Select Rota</option>
                    {
                        groupList.map(grop => (
                            <option key={grop.id} value={JSON.stringify(grop)}>{grop.name}</option>
                        ))
                    }
                </select>

            </div>
            <h4 className='p-5 text-info'>{selectedRota?.name}</h4>
            <hr />
            {/* {
                selectedRota &&
                <RotaCalendarMobi rotaId={selectedRota.id} />
            } */}
            {
                selectedRota ?

                    isLoading ?
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Spinner animation='border' />
                        </div>
                        :

                        // eventList.length > 0 ?

                        <RotaCalendarTwo
                            eventList={eventList}
                            usersList={usersList}
                            selectUsers={selectUsers}
                            rotaId={2}
                            reloadRota={() => getRotaCalendar(selectedRota.id)}

                        />
                    // : null

                    : null
            }
        </div>
    )
}

export default RotaCalendar
import React, { useEffect, useState } from 'react'
import { Paper } from '@material-ui/core'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls'
import { PlusOutlined } from '@ant-design/icons'
import { Spinner } from 'react-bootstrap'
import AreaCard from '../../components/AreaCard/AreaCard'
import { Image, Space, Table, Tag, Tooltip } from 'antd';
import { Link, useHistory } from 'react-router-dom'
import { useQuery } from "react-query";
import { Modal } from 'antd';
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-hot-toast';
import { baseUrl } from '../../../services/config'
import DeleteModal from '../../components/DeleteModal/DeleteModal'
import UpdateStaffModal from '../../components/UpdateStaffModal/UpdateStaffModal'
import { EditOutlined, DeleteOutlined, UserSwitchOutlined } from '@ant-design/icons'
import AssignUsers from '../../components/AssignUsers/AssignUsers'
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { PremissionChecker } from '../../../services/PremissionChecker'
import { useSelector } from 'react-redux'
import { Table as Tabler } from 'react-bootstrap'
import './styles.css'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Departments from '../Departments/Departments'
import AreaAssignUsers from './AreaAssignUsers'
import NoPermission from '../../components/NoPermission/NoPermission'





// const getItems = count =>
// Array.from({ length: count }, (v, k) => k).map(k => ({
//   id: `item-${k}`,
//   content: `item ${k}`
// }));







const StaffArea = () => {

  const history = useHistory()
  const [fetchLoading, setFetchloading] = useState(false)
  const authtoken = localStorage.getItem("userToken");
  const [modal1Open, setModal1Open] = useState(false);
  const [selectedObject, setSelectedObject] = useState({})
  const [deleteModal, setdeleteModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  // const [items, setItems] = useState(getItems(10));
  const [items, setItems] = useState();
  const [assignModal, setAssignModal] = useState(false)
  const [icon,setIcon]=useState(null)
  const user = useSelector(state => state.user.user)

  const getColor = () => {
    let color = ''

    color = Math.floor(Math.random() * 16777215).toString(16)
    return color
  }



  // init values
  const initialValues = {
    name: '',
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };



  // validation schema 
  const LoginSchema = Yup.object().shape({
    name: Yup.string()
      .required('Area Name is Required'),
  });





  // @changing class according to error while input:focus and touched

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };



  const columns = [
    {
      title: 'Sr. no',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, item, index) => index + 1,
    },

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => <Link to={`/view-area/${item.id}/${item.name}`}>{item.name}</Link>,
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Assigned Users',
      dataIndex: 'users',
      key: 'users',
      render: (_, record) => (
        <Space size="middle">
          <AvatarGroup max={4}>

            {
              record?.users?.map((usr) => (
                <Avatar style={{ backgroundColor: `#${usr.color}` }} alt={usr?.username?.toUpperCase()} src="#" />
              ))
            }
            {/* <Avatar className={classes.orange} alt="Travis Howard" src="/static/images/avatar/2.jpg" /> */}
            {/* <Avatar className={classes.purple} alt="Agnes Walker" src="/static/images/avatar/4.jpg" /> */}

          </AvatarGroup>
        </Space>
      ),
    },
    // {
    //   title: 'Status',
    //   key: 'tags',
    //   dataIndex: 'tags',
    //   render: (_, { tags }) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = tag.length > 5 ? 'geekblue' : 'green';
    //         if (tag === 'Pending') {
    //           color = 'orange';
    //         } else {
    //           color = 'green';

    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">

          {PremissionChecker(user, "staff_area.assign_user") ? <div onClick={() => {
            setSelectedObject(record)
            setAssignModal(true)
          }} style={{ cursor: 'pointer' }}>
            <Tooltip title={"Assign Users"}>
              <UserSwitchOutlined size={50} style={{ color: 'orange' }} />
            </Tooltip>

          </div> : ''}
          {PremissionChecker(user, "staff_area.update") ? <div onClick={() => onEditClick(record)} style={{ cursor: 'pointer' }}>
            <Tooltip title={"Update Area"}>
              <EditOutlined size={50} style={{ color: 'blue' }} />
            </Tooltip>

          </div> : ''}
          {PremissionChecker(user, "staff_area.delete") ? <div onClick={() => onDeleteClick(record)} style={{ cursor: 'pointer' }}>
            <Tooltip title={"Delete Area"}>
              <DeleteOutlined size={40} style={{ color: 'red' }} />
            </Tooltip>

          </div> : ''}
        </Space>
      ),
    },
  ];



  const onAssignClick = (item) => {
    console.log(item, "this is item")
    setSelectedObject(item)
    setAssignModal(true)
  }

  const onEditClick = item => {
    setSelectedObject(item)
    setEditModal(true)
  }
  const onDeleteClick = item => {
    setSelectedObject(item)
    setdeleteModal(true)
  }



  const { isLoading, error, data, refetch: reloadGroup, isError } = useQuery("getstaffareas", () =>
    fetch(`${baseUrl}/mgmt/staff-areas`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authtoken}`,
      }
    }).then((res) => res.json())
      .then((resp) => {
        // const modifiedUsers = resp.payload.users.map(user => ({
        //     ...user,
        //     color: getColor()
        // }));
        resp.payload.forEach((item) => {
          item.id = item.id.toString();
          item.users.forEach((us) => {
            us['color'] = getColor()
          })
        })
        // return {
        //     ...resp.payload,
        //     users: modifiedUsers
        // };
        setItems(resp.payload)
        return resp.payload
      })
  );

  // post data with formik 

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      let formData = new FormData()
      formData.append('name', values.name)
      if(icon){
      formData.append('icon',icon)
      }
      enableLoading();
      fetch(`${baseUrl}/mgmt/staff-areas`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
        body: formData
      }).then(res => res.json())
        .then((data) => {
          if (data.metadata.success) {
            toast.success(data.metadata.message)
            disableLoading()
            reloadGroup()
            setModal1Open(false)
            document.getElementById('icon').value = ''
            resetForm()
            setIcon(null);
          } else if (!data.metadata.success) {
          disableLoading()
            if (toString.call(data.metadata.message) == "[object Object]") {
              const errorMessageArray = Object.values(
                data.metadata.message
              ).flatMap((messages) => messages);
              errorMessageArray.forEach((message) => toast.error(message));
            } else if (toString.call(data.metadata.message) == "[object Array]") {
              Object.values(data.metadata.message).map((msg) => {
                toast.error(msg);
              });
            } else {
              toast.error(data.metadata.message);
            }
          } else {
          disableLoading()

            toast.error(`Something went wrong`);
          }

        }).catch(err => {
          disableLoading()

          console.log(err)
        })


    },
  });


  // handling loading states
  if (isLoading || !data) {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Spinner animation='border' />
      </div>
    )
  }


  // handling error 
  if (isError) {
    return (
      <div className='d-flex'>
        <span>{error.toString()}</span>
      </div>
    )
  }

  const resetFormValues = () => {
    formik.resetForm();
  };

  const reorder = (list, startIndex, endIndex) => {
    console.log(list, "my list ")
    console.log(startIndex, "my list ")
    console.log(endIndex, "my list ")

    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    if (result.length > 0) {
      let dummyArray = [];
      result.map((item, i) => {
        dummyArray.push({
          id: item.id,
          order: i + 1,
        });
      });
      console.log(dummyArray, "myIdzzz");

      const senddata = {
        staff_areas: dummyArray,
      };
      dragEndsApi(
        senddata,
        `${baseUrl}/mgmt/staff-area/indexing`
      );
    }
    return result;
  };

  const grid = 8;



  const getItemStyle = (isDragging, draggableStyle,index) => ({
    display: "flex",
    minWidth: "max-content",
    minHeight:"47px",
    justifyContent: "space-between",
    userSelect: "none",
    // padding: grid,
    // marginBottom:index==items.length-1 ? "5px" : '',
    // margin: `0 0 ${grid}px 0`,
    border:"1px solid #D8D8D8",
    background: isDragging ? "lightgreen" : index%2==0 ? '#F8F8F8' : "#F0EFEF",
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    // padding: grid,
    overflowX: "scroll",
    padding: 0,
    // width:
  });

  const dragEndsApi = (data, url) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          // toast.success(data.metadata.message);
          // getFolders();
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        } else {
          toast.error(`Something went wrong`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const updatedItems = reorder(items, result.source.index, result.destination.index);
    setItems(updatedItems);
  };


  return (
    <>
    {PremissionChecker(user,'department.list') ? <Departments/> : ''}

   {PremissionChecker(user,'staff_area.list') ?    <Card>
        <CardHeader title="Staff Area">
          <CardHeaderToolbar>
            {
              PremissionChecker(user, "staff_area.create") ?
                <button
                  type="button"
                  className="btn btn-primary"
                // onClick={() => {
                //     setModal1Open(true)
                // }}
                >
                  <div
                    onClick={() => setModal1Open(true)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      cursor: 'pointer'
                    }}

                  >
                    {/* <PlusOutlined /> */}
                    <span>Add New</span>
                  </div>
                </button>
                :
                ''
            }

          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {
            PremissionChecker(user,'staff_area.list') ? 
            <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between",backgroundColor:"black",color:"white"}}
                  >
                   <div className='changinmydivicon'>
                      <b className='header-area-text'>Icon</b>
                    </div>
                    <div className='changinmydiv'>
                      <b className='header-area-text'>Name</b>
                    </div>
                    <div className='changinmydiv'>
                      <b className='header-area-text'>Created By</b>
                    </div>
                    <div className='changinmydiv'>
                      <b className='header-area-text'>Assigned Users</b>
                    </div>
                    <div className='changinmydiv'>
                      <b className='header-area-text'>Date Created</b>
                    </div>
                    <div className='changinmydiv'>
                      <b className='header-area-text'>Action</b>
                    </div>
                  </div>
                  {items?.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            index
                          )}

                        >
                          {/* <div className='changinmydiv'>
                    {index+1}
                    </div> */}
                      <div className='changinmydiv3'>
                      {item?.icon &&<Image src={item?.icon?.url || ''} alt='icon' height={40} width={40} style={{borderRadius:"100%"}} />}
                      {/* {item?.icon && <img height={50} width={70} src={item?.icon?.url || ''}/>} */}
                          </div>
                          <div className='changinmydiv2'>
                            <Link to={`/view-area/${item.id}/${item.name}`}>{item.name}</Link>
                          </div>
                          <div className='changinmydiv2'>
                            {item.created_by}
                          </div>
                          <div className='changinmydiv2'>
                            <Space size="middle">
                              <AvatarGroup max={4}>

                                {
                                  item?.users?.map((usr) => (
                                    <Avatar sizes='small' style={{ backgroundColor: `#${usr.color}` }} alt={usr?.username?.toUpperCase()} src="#" />
                                  ))
                                }
                                {/* <Avatar className={classes.orange} alt="Travis Howard" src="/static/images/avatar/2.jpg" /> */}
                                {/* <Avatar className={classes.purple} alt="Agnes Walker" src="/static/images/avatar/4.jpg" /> */}

                              </AvatarGroup>
                            </Space>
                          </div>
                          <div className='changinmydiv2'>
                            {item.created_at}
                          </div>
                          <div className='changinmydiv2'>
                            <Space size="middle">

                              {PremissionChecker(user, "staff_area.assign_user") ? <div onClick={() => {
                                setSelectedObject(item)
                                setAssignModal(true)
                              }} style={{ cursor: 'pointer' }}>
                                <Tooltip title={"Assign Users"}>
                                  <UserSwitchOutlined size={50} style={{ color: 'orange' }} />
                                </Tooltip>

                              </div> : ''}
                              {PremissionChecker(user, "staff_area.update") ? <div onClick={() => onEditClick(item)} style={{ cursor: 'pointer' }}>
                                <Tooltip title={"Update Area"}>
                                  <EditOutlined size={50} style={{ color: 'blue' }} />
                                </Tooltip>

                              </div> : ''}
                              {PremissionChecker(user, "staff_area.delete") ? <div onClick={() => onDeleteClick(item)} style={{ cursor: 'pointer' }}>
                                <Tooltip title={"Delete Area"}>
                                  <DeleteOutlined size={40} style={{ color: 'red' }} />
                                </Tooltip>

                              </div> : ''}
                            </Space>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
            :

            <NoPermission/>
          }
       


          {/* <Tabler>
                  <thead>
                    <tr id="tbl">
                      <th>sad</th>
                      <th>sdsad</th>
                      <th>sdsa</th>
                      <th>dsad</th>
                      <th>sdsd</th>
              
                    </tr>
                  </thead>

                  <tbody>
     <tr>

     <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <tr id="tbl">
            
            <td
              style={{ color: "black" }}
              className="text_align_start"
            >
            sadsad
            </td>
            <td
              style={{ color: "black" }}
              className="text_align_start"
            >
            sadsad
            </td>
            <td
              style={{ color: "black" }}
              className="text_align_start"
            >
              dsadsad
            </td>

            <td
              style={{ color: "black" }}
              className="text_align_start"
            >
             sadsa
            </td>
            <td
              style={{ color: "black" }}
              className="text_align_start"
            >
             sadsad
            </td>
          </tr>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
     </tr>
                        
               
                  </tbody>
                </Tabler> */}

          {/* {
            fetchLoading ?
              <div className='d-flex justify-content-center align-items-center'>
                <Spinner animation='border' />
              </div>
              :

              <>
                <Table columns={columns} dataSource={data} />
              </>
          } */}
        </CardBody>
      </Card> : ''}
      <Modal
        title={"Create New Area"}
        centered
        open={modal1Open}
        okText={"Create Area"}
        onOk={() => formik.handleSubmit()}
        className='area-modal'
        confirmLoading={loading}
        cancelButtonProps={{ style: { backgroundColor: "red", color: "white", border: "none" } }}
        onCancel={() => {
          resetFormValues();
          setModal1Open(false)
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Area Name"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "name"
              )}`}
              name="name"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            ) : null}
          </div>
          <div className='col-md-12 col-sm-12 col-lg-12'>
                                <div className="form-group">
                                    <label htmlFor="link"><b>Icon</b></label>
                                    <input
                                        id="icon"
                                        name="icon"
                                        type="file"
                                        accept="image/*"
                                        className='form-control'
                                        onChange={(event) => {
                                          setIcon(event.target.files[0]);
                                        }}
                                    />
                                </div>
                            </div>
                           {icon && <div className='col-md-12'>
                                 {/* <p>Previous Icon</p> */}
                                 <img src={URL.createObjectURL(icon)} alt='folderIcons' style={{height:"100px"}}/>
                                </div>}

        </form>
      </Modal>

      <AreaAssignUsers
      selectedObject={selectedObject}
        permission={PremissionChecker(user, "staff_area.assign_user")}
        getassignUrl={`${baseUrl}/mgmt/staff-areas/${selectedObject.id}/assigned-users`}
        postassignUrl={`${baseUrl}/mgmt/staff-areas/${selectedObject.id}/assign-users`}
        rotaId={selectedObject.id}
        visible={assignModal}
        setModalVisibility={(bol) => setAssignModal(bol)}
        refresh={reloadGroup}

      />

      <UpdateStaffModal
        open={editModal}
        selectedObject={selectedObject}
        reload={reloadGroup}
        onHide={() => setEditModal(false)}
        name={selectedObject.name}
      />

      <DeleteModal
        visible={deleteModal}
        HideModal={() => setdeleteModal(false)}
        method={'DELETE'}
        url={`${baseUrl}/mgmt/staff-areas/${selectedObject.id}`}
        reload={reloadGroup}

      />


    </>
  )
}

export default StaffArea
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,

} from "react-bootstrap-table2-paginator";
// import './styles.css'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../../app/modules/ECommerce/pages/customers/CustomersUIHelpers";
import { Pagination } from '../../../../src/_metronic/_partials/controls'
import { Modal, Spinner } from "react-bootstrap";
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../../modules/ECommerce/pages/customers/CustomersUIContext";
import { baseUrl } from "../../../services/config";
import { toast } from "react-hot-toast";
import { SendNotifaction } from "../../../services/SendNotifaction";
import { useSelector } from "react-redux";
import NoPermission from "../../components/NoPermission/NoPermission";
import { PremissionChecker } from "../../../services/PremissionChecker";



export default function AllPendingLeaves({ reloader,isDashboard=false,modelApprove=false }) {

  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };

  const authtoken = localStorage.getItem("userToken");

  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [addModal, setaddModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [actionsLoading, setactionsLoading] = useState(false);
  const [brands, setBrands] = useState([])
  const [loading, setloading] = useState(false)
  const [editItem, setEditItem] = useState({})
  const customersUIContext = useCustomersUIContext();
  const [SelectedId, setSelectedId] = useState('')
  const [usernameSearch, setusernameSearch] = useState('')
  const [emailsearch, setemailsearch] = useState('')
  const [role_name, setrole_name] = useState('')
  const [selectedObj, setSelectedObj] = useState({})
  const [confirmModel, setconfirmModel] = useState(false)
  useEffect(() => {
    getAllBrands()
  }, [modelApprove])


  const [roles, setRoles] = useState([])

  useEffect(() => {
    getRoles()
  }, [])

  const Ruser = useSelector(state => state.user.user)

  const getRoles = () => {
    fetch(`${baseUrl}/mgmt/roles`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authtoken}`,
      }
    }).then(res => res.json())
      .then((data) => {
        // let arr = []

        // data.payload.forEach(element => {
        //     arr.push({
        //         id: element.id,
        //         label: element.name,
        //         value: element.name
        //     })
        // });
        setRoles(data.payload)
      }).catch(err => {
        console.log(err)
      })
  }

  const getAllBrands = () => {
    setloading(true)
    fetch(`${baseUrl}/mgmt/pending-leave-applications`, {
      method: "GET",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false)
        console.log("brands List", data.payload);

        // let filteredApplications = data.payload.map((user) => {
        //   user.leave_applications = user.leave_applications.filter(
        //     (application) => application.status === "PENDING"
        //   );
        //   return user;
        // });




        // const leaveApplications = filteredApplications.flatMap(item => item.leave_applications);


        // console.log("filterddd", leaveApplications)
        setBrands(data.payload)
      })
      .catch((err) => {
        setloading(false)
        console.log(err);
      });
  };

  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   // sortCaret: sortCaret,


    // },
    {
      dataField: "user",
      text: "User Name",
      sort: true,
      // sortCaret: sortCaret,
      formatter: (row, cell) =>

      // let filterd = cell.leave_applications.filter(lea=>lea.)

      (<span
      // className={`label label-inline`}
      >
        {/* {console.log("filhal row", cell)} */}
        {row?.username}
      </span>
      )
    },
    // {
    //   dataField: "first_name",
    //   text: "Firstname",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },
    // {
    //   dataField: "last_name",
    //   text: "Last Name",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },

    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    //   // sortCaret: sortCaret,
    // },
    {
      dataField: "start_date",
      text: "Date",
      // formatter: (row, cell) => 

      //   // let filterd = cell.leave_applications.filter(lea=>lea.)


      //   (<span
      //   // className={`label label-inline`}
      //   >
      //     {/* {console.log("filhal row", cell)} */}
      //     {cell?.leave_applications[0]?.start_date}
      //   </span>
      //   )

      // sortCaret: sortCaret,

    },
    // {
    //   dataField: "contact",
    //   text: "Contact number ",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },
    // {
    //   dataField: "address",
    //   text: "Home address",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },
    // {
    //   dataField: "emergency_contact",
    //   text: "Emergency contact info",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },
    // {
    //   dataField: "employment_start_date",
    //   text: "Employment start date",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },
    // {
    //   dataField: "role[0].name",
    //   text: "Role",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormattePendingLeaves,
      formatExtraData: {
        user: Ruser,
        approveLeave: (item) => openEdit(item),
        // openDeleteCustomerDialog: (id) => {

        //   setdeleteModal(true)
        //   setSelectedId(id)
        // },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];


  const openEdit = (item) => {

    console.log(item)
    setSelectedObj(item)
    setconfirmModel(true)
    // onStatusChange(item.id)
    // setEditItem(item)
    // seteditModal(true)
    // setaddModal(true)
  }


  console.log(selectedObj,"ehehehhehefsadfsdf")

  const onStatusChange = (status) => {
    setactionsLoading(true)
    fetch(`${baseUrl}/mgmt/leave-applications/${selectedObj.id}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({ status: status }),

    })
      .then((resp) => {
        resp.json().then((res) => {
          setactionsLoading(false)

          if (res.metadata) {
            if (res.metadata.success) {
              toast.success(res.metadata.message);
              reloader()
              const extraData = {
                type: 'accpet_leave',
                userId: selectedObj.user_id
              }
              SendNotifaction("Leave Status Updated", `Your ${selectedObj.type.name} updated to Approved. Your ${selectedObj.type.name} starts from ${selectedObj.start_date} and ended on ${selectedObj.end_date}`, selectedObj.user_id, '', extraData)
              setconfirmModel(false)
              // getLeaveDetails()
              // updateArray(status)
              getAllBrands()
              // SendNotifaction('Leave Status Updated', `Your Leave updated to ${'Approved'}`, item.id)

            }
            else if (!res.metadata.success) {
              if (Array.isArray(res.metadata.message)) {
                Object.values(res.metadata.message).map(msg => {
                  toast.error(msg);

                })

              } else {
                toast.error(res.metadata.message);

              }
            }
          }
        });
      }).catch(err => {
        setactionsLoading(false)
        console.log(err)
      })
  }


  const options = {
    custom: true,
    totalSize: brands.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };



  const getClassOrder = (row) => {
    let classname = ''
    if (row) {
      classname = 'success'
    } else if (!row) {
      classname = 'danger'
    }

    return classname
  }




  //updating query params for pagination

  const updatingQueryParams = (page) => {
    console.log(page)
    setQueryParamsBase({
      pageNumber: page,
    });
  };


  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  }

  //end   

  const dataSearch = brands.filter((user) => {
    // let text = searchText.toLowerCase();
    // return searchText ? user?.topic?.toLowerCase().includes(text) || user?.section?.toLowerCase().includes(text) || user?.appellant_vs_respondent?.toLowerCase().includes(text) || user?.citations_ref?.toLowerCase().includes(text) : true;
    let text1 = usernameSearch.toLowerCase()
    const topicSearchData = text1 ? user?.username?.toLowerCase().includes(text1) : true;
    let text2 = emailsearch.toLowerCase()
    const appellantSearchData = text2 ? user?.email?.toLowerCase().includes(text2) : true;
    let text3 = role_name.toLowerCase()
    const sectionSearchData = text3 ? user?.role[0]?.name?.toLowerCase().includes(text3) : true;
    // let text4 = citationtext.toLowerCase()
    // const citationSearchData = text4 ? user?.citations_ref?.toLowerCase().includes(text4) : true;
    return topicSearchData && appellantSearchData && sectionSearchData
  });


  console.log("brand", brands)
  console.log("selected object", selectedObj)
  const rowClasses = (row, rowIndex) => {
    return rowIndex % 2 === 0 ? 'light-row-pending' : '';
  };
  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Card
      noPadding={true}
      className={isDashboard ? 'pendingDashboard p-0' : ''}
      >

        <>

          <CardHeader title="Pending Leaves">

          </CardHeader>
          <CardBody>
            {/* <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search by username"
                  value={usernameSearch}
                  onChange={(e) => {
                    setusernameSearch(e.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in username
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search by email"
                  value={emailsearch}
                  onChange={(e) => {
                    setemailsearch(e.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in email
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Role"
                  name="type"
                  onChange={(e) => {
                    setrole_name(e.target.value);
                  }}
                  value={role_name}
                >
                  <option value="">All</option>
                  {
                    roles.map(role => (
                      <option key={role.isEdit} value={role.name}>{role.name}</option>
                    ))
                  }
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Type
                </small>
              </div>

            </div> */}


          {PremissionChecker(Ruser, "leave_application.list") ?   <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <>
                    <Pagination
                      updatingQueryParams={updatingQueryParams}
                      onPaginationSelectChange={onPaginationSelectChange}
                      isLoading={loading}
                      paginationProps={paginationProps}
                    >
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes={isDashboard ? "table my_pending_table_header_custom table-vertical-center overflow-hidden" :"table table-head-custom table-vertical-center overflow-hidden"}
                        bootstrap4
                        keyField="id"
                        data={dataSearch}
                        columns={columns}
                        defaultSorted={uiHelpers.defaultSorted}
                        rowClasses={isDashboard && rowClasses}
                        {...paginationTableProps}
                        filter={filterFactory()}
                      ></BootstrapTable>
                    </Pagination>
                    {/* )} */}
                  </>
                );
              }}
            </PaginationProvider> : 
            <NoPermission/>
            }
          </CardBody>
        </>
      </Card>


      <Modal
        show={confirmModel}
        centered
        animation

      >
        <Modal.Header>
          <Modal.Title>Are you sure you want to approve this leave?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 text-dark">
              <b>Leave Type</b>
            </div>
            <div className="col-md-6">
              {selectedObj?.type?.name}
            </div>

            <div className="col-md-6">
              <b>Paid</b>
            </div>
            <div className="col-md-6">
              {selectedObj.paid ? 'Yes' : 'No'}
            </div>
            <div className="col-md-6">
              <b>Start Date</b>
            </div>
            <div className="col-md-6">
              {selectedObj?.start_date}
            </div>
            <div className="col-md-6">
              <b>End Date</b>
            </div>
            <div className="col-md-6">
              {selectedObj?.end_date}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setconfirmModel(false)} className="btn btn-light">Cancel</button>
          <button disabled={actionsLoading} onClick={() => onStatusChange(2)} className="btn btn-primary">Approve</button>
          <button disabled={actionsLoading} onClick={() => onStatusChange(3)} className="btn btn-danger">Reject</button>
        </Modal.Footer>
      </Modal>
    </>

  );
}



import React, { useState, useEffect } from "react";
import { Modal, DatePicker } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import Alert from "react-bootstrap/Alert";
import { baseUrl } from "../../../services/config";
import BootstrapTable from "react-bootstrap-table-next";
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as uiHelpers from "../../../app/modules/ECommerce/pages/customers/CustomersUIHelpers";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";
import Select from "react-select";
import { FormControlLabel, Switch } from "@material-ui/core";


function SettingModal({ open, onHide, reload, rotaId }) {

  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState("");
  const [SelectedId, setSelectedId] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});
  const [error, setError] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [isfullDay, setIsFullday] = useState(false);

  const [startTime, setstartTime] = useState({
    label: "9:00 am",
    value: "09:00:00",
  });
  const [endTime, setendTime] = useState({
    label: "6:00 pm",
    value: "18:00:00",
  });

  useEffect(() => {
    getAllTitle();
  }, []);


  useEffect(() => {
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }, [selectedElement]);

  const timeArray = [
    { label: "12:00 am", value: "00:00:00" },
    { label: "0:30 am", value: "00:30:00" },
    { label: "1:00 am", value: "01:00:00" },
    { label: "1:30 am", value: "01:30:00" },
    { label: "2:00 am", value: "02:00:00" },
    { label: "2:30 am", value: "02:30:00" },
    { label: "3:00 am", value: "03:00:00" },
    { label: "3:30 am", value: "03:30:00" },
    { label: "4:00 am", value: "04:00:00" },
    { label: "4:30 am", value: "04:30:00" },
    { label: "5:00 am", value: "05:00:00" },
    { label: "5:30 am", value: "05:30:00" },
    { label: "6:00 am", value: "06:00:00" },
    { label: "6:30 am", value: "06:30:00" },
    { label: "7:00 am", value: "07:00:00" },
    { label: "7:30 am", value: "07:30:00" },
    { label: "8:00 am", value: "08:00:00" },
    { label: "8:30 am", value: "08:30:00" },
    { label: "9:00 am", value: "09:00:00" },
    { label: "9:30 am", value: "09:30:00" },
    { label: "10:00 am", value: "10:00:00" },
    { label: "10:30 am", value: "10:30:00" },
    { label: "11:00 am", value: "11:00:00" },
    { label: "11:30 am", value: "11:30:00" },
    { label: "12:00 pm", value: "12:00:00" },
    { label: "12:30 pm", value: "12:30:00" },
    { label: "1:00 pm", value: "13:00:00" },
    { label: "1:30 pm", value: "13:30:00" },
    { label: "2:00 pm", value: "14:00:00" },
    { label: "2:30 pm", value: "14:30:00" },
    { label: "3:00 pm", value: "15:00:00" },
    { label: "3:30 pm", value: "15:30:00" },
    { label: "4:00 pm", value: "16:00:00" },
    { label: "4:30 pm", value: "16:30:00" },
    { label: "5:00 pm", value: "17:00:00" },
    { label: "5:30 pm", value: "17:30:00" },
    { label: "6:00 pm", value: "18:00:00" },
    { label: "6:30 pm", value: "18:30:00" },
    { label: "7:00 pm", value: "19:00:00" },
    { label: "7:30 pm", value: "19:30:00" },
    { label: "8:00 pm", value: "20:00:00" },
    { label: "8:30 pm", value: "20:30:00" },
    { label: "9:00 pm", value: "21:00:00" },
    { label: "9:30 pm", value: "21:30:00" },
    { label: "10:00 pm", value: "22:00:00" },
    { label: "10:30 pm", value: "22:30:00" },
    { label: "11:00 pm", value: "23:00:00" },
    { label: "11:30 pm", value: "23:30:00" },
  ];

  const repeatoptions = [
    { label: "Never", value: "never" },
    { label: "Every Day", value: "everyday" },
    { label: "Every Week", value: "weekly" },
    { label: "Every 2 Weeks", value: "every_two_week" },
    { label: "Every 3 Weeks", value: "every_three_week" },
    { label: "Every Month", value: "monthly" },
    { label: "Every Other Month", value: "every_other_month" },
    { label: "Every Year", value: "yearly" },
    { label: "Every Week Gap", value: "weekly_gape" },
    { label: "Every 2 Weeks Gap", value: "every_two_week_gape" },
    { label: "Every 3 Weeks Gap", value: "every_three_week_gape" },
    { label: "Every Month Gap", value: "monthly_gape" },
    // { label: "Every Other Month Gap", value: "every_other_month_gape" },
    { label: "Every Year Gap", value: "yearly_gape" },
    // { label: "Custom", value: "custom" },
  ];

 
  useEffect(() => {
    if (!open) {
      // Reset Formik values when the modal is about to open
      formik.resetForm();
    }
  }, [open]);
  function removeSeconds(timeStr) {
   const response= timeArray.find((item)=>item.value==timeStr)
   if(response==undefined){
     return timeStr
   }else{
   return response.label.toUpperCase()
   }
  //  return timeStr.substring(0, 5);
}
  function gettingRepeat(repeat) {
   const response= repeatoptions.find((item)=>item.value==repeat)
   if(response==undefined){
     return repeat
   }else{
   return response.label
   }
  //  return timeStr.substring(0, 5);
}


  const handleTitleChange = (event) => {
    formik.handleChange(event);
  };


  const columns = [
    {
      dataField: "sr_no",
      text: "Sr.No",
    },
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "start_time",
      text: "Start Time",
      formatter: (cell, row) => {
        // Define a custom formatter function to render the delete icon
        return (
          <>
            {removeSeconds(row.start_time)}
          </>
        );
      },
    },
    {
      dataField: "end_time",
      text: "End Time",
    
        formatter: (cell, row) => {
          // Define a custom formatter function to render the delete icon
          return (
            <>
              {removeSeconds(row.end_time)==null ? 'No End' : removeSeconds(row.end_time)}
            </>
          );
        },
      },
  
    {
      dataField: "repeat",
      text: "Repeat",
      formatter: (cell, row) => {
        // Define a custom formatter function to render the delete icon
        return (
          <>
            {gettingRepeat(row.repeat)}
          </>
        );
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => {
        // Define a custom formatter function to render the delete icon
        return (
          <>
            {PremissionChecker(user, "rota_setting.update") ? (
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => openEdit(row)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    title="Edit title"
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
            ) : null}
            {PremissionChecker(user, "rota_setting.delete") ? (
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => opendeleteModal(row.id)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    title="Delete title"
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
              </a>
            ) : null}
          </>
        );
      },
      // formatter: columnFormatters.RolesFormatter,
      // formatExtraData: {
      //   // user: user,
      //   // attachPermissions: (item, i) => openAttachModal(item, i),
      //   // openEditCustomerDialog: (item) => openEdit(item),

      //    openDeleteCustomerDialog: (id) => opendeleteModal(id),
      // },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  const openEdit = (item) => {
    formik.setValues({
      title: item.title,
      repeat: item.repeat,
    });
    console.log(item,"editClick")
  const gettingStart=  timeArray.find((ti)=>ti.value==item.start_time)
  const gettingEnd=  timeArray.find((ti)=>ti.value== item.end_time )
  console.log(gettingEnd,"editClick")

  setstartTime(gettingStart)
  setendTime(gettingEnd == undefined ?  { label: "6:00 pm", value: "18:00:00"} : gettingEnd)
    setSelectedObj(item);
    setIsFullday(item.full_day);
    seteditModal(true);
  };
  const opendeleteModal = (id) => {
    setSelectedId(id);
    setdeleteModal(true);
  };

  const getAllTitle = () => {
    // setloading(true);
    fetch(`${baseUrl}/mgmt/rotas/${rotaId}/rota-settings`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        // setloading(false);
        console.log("title list", data.payload);
        let newTitle = data.payload;
        data.payload.map((item, i) => {
          // item["isLoading"] = false;
          item["sr_no"] = i + 1;
          newTitle[i] = item;
        });
        console.log("ne", newTitle);
        setData(newTitle);
      })
      .catch((err) => {
        // setloading(false);
        console.log(err);
      });
  };
  const TitleSchema = Yup.object().shape({
    title: Yup.string().required("Title is Required"),
    repeat: Yup.string().required("Repeat is Required"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const resetFormValues = () => {
    formik.resetForm();
  };
  const formik = useFormik({
    initialValues: {
      title: editModal ? selectedObj.title : "",
      start_time: editModal ? selectedObj.start_time : "",
      end_time: editModal ? selectedObj.end_time ? selectedObj.end_time : "" : "",
      repeat: editModal ? selectedObj.repeat : ""

    },
    validationSchema: TitleSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      console.log(endTime?.value,"is my full day")
      console.log(startTime.value,"ya hy issue seeting ka")
      setLoading(true);
      setError(null);

      const dataToSend = {
        title: values.title,
        start_time: startTime.value,
        end_time: endTime?.value == undefined ? '18:00:00' : endTime?.value,
        repeat: values.repeat,
        full_day: 0,

      };
      const dataToSend2 = {
        title: values.title,
        start_time: startTime.value,
        repeat: values.repeat,
        full_day: 1,

      };

      let url = editModal
        ? `mgmt/rota-settings/${selectedObj.id}?_method=PUT`
        : `mgmt/rotas/${rotaId}/rota-settings`;
      fetch(`${baseUrl}/${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body:!isfullDay ?  JSON.stringify(dataToSend) : JSON.stringify(dataToSend2),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.metadata.success) {
            toast.success(data.metadata.message);
            onHide()
            getAllTitle();
            // setTitle('');
            reload();
            seteditModal(false);
            setError(null);
            resetForm();
            setstartTime({label: "9:00 am",value: "09:00:00"})
            setendTime({ label: "6:00 pm", value: "18:00:00",})
            setIsFullday(false)
            setLoading(false);
           
          }else if (!data.metadata.success) {
            setLoading(false);

            if (toString.call(data.metadata.message) == "[object Object]") {
              const errorMessageArray = Object.values(
                data.metadata.message
              ).flatMap((messages) => messages);
              errorMessageArray.forEach((message) => toast.error(message));
            } else if (toString.call(data.metadata.message) == "[object Array]") {
              Object.values(data.metadata.message).map((msg) => {
                toast.error(msg);
              });
            } else {
              toast.error(data.metadata.message);
            }
          } else {
            toast.error(`Something went wrong`);
            setLoading(false);

          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          // toast.error("An error occurred while processing your request.");
        })


    },
  });



  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "MyDropdown__option--is-selected"
      )[0];
      if (selectedEl) {
        setSelectedElement(selectedEl);
      }
    }, 15);
  };



  const onChangeStartTime = (time) => {
    setstartTime(time);
  };

  const onChangeEndTime = (time) => {
    console.log(time);
    setendTime(time);
  };


  return (
    <div>
      <Modal
        title={editModal ? "Edit Rota Setting" : "Add New Rota Setting"}
        isEdit={editModal ? true : false}
        centered
        open={open}
        okText={editModal ? "Update" : "Add "}
        okButtonProps={{style:{display:!editModal && !PremissionChecker(user, "rota_setting.create") ? "none" : ""  }}}
        onOk={() => formik.handleSubmit()}
        confirmLoading={loading}
        onCancel={onHide}
        afterClose={() => {
          console.log("Formik Values (Before Reset):", formik.values);
          resetFormValues();
          seteditModal(false);
          setstartTime({label: "9:00 am",value: "09:00:00"})
          setendTime({ label: "6:00 pm", value: "18:00:00",})
          setIsFullday(false)
          setTimeout(() => {
            formik.resetForm();
            console.log("Formik Values (After Reset):", formik.values);
          }, 0);
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Title"
              type="text"
              value={formik.values.title}
              onChange={handleTitleChange}
              className={`form-control ${getInputClasses(
                "title"
              )}`}
              name="title"
              {...formik.getFieldProps("title")}
            />
            {formik.touched.title && formik.errors.title ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.title}</div>
              </div>
            ) : null}

          </div>

          <div className="row">

            <div className="col-md-6">
              <label>Start Time</label>
              <div>

                <Select
                  onMenuOpen={onMenuOpen}
                  className="MyDropdown"
                  classNamePrefix="MyDropdown"
                  // defaultValue="lucy"
                  style={{ width: 120 }}
                  onChange={(e) => onChangeStartTime(e)}
                  options={timeArray}
                  value={startTime}
                />
              
              </div>
            </div>
            <div className="col-md-6">
              <label>End Time</label>
              <div>
                <Select
                  onMenuOpen={onMenuOpen}
                  className="MyDropdown"
                  classNamePrefix="MyDropdown"
                  isDisabled={isfullDay}
                  style={{ width: 120 }}
                  onChange={(e) => onChangeEndTime(e)}
                  options={timeArray}
                  value={endTime}
                />
              </div>
            </div>

            <div className="col-md-6">
              <label>Repeat</label>
              <select
                value={formik.values.repeat}
                // onChange={handleFrequencyChange}
                className={`form-control ${getInputClasses(
                  "repeat"
                )}`}
                name="repeat"
                {...formik.getFieldProps("repeat")}
              >
                <option value="">Select</option>
                {repeatoptions.map(op => (
                  <option key={op.value} value={op.value}>{op.label}</option>
                ))}
              </select>

              {formik.touched.repeat && formik.errors.repeat ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.repeat}</div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 mt-9">
            <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        checked={isfullDay}
                        onChange={(check) => {
                          console.log("Checking", check.target.checked);
                          setIsFullday(check.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label="No End"
                    labelPlacement="start"
                  />
            </div>
          </div>
        </form>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          defaultSorted={uiHelpers.defaultSorted}
        ></BootstrapTable>
      </Modal>
      {/* <Modal
        title={editModal ? "Edit Title" : "Add New Title"}
        isEdit={editModal ? true : false}
        centered
        open={open}
        okText={editModal ? "Edit" : "Add "}
        onOk={onOk}
        afterClose={() => {
          seteditModal(false)
          setTitle('')
        }}
        confirmLoading={loading}
        onCancel={onHide}
      >
        <form className="form fv-plugins-bootstrap fv-plugins-framework">
          {/* {error ? (
            error.map((err, index) => (
              <Alert key={index} variant={'danger'}>
                {err}
              </Alert>
            ))
          ) : null} */}
      {/* <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Title"
              type="text"
              required
              value={title}
              onChange={onTitleChange}
              className={`form-control form-control-solid h-auto py-5 px-6`}
              name="title"
            />
          </div>
        </form>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          defaultSorted={uiHelpers.defaultSorted}
        ></BootstrapTable>
      </Modal>  */}
      <DeleteModal
        HideModal={() => {
          reload()
          setdeleteModal(false)}}
        visible={deleteModal}
        // id={SelectedId}
        method="DELETE"
        reload={getAllTitle}
        url={`${baseUrl}/mgmt/rota-settings/${SelectedId}`}
      />
    </div>
  );
}

export default SettingModal;

import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls'
import "./applyleave.css"
import { baseUrl } from '../../../services/config'
import { DatePicker, Divider, Space } from 'antd';
import { useLocation } from 'react-router-dom'
import moment from 'moment';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
const { RangePicker } = DatePicker;


const ApplyLeave = () => {
    let location = useLocation();
    const myUser = location.state;
    const authtoken = localStorage.getItem("userToken");


    const [list, setList] = useState([])
    const [leaveType, setLeaveType] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [note, setNote] = useState("")


    const [fromDate, setFromDate] = useState(null);
    const [fromDateObj, setFromDateObj] = useState(new Date());
    const [toDate, settoDate] = useState(null);
    const [toDateObj, settoDateObj] = useState(new Date());
    const [leaveDuration, setLeaveDuration] = useState([]);
    const [leaveDurationList, setLeaveDurationList] = useState([]);
    const [workPatternDataObject, setWorkPatternDataObject] = useState({});
    const [loading,setLoading]=useState(false)
    const [count,setCount]=useState(0)

    console.log('myUser', myUser)
    const history = useHistory();

    const goBack = () => {
      history.goBack();
    };

    useEffect(() => {
        getHoliDaysTypes()
    }, [])

    const getHoliDaysTypes = () => {
        fetch(`${baseUrl}/mgmt/leave-types`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    if (data.payload) {
                        setList(data.payload);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };






    function calculateWorkingDays(startDateStr, endDateStr, workPattern,leavePattern) {
        console.log(workPattern,"here goooooo")
        console.log(leavePattern,"here goooooo")
        const startDate = moment(startDateStr);
        const endDate = moment(endDateStr);
        const workingDaysArray = [];
    
        let currentDate = startDate.clone();
        while (currentDate.isSameOrBefore(endDate, 'day')) {
            const dayName = currentDate.format('dddd').toLowerCase();
            const workingDayValue = workPattern[dayName] && leavePattern[dayName] ? 1 : 0;
            workingDaysArray.push({ day: currentDate.format('YYYY-MM-DD'), duration: "3", working_day: workingDayValue });
            currentDate.add(1, 'day');
        }
    
        return workingDaysArray;
    }
    

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        // Calculate leave duration based on working days
        const leaveDuration = calculateWorkingDays(start, end, myUser.work_pattern,myUser.leave_pattern);
        console.log(leaveDuration,"hgdshfgsafgsajhf")
        console.log(leaveDuration,"this is the resultant")
      const filteredData=  leaveDuration.filter((lev)=>lev.working_day=="1")
      console.log(filteredData,"ffabsha")
      setCount(filteredData.length)
        setLeaveDuration(filteredData);

    };



    const handleApplyLeave = () => {
        if(leaveDuration.length==0){
            return 
        }
        if(leaveType==""){
            return toast.error('Please select the Leave Type')
        }
        if(startDate=="" && endDate==""){
            return toast.error('Please select the start and end date')
        }
        if(note==''){
            return toast.error('Please enter note')
        }
        console.log(startDate)
        setLoading(true)
        fetch(`${baseUrl}/mgmt/apply-user/${myUser.id}/leave-application`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authtoken,
            },
            body: JSON.stringify({
                leave_type_id: leaveType,
                start_date: moment(startDate).format("YYYY-MM-DD"),
                end_date: moment(endDate).format("YYYY-MM-DD"),
                leaves: leaveDuration,
                // working_day: true,
                note: note,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
        setLoading(false)
                if (data.metadata.success) {
                    toast.success(data.metadata.message)
                    goBack()
                } else if (!data.metadata.success) {
                    if (toString.call(data.metadata.message) == "[object Object]") {
                      const errorMessageArray = Object.values(
                        data.metadata.message
                      ).flatMap((messages) => messages);
                      errorMessageArray.forEach((message) => toast.error(message));
                    } else if (toString.call(data.metadata.message) == "[object Array]") {
                      Object.values(data.metadata.message).map((msg) => {
                        toast.error(msg);
                      });
                    } else {
                      if(data.metadata.message !=""){
                        toast.error(data.metadata.message);
                      }
                    }
                  } else {
                    toast.error(`Something went wrong`);
                  }
            }).catch(err => {
        setLoading(false)
                toast.error('Something went wrong!')
            })
    }

const duration=[
    {label:"Full Day",id:"3"},
    {label:"First Half",id:"1"},
    {label:"Second Half",id:"2"},
]
const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'days').endOf('day');
  };
console.log(leaveDuration.length,"here is the length")
console.log(leaveDuration,"here is the length")
    return (
        <>
            <Card>
                <CardHeader title="Apply">
                    <CardHeaderToolbar>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <div className='container'>
                        <div className='row'>

                            <div className='col-md-4'>
                                <label>
                                    <b>Select Leave Type</b>
                                </label>
                                <select
                                    onChange={(e) => setLeaveType(e.target.value)}
                                    className="form-control"
                                    style={{ height: 40 }}
                                    aria-label="Default select example"
                                >
                                    <option selected value="">
                                        Select..
                                    </option>
                                    {
                                        list.map((item) => (
                                            <option value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className='col-md-4'>
                                <div>
                                    <label>
                                        <b>Select Start and End Date</b>
                                    </label>
                                </div>
                                <RangePicker disabledDate={disabledDate} onChange={(e, value) => {
                                    console.log("ffff", value); //  ['2024-02-16', '2024-02-19']
                                    handleDateChange(value)
                                }} style={{ width: "100%", height: "40px" }} />
                            </div>
                            <div className='col-md-3'>
                                <div className='btn btn-primary mt-9' style={{ width: "100%" }}>Total Working Day Leaves {count} </div>
                            </div>
                            <div className='col-md-12 mt-2'>
                                <label>
                                    <b>Add Note</b>
                                </label>
                                <textarea
                                    onChange={(e) => setNote(e.target.value)}
                                    className='form-control' placeholder='Add Note' style={{ height: "100px", width: "100%" }}></textarea>
                            </div>
                            <Divider/>
                            {
                                leaveDuration.map((lev,index)=>(    
                                    lev.working_day=="1" &&
                                <div className='col-md-4 mt-3'>
                                    <div className='leaves-cards'>
                                       <div>
                                        <b>Leave Date</b>
                                        <p className='mt-2'>{lev.day}</p>
                                       </div>
                                       <div>
                                        <b>Single Day Options</b>
                                        <select
                                    onChange={(e) => {
                                        leaveDuration[index].duration=e.target.value
                                        setLeaveDuration([...leaveDuration])
                                    }}
                                    className="form-control"
                                    style={{ height: 40 }}
                                    aria-label="Default select example"
                                    value={lev.duration}
                                >
                                    {
                                        duration.map((item) => (
                                            <option value={item.id}>{item.label}</option>
                                        ))
                                    }
                                </select>
                                       </div>
                                    </div>
                                </div>
                                ))
                            }
                        </div>
                        <div className='submit-button-leaver'>
                            <button className='btn btn-secondary' onClick={goBack}>Cancel</button>
                            {loading ? 
                             <div style={{display:"flex",justifyContent:"center",width:"100px"}}>
                                <Spinner variant='dark' animation='border'/>
                             </div>:
                            <button disabled={leaveDuration.length==0 ? true :false} onClick={() => handleApplyLeave()} className='btn btn-primary mx-2'>Submit</button>}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default ApplyLeave
import React from 'react';
import { Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { baseUrl } from '../../../services/config';
import { Modal } from 'antd';
import { toast } from 'react-hot-toast';
import { Input } from '../../../_metronic/_partials/controls';
import { useEffect } from 'react';

const UpdateGroup = ({
  selectedObject,
  open,
  onHide,
  reload,
  name
}) => {
  const [loading, setLoading] = useState(false);
  const authtoken = localStorage.getItem('userToken');

  useEffect(() => {
    formik.setValues({
      name: name
    });
  }, [name]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const initialValues = {
    name: name
  };

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Group Name is Required')
  });

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      enableLoading();

      fetch(`${baseUrl}/mgmt/rotas/${selectedObject.id}?_method=PUT`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authtoken}`
        },
        body: JSON.stringify(values)
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.metadata.success) {
            toast.success(data.metadata.message);
            disableLoading();
            reload();
            onHide();
            resetForm();
          } else if (!data.metadata.success) {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
            disableLoading();
          } else {
            toast.error('unknown error!');
            disableLoading();
          }
        })
        .catch((err) => {
          disableLoading();
          console.log(err);
        });
    }
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  return (
    <Modal
      title={`Update ${selectedObject.name} Group`}
      centered
      open={open}
      okText={'Update'}
      onOk={() => formik.handleSubmit()}
      confirmLoading={loading}
      onCancel={() => {
        onHide();
        formik.resetForm();
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Group Name"
            value={formik.values.name} 
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'name'
            )}`}
            name="name"
            {...formik.getFieldProps('name')}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
      </form>
    </Modal>
  );
};

export default UpdateGroup;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
const OverlapModal = ({
  open,
  close,
  singleDelete,
  muLoading =false
}) => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 0, 0),
      width: 280,
      borderRadius: "20px",
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <p style={{ textAlign: "center" }} id="transition-modal-title">
              <b>
              You have overlapping shifts. Please remove them before going live.
              </b>
            </p>
            {muLoading ? 
             <div style={{display:"flex",justifyContent:"center"}}>
             <div class="loader-button-me"></div> 
             </div>
          :
          <>
          <div>
              <button
                onClick={() => singleDelete()}
                className="deleteRecureButton"
              >
                Delete Shifts
              </button>
            </div>
          </>
          }
            <div>
              <button onClick={() => close()} className="deleteRecureButton2">
                <b>Cancel</b>
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default OverlapModal;

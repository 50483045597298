import { baseUrl } from "./config";
import { serverKey } from "./constants";


// export const SendNotifaction = async (title, des, token, image,extraData) => {
//   const notification = {
//     body: des,
//     title: title,
//     image: image
//   };


//   console.log(token,"test Tokenm")

//   await fetch('https://fcm.googleapis.com/fcm/send', {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: serverKey
//     },
//     method: 'POST',
//     body: JSON.stringify({
//       notification: notification,
//       to: `/topics/${token}`,
//       data:extraData
//     }),
//   })
//     .then(response => response.json())
//     .then(data => {
//       updateHolidays(token,title,des)
//       console.log('response', data);
//     });
// }

export const SendNotifaction = async (title, des, token, image,extraData) => {
    const notification = {
      topic:token?.toString(),
      body: des,
      title: title,
      extraData:{
        type:extraData?.type,
        userId:extraData?.userId?.toString()
      }
    };
  
  
    console.log(token,"test Tokenm")
  
    await fetch('https://shl-push-notification-firebase-nodejs-2kal.vercel.app/notifications', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(notification),
    })
      .then(response => response.json())
      .then(data => {
        updateHolidays(token,title,des)
        console.log('response', data);
      });
  }
export const SendNotifactionChat = async (userId, title, des, postId, receiverId, clickAction, icon, token, image) => {
  const notification = {
    user: userId,
    body: des,
    title: title,
    sent: receiverId,
    post: postId,
    clickAction: clickAction,
    icon: icon,
    image: image
  };

  await fetch('https://fcm.googleapis.com/fcm/send', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: serverKey
    },
    method: 'POST',
    body: JSON.stringify({
      notification: notification,
      to: token,
    }),
  })
    .then(response => response.json())
    .then(data => {
      console.log('response', data);
    });
}
const authtoken = localStorage.getItem("userToken");


const updateHolidays = (userId,title,description) => {

  const data = {
    user_id: userId,
    title: title,
    description: description,
  };
  fetch(
    `${baseUrl}/mgmt/notifications`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    }
  )
    .then((res) => res.json())
    .then((data) => {
   
    })
    .catch((err) => {
      console.log(err);
    });
};
import { useSelector } from 'react-redux'



export const PremissionChecker = (user,permissionName) => {
    if (user) {
        // return user?.role[0]?.permissions.some(permission => permission.name.toLowerCase() === permissionName.toLowerCase());
        for (let i = 0; i < user.role[0]?.permissions.length; i++) {
            if (user.role[0]?.permissions[i].name === permissionName) {
                return true;
            }
        }
        return false;
    }


};

export const PremissionChecker2= (user,permissionName) => {
    if (user) {
        console.log(user,'user')
        console.log(user?.role[0]?.permissions.some(permission => permission.name.toLowerCase() === permissionName.toLowerCase()),"result")
        return user?.role[0]?.permissions.some(permission => permission.name.toLowerCase() === permissionName.toLowerCase());

    }


};




import React, { useEffect } from 'react'
import { useState } from 'react';
import { Divider, Modal } from 'antd';
import * as Yup from "yup";
import { toast } from 'react-hot-toast';
import { baseUrl } from '../../../services/config';
import { Formik, Form, Field, ErrorMessage } from "formik";
import folder1 from '../../asserts/folder1.png'
import folder2 from '../../asserts/folder2.png'
import folder3 from '../../asserts/folder3.png'
import folder4 from '../../asserts/folder4.png'
import folder5 from '../../asserts/folder5.png'
import {
    Input,
    Select,
    DatePickerField,
} from "../../../_metronic/_partials/controls";

const AddFolderModel = ({
    onHide,
    open,
    reload,
    areaId,
    isEdit,
    selectedObject,
    title,
    hidePreviousModal
}) => {




    const [loading, setLoading] = useState(false);

    const authtoken = localStorage.getItem("userToken");
    const [selectedFolder, setSelectedFolder] = useState("");
    const [selectedImage,setSelectedImage]=useState()
    const [key, setKey] = useState(0);

    // const handleImageClick = (folder) => {
    //     dataURItoBlob(folder)
   
    // };
    const addinitialValues = {
        title: '',
        icon: ''
    }
    const CustomerEditSchema = Yup.object().shape({
        title:Yup.string()
        .required('File Name is Required'),
        icon: Yup.mixed().when('type', {
            is: 'file',
            then: Yup.mixed().required('Please select a icon'),
            otherwise: Yup.mixed()
        }),
    });

    const editinitialValues = {
        title: selectedObject.title
    }
 
    const dataURItoBlob = async (dataURI) => {
        setSelectedImage(dataURI)
        const response = await fetch(dataURI);
        const blob = await response.blob();
        // setSelectedFolder(blob);
        // return blob;
        setSelectedFolder(blob)
        console.log(blob)
    };

    const handleSubmit = (values, resetForm) => {
        let url = isEdit ? `${baseUrl}/mgmt/staff-area-items/${selectedObject.id}?_method=PUT` : `${baseUrl}/mgmt/staff-area/${areaId}/staff-area-items`
        // if(!isEdit && !values.icon && selectedFolder==""){
        //     return toast.error("Icon is required")
        // }
        setLoading(true)

        let formdata = new FormData()

        formdata.append('title', values.title)
        formdata.append('category',2)
        formdata.append('icon', values.icon ? values.icon : selectedFolder)

        fetch(url, {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`,
            },
            body: formdata
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    reload()
            
                    setTimeout(() => {
                        resetForm()
                        hidePreviousModal()
                    toast.success(data.metadata.message)
                    setSelectedFolder("")
                    setSelectedImage("")
                    setKey(prevKey => prevKey + 1);
                   
                        onHide()
                        setLoading(false)
                    }, 1000);
                   

                }  else if (!data.metadata.success) {
                    if (toString.call(data.metadata.message) == "[object Object]") {
                      const errorMessageArray = Object.values(
                        data.metadata.message
                      ).flatMap((messages) => messages);
                      errorMessageArray.forEach((message) => toast.error(message));
                    } else if (toString.call(data.metadata.message) == "[object Array]") {
                      Object.values(data.metadata.message).map((msg) => {
                        toast.error(msg);
                      });
                    } else {
                      if(data.metadata.message !=""){
                        toast.error(data.metadata.message);
                      }
                    }
                    setLoading(false)
                  } else {
                    toast.error(`Something went wrong`);
                    setLoading(false)
                  }

            }).catch(err => {
                setLoading(false)
                console.log(err)
            })

    }
console.log(selectedObject,"here we go")


    return (
        <Formik
            enableReinitialize={true}
            initialValues={isEdit != '' ? editinitialValues : addinitialValues}
            validationSchema={CustomerEditSchema}
            onSubmit={(values, { resetForm }) => {
                handleSubmit(values, resetForm);
            }}
        >
            {({ values, resetForm, setFieldValue, }) => (
                <>
                    <Modal
                        title={isEdit ? `Update ${title}` : `Add New Folder`}
                        centered
                        className='my-custom-modal'
                        open={open}
                        okText={isEdit ? "Update" : "Save"}
                        onOk={() => handleSubmit(values, resetForm)}
                        confirmLoading={loading}
                        onCancel={() => {
                            setSelectedFolder("")
                            setSelectedImage("")
                            setKey(prevKey => prevKey + 1);
                            resetForm()
                            onHide()
                        }}
                    >

                        <Form className="form form-label-right">
                            <div className="form-group row">
                                {/* First Name */}
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="icon">Title:&nbsp;&nbsp;</label>
                                        <Field
                                            component={Input}
                                            placeholder="Enter Title"
                                            value={values.title}
                                            name="title"
                                        />
                                    </div>

                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">

                                    <div className="form-group">
                                        <label htmlFor="icon">Icon:&nbsp;&nbsp;</label>
                                        <input
                                        key={key}
                                            id="icon"
                                            name="icon"
                                            type="file"
                                            className='form-control'
                                            onChange={(event) => {
                                                setSelectedImage("")
                                                setSelectedFolder("")
                                                setFieldValue('icon', event.currentTarget.files[0]);
                                            }}
                                        />
                                        <ErrorMessage name="icon" component="div" className="text-danger" />
                                    </div>

                                </div>
                              {!! isEdit &&
                              <>
                              <div className='col-md-12'>
                                 <p>Previous Icon</p>
                                 <img src={selectedObject?.icon?.url} alt='folderIcons' height={100} width={120}/>
                                </div>
                                <Divider/>
                                </>
                                }
                                <div className='col-md-12'>
                                    <div className='row'>
                                    {
                                    [folder1,folder2,folder3,folder4,folder5].map((item)=>(
                                       <div className='col-md-4'>
                                        <img src={item} alt='folderIcons' height={100} width={120} style={{marginRight:"10px",border:item==selectedImage ? "1px solid black" : "",cursor:"pointer"}} onClick={() =>{
                                              setKey(prevKey => prevKey + 1);
                                            setFieldValue('icon',"")
                                            dataURItoBlob(item)}}/>
                                       </div>
                                         ))
                                        }
                                    </div>
                            
                                  
                                </div>

                            </div>

                        </Form>



                    </Modal>
                </>
            )}
        </Formik>
    )
}

export default AddFolderModel
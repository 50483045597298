import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import './announce.scss'
import { Modal, Spinner } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse, Card as Muicard } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SVG from "react-inlinesvg";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import LinearProgress from "@material-ui/core/LinearProgress";
// import Addannouncement from "./Addannouncement";
import { baseUrl } from "../../../services/config";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Empty, Tooltip } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import AddFeed from "./AddFeed";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";
import Departments from "../Departments/Departments";
import NoPermission from "../../components/NoPermission/NoPermission";
// import EditAnnoucment from "./EditAnnoucment";

export default function Feed() {
  const useStyles = makeStyles({
    media: {
      // height: 200,
      // width: '100%'
    },
  });
  const authtoken = localStorage.getItem("userToken");

  const [addModal, setaddModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [editItem, setEditItem] = useState({});
  const [feeds, setFeeds] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("");

  const Ruser = useSelector(state => state.user.user)

  useEffect(() => {
    getFeeds();
  }, []);

  const openEdit = (item) => {
    
    setEditItem(item)
    seteditModal(true)
    setaddModal(true)
  }


  const getFeeds = async () => {
    setloading(true);
    await fetch(`${baseUrl}/mgmt/feeds`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        console.log(data, "======");
        const colorMap = new Map();
        const myAyyAy=data.payload
        myAyyAy.forEach((item)=>{
if(item.comments){
  if(item.comments.length>0){
    for (const comment of item.comments) {
      const name = comment.user;
      if (colorMap.has(name)) {
        comment.color = colorMap.get(name);
      } else {
        const color = getRandomColor();
        colorMap.set(name, color);
        comment.color = color;
      }
    }
  }
}

        })
        setFeeds(myAyyAy);
      })
      .catch((err) => {
        setloading(false);

        console.log("", err);
      });
  };
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const deleteAnnoucement = () => {
    setDeleteLoading(true);
    fetch(`${baseUrl}/mgmt/feeds/${selectedType}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setDeleteLoading(false);
          setdeleteModal(false);
          getFeeds();
        } else if (!data.metadata.success) {
          setDeleteLoading(false);
          toast.error(data.metadata.message);
        } else {
          setDeleteLoading(false);
          toast.error("Unknown error :(");
        }
      })
      .catch((err) => {
        setDeleteLoading(false);
        console.log(err);
      });
  };

  const oppner = (id) => {
    setSelectedType(id);
    setdeleteModal(true);
  };

  const classes = useStyles();
  return (
    <>

      {loading ? <LinearProgress /> : null}
      <Card>
        <>
          <CardHeader title="Feeds">
            <CardHeaderToolbar>
            {
          PremissionChecker(Ruser, "feed.create") ?
              <button
                // disabled={announce?.length<2?false:true}
                type="button"
                className="btn btn-primary mx-2"
                onClick={() => {
                  seteditModal(false)
                  setaddModal(true)
                }}
              >
                New Feed
              </button>
                   :
                   null
               }
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
           { PremissionChecker(Ruser, "feed.list") ? <div className="row">
              {/* <div className="col-l-4"> */}
              <>
                {feeds?.map((item)=>(
 <div style={{ height: 430 }} className="mt-5 col-3">
 <div className="annouccard" style={{minHeight:"400px"}}>
     <Link to={{ pathname: '/feed-view', state: { item } }}>
   <CardActionArea>
     <div style={{ height: 250 }}>
{
  item?.file?.type.split('/')[0]=="image"?
  <img
         style={{ height: 250, width: "100%",borderTopLeftRadius:"10px",borderTopRightRadius:"10px" }}
         src={item.file?.url}
         //  src={item?.image?.url}
       />:
       <video src={item.file?.url} controls style={{ height: 250, width: "100%" }}/>
}
     
     </div>
 
     <CardContent>
       <Typography style={{color:"black"}} gutterBottom variant="h5" component="h2">
         {item.title.length > 24 ? item.title.slice(0, 24) + '...' : item.title}
       </Typography>
       <Tooltip
         placement="top"
         //   title={item?.description}
       >
         {/* <Typography style={{ width: "fit-content",color:"black" }}>
           {item?.description.length > 50 ? item?.description.slice(0, 50) + "...." : item?.description}
         </Typography> */}
       </Tooltip>
     </CardContent>
   </CardActionArea>
   </Link>


   <CardActions style={{ justifyContent: "center" }}>
   {
          PremissionChecker(Ruser, "feed.delete") ?
     <a
       onClick={() => oppner(item.id)}
       className="btn btn-icon btn-light btn-hover-danger btn-sm"
     >
       <span
       title="Delete"
       className="svg-icon svg-icon-md svg-icon-danger">
         <SVG
           src={toAbsoluteUrl(
             "/media/svg/icons/General/Trash.svg"
           )}
         />
       </span>
     </a>
          :
          null
      }
        {
          PremissionChecker(Ruser, "feed.update") ?
     <a
       onClick={() =>
         openEdit(item)
       }
       className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
     >
       <span className="svg-icon svg-icon-md svg-icon-primary">
         <SVG
           title="Edit"
           src={toAbsoluteUrl(
             "/media/svg/icons/Communication/Write.svg"
           )}
         />
       </span>
     </a>
          :
          null
      }
   </CardActions>
 </div>
</div>
                ))
           }

              </>
            </div> :
             <NoPermission/>
            }
          </CardBody>
        </>
      </Card>
      <Modal size="md" animation={true} show={addModal}>
      <Modal.Header><h4>
          {editModal ? 'Edit Feed' : 'Add New Feed'}
        </h4></Modal.Header>
        <AddFeed
          reload={()=>getFeeds()}
          editItem={editModal ? editItem : ""}
          isEdit={editModal ? true : false}
          saveCustomer={(values) => console.log(values)}
          onHide={() => setaddModal(false)}
        />
      </Modal>


      <Modal centered show={deleteModal}>
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        {deleteLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
            }}
          >
            <Spinner animation="border" color="green" />
          </div>
        ) : (
          <>
            <Modal.Body>Are you sure you want to Delete?</Modal.Body>
            <Modal.Footer>
              <button
                onClick={() => setdeleteModal(false)}
                className="btn btn-danger"
              >
                Close
              </button>

              <button onClick={deleteAnnoucement} className="btn btn-primary">
                Delete
              </button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

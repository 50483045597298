import React from "react";
// import { Modal, Spinner } from "react-bootstrap";
// import Checkbox from "@material-ui/core/Checkbox";
// import { Divider } from "antd";



import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { MdEditCalendar } from "react-icons/md";
import { MdEventRepeat } from "react-icons/md";
import { Divider } from "antd";
import image1 from  './image1.png'
import image2 from  './image2.png'
const ConfirmForRecurring = ({
  modalVisible,
  hideModal,
  onClickAnyWay,
  title,
  btnText,
  modelTitle,
  loading,
  mainLoading = false,
  onClickAnyWay2,
  hasPublishDraft = ()=>{},
  handleCheck=()=>{}
}) => {

    const useStyles = makeStyles((theme) => ({
        modal: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: mainLoading ? 0.3 : 1,
        },
        paper: {
          backgroundColor: theme.palette.background.paper,
          opacity: mainLoading ? 0.3 : 1,
          // border: '2px solid #000',
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 2, 0),
          width: 380,
          borderRadius: "20px",
        },
      }));
  const classes = useStyles();

  return (
    // <Modal
    //   style={{ opacity: mainLoading ? 0.3 : 1 }}
    //   centered
    //   show={modalVisible}
    // >
    //   <Modal.Header>
    //     <Modal.Title>{modelTitle}</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     {title}
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <button onClick={hideModal} className="btn btn-danger">
    //       Cancel
    //     </button>
    //     <button onClick={onClickAnyWay2} className="btn btn-primary">
    //        Edit Default 
    //       </button>
    //     {loading ? (
    //       <Spinner
    //         style={{ marginLeft: "20px" }}
    //         animation="border"
    //         color="green"
    //       />
    //     ) : (
    //       <button onClick={onClickAnyWay} className="btn btn-primary">
    //         {btnText}
    //       </button>
    //     )}
    //   </Modal.Footer>
    // </Modal>
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={modalVisible}
    onClose={() => hideModal()}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={modalVisible}>
      <div className={classes.paper}>
        <p style={{ textAlign: "center" }} id="transition-modal-title">
          <b>
          Do you want to repeat this shift
          </b>
        </p>

         <div className="d-flex" style={{gap:"1px"}}>
           <div className="text-center">
              <img onClick={onClickAnyWay}  src={image1} style={{width:"170px",height:"150px",borderRadius:"10px"}}/>
              <button onClick={onClickAnyWay}  type="button" class="btn btn-outline-dark w-50 btn-sm">Edit</button>
           </div>
           <div className="text-center">
           <img onClick={onClickAnyWay2}  src={image2} style={{width:"170px",height:"150px",borderRadius:"10px"}}/>
           <button onClick={onClickAnyWay2} type="button" class="btn btn-outline-dark w-50 btn-sm">Repeat</button>
           </div>
         </div>
        <div>
          <button onClick={() => hideModal()} className="deleteRecureButton2">
            <b>Cancel</b>
          </button>
        </div>
      </div>
    </Fade>
  </Modal>
  );
};

export default ConfirmForRecurring;

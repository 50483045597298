import React from 'react'
import { CustomersPage } from '../../modules/ECommerce/pages/customers/CustomersPage'

const UserManagment = () => {
  return (
    <>
    <CustomersPage/>
    </>
  )
}

export default UserManagment
import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { baseUrl } from "../../../services/config";
import { Checkbox, Divider } from "antd";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Input } from "../../../_metronic/_partials/controls";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";
import './tabs.css'
const AssignedModal2 = ({ visible, closeModel, item, reload, users,currentCategory }) => {
  const [usersList, setUsersList] = useState([]);
  const authtoken = localStorage.getItem("userToken");
  const params = useParams();

  const { id, groupname } = params;
  const user = useSelector((state) => state.user.user);
  const [loading, setloading] = useState(false);
  const [assignedLoading, setAssignedLoading] = useState(false);
  const [secondUserList, setSecondUserList] = useState([]);
  useEffect(() => {
    getUsers();
  }, [item]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getUsers = () => {
    setloading(true);
    fetch(`${baseUrl}/mgmt/staff-areas/${id}/assigned-users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
      
        getAssignedUsers(data.payload, item.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
 
  const getAssignedUsers = (users, id) => {
    let array = [...users]; // Make a copy of the users array
    fetch(`${baseUrl}/mgmt/staff-area-item/${id}/assigned-users`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload, "this is yrr");
        let dummy = data.payload.map(item => item.id); // Extract ids from data.payload
        console.log(dummy, "this is dummy");
        let checkboxes = array.map(item => ({ ...item })); // Create a copy of array
        checkboxes.forEach((item, index) => {
          if (dummy.includes(item.id)) {
            item.checked = true;
            item.selected = true;
          } else {
            item.checked = false;
            item.selected = false;
          }
        });
        setUsersList(checkboxes);
        changinMyData([...checkboxes]); // Pass a copy of checkboxes to avoid mutation
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  const changinMyData = (users) => {
    let myAllusers = [...users.map(user => ({...user}))]; // Create a deep copy of the users array
    console.log(myAllusers, "myUsers");
  
    if (item.children.length > 0) {
      let arr = [];
      item.children.forEach((fi) => {
        let dummy = [];
        fi.users.forEach((u) => dummy.push(u.id)); 
  
        let updatedUsers = myAllusers.map((item) => {
          if (dummy.includes(item.id)) {
            return { ...item, checked: true, selected: true }; 
          } else {
            return { ...item, checked: false, selected: false  }; 
          }
        });
  
        arr.push({ fileId: fi.id, users: updatedUsers,isFolder:fi.isFolder });
      });
    if(!PremissionChecker(user, "file.assign_user")){
     const result= arr.filter((item)=>item.isFolder)
     setSecondUserList(result);
     console.log(result, "dd=>>>>>>>>>>>>>>>>>>>>>>>>>>>>");


    }else{
      setSecondUserList(arr);
    }
      console.log(arr, "dd=>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    }
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div className="row">{children}</div>}
      </div>
    );
  }

  const handleCheckboxChange = (itemId) => {
    setUsersList((prevData) => {
      const newData = [...prevData];
      const itemIndex = newData.findIndex((item) => item.id === itemId);
      newData[itemIndex].checked = !newData[itemIndex].checked;
      return newData;
    });
  };

  const handleCheckboxChange2 = (parentIndex,childIndex) => {
    setSecondUserList((prevData) => {
      const newData = [...prevData];
      newData[parentIndex].users[childIndex].checked = !newData[parentIndex]
        .users[childIndex].checked;
      return newData;
    });
  };

  const handleCheckAllChange = (event) => {
    setUsersList((prevData) => {
      const newData = [...prevData];
      newData.forEach((item) => (item.checked = event.target.checked));
      return newData;
    });
  };

  const assignUsersToGroup = () => {
    let filterdUsers = usersList.filter((item) => item.checked == true);
    let ids = filterdUsers.map((item) => item.id);
    // if (ids.length == 0) {
    //   if(item.children.length>0 ){
    //     assignUsersToFiles()
    //   }else{
    //     setAssignedLoading(false);
    //     closeModel();
    //   }
    //   // toast.error("Please Select At Least 1 User");
    //   return;
    // }
    setAssignedLoading(true);

    let formData = new FormData();
    if(filterdUsers.length>0){
      for (let i = 0; i < filterdUsers.length; i++) {
        formData.append(`user_ids[${i}]`, ids[i]);
      }
    }
    

    fetch(`${baseUrl}/mgmt/staff-area-item/${item.id}/assign-users`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          if(item?.children?.length>0 ){
          assignUsersToFiles()
          }else{
            toast.success("Users assigned successfully");
            setAssignedLoading(false);
            closeModel();
          }
          // toast.success(data.metadata.message);
          // setAssignedLoading(false);
          // closeModel();
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
          setAssignedLoading(false);
        }
        // else {
        //     toast.error(`At Least One User Is Required`)
        //     setAssignLoading(false)

        // }
      })
      .catch((err) => {
        setAssignedLoading(false);
        console.log(err);
      });
  };

  const assignUsersToFiles = () => {
    let arr=[]
   secondUserList.map((fi)=>{
    const userIds=[]
    fi.users.filter((use)=>{
        if(use.checked == true){
          userIds.push(use.id)
        }
      })
     arr.push({file_id:fi.fileId,user_ids:userIds})
    });

    console.log(arr,"????????????????")

 
    setAssignedLoading(true);

  

    fetch(`${baseUrl}/mgmt/assign-users-to-files`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({data_array:arr}),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          reload()
          setAssignedLoading(false);
          closeModel();
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
          setAssignedLoading(false);
        }
      
      })
      .catch((err) => {
        setAssignedLoading(false);
        console.log(err);
      });
  };

  const handleSingleCheckAll = (index, event) => {
    console.log(event.target.checked, "this is working");
    setSecondUserList((prevData) => {
      const newData = [...prevData];
      newData.forEach((parent, ind) => {
        if (index == ind) {
          parent.users = parent?.users?.map((item) => ({
            ...item,
            checked: event.target.checked,
          }));
        } else {
        }
      });
      return newData;
    });
  };
  return (
    <Modal
      show={visible}
      centered
      animation
      size="lg"
      //  dialogAs={'animate'}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>Assign Users</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <>
          <div className="folder-container">
          <div className="mb-2">
              <h4>{currentCategory=="file" ? "File" : 'Folder'}</h4>
            </div>
            <Divider />

            <Checkbox
              checked={usersList.every((item) => item.checked === true)}
              onChange={handleCheckAllChange}
            >
              Check All
            </Checkbox>

            <Divider />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {usersList.map((user, i) => (
                <div style={{ width: "25%" }}>
                  <Checkbox
                    checked={user.checked}
                    onChange={(event) => handleCheckboxChange(user.id)}
                  >
                    {user.username}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
          {
            currentCategory !="file"  && PremissionChecker(user, "staff_area.assign_user")  ? 
            <>

            <Divider />
            <div className="mb-2">
              <h4>Childern (Folder / Files) </h4>
            </div>
            <Divider />

            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              className="staff-area-tabs"
            >
         {item?.children?.length &&
                item.children.map((tab, i) => !PremissionChecker(user, "file.assign_user") ?  (tab.isFolder ?  <Tab label={tab.title} key={i} /> : '') :  <Tab label={tab.title} key={i} /> )}
            </Tabs>
            {secondUserList.map((item, i) => (
              <TabPanel className="staff-area-tab-pannel" value={value} index={i}>
                <Divider />
                <div style={{ padding: "0px 13px" }}>
                  <Checkbox
                    checked={secondUserList[i]?.users?.every(
                      (item) => item.checked === true
                    )}
                    onChange={(event) => handleSingleCheckAll(i, event)}
                  >
                    Check All
                  </Checkbox>
                </div>

                <Divider />
                <div
                  style={{
                    padding: "0px 13px",
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {item.users.map((user, index) => (
                    <div style={{ width: "25%" }}>
                      <Checkbox
                        checked={user.checked}
                        onChange={(event) => handleCheckboxChange2(i, index)}
                      >
                        {user.username}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              </TabPanel>
            ))}
            </>
            : ''
          }
         
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div onClick={closeModel} className="btn btn-danger ml-2">
          Close
        </div>

        {PremissionChecker(user, "folder.assign_user") ? (
          <button
            onClick={assignUsersToGroup}
            className="btn btn-primary"
            disabled={assignedLoading}
          >
            Assign
          </button>
        ) : (
          ""
        )}

        {/* <button onClick={closeModel} className="btn btn-danger">Close</button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default AssignedModal2;

import React from 'react'
import { useState } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls'
import { Modal } from 'antd';
import { useFormik } from "formik";
import * as Yup from "yup";
import { baseUrl } from "../../../services/config";
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';
import GroupCard from '../../components/GroupCard/GroupCard';
import UpdateGroup from '../../components/UpdateGroup/UpdateGroup';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { Spinner } from 'react-bootstrap';
import { PremissionChecker } from '../../../services/PremissionChecker';
import { useSelector } from 'react-redux';
import SettingModal from '../../components/settingmodal/SettingModal'




const RotaGroup = () => {

    const authtoken = localStorage.getItem("userToken");

    const [fetchLoading, setFetchloading] = useState(false)
    const [modal1Open, setModal1Open] = useState(false);
    const [settingmodal, setSettingmodal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [groupList, setgroupList] = useState([])
    const [isEdit, setisEdit] = useState(false)
    const [selectedObject, setSelectedObject] = useState({})
    const [deleteModal, setdeleteModal] = useState(false)
    const user = useSelector(state => state.user.user)
    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };


    const initialValues = {
        name: '',
    };
    const resetFormValues = () => {
        formik.resetForm();
    };

    useEffect(() => {
        getRotaGroups()
    }, [])


    const LoginSchema = Yup.object().shape({
        name: Yup.string()
            .required('Group Name is Required'),
    });


    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            enableLoading();
            fetch(`${baseUrl}/mgmt/rotas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authtoken}`,
                },
                body: JSON.stringify(values)
            }).then(res => res.json())
                .then((data) => {
                    if (data.metadata.success) {
                        toast.success(data.metadata.message)
                        disableLoading()
                        getRotaGroups()
                        setModal1Open(false)
                        resetForm()
                    } else if (!data.metadata.success) {
                        Object.values(data.metadata.message).map(msg => {
                            toast.error(msg)
                        })
                        disableLoading()

                    } else {
                        toast.error('unknown error!')
                        disableLoading()

                    }

                }).catch(err => {
                    disableLoading()

                    console.log(err)
                })


        },
    });


    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };


    const getRotaGroups = () => {
        setFetchloading(true)
        fetch(`${baseUrl}/mgmt/rotas`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            }
        }).then((res) => res.json().then((data) => {
            console.log(data)
            setgroupList(data.payload)
            setFetchloading(false)
        })).catch(err => {
            console.log(err)
            setFetchloading(false)
        })


    }



    const onEditClick = item => {
        setSelectedObject(item)
        setEditModal(true)
    }
    const onDeleteClick = item => {
        setSelectedObject(item)
        setdeleteModal(true)
    }




    return (
        <>
            <Card>
                <CardHeader title="Rota Planner">
                    <CardHeaderToolbar>
                        {/* {
                            PremissionChecker(user, "shift_title.list") ?
                                <button
                                    type="button"
                                    className="btn btn-primary mx-3"
                                    onClick={() => {
                                        setSettingmodal(true)
                                    }}
                                >
                                    Setting
                                </button>
                                :
                                null
                        } */}
                        {
                            PremissionChecker(user, "rota.create") ?
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setModal1Open(true)
                                    }}
                                >
                                    New Group
                                </button>
                                :
                                null
                        }
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>

                    {
                        fetchLoading ?
                            <div className='d-flex justify-content-center align-items-center'>
                                <Spinner animation='border' />
                            </div>
                            :

                            <>
                                {
                                    groupList.length == 0 ?
                                        <div className='d-flex align-items-center justify-content-center flex-wrap'>
                                            <span className='text-warning font-weight-bold'>No Shift scheduling</span>
                                        </div>
                                        : null
                                }
                                <div className='row d-flex justify-content-center'>
                                    {
                                        groupList && groupList.map((item, index) => {
                                            return <GroupCard
                                                key={item.id}
                                                onEditClick={(item) => onEditClick(item)}
                                                onDeleteClick={(item) => onDeleteClick(item)}
                                                loading={loading}
                                                index={index}
                                                item={item}
                                                refresh={getRotaGroups}
                                            />
                                        })
                                    }

                                </div>
                            </>
                    }
                </CardBody>
            </Card>

            <Modal
                title={"New Rota Planner Group"}
                centered
                open={modal1Open}
                okText={"Create new Group"}
                onOk={() => formik.handleSubmit()}
                confirmLoading={loading}
                onCancel={() => {
                    resetFormValues();
                    setModal1Open(false)
                }}
            >
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Group Name"
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "name"
                            )}`}
                            name="name"
                            {...formik.getFieldProps("name")}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.name}</div>
                            </div>
                        ) : null}
                    </div>

                </form>

            </Modal>

            <SettingModal
                open={settingmodal}
                onHide={() => {
                    setSettingmodal(false)
                }}
                // onOk={() => formik.handleSubmit()}
                reload={getRotaGroups}
            />
            <UpdateGroup
                open={editModal}
                selectedObject={selectedObject}
                reload={getRotaGroups}
                onHide={() => setEditModal(false)}
                name={selectedObject.name}
            />

            <DeleteModal
                visible={deleteModal}
                HideModal={() => setdeleteModal(false)}
                method={'DELETE'}
                url={`${baseUrl}/mgmt/rotas/${selectedObject.id}`}
                reload={getRotaGroups}

            />
        </>
    )
}

export default RotaGroup
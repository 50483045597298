import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { baseUrl } from "../../../services/config";
import { toast } from "react-hot-toast";
import { Input, Checkbox } from "../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function HolidayTypeModel({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  editItem,
  reload,
  id,
}) {
  console.log(editItem);
  const [loading, setLoading] = useState(false);
  const [logo, setlogo] = useState();
  const [logoImage, setlogoImage] = useState();
  const [checked, setChecked] = useState(editItem.paid);
  const authtoken = localStorage.getItem("userToken");
  const [leaves, setleaves] = useState('')
  const [settingObj, setsettingObj] = useState({})
  const [updateLoading, setUpdateLoading] = useState(false)

  const CustomerEditSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    type: Yup.string().notRequired().nullable(),
  });

  const editinitialValues = {
    name: editItem.name,
    type: editItem?.type,
    color: editItem.color
  };
  const addinitialValues = {
    name: "",
    type: "",
    color: ""
  };



  useEffect(() => {
    getSettings()
  }, [])


  const getSettings = () => {
    fetch(`${baseUrl}/asset/setting`)
      .then(res => res.json())
      .then(data => {
        setsettingObj(data.payload)
        setleaves(data?.payload?.allow_leaves)
      })
      .catch(err => {
        console.log(err)
      })
  }


  const handleSubmit = (values) => {
    if (editItem.name == 'Annual leave') {
      updateSettings()
    }
    // setLoading(true)
    setUpdateLoading(true)
    console.log("this is values", values);
    const fdata = new FormData();
    fdata.append("name", values.name);
    fdata.append("type", values.type);
    fdata.append("color", values.color);
    fdata.append("paid", checked == true ? 1 : 0);
    if (logo) {
      fdata.append("icon", logo);
    }
    if (logoImage) {
      fdata.append("image", logoImage);
    }

    const editUrl = `mgmt/leave-types/${editItem.id}?_method=PUT`;
    const addUrl = `mgmt/leave-types`;

    fetch(`${baseUrl}/${editItem ? editUrl : addUrl} `, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: fdata,
    })
      .then((resp) => {
        resp.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              reload();
              onHide();
              setUpdateLoading(false)
              setlogo("");
              toast.success(data.metadata.message);


            } else if (!data.metadata.success) {
              Object.values(data.metadata.message).map((msg) => {
                toast.error(msg);
              });
              setUpdateLoading(false)

            }
          }
        });
      })
      .catch((err) => {
        setUpdateLoading(false)
        console.log(err);
        toast.error("Opps something wents wronge");
        onHide();
      });
  };




  const updateSettings = () => {

    const data = {
      allow_leaves: leaves
    }

    setTimeout(() => {
      fetch(`${baseUrl}/mgmt/setting/update?_method=PUT`, {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(data => {
          if (data.metadata.success) {
            // toast.success(data.metadata.message)
            // getSettings()

          }
          // setsettingObj(data.payload)
        })
        .catch(err => {
          console.log(err)


        })
    }, 2000);

  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={editItem != "" ? editinitialValues : addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values }) => (
          <>
            <Form className="form form-label-right">
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label>Enter Name</label>
                    <Field
                      component={Input}
                      placeholder="Enter Name"
                      value={values.name}
                      name="name"
                    />
                  </div>

                  <div className="col-lg-1 col-md-1 col-sm-1 mt-1">
                    <label>Paid</label>
                    <input type="checkbox" style={{ display: "none" }} />
                    <label className="checkbox checkbox-lg checkbox-single">
                      <input
                        type="checkbox"
                        defaultChecked={editItem.paid}
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                      />
                      <span />
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 mt-4">
                    <label>Color</label>
                    <Field
                      type="color"
                      component={Input}
                      value={values.color}
                      name="color"
                    />
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6 mt-4">
                    <label>Enter Type</label>
                    <Field
                      component={Input}
                      placeholder="Enter Type"
                      value={values.type}
                      name="type"
                    />
                  </div> */}
                </div>

                <div className="form-group row">
                  {
                    editItem.name == 'Annual leave' ?
                      <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                        <label>Leaves</label>
                        <input
                          className='form-control'
                          placeholder={`Type leaves`}
                          value={leaves}
                          onChange={(e) => setleaves(e.target.value)}
                        />

                      </div>
                      : null

                  }
                </div>

                <div className="form-group row">
                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <div className="mt-5">
                      <label>Please select image</label>
                    </div>
                    <label
                      htmlFor="fileImage"
                      style={{
                        marginTop: 10,
                        padding: 10,
                        borderRadius: 5,
                        marginRight: 10,
                        backgroundColor: "#E6E9EC",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{
                          height: 100,
                          width: 100,
                          // aspectRatio:3/2,
                          // objectFit:'contain',
                          // mixBlendMode:'color-burn'
                        }}
                        // src={toAbsoluteUrl("/media/logos/upload-icons.png")}
                        src={
                          logoImage
                            ? URL.createObjectURL(logoImage)
                            : editItem
                              ? editItem.image?.url
                              : `${toAbsoluteUrl(
                                "https://www.pngarts.com/files/2/Upload-Transparent-Images.png"
                              )}`
                        }
                      />
                    </label>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id={`fileImage`}
                      // ref={fileRef}
                      accept="image/png, image/jpeg"
                      onChange={(e) => {
                        setlogoImage(e.target.files[0]);
                      }}
                    // onChange={(e) => handleChange(e.target.files[0])}
                    />
                  </div>

                  <div className="col-md-6 col-lg-6 col-sm-12">
                    <div className="mt-5">
                      <label>Please select icon</label>
                    </div>
                    <label
                      htmlFor="fileImage2"
                      style={{
                        marginTop: 10,
                        padding: 10,
                        borderRadius: 5,
                        marginRight: 10,
                        backgroundColor: "#E6E9EC",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{
                          height: 100,
                          width: 100,
                          // aspectRatio:3/2,
                          // objectFit:'contain',
                          // mixBlendMode:'color-burn'
                        }}
                        // src={toAbsoluteUrl("/media/logos/upload-icons.png")}
                        src={
                          logo
                            ? URL.createObjectURL(logo)
                            : editItem
                              ? editItem.icon?.url
                              : `${toAbsoluteUrl(
                                "https://www.pngarts.com/files/2/Upload-Transparent-Images.png"
                              )}`
                        }
                      />
                    </label>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id={`fileImage2`}
                      // ref={fileRef}
                      accept="image/png, image/jpeg"
                      onChange={(e) => {
                        setlogo(e.target.files[0]);
                      }}
                    // onChange={(e) => handleChange(e.target.files[0])}
                    />
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-danger btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  disabled={updateLoading}
                  type="submit"
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
import { Grid, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { useState, useEffect } from 'react'
import FolderIcon from '@material-ui/icons/Folder';
import { makeStyles } from '@material-ui/core/styles';
import { baseUrl } from '../../../services/config';
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Divider, Menu } from 'antd';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import './style.css'
import { useSelector } from 'react-redux';
import AssignRotaAndHoliday from '../../components/AssignRotaAndHoliday/AssignRotaAndHoliday';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import toast from 'react-hot-toast';
import { PremissionChecker } from '../../../services/PremissionChecker';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));


const items = [
  {
    key: '1',
    icon: <PieChartOutlined />,
    label: 'User Managment',
    // children: [
    //   {
    //     key: '1-1',
    //     label: 'Users',
    //   },
    //   {
    //     key: '1-2',
    //     label: 'Roles',
    //   },
    //   {
    //     key: '1-3',
    //     label: 'Leaver',
    //   },
    // ],
  },
  {
    key: '2',
    icon: <DesktopOutlined />,
    label: 'Holiday',
    // children: [
    //   {
    //     key: '2-1',
    //     label: 'Holiday Management',
    //   },
    //   {
    //     key: '2-2',
    //     label: 'Holiday Settings',
    //   },
    // ],
  },
  {
    key: '3',
    icon: <ContainerOutlined />,
    label: 'Rota Planner',
  },
  {
    key: '4',
    icon: <ContainerOutlined />,
    label: 'DVLA Drivers',
  },
  {
    key: '5',
    icon: <ContainerOutlined />,
    label: 'Staff Announcement',
  },
  {
    key: '6',
    icon: <ContainerOutlined />,
    label: 'Staff Area',
  },

];

const Permissions = () => {
  let location = useLocation();
  let params = useParams();
  const myRole = location.state;
  const user = useSelector((state) => state.user.user);

  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const [SelectedId, setSelectedId] = useState("");
  const [list, setList] = useState([]);
  const [myRoleIndexArray, setMyRoleIndexArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [allHoliGroups, setAllHoliGroups] = useState([]);
  const [allRotaGroups, setAllRotaGroups] = useState([]);
  const [defHoliGroups, setDefHoliGroups] = useState([]);
  const [defRotaGroups, setDefRotaGroups] = useState([]);
  const [buttonOneLoading, setButtonOneLoading] = useState(false);
  const [buttonTwoLoading, setButtonTwoLoading] = useState(false);
  const authtoken = localStorage.getItem("userToken");
 const [selectedTab,setSelectedTab]=useState("1")
const [attachLoading,setAttachLoading]=useState(false)
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
const usersList=[
  {
    username:"User can create user"
  },
  {
    username:"User can edit user"
  },
  {
    username:"User can delete user"
  },
  {
    username:"User can apply leave application"
  },
  {
    username:"User can create user"
  },
  {
    username:"User can edit user"
  },
  {
    username:"User can delete user"
  },
  {
    username:"User can apply leave application"
  },
  {
    username:"User can create user"
  },
  {
    username:"User can edit user"
  },
  {
    username:"User can delete user"
  },
  {
    username:"User can apply leave application"
  },
]








useEffect(() => {
  getAllHolidayGropups();
  getAllRotaGropups();
}, [attachSubmit]);

const getAllRotaGropups = () => {
  fetch(`${baseUrl}/mgmt/rotas`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  })
    .then((res) => res.json())
    .then((resp) => {
      if (resp.metadata.success) {
        let temparr = [];
        resp.payload.forEach((item) => {
          temparr.push({
            label: item.name,
            value: item.id,
          });
        });
        setAllRotaGroups(temparr);
      }
    });
};


const getAllHolidayGropups = () => {
  fetch(`${baseUrl}/mgmt/leave-groups`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  })
    .then((res) => res.json())
    .then((resp) => {
      if (resp.metadata.success) {
        let temparr = [];
        resp.payload.forEach((item) => {
          temparr.push({
            label: item.name,
            value: item.id,
          });
        });

        setAllHoliGroups(temparr);
      }
    });
};

const getAlreadyAssignedGroups = (id) => {
  setButtonOneLoading(true);
  fetch(`${baseUrl}/mgmt/role/${id}/assigned/leave-groups`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  })
    .then((res) => res.json())
    .then((resp) => {
      if (resp.metadata.success) {
        let temparr = [];
        resp.payload.forEach((item) => {
          temparr.push({
            label: item.name,
            value: item.id,
          });
        });
        setDefHoliGroups(temparr);
        setOpen2(false);
        setOpen(true);
        setButtonOneLoading(false);
      }
    });
};


const getAlreadyAssignedRotaGroups = (id) => {
  setButtonTwoLoading(true);
  fetch(`${baseUrl}/mgmt/role/${id}/assigned/rotas`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authtoken}`,
    },
  })
    .then((res) => res.json())
    .then((resp) => {
      if (resp.metadata.success) {
        let temparr = [];
        resp.payload.forEach((item) => {
          temparr.push({
            label: item.name,
            value: item.id,
          });
        });
        setDefRotaGroups(temparr);
        setOpen(false);
        setOpen2(true);
        setButtonTwoLoading(false);
      }
    });
};




 

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = () => {
    setloading(true);
    fetch(`${baseUrl}/asset/permissions`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {

        ///old
        //  let res= data.payload.sort((a, b) => a.name.localeCompare(b.name));
        //  data.payload=res

        ///new
        data.payload.sort((a, b) => {
          const nameA = a.name.split(".")[0];
          const nameB = b.name.split(".")[0];
          return nameA.localeCompare(nameB);
        });

        console.log("i think so permission", data.payload);
        convertToIndexArray(data.payload);


        setList(data.payload);
        getPermissionsbyRole(params.id,data.payload)
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };


  const getPermissionsbyRole = (id,list) => {
    console.log("running");
    fetch(`${baseUrl}/mgmt/roles/${id}/permissions`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let dummy = [];
        for (let i = 0; i < data.payload.length; i++) {
          dummy[i] = data.payload[i].id;
        }

        let checkboxes = list;
        list.map((item, index) => {
          if (dummy.includes(item.id)) {
            item["checked"] = true;
            item["selected"] = true;
            checkboxes[index] = item;
          } else {
            item["checked"] = false;
            item["selected"] = false;
            checkboxes[index] = item;
          }
        });
        console.log("List", checkboxes);
        console.log(checkboxes, "fsdfhgsdhf")

        const outputArray = [];

        checkboxes.forEach((item) => {
          const [category, action] = item.name.split(".");
          let existingCategory = outputArray.find((categoryObj) => categoryObj.name === category);

          if (!existingCategory) {
            existingCategory = { name: category, data: [] };
            outputArray.push(existingCategory);
          }

          existingCategory.data.push(item);
        });
        console.log(outputArray,"here it is=>>>>>>>>>.")


        setList(rearrangeArray(outputArray));
        setSelectedId(id);
        setloading(false);
      });
  };

  function rearrangeArray(arr) {
    const order = [
        'announcement', 'feed', 'holiday_group', 'leave_application', 
        'leave_calendar', 'leave_type', 'rota', 'rota_shift', 
        'rota_setting', 'staff_area', 'file', 
        'folder', 'department', 'user', 'role', 'leaver', 'dvla'
    ];

    const orderMap = order.reduce((acc, name, index) => {
        acc[name] = index;
        return acc;
    }, {});

    return arr.sort((a, b) => orderMap[a.name] - orderMap[b.name]);
}

function titleCase(str) {
  // Split the string into an array of words
  let words = str.toLowerCase().split(' ');

  // Capitalize the first letter of each word
  for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back into a single string and return it
  return words.join(' ');
}

  const attachSubmit = () => {
   setAttachLoading(true)
    let arr=[]
     list.map((mainItem)=>(
      mainItem.data.filter((item) => {
        if(item.checked){
          arr.push(item.id)
        }
      
      })
    ))
    console.log(arr)
      
    const formData = new FormData();

    for (let i = 0; i < arr.length; i++) {
      formData.append(`permissions[${i}]`, arr[i]);
    }

    fetch(`${baseUrl}/mgmt/roles/${SelectedId}/attach-permissions`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
   setAttachLoading(false)
        if (data.metadata.success) {
          getPermissions();
         toast.success(data.metadata.message)
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
          });
        }
      })
      .catch((err) => {
   setAttachLoading(false)
        console.log(err);
      });
  };

  const handleCheckboxChange = (parentIndex, childIndex) => {
    let previous = null;

    // for (let i = 0; i < myRoleIndexArray.length; i++) {
    //   if (myRoleIndexArray[i] === itemId) {
    //     previous = myRoleIndexArray[i - 1];
    //     break;
    //   } else if (myRoleIndexArray[i] < itemId) {
    //     previous = myRoleIndexArray[i];
    //   } else {
    //     break;
    //   }
    // }
    setList((prevData) => {
      const newData = [...prevData];
      // const parentIndex = newData.findIndex((parent) => parent.id == parentId);
      // const childIndex = newData[parentIndex].data.findIndex((item) => item.id == itemId);
      // console.log(parentIndex)
      // console.log(childIndex)

      newData[parentIndex].data[childIndex].checked = !newData[parentIndex].data[childIndex].checked;
      return newData;
    });

    // setList((prevData) => {
    //   const newData = [...prevData];
    //   const itemIndex = newData.findIndex((item) => item.id === itemId);
    //   newData[itemIndex].checked = !newData[itemIndex].checked;
    //   return newData;
    // });
    // if(itemId !=previous){
    //   setList((prevData) => {
    //     const newData = [...prevData];
    //     const itemIndex = newData.findIndex((item) => item.id === previous+1);
    //     console.log(itemIndex)
    //     newData[itemIndex].checked = true
    //     return newData;
    //   });
    // }
    // console.log(previous)
    // console.log(itemId)
    // if(itemId ==previous){
    //   setList((prevData) => {
    //     const newData = [...prevData];
    //     const itemIndex = newData.findIndex((item) => item.id === previous+1);
    //     console.log(itemIndex)
    //     newData[itemIndex].checked = !newData[itemIndex].checked
    //     return newData;
    //   });
    // }
  };

  const handleCheckAllChange = (event) => {
    setList((prevData) => {
      const newData = [...prevData];

      // Update the checked status for all checkboxes
      newData.forEach((parent) => {
        parent.data = parent?.data?.map((item) => ({
          ...item,
          checked: event.target.checked,
        }));
      });

      return newData;
    });
  };
  const handleSingleCheckAll = (index,event) => {
    console.log(event.target.checked,"this is working")
    setList((prevData) => {
      const newData = [...prevData];

      // Update the checked status for all checkboxes
      newData.forEach((parent,ind) => {
        if(index==ind){
          parent.data = parent?.data?.map((item) => ({
            ...item,
            checked: event.target.checked,
          }));
        }else{
        }
      });

      return newData;
    });
  };

  const convertToIndexArray = (array) => {
    const dummy = array.map((item, i) => {
      const newObj = new Object();
      newObj.index = i;
      newObj.name = item.name.split(".")[0];
      return newObj;
    });

    const uniqueArr = dummy.reduce((result, current) => {
      const existingObject = result.find((item) => item.name === current.name);
      if (!existingObject) {
        result.push(current);
      }
      return result;
    }, []);
    let finnal = [];
    uniqueArr.map((ss) => {
      finnal.push(ss.index);
    });

    console.log(finnal);
    setMyRoleIndexArray(finnal);
  };





  return (
    <div>
       {loading ? <LinearProgress /> : null}
      <Card
      style={{margin:0}}
      noPadding={true}
      >
        <>
          <CardHeader className="view_area_header" title={`Assign Permissions to ${myRole?.name}`}>
            <CardHeaderToolbar>
            <Link to={"/view-hierarchy"}>
              <BsFillArrowLeftCircleFill
                style={{
                  top: "20px",
                  left: "25px",
                  position: "absolute",
                  fontSize: "2.5rem",
                  color: "black",
                  marginRight: "20px",
                  textDecoration: "none",
                }}
              />
            </Link>
           {!loading && <div className='mr-4'>
            <Checkbox
                  checked={list.every((parent) => parent?.data?.every((item) => item.checked === true))}

                  onChange={handleCheckAllChange}
                >
                  Check All
                </Checkbox>
            </div>}
         {PremissionChecker(user,"role.leave_groups_attach") &&     <div>
                {buttonOneLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "150px",
                    }}
                  >
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      getAlreadyAssignedGroups(params.id);
                    }}
                    className="btn btn-primary"
                  >
                    Assign Holiday Groups
                  </button>
                )}
                </div>}
               {PremissionChecker(user,"role.rotas_attach") &&  <div>
                {buttonTwoLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "150px",
                    }}
                  >
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      getAlreadyAssignedRotaGroups(params.id);
                    }}
                    className="btn btn-primary mx-2"
                  >
                    Assign Rota Groups
                  </button>
                )}
                </div>}
                {attachLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "120px",
                    }}
                  >
                    <Spinner animation="border" />
                  </div>
                ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={attachSubmit}
                >
                 Save Changes
                </button>
          )}
            </CardHeaderToolbar>
          </CardHeader>
    
        </>
      </Card>
      <div className='d-flex' style={{gap:"10px",width:"100%"}}>
      <div
      style={{
        width: 276,
      }}
    >
      {/* <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{
        width: 256,
        textAlign:"end",
        borderRadius:"0px",
        height:"45px",
        backgroundColor:"#1A1A27"
        }}
      >
        {collapsed ? 
          <span className="svg-icon svg-icon-xl">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
      </span>
        : 
        <span className="svg-icon svg-icon-xl">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
    </span>
        }
      </Button> */}

      <Menu
       style={{height:"100%"}}
        onSelect={(e)=>setSelectedTab(e.key)}
        mode="inline"
        theme="dark"
        inlineCollapsed={collapsed}
        items={items}
        defaultSelectedKeys={["1"]}
      />
    </div>
          <Paper style={{padding:"10px 20px",width:"100%",borderRadius:0}}>


          {list.length> 0 && list?.map((tab, index) => (
  (selectedTab === "1" && (tab.name === 'user' || tab.name === 'leaver' || tab.name === 'role')) ||
  (selectedTab === "2" && (tab.name === 'leave_calendar' || tab.name === 'holiday_group' || tab.name === 'leave_application' || tab.name === 'leave_type')) ||
  (selectedTab === "3" && (tab.name === 'rota' || tab.name === 'rota_setting' || tab.name === 'rota_shift')) ||
  (selectedTab === "4" && (tab.name === 'dvla')) ||
  (selectedTab === "5" && (tab.name === 'announcement' || tab.name === 'feed')) ||
  (selectedTab === "6" && (tab.name === 'staff_area' || tab.name === 'folder' || tab.name === 'file' || tab.name === 'department')) 
  ? (
    <div className='main-card-permissions' key={index}>
      <div className='permission-card-header'>
        <h5>{titleCase(tab.name.replace(/_/g, ' '))}</h5>
      </div>
      <div className='permission-card-body'>
        <Checkbox
          checked={tab?.data?.every((item) => item.checked === true)}
          onChange={(event) => handleSingleCheckAll(index, event)}
        >
          Check All
        </Checkbox>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {tab?.data?.map((per, perIndex) => (
            <div style={{ width: '25%', marginTop: '10px' }} key={perIndex}>
              <Checkbox
                checked={per.checked}
                onChange={(event) => handleCheckboxChange(index, perIndex)}
              >
                {per.description}
              </Checkbox>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null
))}



    

    </Paper>
    </div>
    <AssignRotaAndHoliday
        url={`${baseUrl}/mgmt/role/${params.id}/assign/leave-groups`}
        defaultValues={defHoliGroups}
        title={"Holiday Groups"}
        open={open}
        role={myRole}
        list={allHoliGroups}
        handleClose={() => setOpen(false)}
        type="holiday"
      />
      <AssignRotaAndHoliday
        url={`${baseUrl}/mgmt/role/${params.id}/assign/rotas`}
        title={"Rota Groups"}
        defaultValues={defRotaGroups}
        open={open2}
        role={myRole}
        list={allRotaGroups}
        handleClose={() => setOpen2(false)}
        type="rota"
      />
    </div>
  )
}

export default Permissions
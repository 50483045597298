import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
const UpdateRecureModal = ({updateClose,updateOpen,singleUpdate,mainLoading =false,addLoading=false}) => {


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: mainLoading ? 0.3 : 1,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    opacity: mainLoading ? 0.3 : 1,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 0, 0),
    width:280,
    borderRadius:"20px"

  },
}));
const classes = useStyles();



  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={updateOpen}
        onClose={updateClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={updateOpen}>
          <div className={classes.paper}>
            <p style={{textAlign:"center"}} id="transition-modal-title"><b>This Is A Repeating Shift.</b></p>

            {addLoading ?
            <div style={{display:"flex",justifyContent:"center"}}>
            <div class="loader-button-me"></div> 
            </div>
          :
          <>
               <div>
           <button onClick={()=>singleUpdate(0)} className='deleteRecureButton text-primary'>Save For This Shift Only</button>
           </div>
           <div>
           <button onClick={()=>singleUpdate(1)} className='deleteRecureButton text-primary'>Save For Future Shifts</button>
           </div>
          </>  
          }
      
           <div>
           <button onClick={()=>updateClose()} className='deleteRecureButton2 text-danger'><b>Cancel</b></button>
           </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default UpdateRecureModal
import React from "react";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage, } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { baseUrl } from "../../../services/config";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Input } from "../../../_metronic/_partials/controls";
import { useEffect } from "react";
import { Divider } from "antd";
import file1 from '../../asserts/file1.png'
import file2 from '../../asserts/file2.png'
import file3 from '../../asserts/file3.png'
import file4 from '../../asserts/file4.png'
import file5 from '../../asserts/file5.png'

const EditFileModal = ({ visible, closeModel, item, reload,currentCategory }) => {
  console.log(item, "item");
  const [loading, setLoading] = useState(false);
  const authtoken = localStorage.getItem("userToken");
  const [value, setValue] = useState(item.html_tags);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedFolder, setSelectedFolder] = useState('');
  const [selectedImage,setSelectedImage]=useState()
  const [key, setKey] = useState(0);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (item.type =="page_builder") {
      setValue(item.html_tags)
      console.log("sdfsad")
      setSelectedOption(item.html_tags == null ? "link" : "linkbuilder")

    } else {
      setSelectedOption("")

    }
  }, [item])
console.log(value)

  const initialValues = {
    title: item.title,
    type: item.type,
    link: item.link,
    file: "",
    icon:''
  };

  const LoginSchema = Yup.object().shape({
    title: Yup.string().required("File Name is Required"),
    type: Yup.string().required("Please select Type"),
    file: Yup.mixed().notRequired('Please select a Icon').nullable(),
    icon: Yup.mixed().notRequired('Please select a Icon').nullable(),
  });

  const handleSubmit = (values, resetForm) => {
    
    if(values.type=="file" && item.file ==null && values.file==""){
      return  toast.error("Please choose the file")
    }
    enableLoading();

    console.log(values);
    let formData = new FormData();
     
    formData.append("title", values.title);
    formData.append("type", values.type);
    formData.append('icon', values.icon ? values.icon : selectedFolder)
    if (values.type == "file") {
      formData.append("file", values.file);
    }
    if (values.type == "page_builder") {
      formData.append("html_tags", value);
    }
    if (values.type == "link") {
      formData.append("link", values.link);
    }
    if(currentCategory=='file'){
      formData.append('category', 1)
  }
    // mgmt/folders/1?_method=PUT
    let url = `${baseUrl}/mgmt/staff-area-items/${item.id}?_method=PUT`;
    let url2 = `${baseUrl}/mgmt/staff-area-items/${item.id}?_method=PUT`;
    fetch(currentCategory=='file' ? url2 :url, {
      method: "POST",
      headers: {
        // 'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
         
          reload();
        setTimeout(() => {
          toast.success(data.metadata.message);
          disableLoading();
          closeModel();
          setValue("");
          setSelectedFolder("")
          setSelectedImage("")
          setKey(prevKey => prevKey + 1);
        }, 1000);
          // resetForm()
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
          disableLoading();
        } else {
          toast.error("unknown error!");
          disableLoading();
        }
      })
      .catch((err) => {
        disableLoading();
        console.log(err);
      });
  };
  console.log(selectedOption)



  const toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['link', 'image', 'video'],
    ['clean']
  ];


  const dataURItoBlob = async (dataURI) => {
    setSelectedImage(dataURI)
    const response = await fetch(dataURI);
    const blob = await response.blob();
    // setSelectedFolder(blob);
    // return blob;
    setSelectedFolder(blob)
    console.log(blob)
};

  return (
    <Modal
      show={visible}
      centered
      animation
      size="lg"
    //  dialogAs={'animate'}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>Edit File/Link</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, setFieldValue, }) => (
          <Form>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="title">Title:</label>
                <Field
                  id="title"
                  name="title"
                  type="text"

                  className="form-control"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group">
                <label htmlFor="type">Type:</label>
                <Field
                  as="select"
                  id="type"
                  name="type"
                  className="form-control"
                >
                  <option value="">Select Type</option>
                  <option value="file">File</option>
                  <option value="link">Link</option>
                  <option value="page_builder">Page Builder</option>

                </Field>
                <ErrorMessage
                  name="type"
                  component="div"
                  className="text-danger"
                />
              </div>

              {values.type === "file" && (
                <div className="form-group">
                  <label htmlFor="file">File:</label>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    onChange={(event) => {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage
                    name="file"
                    component="div"
                    className="text-danger"
                  />
                  <div>
                  </div>
                </div>
              )}
              {/* 
              {values.type == "link" && (
                <div className="btn-group" data-toggle="buttons">
                  <label
                    onClick={() => setSelectedOption("link")}
                    className={`btn btn-secondary ${selectedOption === "link" ? "btn btn-primary" : ""
                      }`}
                  >

                    Link
                  </label>
                  <label
                    onClick={() => setSelectedOption("linkbuilder")}
                    className={`btn btn-secondary ${selectedOption === "linkbuilder" ? "btn btn-primary" : ""
                      }`}
                  >

                    Page Builder
                  </label>
                </div>
              )} */}

              {values.type === "link" && (
                <div className="form-group">
                  <label htmlFor="link">Link:</label>
                  <Field
                    id="link"
                    name="link"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="link"
                    component="div"
                    className="text-danger"
                  />
                </div>
              )}

              {values.type === "page_builder" && (
                <ReactQuill

                  modules={{
                    toolbar: toolbarOptions
                  }}
                  formats={null}
                  theme="snow" value={value} onChange={setValue} />
              )}
                                  {item.type == "file" ? (
                                  <>
                                   <div className='col-md-12'>
                                 <p>Previous File</p>
                                </div>
                                   <div>
            {item?.file?.type == "application/msword" ||
            item?.file?.type ==
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            item?.file?.type == "text/csv" ||
            item?.file?.type == "application/vnd.ms-excel" ||
            item?.file?.type ==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
              <div>
                <object
                  data={`https://docs.google.com/gview?url=${encodeURIComponent(
                    item?.file?.url
                  )}&embedded=true`}
                  title="Document Viewer"
                  height="150px"
                />

              </div>
            ) : (
           

              <object
                height="150px"
                type={item?.file?.type}
                // sandbox="allow-same-origin allow-scripts allow-forms"
                data={item?.file?.url}
              ></object>
            )}
       
          </div>
          <Divider/>

                                  </>
         
        ) : null}
        <div className='col-md-12 col-sm-12 col-lg-12'>
                                <div className="form-group">
                                    <label htmlFor="link">File Icon:</label>
                                    <input
                                        id="icon"
                                        name="icon"
                                        type="file"
                                        key={key}
                                        className='form-control'
                                        onChange={(event) => {
                                            setSelectedImage("")
                                            setSelectedFolder("")
                                            setFieldValue('icon', event.currentTarget.files[0]);
                                        }}
                                    />
                                    <ErrorMessage name="icon" component="div" className="text-danger" />
                                </div>
                            </div>
                           

            

                            
                              <div className='col-md-12'>
                                 <p>Previous Icon</p>
                                 <img src={item?.icon?.url} alt='folderIcons' style={{height:"100px"}}/>
                                </div>
                                <Divider/>
                              
                                <div className='col-md-12'>
                                    <div className='row'>
                                   
                                       <div className='col-md-3 mt-2'>
                                        <img src={file1} alt='folderIcons'  style={{marginRight:"10px",border:file1==selectedImage ? "1px solid black" : "",cursor:"pointer",height:"115px",width:"120px"}} onClick={() =>{
                                              setKey(prevKey => prevKey + 1);
                                            setFieldValue('icon',"")
                                            dataURItoBlob(file1)}}/>
                                       </div>

                                       <div className='col-md-3 mt-2'>
                                        <img src={file2} alt='folderIcons'  style={{marginRight:"10px",border:file2==selectedImage ? "1px solid black" : "",cursor:"pointer",height:"120px",width:"105px",marginTop:"5px"}} onClick={() =>{
                                              setKey(prevKey => prevKey + 1);
                                            setFieldValue('icon',"")
                                            dataURItoBlob(file2)}}/>
                                       </div>


                                       <div className='col-md-3 mt-2'>
                                        <img src={file3} alt='folderIcons'  style={{marginRight:"10px",border:file3==selectedImage ? "1px solid black" : "",cursor:"pointer",height:"95px",width:"90px",marginTop:"5px"}} onClick={() =>{
                                              setKey(prevKey => prevKey + 1);
                                            setFieldValue('icon',"")
                                            dataURItoBlob(file3)}}/>
                                       </div>

                                       <div className='col-md-3 mt-2'>
                                        <img src={file4} alt='folderIcons'  style={{marginRight:"10px",border:file4==selectedImage ? "1px solid black" : "",cursor:"pointer",height:"105px",width:"170px"}} onClick={() =>{
                                              setKey(prevKey => prevKey + 1);
                                            setFieldValue('icon',"")
                                            dataURItoBlob(file4)}}/>
                                       </div>

                                       <div className='col-md-3 mt-2'>
                                        <img src={file5} alt='folderIcons'  style={{marginRight:"10px",border:file5==selectedImage ? "1px solid black" : "",cursor:"pointer",height:"100px",width:"105px"}} onClick={() =>{
                                              setKey(prevKey => prevKey + 1);
                                            setFieldValue('icon',"")
                                            dataURItoBlob(file5)}}/>
                                       </div>
                                        
                                    </div>
                            
                                  
                                </div>
            </Modal.Body>
            <Modal.Footer>
            <div onClick={closeModel} className="btn btn-danger ml-2">
                Close
              </div>
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary"
              >
                Update
              </button>
           
              {/* <button onClick={closeModel} className="btn btn-danger">Close</button> */}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditFileModal;

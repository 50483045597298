// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { Spinner } from "react-bootstrap";
import { FcApprove } from 'react-icons/fc'
import { PremissionChecker } from "../../../../../../../services/PremissionChecker";
export function ActionsColumnFormattePendingLeaves(
    cellContent,
    row,
    rowIndex,
    { attachPermissions, approveLeave,user }
) {

  
    //console.log('ye row', row)

    return (
        <>
          {
          PremissionChecker(user, "leave_application.action") &&
          <a
                title="Approve"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => {
                        approveLeave(row)
                }}
            >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                    <FcApprove />
                    {/* <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                    /> */}
                </span>
            </a>}

        </>
    );
}

// import { Avatar } from '@mui/material';
// import { AvatarGroup } from '@mui/material';
import { List, Popover, Space, Typography } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { Handle, MarkerType, Position } from 'reactflow';
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import {useHistory } from "react-router-dom";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Avatar from '@material-ui/core/Avatar';
import { Modal, Spinner } from 'react-bootstrap';
import AddRole from './AddRole';
import { baseUrl } from '../../../services/config';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Checkbox, Divider } from "antd";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useSelector } from "react-redux";
import { FaList } from "react-icons/fa";
import AssignRotaAndHoliday from '../../components/AssignRotaAndHoliday/AssignRotaAndHoliday';
export default memo(({ data, isConnectable }) => {
  const user = useSelector((state) => state.user.user);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className="row">
            {children}
          </div>
        )}
      </div>
    );
  }
  const history = useHistory();

  const [editModal, seteditModal] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [SelectedId, setSelectedId] = useState("");
  const [loading, setloading] = useState(false);
  const [value, setValue] = React.useState(0);





  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const authtoken = localStorage.getItem("userToken");
  const [error, setError] = useState(null);
         const users = [
        {
          color: "orange",
          username: "Alice"
        },
        {
          color: "yellow",
          username: "Bob"
        },
        {
          color: "purple",
          username: "Charlie"
        },
        {
          color: "yellow",
          username: "Diana"
        },
        {
          color: "purple",
          username: "Eve"
        },
        {
          color: "orange",
          username: "Frank"
        }
      ];
      console.log(data)
      const dispatch = useDispatch();

       function getRandomLightColor() {
        // Generate random values for red, green, and blue in the range of 128-255
        const r = Math.floor(Math.random() * 128) + 128;
        const g = Math.floor(Math.random() * 128) + 128;
        const b = Math.floor(Math.random() * 128) + 128;
        
        // Convert the RGB values to a hexadecimal color code
        const color = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
        
        return color;
      }
        const getRoles = async () => {
      setloading(true);
          await fetch(`${baseUrl}/mgmt/roles-with-positions`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          })
            .then((res) => res.json())
            .then((data) => {
      setloading(true);
              if (data.metadata) {
                if (data.metadata.success) {
                  dispatch({
                    type: "SHOW_BUTTON",
                    payload: {
                      show: false,
                    },
                  });
                  data.payload.forEach((item) => {
                    item.id = item.id.toString();
                    item.data.color =getRandomLightColor();
                    item.data.id =item.id.toString();
                  });
                  getHierarchy(data.payload)
                }
              }
            })
            .catch((err) => {
              console.log("vehicle", err);
            });
        };
        const getHierarchy = async (roles) => {
          await fetch(`${baseUrl}/mgmt/roles-hierarchy`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.metadata) {
                if (data.metadata.success) {
                   console.log(roles,"here is the roles")
                   console.log(data.payload,"here is the Positioning")
                   data.payload.forEach((item) => {
                    item.id = item.id.toString();
                    item.style = {strokeWidth:2, stroke: '#1e1e2d',}
                    item.type = 'buttonedge'
                    item.markerEnd= {
                      type: MarkerType.ArrowClosed,
                      width: 20,
                      height: 20,
                      color: '#1e1e2d',
                    }
                  });
                   dispatch({
                    type: "GET_ROLES",
                    payload: {
                      edges: data.payload,
                      roles: roles,
                    },
                  });
                   dispatch({
                    type: "GET_ROLES",
                    payload: {
                      edges: data.payload,
                      roles: roles,
                    },
                  });
                }
              }
            })
            .catch((err) => {
              console.log("vehicle", err);
            });
        };


      const openEdit = (item) => {
        setSelectedObj(item);
        seteditModal(true);
        setaddModal(true);
      };
      const saveRole = (values) => {
        setError(null);
        setisLoading(true);
        let url = editModal
          ? `mgmt/roles/${selectedObj.id}?_method=PUT`
          : "mgmt/roles";
    
        fetch(`${baseUrl}/${url}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authtoken}`,
          },
          body: JSON.stringify(values),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.metadata.success) {
              setaddModal(false);
              toast.success(data.metadata.message);
              getRoles();
              setisLoading(false);
            } else if (!data.metadata.success) {
              setError(Object.values(data.metadata.message));
              setisLoading(false);
              // Object.values(data.metadata.message).map(msg => {
              //     toast.error(msg)
              //     setisLoading(false)
              // })
            }
          })
          .catch((err) => {
            console.log(err);
            setisLoading(false);
          });
      };


      const opendeleteModal = (id) => {
        setSelectedId(id);
        setdeleteModal(true);
      };

  

  




  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#1e1e2d',height:"15px",width:"25px",borderRadius:"5px" }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
    <div className="node-template" style={{backgroundColor:`${data.color}`}}>
            <div className='hierarchy-title'>
            <strong >{data?.name}</strong>
            </div>
            {/* <div className='giving-min-hight'>
            <Space size="middle">
                              <AvatarGroup max={4}>
                              {
                                data.users?.map((usr) => (
                                    <Avatar sizes='small' style={{ backgroundColor: `#${usr.color}` }} alt={usr?.first_name?.toUpperCase()} src="#" />
                                  ))
                                }

                              </AvatarGroup>
                            </Space>
        
            </div> */}
            <div className='myLine'></div>
            <Space size="middle">
            {PremissionChecker(user,"role.update") ? (
            <FaRegEdit
    className='editRoleIcon'
           onClick={ () => openEdit(data)} 
          //  style={{fontSize:"1.5rem",cursor:"pointer"}}
           />
            ): ''}
          {/* <IoSettingsOutline
        //   onClick={() => assignRolesOpner(node)}
           style={{fontSize:"1.5rem",cursor:"pointer"}}/> */}

  {PremissionChecker(user,"role.permissions_attach") ? (
  <FaUserCheck
     onClick={()=>history.push(`/permissions/${data.id}`,data)}
    // onClick={() => openAttachModal(data, data.id)}
    style={{ fontSize: "1.5rem", cursor: "pointer" }}
  />) : ''}

 {PremissionChecker(user,"role.delete") ? (
          <MdDeleteOutline
           onClick={() => opendeleteModal(data.id)} 
           style={{fontSize:"1.5rem",cursor:"pointer"}}/> ) :""}
         
             {/* <Popover placement="rightBottom" title={text} content={<>
              <List
      bordered
      dataSource={data.users}
      renderItem={(item) => (
        <List.Item>
          <Typography.Text>{item.first_name} {item.last_name}</Typography.Text> 
        </List.Item>
      )}
    />
              </>}>
             <FaList
    style={{fontSize:"1.5rem",cursor:"pointer"}}/>
        </Popover> */}
            </Space>
            <div className='myLine'></div>
        

              {/* <span>{node.name} </span> */}
                <h6 className='my-2'>Assigned Users</h6>
            <div className='myLine2'></div>
              <div className='row' style={{width:"100%"}}>
{data?.users?.length>0 && data?.users?.map((usr)=>(
  <div className='col-md-12 d-flex' style={{marginBottom:"5px"}}>
               <Avatar sizes='small' style={{ backgroundColor: `#${usr.color}` }} alt={usr?.first_name?.toUpperCase()} src="#" />
               <h5 style={{marginTop:"10px",marginLeft:"5px"}}>{usr.first_name} {usr.last_name}</h5>
               </div>
))}         
</div>
            </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{ top: "97%", background: '#1e1e2d',height:"15px",width:"25px",borderRadius:"5px" }}
        isConnectable={isConnectable}
      />

<Modal show={addModal}>
        <Modal.Header>{editModal ? "Edit Role" : "Add new Role"}</Modal.Header>
        <AddRole
          customer={{
            name: editModal ? selectedObj.name : "",
          }}
          isEdit={editModal ? true : false}
          saveCustomer={(values) => saveRole(values)}
          onHide={() => setaddModal(false)}
          actionsLoading={isLoading}
          errors={error}
        />
      </Modal>
      <DeleteModal
        HideModal={() => setdeleteModal(false)}
        visible={deleteModal}
        // id={SelectedId}
        method="DELETE"
        reload={getRoles}
        url={`${baseUrl}/mgmt/roles/${SelectedId}`}
      />
    </>
  );
});
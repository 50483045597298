
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input, TextArea } from '../../../_metronic/_partials/controls';
import { Modal } from 'react-bootstrap';
import { baseUrl } from '../../../services/config';
import { toast } from 'react-hot-toast';
import DeleteIcon from '@material-ui/icons/Delete';
import { CloseCircleFilled, PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons'
import { Image } from 'antd';

const Addannouncement = ({
  onHide, reload

}) => {
 
  const [loading, setloading] = useState(false)
  const [image, setimage] = useState('')
  const [imgShow, setimgShow] = useState('')
  const [imgError, setimgError] = useState('')
  const [boxes, setBoxes] = useState([{ title: '', description: '', link: '', image: null }]);

  const authtoken = localStorage.getItem("userToken");
const [recogniseError,setRecogniseError]=useState(false)
  const [images, setimages] = useState("")


  const urlRegex = /^(https?|ftp):\/\/([A-Z0-9.-]+\.[A-Z]{2,})(:\d{1,5})?(\/[^\s]*)?$/i;

  const CustomerEditSchema = Yup.object().shape({
    // title: Yup.string().notRequired("title is required").nullable(),
    title: Yup.string().when("type", {
      is: "2",
      then: Yup.string().required("Title is required"),
    }),

    description: Yup.string().notRequired("description is required").nullable(),
    // .max(500, "You cannot enter more then 500 character"),
    // link: Yup.string()
    //   .matches(urlRegex, 'Invalid URL')
    //   .required("link is required"),
    type: Yup.string().required('Please select an option'),
  });


  

  const addinitialValues = {
    title: '',
    description: '',
    link: '',
    type: '',
  }


  const handleSubmit = (values) => {
//     let titleArray=[]
//     let desArray=[]
//     let linkArray=[]
//     let imageArray=[]
//  for(let i=0; i<boxes.length; i++){
//   titleArray[i]=boxes[i].title
//   desArray[i]=boxes[i].description
//   linkArray[i]=boxes[i].link
//   imageArray[i]=boxes[i].image
//  }
if(values.type==2){
if(images==""){
  setimgError(true)
  return 
}
}else{
  const checker=boxes.map((item)=>{
    if(item.title==''  || item.image==null){
      return true
    }
  })
  if(checker.includes(true)){
    toast.error('Title And Image Is Required')
    return
  }
  // for (let i = 0; i < boxes.length; i++) {
  //   if(boxes[i].title==""){
  //    toast.error(`Please Enter Title On ${i+1} Row`)
  //   }
  //   if(boxes[i].description==""){
  //    toast.error(`Please Enter Description On ${i+1} Row`)
  //   }
  //   if(boxes[i].image==null){
  //    toast.error(`Please Enter Image On ${i+1} Row`)
  //   }
  //   if(boxes[i].title=="" || boxes[i].description=="" || boxes[i].image==""){
  //     return
  //   }
  // }
}

    setloading(true)
    const fdata = new FormData();
    // fdata.append("title", values.title);
    // fdata.append("description", values.description);
    fdata.append("type", values.type);
   if(values.type==1){
    for (let i = 0; i < boxes.length; i++) {
      fdata.append(`title[${i}]`,boxes[i].title);
      fdata.append(`description[${i}]`,boxes[i].description);
      fdata.append(`link[${i}]`,boxes[i].link);
      fdata.append(`image[${i}]`,boxes[i].image);
    }
   }else{
    fdata.append(`title[${0}]`,values.title);
    fdata.append(`description[${0}]`,values.description);
    fdata.append(`link[${0}]`,values.link);
    fdata.append(`image[${0}]`,images);
   }
    
    // for (let i = 0; i < boxes.length; i++) {
    //   for (let key of Object.keys(boxes[i])) {
    //     fdata.append(`announcements[${i}][${key}]`, boxes[i][key]);
    //   }
    // }
 
 
    // if (values.type == '1') {
    //   for (let i = 0; i < images.length; i++) {
    //     console.log(i)
    //     fdata.append(`image[${i}]`, images[i])
    //   }
    // } else {
    //   fdata.append(`image[${0}]`, images[images.length - 1])
    // }



    for (var pair of fdata.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    let url =`${baseUrl}/mgmt/announcements`

    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false)
        if (data.metadata) {
          if (data.metadata.success) {
            onHide()
            toast.success("Added Successfully")
            reload()
          setloading(false)
          } else if (!data.metadata.success) {
            if (toString.call(data.metadata.message) == "[object Object]") {
              const errorMessageArray = Object.values(
                data.metadata.message
              ).flatMap((messages) => messages);
              errorMessageArray.forEach((message) => toast.error(message));
            } else if (toString.call(data.metadata.message) == "[object Array]") {
              Object.values(data.metadata.message).map((msg) => {
                toast.error(msg);
              });
            } else {
              toast.error(data.metadata.message);
            }
            setloading(false)
          }else{
            toast.error("Internal server error");

          }
        }
        console.log(data);
      })
      .catch((err) => {
        setloading(false)

        console.log("err is re", err);
      });
  }
  const options = [
    { value: '2', label: 'STATIC' },
    { value: '1', label: 'SLIDER' },
  ];







  const removeitem = (img, index) => {

    console.log(img);
    let arr = [...images]
    // let foundindex = arr.findIndex(img)
    let filterd = arr.filter((item, i) => item !== img)

    setimages(filterd)
  }



  console.log("selected", images);


  //multipule images codes

  const addInputBox = () => {
    setBoxes(prevBoxes => [...prevBoxes, { title: '', description: '', file: null }]);
  };

  const hangleinputtitle = (index, value) => {
    const updatedBoxes = [...boxes];
    updatedBoxes[index].title = value;
    setBoxes(updatedBoxes);
  };

  const handleDesc = (index, value) => {
    const updatedBoxes = [...boxes];
    updatedBoxes[index].description = value;
    setBoxes(updatedBoxes);
  };
  const handleLink = (index, value) => {
    const updatedBoxes = [...boxes];
    updatedBoxes[index].link = value;
    setBoxes(updatedBoxes);
  };

  const handleFileChange = (index, file) => {
    const updatedBoxes = [...boxes];
    updatedBoxes[index].image = file;
    setBoxes(updatedBoxes);
  };

  const removeInputBox = (index) => {
    setBoxes(prevBoxes => prevBoxes.filter((box, i) => i !== index));
  };
  console.log(boxes)
  return (
    <div className='container'>
      <Formik
        enableReinitialize={true}
        initialValues={addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          // console.log(values)
          handleSubmit(values);

        }}
      >
        {({ values, errors, touched, setFieldTouched, setFieldValue }) => (
          <Form>
            <Modal.Body className="overlay overlay-block cursor-default">
            <div className='col-md-12 mt-4'>
                <label htmlFor="type">Select an option:</label>
                <select
                  className="form-control"
                  name="type"
                  id="type"
                  onChange={(e) => {
                    setimgError(false)
                    setFieldValue('type', e.target.value)
                    setFieldTouched('type', false)
                  }

                  }
                >
                  <option value="">Select an option</option>
                  {options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.type && touched.type && (
                  <div className="error text-danger">{errors.type}</div>
                )}
              </div>
              {
                values.type == '2' &&
                <>
                  <div className='col-md-12 mt-2'>
                    <label className='text-dark'>Title</label>
                    <Field
                      component={Input}
                      placeholder="Title"
                      value={values.title}
                      name="title"
                    />
                  </div>
                  <div className='col-md-12 mt-2'>
                    <label className='text-dark'>Description</label>

                    <Field
                      component={TextArea}
                      placeholder="Description"
                      value={values.description}
                      name="description"
                    />
                  </div>
                  <div className='col-md-12 mt-2'>
                    <label className='text-dark'>Link</label>
                    <Field
                      component={Input}
                      placeholder="Link"
                      value={values.link}
                      name="link"
                    />
                  </div>
                </>
              }
             
              {values.type ?
                <div className='col-md-12 mt-2'>
                  {values.type == 2 ?
                    <>
                      <label className='text-dark'>Image</label>
                      <input
                      accept="image/png, image/jpeg"
                        // multiple={values.type == '1' ? true : false}
                        onChange={e => {
                          setimgError(false)
                          // let arr=[]
                          // arr.push(e.target.files[0])
                          // setimages(Object.values(e.target.files).concat(images))
                          setimages(e.target.files[0])

                          setimgShow(URL.createObjectURL(e.target.files[0]))
                        }} className='form-control' type='file'

                      // value={images}
                      />
                    </>

                    :
                    <>
                      <div>
                        <h2 className='mt-4' style={{ width: "100%", textAlign: "center" }}>Upload Images and Captions</h2>
                        <div className='yrrkiaanu'>
                          <div></div>
                        </div>
                        <div className='row mt-3'>
                          <div className='col-md-3'>
                            <label><b>Enter Title</b></label>
                          </div>
                          <div className='col-md-3'>
                            <label><b>Enter Description</b></label>
                          </div>
                          <div className='col-md-3'>
                          <label><b>Enter Link</b></label>
                          </div>
                        </div>
                        {boxes.map((box, index) => (
                          <div key={index} className='row' style={{position:"relative"}}>
                            <div className='col-md-3'>
                              <input
                                className='form-control'
                                type="text"
                                value={box.title}
                                onChange={e => hangleinputtitle(index, e.target.value)}
                                placeholder="Title"
                              />
                            </div>
                            <div className='col-md-3'>
                              <input
                                className='form-control'
                                type="text"
                                value={box.description}
                                onChange={e => handleDesc(index, e.target.value)}
                                placeholder="Description"
                              />
                            </div>
                            <div className='col-md-3'>
                              <input
                                className='form-control'
                                type="text"
                                value={box.link}
                                onChange={e => handleLink(index, e.target.value)}
                                placeholder="Link"
                              />
                            </div>
                            <div className='col-md-3'>
                            <label htmlFor={`fileImage${index}`} className='btn btn-primary'>
                                 Choose Image
                              </label>
                              <input
                                style={{ display: "none" }}
                      accept="image/png, image/jpeg"
                                type="file"
                                id={`fileImage${index}`}
                                onChange={e => handleFileChange(index, e.target.files[0])}
                              />
                             {box.image? <span style={{marginLeft:"10px"}}><Image className='myPreviewImage' src={URL.createObjectURL(box.image)} alt='img'/></span>:''}
                            </div>
                            <div className={'col-md-3 mt-2'} style={{ paddingLeft: "50px",position:"absolute",top:"3px",right:"-17%" }}>
                              {boxes.length > 1 && (
                                <MinusCircleFilled style={{ fontSize: "2rem", color: "red" }} onClick={() => removeInputBox(index)} />
                              )}
                              {boxes.length == index + 1 && (
                                <PlusCircleFilled style={{ fontSize: "2rem", marginLeft: "5px", color: "4BB543" }} onClick={addInputBox} />
                              )}


                            </div>
                          </div>
                        ))}


                      </div>
                    </>
                  }
                  {
                    imgError ?
                      <label className='text-danger'>Image is required</label> : null
                  }
                </div> : ''}
              {/* {
                imgShow ?
                  <img style={{ border: '2px solid black', padding: 10 }} src={imgShow} height={100} width={100} /> :
                  editItem ?
                    <img style={{ border: '2px solid black', padding: 10 }} src={editItem.image.url} height={100} width={100} /> : ''
              } */}




            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-danger btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                disabled={loading}
                type="submit"
                // onClick={() =>  handleSubmit(values)}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </Form>

        )}

      </Formik>




    </div>
  )
}

export default Addannouncement
import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage, } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { baseUrl } from "../../../services/config";
import { Checkbox, Divider } from 'antd';
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import { Input } from "../../../_metronic/_partials/controls";
import { useEffect } from "react";
import { useParams } from 'react-router-dom'
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";
const AssignedModal = ({ visible, closeModel, item, reload,users }) => {
const [usersList,setUsersList]=useState([])
const authtoken = localStorage.getItem("userToken");
const params = useParams()

const { id, groupname } = params
const user = useSelector(state => state.user.user)
const [loading,setloading]=useState(false)
const [assignedLoading,setAssignedLoading]=useState(false)
useEffect(() => {
  getUsers()
}, [item])

const getUsers = () => {
  setloading(true)
  fetch(`${baseUrl}/mgmt/staff-areas/${id}/assigned-users`, {
      method: 'GET',
      headers: {
          Authorization: `Bearer ${authtoken}`,

      }
  }).then(res => res.json())
      .then((data) => {
  getAssignedUsers(data.payload,item.id)

      }).catch(err => {
          console.log(err);
      })
}

const getAssignedUsers = (array, id) => {
  
  fetch(`${baseUrl}/mgmt/staff-area-item/${id}/assigned-users`, {
      method: "GET",
      headers: {
          // Accept: "application/json",
          // "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
      },
  })
      .then((res) => res.json())

      .then((data) => {

          console.log(data.payload, "this is yrr")
          let dummy = []
          for (let i = 0; i < data.payload.length; i++) {
              dummy[i] = data.payload[i].id
          }
          console.log(dummy,"this is dummy")
          // console.log(array,"yaha per userlist")
          // let arr=[70,21]
          let checkboxes = array;
          array.map((item, index) => {
              if (dummy.includes(item.id)) {
                  item["checked"] = true;
                  item["selected"] = true;
                  checkboxes[index] = item;
              } else {
                  item["checked"] = false;
                  item["selected"] = false;
                  checkboxes[index] = item;
              }
          });
          console.log(checkboxes, "checkbox")
          setUsersList(checkboxes)
          setloading(false)


      })
      .catch((err) => {
        setloading(false)
          console.log(err);
      });
};

const handleCheckboxChange = (itemId) => {
  setUsersList(prevData => {
      const newData = [...prevData];
      const itemIndex = newData.findIndex(item => item.id === itemId);
      newData[itemIndex].checked = !newData[itemIndex].checked;
      return newData;
  });
};



const handleCheckAllChange = (event) => {
  setUsersList(prevData => {
      const newData = [...prevData];
      newData.forEach(item => item.checked = event.target.checked);
      return newData;
  });
};




const assignUsersToGroup = () => {
 

  let filterdUsers = usersList.filter(item => item.checked == true)
  let ids = filterdUsers.map(item => item.id)
  if(ids.length==0){
    toast.error("Please Select At Least 1 User")
   return
  }
  setAssignedLoading(true)


  let formData = new FormData()
  for (let i = 0; i < filterdUsers.length; i++) {
      formData.append(`user_ids[${i}]`, ids[i]);
  }

  fetch(`${baseUrl}/mgmt/staff-area-item/${item.id}/assign-users`, {
      method: 'POST',
      headers: {

          Authorization: `Bearer ${authtoken}`,
      },
      body: formData
  }).then(res => res.json())
      .then((data) => {
          if (data.metadata.success) {
              toast.success(data.metadata.message)
              reload()
              setAssignedLoading(false)
              closeModel()
          } else if (!data.metadata.success) {
            if (toString.call(data.metadata.message) == "[object Array]") {
        
              Object.values(data.metadata.message).map(msg => {
                toast.error(msg)
              })
            } else {
              toast.error(data.metadata.message)
            }
            setAssignedLoading(false)
          } 
          // else {
          //     toast.error(`At Least One User Is Required`)
          //     setAssignLoading(false)

          // }
      }).catch(err => {
  setAssignedLoading(false)
          console.log(err)

      })
}
  return (
    <Modal
      show={visible}
      centered
      animation
      size="lg"
      //  dialogAs={'animate'}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>Assign Users</Modal.Title>
      </Modal.Header>
    
            <Modal.Body>

              {
                loading?
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
              }}>
                  <Spinner animation='border' />
              </div>
                
                :
                <>
                          <Checkbox checked={usersList.every(item => item.checked === true)} onChange={handleCheckAllChange}>Check All</Checkbox>

<Divider />
<div
    style={{
        display: 'flex',
        flexWrap: 'wrap',

    }}
>
    {
        usersList.map((user, i) => (
            <div style={{ width: '25%' }}>

                <Checkbox checked={user.checked} onChange={(event) => handleCheckboxChange(user.id)}>{user.username}</Checkbox>
            </div>
        ))
    }

</div>

                </>
              }
  
            </Modal.Body>
            <Modal.Footer>
            <div onClick={closeModel} className="btn btn-danger ml-2">
                Close
              </div>

              {
               PremissionChecker(user, 'file.assign_user') ?
              <button
                onClick={assignUsersToGroup}
                className="btn btn-primary"
                disabled={assignedLoading}
              >
                Assign
              </button>:''}
              
              {/* <button onClick={closeModel} className="btn btn-danger">Close</button> */}
            </Modal.Footer>
      </Modal>
    
  );
};

export default AssignedModal;

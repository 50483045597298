import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import EquipmentImageModal from '../EquipmentImageModal/EquipmentImageModal';
import { useState } from 'react';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import SVG from "react-inlinesvg";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        margin: 5
    },
});

export default function EquipmentCard({ item, reload }) {
    const classes = useStyles();
    const history = useHistory()


    const [modalVisible, setModalVisible] = useState(false)

    const goToView = () => {
        let id = 2
        history.push(`/view-equipment/${id}`)
    }

    return (
        <>
            <Card className={classes.root}>
                <CardActionArea onClick={() => goToView()}>
                    <CardMedia
                        component="img"
                        alt={item.name}
                        height="200"
                        width={'200'}
                        loading={'lazy'}
                        image={item?.image?.url}
                        title={item.name}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {item.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {item.price}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <a
                        title="Edit"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => setModalVisible(true)}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                            />
                        </span>
                    </a>

                    <a
                        title="View"
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => goToView()}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Devices/TV1.svg")}
                            />
                        </span>
                    </a>
                </CardActions>
            </Card>
            <EquipmentImageModal
                reloadfunc={reload}
                id={item.id}
                visible={modalVisible}
                hideModal={() => setModalVisible(false)}
            // item}

            />
        </>
    );
}

import React from 'react'
import './style.css'
import { Empty } from 'antd'
const NoPermission = () => {
  return (
    <div className='main-container-no-permission'>

  <Empty description="You don't have permission"/>
    </div>
  )
}

export default NoPermission
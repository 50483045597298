import React, { useState, useMemo, useRef } from "react";
// import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Eventcalendar } from "@mobiscroll/react";
// import { Drawer } from '@material-ui/core';
import SVG from "react-inlinesvg";
import "./styles.css";
import {
  PlusOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  TimePicker,
  Switch as AntdSwitch,
  Col,
  DatePicker,
  Drawer,
  Popover,
  Input,
  Spin,
  Select as AntSelect,
  Space,
  Button,
  Card,
  Divider,
  Modal,
} from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { baseUrl } from "../../../services/config";
import { toast } from "react-hot-toast";
import collect from "collect.js";
import { Switch as Switcher } from "antd";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import EditDrawer from "../EditDrawer/EditDrawer";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import ColorPicker from "../ColorPicker/ColorPicker";
import { Avatar } from "@material-ui/core";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { api_key } from "../../../services/utils";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";
import Select from "react-select";
import { SendNotifaction } from "../../../services/SendNotifaction";
import { object } from "prop-types";
import { Card as Crude, CardContent } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import DeleteModal from "../DeleteModal/DeleteModal";

const { Option } = AntSelect;
const { TextArea } = Input;

const { RangePicker } = DatePicker;

function RotaCalendarTwo({ rotaId,eventList,usersList,reloadRota,selectUsers }) {
  console.log("ye id ", rotaId);

  // useRef to create a reference for the autoComplete component
  const autoCompleteRef = useRef();
  const scrollRef = useRef();

  // useRef to create a reference for the input component
  const inputRef = useRef();

  // location options
  const options = {
    componentRestrictions: { country: "us" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  var currentDate1 = new Date();

  currentDate1.setHours(9);
  currentDate1.setMinutes(0);
  currentDate1.setSeconds(0);

  var currentDate2 = new Date();

  currentDate2.setHours(18);
  currentDate2.setMinutes(0);
  currentDate2.setSeconds(0);

  const authtoken = localStorage.getItem("userToken");

  const [onLeaveToday, setOnLeaveToday] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState("");
  const [color, setColor] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [view, setView] = React.useState("month");
  const [selectedObject, setSelectedObject] = useState({});
  const [editDrawerModal, setEditDrawerModal] = useState(false);
  const [search, setSearch] = useState("");
  const [copyUpdate, setCopyUpdate] = useState(false);
  const [catchStartEvent, setCatchStartEvent] = useState("");
  const [catchEndEvent, setCatchEndEvent] = useState("");
  const [defaultColor, setDefaultColor] = useState({});
  const [date, setDate] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showStartDate, setShowStartDate] = useState(null);
  const [showEndDate, setShowEndDate] = useState(null);
  const [pikerModal, setPikerModal] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [defaultUser, setDefaultUser] = useState({});
  const [defaultDateRange, setDefaultDateRange] = useState(null);
  const [deleteModal, setdeleteModal] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [selectedTemplate,setSelectedTemplate]=useState("")
  const [calView, setCalView] = React.useState({
    calendar: { labels: true },
  });
  const [locError, setlocError] = useState("");
  const changeView = (event) => {
    let calView;

    switch (event.target.value) {
      case "year":
        calView = {
          calendar: { type: "year" },
        };
        break;
      case "month":
        calView = {
          calendar: { labels: true },
        };
        break;
      case "week":
        calView = {
          schedule: { type: "week" },
        };
        break;
      case "day":
        calView = {
          schedule: { type: "day" },
        };
        break;
      case "agenda":
        calView = {
          calendar: { type: "week" },
          agenda: { type: "week" },
        };
        break;
    }

    setView(event.target.value);
    setCalView(calView);
  };
  //loading states

  const [isLoading, setIsLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [isGeoFence, setIsGeoFence] = useState(false);
  const user = useSelector((state) => state.user.user);
  // shift states
  const [title, setTitle] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [shiftDate, setShiftdate] = useState();
  const [startTime, setstartTime] = useState(new Date(currentDate1.getTime()));
  const [endTime, setendTime] = useState(new Date(currentDate2.getTime()));
  const [isfullDay, setIsFullday] = useState(false);
  const [selectedResource, setSelectedResource] = useState("");
  const [isEdit, setIsEdit] = useState();
  const [type, setType] = useState("week");
  const [theme, setTheme] = useState("light");
  const [draft, setDraft] = useState(false);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [templates, setTemplates] = useState([]);
  const [dataPayload, setDataPayload] = useState([]);

  useEffect(() => {
    getTemplates();
  }, []);


  const getTemplates = () => {
    fetch(`${baseUrl}/mgmt/rota-shift-templates`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setTemplates(data.payload);
        console.log(data, "templates");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(usersList,"lister");


  const colorOptions = [
    { value: "#FF0000", label: "Red" },
    { value: "#00FF00", label: "Green" },
    { value: "#0000FF", label: "Blue" },
    { value: "#FF69B4", label: "HotPink" },
    { value: "#FFA07A", label: "LightSalmon" },
    { value: "#BDB76B", label: "DarkKhaki" },
    { value: "#663399", label: "RebeccaPurple" },
    { value: "#32CD32", label: "LimeGreen" },
    { value: "#6B8E23", label: "OliveDrab" },
    { value: "#48D1CC", label: "MediumTurquoise" },
    { value: "#5F9EA0", label: "CadetBlue" },
  ];
  const onEventClick = React.useCallback((args) => {
    const event = args.event;
    console.log(event, "this is event");
    if (event?.completed == "1") {
      toast("Shif is completed you cannot edit it", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: event.color == null ? "black" : event.color,
          color: "#fff",
        },
      });
    } else {
      setIsEdit(true);
      setSelectedId(event.id);
      setSelectedObject(event);
      console.log(usersList, "myuser");
      const resultant = colorOptions.find((item) => item.value == event.color);
      //  const resultant2= rotaUsers.find((item)=>item.value==event.user_id)
      setDefaultColor(resultant);
      //  setDefaultUser(resultant2)
      // setShiftdate(event.date)
      // setstartTime(event.start)
      // setendTime(event.end)
      // setTitle(event.title)
      setEditDrawerModal(true);
    }
  }, []);

  const onClickCell = React.useCallback((args) => {
    checkIfUseronLeave(args);
    setIsEdit(false);
    console.log(args.resource);
    setSelectedResource(args.resource);
    let dd = new Date(args.date);
    let fd = new Intl.DateTimeFormat("en-us", {
      dateStyle: "full",
    });
    setCurrentDate(fd.format(dd));
    setShiftdate(args.date);
    let newD = `${new Date(args.date).getFullYear()}-${new Date(
      args.date
    ).getMonth() + 1}-${new Date(args.date).getDate()}`;
    console.log(dayjs(args.date, "YYYY:MM:DD"));

    fetch(`${baseUrl}/mgmt/rotas/${rotaId}/assigned-users`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        let tempArray = [];
        data.payload.forEach((item, index) => {
          tempArray.push({
            label: item.first_name,
            value: item.id,
          });
        });
        console.log("getAllUsers", tempArray);

        if (tempArray) {
          let filterd = tempArray.filter((user) => user.value == args.resource);
          let filterd2 = filterd.map((user) => user.value);
          if (PremissionChecker(user, "rota_shift.create")) {
            console.log("filter thek ho rha?", filterd2);
            setselectedUsers(filterd2);
            setDefaultColor(null);
            setColor("");
            setIsDrawerOpen(true);
          } else {
            toast.error("Do not have permission to create rota shift.");
          }
        }
        // setrotaUsers(tempArray)

        // setUsersList2(data.payload)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const checkIfUseronLeave = (event) => {
    let newDate = moment(event.date).format("YYYY-MM-DD");
    fetch(
      `${baseUrl}/mgmt/asset/users/${event.resource}/on-leave/date/${newDate}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setOnLeaveToday(data?.payload?.has_leave);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeDate = (date, dateString) => {
    console.log(date);
    setShiftdate(dateString);
  };

  const onChangeStartTime = (time) => {
    setstartTime(time);
  };

  const onChangeEndTime = (time) => {
    console.log(time);
    setendTime(time);
  };

  const CheckerEvent = () => {
    setlocError("");
    if (onLeaveToday) {
      setConfirmModal(true);
    } else {
      console.log("tum chl rhy ho?");
      setConfirmModal(false);
      if (selectedUsers.length < 0) {
        toast.error("Please select Users");
      } else {
        saveEvent();
      }
    }
  };

  const saveEvent = () => {
    // if(isfullDay){
    //   console.log('isfullday')
    //   if(address == ''){
    //     console.log('empty?')
    //     setlocError('Please select location')
    //   }
    // }

    setConfirmModal(false);
    // let arr = [...myEvents]
    // // let findUser = myResources.find(user=>user.id == )
    // let start1 = `${shiftDate}T${startTime}`
    // let end1 = `${shiftDate}T${endTime}`
    // arr.push({
    //     start: start1,
    //     end: end1,
    //     title: title,
    //     resource: selectedResource
    // })

    // setMyEvents(arr)
    // setIsDrawerOpen(false)
    // clearStates()

    let users_idss = selectedUsers.map((item) => item.value);

    setAddLoading(true);

    let formatedDate = moment(shiftDate).format("YYYY-MM-DD");
    let formatedStartTime = moment(startTime).format("HH:mm:ss");
    let formatedEndTime = moment(endTime).format("HH:mm:ss");

    const data = {
      // user_id: selectedResource,
      title: title,
      // date: formatedDate,
      start_date: showStartDate || "",
      end_date: showEndDate || showStartDate,
      full_day: 0,
      start_time: formatedStartTime,
      end_time: formatedEndTime,
      color: color,
      geo_fence: isGeoFence ? 1 : 0,
      latitude: isGeoFence ? latitude : null,
      longitude: isGeoFence ? longitude : null,
      address: isGeoFence ? address : null,
      draft: draft ? 1 : 0,
      user_ids: selectedUsers,
    };

    let s = `${new Date(startTime).getHours()}:${new Date(
      startTime
    ).getMinutes()}:${new Date(startTime).getSeconds()}`;
    let e = `${new Date(endTime).getHours()}:${new Date(
      endTime
    ).getMinutes()}:${new Date(endTime).getSeconds()}`;

    const data2 = {
      // user_id: selectedResource,
      title: title,
      // date: formatedDate,
      start_date: showStartDate || "",
      end_date: showEndDate || showStartDate,
      full_day: 1,
      color: color,
      geo_fence: isGeoFence ? 1 : 0,
      latitude: isGeoFence ? latitude : null,
      longitude: isGeoFence ? longitude : null,
      address: isGeoFence ? address : null,
      draft: draft ? 1 : 0,
      start_time: formatedStartTime,
      user_ids: selectedUsers,

      // end_time: !isfullDay && endTime
    };

    console.log(data);
    console.log(data2);

    let url = `${baseUrl}/mgmt/rotas/${rotaId}/rota-shifts`;

    console.log(url);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: isfullDay ? JSON.stringify(data2) : JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setAddLoading(false);
          reloadRota();
          setIsDrawerOpen(false);
          setShiftdate("");
          setIsFullday(false);
          setstartTime(currentDate1.getTime());
          setendTime(currentDate2.getTime());
          setTitle("");
          setShowEndDate("");
          setShowStartDate("");
          setEndDate(null);
          setStartDate(null);
          setDefaultColor(null);
          setConfirmModal(false);

          if (!draft) {
            users_idss.map((id) => {
              const extraData = {
                type: "create_shift",
                userId: id,
              };

              SendNotifaction(
                title,
                isfullDay
                  ? `Your shift is created at ${formatedDate} and Shift start time is ${formatedStartTime}`
                  : `Your shift is created at ${formatedDate} and Shift start time is ${formatedStartTime}  and ended at ${formatedEndTime} `,
                id,
                "",
                extraData
              );
            });
          }
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
            setAddLoading(false);
          });
        } else {
          toast.error(`Something went wrong`);
          setAddLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setAddLoading(false);
      });
  };

  const saveTemplate = () => {
    setConfirmModal(false);
    setAddLoading(true);

    let formatedDate = moment(shiftDate).format("YYYY-MM-DD");
    let formatedStartTime = moment(startTime).format("HH:mm:ss");
    let formatedEndTime = moment(endTime).format("HH:mm:ss");

    const data = {
      title: title,
      start_date: showStartDate || "",
      end_date: showEndDate || showStartDate,
      full_day: 0,
      start_time: formatedStartTime,
      end_time: formatedEndTime,
      color: color,
      geo_fence: isGeoFence ? 1 : 0,
      latitude: isGeoFence ? latitude : null,
      longitude: isGeoFence ? longitude : null,
      address: isGeoFence ? address : null,
      draft: draft ? 1 : 0,
      user_ids: selectedUsers,
    };

    let s = `${new Date(startTime).getHours()}:${new Date(
      startTime
    ).getMinutes()}:${new Date(startTime).getSeconds()}`;
    let e = `${new Date(endTime).getHours()}:${new Date(
      endTime
    ).getMinutes()}:${new Date(endTime).getSeconds()}`;

    const data2 = {
      title: title,
      start_date: showStartDate || "",
      end_date: showEndDate || showStartDate,
      full_day: 1,
      color: color,
      geo_fence: isGeoFence ? 1 : 0,
      latitude: isGeoFence ? latitude : null,
      longitude: isGeoFence ? longitude : null,
      address: isGeoFence ? address : null,
      draft: draft ? 1 : 0,
      start_time: formatedStartTime,
      user_ids: selectedUsers,
    };

    console.log(data);
    console.log(data2);

    let url = `${baseUrl}/mgmt/rota-shift-templates`;

    console.log(url);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: isfullDay ? JSON.stringify(data2) : JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setAddLoading(false);
          getTemplates();
          setIsDrawerOpen(false);
          setShiftdate("");
          setIsFullday(false);
          setstartTime(currentDate1.getTime());
          setendTime(currentDate2.getTime());
          setTitle("");
          setConfirmModal(false);
          setShowEndDate("");
          setShowStartDate("");
          setEndDate(null);
          setStartDate(null);
          setDefaultColor(null);
          // getTemplates();
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
            setAddLoading(false);
          });
        } else {
          toast.error(`Something went wrong`);
          setAddLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setAddLoading(false);
      });
  };

  const dateFormat = "YYYY-MM-DD";

  const handleDateChange = (date) => {
    console.log(date);
    setShiftdate(date);
  };

  function renderEventContent(event) {
    // Customize this function to render your own component for each event
    return (
      <div>
        <p>{event.title}2</p>
        <p>{event.start.toLocaleString()}</p>
      </div>
    );
  }

  console.log("address", address);

  const handleSelect = (result) => {
    console.log(result.value.place_id);
    geocodeByPlaceId(result.value.place_id)
      .then((results) => {
        const { lat, lng } = results[0].geometry.location;

        console.log(results);
        setAddress(results[0].formatted_address);
        setLatitude(lat());
        setLongitude(lng());
        console.log(`Latitude: ${lat()}, Longitude: ${lng()}`);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const renderCurrentLocationSuggestion = () => {
    return (
      <div
        className="autocomplete-item"
        onClick={() =>
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
            },
            (error) => console.log("Error", error)
          )
        }
      >
        <span className="autocomplete-icon">&#9679;</span>
        <span className="autocomplete-text">Use current location</span>
      </div>
    );
  };

  const getHourseToString = () => {
    const sTime = moment(startTime);
    const eTime = moment(endTime);

    const duration = moment.duration(eTime.diff(sTime));
    const formattedDuration = moment
      .utc(duration.asMilliseconds())
      .format("HH:mm [hours]");
    return formattedDuration;
  };
  const formetTime = (date) => {
    let d = new Date(date);

    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let newdate = `${d.getFullYear()}-${curr_moth}-${curr_date}`;
    return newdate;
  };

  const handleDrage = (some) => {
    console.log(some, "over");
    let userId = [];
    userId.push(some.resource);
    const data = {
      // user_id: some.resource,
      title: some.title,
      // date: formetTime(some.end),
      start_date: formetTime(some.end),
      end_date: formetTime(some.end),
      full_day: some.full_day,
      start_time: some.start_time != null ? some.start_time : "00:00:00",
      end_time: some.end_time != null ? some.end_time : "00:00:00",
      color: some.color != null ? some.color : "",
      geo_fence: some.geo_fence,
      latitude: some.latitude != null ? some.latitude : "",
      longitude: some.longitude != null ? some.longitude : "",
      address: some.address != null ? some.address : "",
      draft: draft ? 1 : 0,
      ///onlyforAddd
      user_ids: userId,
    };
    const data2 = {
      // user_id: some.resource,
      title: some.title,
      // date: formetTime(some.end),
      start_date: formetTime(some.end),
      end_date: formetTime(some.end),
      full_day: some.full_day,
      start_time: some.start_time != null ? some.start_time : "00:00:00",
      // end_time: some.end_time !=null?some.end_time:"00:00:00" ,
      color: some.color != null ? some.color : "",
      geo_fence: some.geo_fence,
      latitude: some.latitude != null ? some.latitude : "",
      longitude: some.longitude != null ? some.longitude : "",
      address: some.address != null ? some.address : "",
      draft: some.draft,
      ///onlyforAddd
      user_ids: userId,
    };

    ///addd
    let url = `${baseUrl}/mgmt/rotas/${rotaId}/rota-shifts`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: isfullDay ? JSON.stringify(data2) : JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setAddLoading(false);
          reloadRota();
          setIsDrawerOpen(false);
          setShiftdate("");
          setIsFullday(false);
          setstartTime(currentDate1.getTime());
          setendTime(currentDate2.getTime());
          setTitle("");
          setConfirmModal(false);
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
            setAddLoading(false);
          });
        } else {
          toast.error(`Something went wrong`);
          setAddLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setAddLoading(false);
      });
  };
  const handleMove = (some) => {
    console.log(some);
    let userId = [];
    userId.push(some.resource);
    const data = {
      user_id: some.resource,
      title: some.title,
      date: formetTime(some.end),
      full_day: some.full_day,
      start_time: some.start_time != null ? some.start_time : "00:00:00",
      end_time: some.end_time != null ? some.end_time : "00:00:00",
      color: some.color != null ? some.color : "",
      geo_fence: some.geo_fence,
      latitude: some.latitude != null ? some.latitude : "",
      longitude: some.longitude != null ? some.longitude : "",
      address: some.address != null ? some.address : "",
      draft: draft ? 1 : 0,
      ///onlyforAddd
      // user_ids: userId
    };
    const data2 = {
      user_id: some.resource,
      title: some.title,
      date: formetTime(some.end),
      full_day: some.full_day,
      start_time: some.start_time != null ? some.start_time : "00:00:00",
      // end_time: some.end_time !=null?some.end_time:"00:00:00" ,
      color: some.color != null ? some.color : "",
      geo_fence: some.geo_fence,
      latitude: some.latitude != null ? some.latitude : "",
      longitude: some.longitude != null ? some.longitude : "",
      address: some.address != null ? some.address : "",
      draft: some.draft,
      ///onlyforAddd
      // user_ids: userId
    };

    //update

    fetch(`${baseUrl}/mgmt/rota-shifts/${some.id}?_method=PUT `, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(some.full_day == "1" ? data2 : data),
    })
      .then((resp) => {
        resp.json().then((data) => {
          if (data.metadata) {
            if (data.metadata.success) {
              toast.success(data.metadata.message);
            } else if (!data.metadata.success) {
              Object.values(data.metadata.message).map((msg) => {
                toast.error(msg);
              });
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Opps something wents wronge");
      });
  };
  const dataSearch = usersList.filter((user) => {
    let text2 = search.toLowerCase();
    const usered = text2 ? user?.name?.toLowerCase().includes(text2) : true;
    return usered;
  });

  const onPublishClick = async () => {
    const response = await fetch(
      `${baseUrl}/mgmt/publish-rota-shifts/${rotaId}`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    const data = await response.json();
    if (data.metadata.success) {
      toast.success(data.metadata.message);
      reloadRota();
    } else if (!data.metadata.success) {
      Object.values(data.metadata.message).map((msg) => {
        toast.error(msg);
      });
    }
  };

  // react select color options

  // const CustomOption = ({ children, value }) => (
  //   <Option value={value}>
  //     <div style={{ display: 'flex', alignItems: 'center' }}>
  //       <div
  //         style={{
  //           backgroundColor: value,
  //           width: '20px',
  //           height: '20px',
  //           borderRadius: '10px',
  //           marginRight: '10px',
  //         }}
  //       ></div>
  //       {children}
  //     </div>
  //   </Option>
  // );

  // Custom option component to display color and label
  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          padding: 10,
          backgroundColor: data.value,
          width: "20px",
          margin: 10,
          height: "20px",
          marginRight: "10px",
          borderRadius: 10,
        }}
      ></div>
      <div>{label}</div>
    </div>
  );

  // Custom value component to display color and label
  const CustomValue = ({ innerProps, label, data }) => (
    <div {...innerProps} style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          backgroundColor: data.value,
          width: "20px",
          height: "20px",
          padding: 10,
          margin: 10,
          marginRight: "10px",
          borderRadius: 10,
        }}
      ></div>
      {label}
    </div>
  );

  // Function to format the option label
  const formatOptionLabel = ({ label, value }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          padding: 10,
          backgroundColor: value,
          margin: 10,
          width: "20px",
          height: "20px",
          marginRight: "10px",
        }}
      ></div>
      {label}
    </div>
  );

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value: selectedUsers,
    options: selectUsers,
    onChange: (newValue) => {
      setselectedUsers(newValue);
    },
    placeholder: "Select Users...",
    maxTagCount: "responsive",
  };

  let TEMPLATES = [
    {
      shiftTitle: "Morning shift",
      startTime: new Date(),
      endTime: new Date(),
      allDay: false,
      shiftColor: "#ffff",
    },
    {
      shiftTitle: "Shift shift",
      startTime: "09:00AM",
      endTime: "17:30PM",
      allDay: true,
      shiftColor: "#ffff",
    },
  ];
  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    const formattedStartDate = startDate ? startDate.format("YYYY-MM-DD") : "";
    const formattedEndDate = endDate ? endDate.format("YYYY-MM-DD") : "";
    setShowStartDate(formattedStartDate);
    setShowEndDate(formattedEndDate);
  };

  const handleFocusChange = (focusedInput) => {
    console.log(focusedInput);
    setFocusedInput(focusedInput);
  };
  const handleSetDefaultDates = (dd, dd2) => {
    setStartDate(moment(dd));
    setEndDate(moment(dd2));
  };
  const handleCancel = () => {
    setPikerModal(false);
  };
  const handleOk = () => {
    if (showEndDate == "") {
      setShiftdate(showStartDate);
      setEndDate(startDate);
    }
    setPikerModal(false);
  };

  return (
    <div className="">
      {/* <div className="row">
        <div className="col-md-2">
          <label>
            <strong>Search</strong>
          </label>
          <input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Users"
            className="form-control"
          />
        </div>
        <div className="col-md-2">
          <label>
            <strong>View</strong>
          </label>
          <select
            className="form-control"
            defaultValue={"week"}
            onChange={(e) => setType(e.target.value)}
          >
            <option value={"day"}>Day</option>
            <option value={"week"}>Week</option>
            <option value={"month"}>Month</option>
          </select>
        </div>
        <div className="col-md-2">
          <label>
            <strong>Theme</strong>
          </label>
          <select
            className="form-control"
            onChange={(e) => setTheme(e.target.value)}
          >
            <option value={""}>Select Theme</option>
            <option value={"dark"}>Dark</option>
            <option value={"light"}>Light</option>
          </select>
        </div>
        <div className="col-md-2 mt-9">
          <label></label>
          <Switcher
            onChange={(e) => setCopyUpdate(e)}
            checkedChildren="Copy"
            unCheckedChildren="Move"
          />
        </div>

        <div
          onClick={() => setIsDrawerOpen(true)}
          className="col-md-2 text-right mt-7"
        >
          <button className="btn btn-primary">Add shift</button>
        </div>
        <div className="col-md-2 text-right mt-7">
          <button onClick={onPublishClick} className="btn btn-primary">
            Publish
          </button>
        </div>
      </div> */}

      {isLoading ? (
        <div>
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
        </div>
      ) : (
        <div style={{ width: "100%", height: "73vh" }}>
          <Eventcalendar
            // height={'100%'}
            width={"100%"}
            theme="ios"
            calendarHeight={200}
            themeVariant={theme}
            view={{
              timeline: {
                type: type,
                eventList: true,
              },
              // schedule:{
              //     type:'week',
              //     days:true,
              // }
            }}
            onCellClick={onClickCell}
            data={eventList}
            dragToMove
            // dragToResize
            // dragToCreate
            // clickToCreate
            onEventDragStart={(e) => {
              console.log(e, "sth discription likh dena koi");
              setCatchStartEvent(e);
            }}
            onEventDragEnd={(e) => {
              console.log(catchStartEvent);
              console.log(e);
              setCatchEndEvent(e.resource);
              if (copyUpdate) {
                handleDrage(e.event);
              } else {
                handleMove(e.event);
              }
            }}
            dragTimeStep={2}
            eventDelete={true}
            renderScheduleEvent={(eventdata) => (
              <div
                style={
                  eventdata?.original.draft == "1"
                    ? { backgroundColor: "#fff", border: "1px solid red" }
                    : {
                        backgroundColor:
                          eventdata.color[0] != "#" ? "black" : eventdata.color,
                      }
                }
                className="event-card"
              >
                {/* {console.log(eventdata, "ya hy")} */}
                {/* {console.log(eventdata,"this is draf")} */}

                {/* {console.log(eventdata)} */}
                <p
                  style={
                    eventdata?.original.draft == "1"
                      ? { color: "black" }
                      : { color: "#fff" }
                  }
                >
                  {" "}
                  {eventdata.allDay
                    ? eventdata.allDayText
                    : `${eventdata.start}-${eventdata.end}`}
                  {eventdata.holiday ? "(On Holiday)" : ""}
                </p>
                <strong
                  style={
                    eventdata?.original.draft == "1"
                      ? { color: "black" }
                      : { color: "#fff" }
                  }
                >
                  {eventdata.title.slice(0, 15)}
                </strong>
                {/* <strong>{eventdata.draft}</strong> */}
              </div>
            )}
            renderResource={(resource, index) => (
              <Popover
                placement={"right"}
                content={<span>{resource.name}</span>}
                title="Info"
                trigger="hover"
              >
                <div className="mt-9">
                  <div className="d-flex">
                    <Avatar
                      style={{ backgroundColor: `#${resource?.color}` }}
                      alt={resource?.name?.toUpperCase()}
                      src="#"
                    />
                    <span className="mx-1 mt-3">{resource.name}</span>
                  </div>
                </div>
              </Popover>
            )}
            onEventClick={onEventClick}
            // onCellClick={(args)=>console.log(args.)}
            resources={dataSearch}
            onMonthChange={(event) => console.log(event)}
          />
        </div>
      )}

      <Drawer
        // title={currentDate}
        width={450}
        onClose={() => {
          setColor("");
          setShowEndDate("");
          setShowStartDate("");
          setEndDate(null);
          setStartDate(null);
          setDefaultColor(null);
          setselectedUsers([]);
          setIsDrawerOpen(false);
        }}
        open={isDrawerOpen}
        bodyStyle={{
          paddingBottom: 50,
        }}
        extra={
          <Space>
            <h3 className="text-center">New Shift:{currentDate}</h3>
          </Space>
        }
      >
        {addLoading ? (
          <div>
            <Spin tip="Loading" size="large">
              <div className="content" />
            </Spin>
          </div>
        ) : (
          <>
           <div className="" ref={scrollRef}>
      </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="row d-flex justify-content-around m-3">
                <div className="d-flex align-items-center col-md-8">
                  {/* <span>Date</span> */}
                  {/* <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date"
                    value={shiftDate}
                    // defaultValue={new Date(object.date}
                    // value={shiftDate ? shiftDate : new Date(object.start)}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  /> */}
                  {console.log(defaultDateRange, "date")}

                  <div
                    className="customButton"
                    onClick={() => {
                      handleFocusChange();
                      setPikerModal(true);
                    }}
                  >
                    <span>
                      {" "}
                      {startDate
                        ? startDate.format("YYYY-MM-DD")
                        : "" || "Start Date"}{" "}
                    </span>
                    <span>
                      <ArrowRightOutlined className="myarrorw" />
                    </span>
                    <span>
                      {endDate
                        ? endDate.format("YYYY-MM-DD")
                        : "" || "End Date"}
                    </span>
                  </div>
                  {/* <RangePicker
                  defaultValue={[
                    defaultDateRange ? dayjs(defaultDateRange, dateFormat) : null,
                    defaultDateRange ? dayjs(defaultDateRange, dateFormat) : null,
                  ]}
                 format={dateFormat}
                  onChange={(dn,date)=>console.log(date)} /> */}

                  {/* <RangePicker
                      onChange={(dn,date)=>setDate(date)} />  */}
                </div>
                <div className="d-flex align-items-center col-md-4">
                  {/* <span>All day</span> */}
                  {/* <AntdSwitch checked={isfullDay} className='ml-3' onChange={(checked) => setIsFullday(checked)} /> */}
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        value={isfullDay}
                        onChange={(check) => {
                          console.log("Checking", check.target.checked);
                          setIsFullday(check.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label="All Day"
                    labelPlacement="start"
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="d-flex align-items-center col-md-5">
                  <KeyboardTimePicker
                    // disabled={isfullDay}
                    margin="normal"
                    id="start-time-picker"
                    label="Start Time"
                    value={startTime}
                    onChange={(date) => onChangeStartTime(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </div>
                <div className="d-flex align-items-center col-md-5">
                  <KeyboardTimePicker
                    margin="normal"
                    disabled={isfullDay}
                    id="end-time-picker"
                    label="End Time"
                    value={endTime}
                    onChange={(date) => onChangeEndTime(date)}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </div>
                <div className="d-flex align-items-center col-md-2">
                  <b>{getHourseToString().toLocaleUpperCase()}</b>
                </div>
              </div>

              <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="col-md-12">
                  <label>
                    Title <span className="text-danger">*</span>
                  </label>
                  <textarea
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    rows={4}
                    className="form-control"
                    placeholder="Shift title"
                  />
                </div>
              </div>
              <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="col-md-12">
                  <label>
                    Users <span className="text-danger">*</span>
                  </label>
                  {/* <Select
                    options={rotaUsers}
                    onChange={(users) => setselectedUsers(users)}
                    allowClear
                    isMulti

                    mode="multiple"
                    defaultValue={selectedUsers}
                  /> */}
                  <AntSelect {...selectProps} />
                </div>
              </div>

              <div>
                <div className="row d-flex justify-content-around m-3 mt-5">
                  <div className="col-md-12">
                    <label>Shift Color</label>
                    <Select
                      defaultValue={defaultColor || ""}
                      value={defaultColor || ""}
                      onChange={(e) => {
                        setDefaultColor(e);
                        setColor(e.value);
                      }}
                      options={colorOptions}
                      components={{
                        Option: CustomOption,
                        SingleValue: CustomValue,
                      }}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {/* <AntSelect
                      options={colorOptions}
                      optionLabelProp="label"
                      className="custom-dropdown"
                      // dropdownClassName="custom-dropdown"
                    >
                      {colorOptions.map((option) => (
                        <div>
                          <div>circle</div>
                          <div>{option.label}</div>
                        </div>
                        // <CustomOption
                        //   key={option.value}
                        //   value={option.value}>
                        //   {option.label}
                        // </CustomOption>
                      ))}
                    </AntSelect> */}
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-around m-3 mt-5">
                <div className="d-flex align-items-center col-md-4">
                  {/* <span>All day</span> */}
                  {/* <AntdSwitch checked={isfullDay} className='ml-3' onChange={(checked) => setIsFullday(checked)} /> */}
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        value={isGeoFence}
                        onChange={(check) => {
                          console.log("Checking", check.target.checked);
                          setIsGeoFence(check.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label="Geo Fence"
                    labelPlacement="start"
                  />
                </div>

                <div className="d-flex align-items-center col-md-4">
                  {/* <span>All day</span> */}
                  {/* <AntdSwitch checked={isfullDay} className='ml-3' onChange={(checked) => setIsFullday(checked)} /> */}
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch
                        color="primary"
                        value={draft}
                        onChange={(check) => {
                          console.log("Checking", check.target.checked);
                          setDraft(check.target.checked);
                        }}
                      />
                    }
                    label="Draft"
                    labelPlacement="start"
                  />
                </div>
              </div>
              {isGeoFence && (
                <div className="m-3 mt-5 ml-3">
                  <label className="ml-3">
                    Location <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex align-items-center col-md-12">
                    <GooglePlacesAutocomplete
                      apiKey={api_key}
                      selectProps={{
                        renderSuggestions: () => {
                          return (
                            <div>
                              <label>Current location</label>
                            </div>
                          );
                        },
                        address,
                        onChange: handleSelect,
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            color: "blue",
                            width: 300,
                          }),
                          option: (provided) => ({
                            ...provided,
                            color: "blue",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "blue",
                          }),
                        },
                      }}
                    />
                  </div>

                  {locError && <span className="text-danger">{locError}</span>}
                </div>
              )}
              <div className="row d-flex justify-content-around m-3 mt-5">
                <Button onClick={() => CheckerEvent()} type="primary" success>
                  Save
                </Button>
                <Button onClick={() => saveTemplate()} type="dashed" success>
                  Save as Template
                </Button>

                <Button
                  onClick={() => {
                    // setDraft(false)
                    // setIsFullday(false)
                    // setIsGeoFence(false)
                    setIsDrawerOpen(false);
                  }}
                  type="link"
                  danger
                >
                  Cancel
                </Button>
              </div>

              <Divider />
              <div className="text-center pt-4">
                <b>
                  <h3>Templates</h3>
                </b>
                <div>
                  {templates?.map((shift) => (
                    <div
                      className="mt-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Card
                        className={selectedTemplate==shift.id?"templateCard myselectedTemp":"templateCard"}
                        size="small"
                        title={
                          <h6
                            onClick={() => {
                               scrollRef.current.scrollIntoView({
                                behavior: 'smooth',
                               });
                             setSelectedTemplate(shift.id)
                              console.log(shift);
                              setIsFullday(shift.full_day);
                              setIsGeoFence(shift.geo_fence);
                              const resultant = colorOptions.find(
                                (item) => item.value == shift.color
                              );
                              setDefaultColor(resultant);
                              setShowStartDate(shift.start_date);
                              setShowEndDate(shift.end_date);
                              handleSetDefaultDates(
                                shift.start_date,
                                shift.end_date
                              );
                              setTitle(shift.title);
                              setColor(shift.color);
                              setstartTime(
                                `${shift.start_date}T${
                                  shift.start_time
                                    ? shift.start_time
                                    : "00:00:00"
                                }`
                              );
                              setendTime(
                                `${shift.end_date}T${
                                  shift.end_time ? shift.end_time : "00:00:00"
                                }`
                              );
                            }}
                          >
                            {shift.title}
                          </h6>
                        }
                        extra={
                          <>
                            <div className="d-flex">
                              <div
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  marginTop: "6px",
                                  marginRight: "10px",
                                  borderRadius: "100%",
                                  backgroundColor: shift.color,
                                }}
                              ></div>
                              <a
                                onClick={() => {
                                  setTemplateId(shift.id);
                                  setdeleteModal(true);
                                }}
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                              >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Trash.svg"
                                    )}
                                  />
                                </span>
                              </a>
                            </div>
                          </>
                        }
                        style={{ width: 300 }}
                      >
                        <div
                          onClick={() => {
                            scrollRef.current.scrollIntoView({
                              behavior: 'smooth',
                             });
                             setSelectedTemplate(shift.id)
                            console.log(shift);
                            setIsFullday(shift.full_day);
                            setIsGeoFence(shift.geo_fence);
                            const resultant = colorOptions.find(
                              (item) => item.value == shift.color
                            );
                            setDefaultColor(resultant);
                            setShowStartDate(shift.start_date);
                            setShowEndDate(shift.end_date);
                            handleSetDefaultDates(
                              shift.start_date,
                              shift.end_date
                            );
                            setTitle(shift.title);
                            setColor(shift.color);
                            setstartTime(
                              `${shift.start_date}T${
                                shift.start_time ? shift.start_time : "00:00:00"
                              }`
                            );
                            setendTime(
                              `${shift.end_date}T${
                                shift.end_time ? shift.end_time : "00:00:00"
                              }`
                            );
                          }}
                          className="row"
                        >
                          <div className="col-md-6">
                            <span>
                              <b>All Day</b>
                            </span>
                            <span className="mx-2">
                              {shift.full_day ? (
                                <CheckOutlined
                                  style={{
                                    position: "relative",
                                    color: "green",
                                    bottom: "3px",
                                  }}
                                />
                              ) : (
                                <CloseOutlined
                                  style={{
                                    position: "relative",
                                    color: "red",
                                    bottom: "3px",
                                  }}
                                />
                              )}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <span>
                              <b>Geo Fence</b>
                            </span>
                            <span className="mx-2">
                              {shift.geo_fence ? (
                                <CheckOutlined
                                  style={{
                                    position: "relative",
                                    color: "green",
                                    bottom: "3px",
                                  }}
                                />
                              ) : (
                                <CloseOutlined
                                  style={{
                                    position: "relative",
                                    color: "red",
                                    bottom: "3px",
                                  }}
                                />
                              )}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <span>
                              <b>Start Date</b>
                            </span>
                            <span className="mx-2">{shift.start_date}</span>
                          </div>
                          <div className="col-md-6">
                            <span>
                              <b>End Date</b>
                            </span>
                            <span className="mx-2">{shift.end_date}</span>
                          </div>
                          <div className="col-md-6">
                            <span>
                              <b>Start Time</b>
                            </span>
                            <span className="mx-2">{shift.start_time}</span>
                          </div>
                          <div className="col-md-6">
                            <span>
                              <b>End Time</b>
                            </span>
                            <span className="mx-2">{shift.end_time}</span>
                          </div>
                        </div>
                      </Card>
                      {/* <Crude 
                      style={{cursor:"pointer"}}
                        
                       className="allholiCard" variant="outlined">
                        <CardContent
                          style={{
                            paddingTop: "0px",
                            paddingLeft: "15px",
                            paddingBottom: "5px",
                            paddingRight: "0px",
                          }}
                          className="text-center"
                        >
                          <div className="">
                            <div className="row">
                              <div className="col-md-8 text-left pt-2">
                                <p>
                                  <b>{shift.title}</b>
                                </p>
                              
                              </div>
                              
                              <div className="col-md-4 text-center">
                                <p
                             onClick={() => {
                              console.log(shift)
                              setIsFullday(shift.full_day)
                              setIsGeoFence(shift.geo_fence)
                              const resultant= colorOptions.find((item)=>item.value==shift.color)
                               setDefaultColor(resultant)
                              setShowStartDate(shift.start_date)
                              setShowEndDate(shift.end_date)
                              handleSetDefaultDates(shift.start_date,shift.end_date)
                              setTitle(shift.title)
                              setColor(shift.color)
                              setstartTime(`${shift.start_date}T${shift.start_time ? shift.start_time : "00:00:00"
                            }`)
                              setendTime(`${shift.end_date}T${shift.end_time ? shift.end_time : "00:00:00"
                            }`)
                           
                            }}
                                  className={
                                  "bg-primary"
                                  }
                                  style={{ marginBottom: "0", color: "white" }}
                                >
                                  Click To Add
                                </p>
                              </div>
                            </div>
                            
                          </div>
                        </CardContent>
                      </Crude> */}
                    </div>
                    // <Card onClick={() => {
                    //   setTitle(shift.shiftTitle)
                    //   setColor(shift.shiftColor)
                    //   setstartTime(shift.startTime)
                    //   setendTime(shift.endTime)

                    // }} title={shift.shiftTitle} bordered={true}>
                    //   Click to Add
                    // </Card>
                  ))}
                </div>
              </div>
            </MuiPickersUtilsProvider>
          </>
        )}
      </Drawer>

      {/* edit drawer majburi wala drawer */}

      <EditDrawer
        allUsers={usersList}
        defUser={defaultUser}
        defColor={defaultColor}
        drawerVisible={editDrawerModal}
        hideDrawer={() => setEditDrawerModal(false)}
        object={selectedObject}
        reload={reloadRota}
        rotaId={rotaId}
      />

      <ConfirmModal
        modalVisible={confirmModal}
        hideModal={() => setConfirmModal(false)}
        onClickAnyWay={saveEvent}
      />
      <Modal
        className="pikkerModal"
        title="Select Start and End Date"
        open={pikerModal}
        onCancel={handleCancel}
        onOk={handleOk}
        cancelButtonProps={{style:{display:"none"}}}
        okButtonProps={{style:{backgroundColor:"#151515"}}}
      >
        <div className="faker">
          <DateRangePicker
            startDate={startDate}
            startDateId="your_unique_start_date_id"
            endDate={endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={handleDatesChange}
            focusedInput={focusedInput == null ? "startDate" : focusedInput}
            onFocusChange={handleFocusChange}
          />
        </div>
      </Modal>
      <DeleteModal
        HideModal={() => setdeleteModal(false)}
        visible={deleteModal}
        // id={SelectedId}
        method="DELETE"
        reload={() => getTemplates()}
        url={`${baseUrl}/mgmt/rota-shift-templates/${templateId}`}
      />
    </div>
  );
}

export default RotaCalendarTwo;

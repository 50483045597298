import axios from "axios";
import { baseUrl } from "../../../../services/config";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;


const authToken = localStorage.getItem("userToken")


export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  fetch(`${baseUrl}/mgmt/user-profile`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  }).then(res => res.json())
    .then((data) => {
      if (data.metadata.success) {

        return data.payload
        // dispatch({
        //   type: "ADD_USER",
        //   payload: {
        //     // isAuthenticated: true,
        //     // authtoken: user.payload.token,
        //     user: data.payload,
        //   },
        // });
      }
    }).catch(err => {
      console.log(err)
    })
}


import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import './announce.scss'
import { Modal, Spinner } from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Card as Muicard } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SVG from "react-inlinesvg";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import LinearProgress from "@material-ui/core/LinearProgress";
import Addannouncement from "./Addannouncement";
import { baseUrl } from "../../../services/config";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Empty, Tooltip } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { toast } from "react-hot-toast";
import EditAnnoucment from "./EditAnnoucment";
import Departments from "../Departments/Departments";
import Feed from "../Feed/Feed";
import { useSelector } from "react-redux";
import { PremissionChecker } from "../../../services/PremissionChecker";
import NoPermission from "../../components/NoPermission/NoPermission";
import AssignUsers from "../../components/AssignUsers/AssignUsers";

export function Announcement() {
  const useStyles = makeStyles({

    media: {
      // height: 200,
      // width: '100%'
    },
  });
  const authtoken = localStorage.getItem("userToken");
  const Ruser = useSelector(state => state.user.user)


  const [addModal, setaddModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [editItem, setEditItem] = useState({});
  const [announce, setAnnounce] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [selectedType,setSelectedType]=useState("")
  const [assignModal, setAssignModal] = useState(false)
  const [selectedAnoucment, setSelectedAnoucment] = useState({})

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };



  useEffect(() => {
    getAnnounce()
  }, [])


  const openEdit = (item) => {
    setEditItem(item);
    seteditModal(true);
  };

  const getAnnounce = async () => {
    setloading(true)
    await fetch(`${baseUrl}/mgmt/announcements`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false)
        console.log(data, "======")
        setAnnounce(data.payload)
      })
      .catch((err) => {
        setloading(false)

        console.log("", err);
      });
  };

  const deleteAnnoucement = () => {
    setDeleteLoading(true)
    fetch(`${baseUrl}/mgmt/announcement/type/${selectedType}/delete`, {
        method:"DELETE",
        headers: {
            Authorization: `Bearer ${authtoken}`,
        }
    }).then(res => res.json())
        .then((data) => {
            if (data.metadata.success) {
                toast.success(data.metadata.message)
                setDeleteLoading(false)
                setdeleteModal(false)
                getAnnounce()
            } else if (!data.metadata.success) {
                setDeleteLoading(false)
                toast.error(data.metadata.message)
            } else {
                setDeleteLoading(false)
                toast.error('Unknown error :(')
            }
        }).catch(err => {
            setDeleteLoading(false)
            console.log(err);
        })
}

const oppner=(type)=>{
  setSelectedType(type)
  setdeleteModal(true)
}


  const classes = useStyles();
  return (
    <>

      {loading ? <LinearProgress /> : null}
     {PremissionChecker(Ruser, "announcement.list")  ?  <Card>
        <>
          <CardHeader title="Announcements">
            <CardHeaderToolbar>
                  {PremissionChecker(Ruser, "announcement.store")  ?
              <button
              // disabled={announce?.length<2?false:true}
                type="button"
                className="btn btn-primary mx-2"
                onClick={() => {
                  setaddModal(true);
                }}
              >
                New Announcement
              </button>
               : null}
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            {
              PremissionChecker(Ruser, "announcement.list")  ? 
              announce?
              <div className="row">
                <div className="col-md-3">
                {
              announce?.static_announcement?.map((item) => (
                  <div style={{ height: 430 }} className="mt-5">
                    <div className="annouccard">
                      <CardActionArea>
                       {/* <div style={{ height: 250 }}>
                          <Carousel showStatus={false} showThumbs={false}>
                            {
                              item.images.map(img => (
                                <img style={{ height: 250 }} src={img.url} />
                              ))
                            }
                          </Carousel>
                        </div> */}
                
                        <div style={{ height: 250 }}>
                        <img style={{ height: 250,width:"100%" }} src={item?.image?.url} />
                      </div>
                      
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            {item.title?.length > 50 ? item.title.slice(0, 50) + '...' : item.title}
                          </Typography>
                          <Tooltip placement="top" title={item?.description}>
                            <Typography style={{ width: "fit-content",height:"40px" }}>{item.description && item?.description?.length > 50 ? item?.description.slice(0, 50) + "...." : item?.description}</Typography>
                          </Tooltip>

                        </CardContent>

                      </CardActionArea>
                      <CardActions style={{ justifyContent: "center" }}>
                      {PremissionChecker(Ruser, "announcement.assign_user")  ?      <a
            onClick={()=>{
              setSelectedType(2)
              setAssignModal(true)
            }}
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
               title="Reset Password"
                src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
              />
            </span>
          </a> : ''}
                      {PremissionChecker(Ruser, "announcement.delete")  ?
                        <a
                          onClick={()=>oppner(2)}
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG title="Delete" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                          </span>
                        </a>
                          : null}
                        {PremissionChecker(Ruser, "announcement.update")  ?
                        <a
                          onClick={() => openEdit(announce?.static_announcement)}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                            title="Edit"
                              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                            />
                          </span>
                        </a>
                         : null}

                      </CardActions>

                    </div>
                  </div>
                ))
              // :
              // <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
              //   <Empty/>
              // </div>
              }
                </div>
            

<div className="col-md-3">


<Carousel showStatus={false} showThumbs={false} className="announceSlider">
{
              announce?.slider_announcement?.map((item) => (
                  <div style={{ height: 430 }} className="mt-5">
                    <div className="annouccard">
                      <CardActionArea>
                       <div style={{ height: 250 }}>
                                <img style={{ height: 250 }} src={item?.image?.url} />


                          
                        </div>
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2" style={{textAlign:"left"}}>
                            {item.title?.length > 50 ? item.title.slice(0, 50) + '...' : item.title}
                          </Typography>
                          <Tooltip placement="top" title={item?.description}>
                            <Typography style={{ width: "fit-content",height:"40px",textAlign:"left" }}>{item?.description?.length > 50 ? item?.description.slice(0, 50) + "...." : item?.description}</Typography>
                          </Tooltip>

                        </CardContent>

                      </CardActionArea>
                      <CardActions style={{ justifyContent: "center" }}>
                {PremissionChecker(Ruser, "announcement.assign_user")  ?      <a
            onClick={()=>{
              setSelectedType(1)
              setAssignModal(true)
            }}
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
               title="Reset Password"
                src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
              />
            </span>
          </a> : ''}
                      {PremissionChecker(Ruser, "announcement.delete")  ?
                        <a
                           onClick={()=>oppner(1)}
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                          </span>
                        </a>
                        : null}
                        {PremissionChecker(Ruser, "announcement.update")  ?
                        <a
                          onClick={() => openEdit(announce?.slider_announcement)}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                            />
                          </span>
                        </a>
                         : null}

                      </CardActions>

                    </div>
                  </div>
                ))
              // :
              // <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
              //   <Empty/>
              // </div>
            }
            </Carousel>
</div>

            </div>:
            '' 
            : 
           <NoPermission/>
            }
          
           
          </CardBody>
        </>
      </Card> : ''}
      <Modal
        size="xl"
        animation={true}
        show={addModal}>
        <Modal.Header>
          <h4>{"Add New Announcements"}</h4>
        </Modal.Header>
        <Addannouncement
          reload={getAnnounce}
          onHide={() => setaddModal(false)}
        />
      </Modal>
      <Modal
        size="xl"
        animation={true}
        show={editModal}>
        <Modal.Header>
          <h4>{ "Edit Announcements"}</h4>
        </Modal.Header>
        <EditAnnoucment
          editItem={editItem}
          reload={getAnnounce}
          onHide={() => {
            seteditModal(false)}}
        />
      </Modal>
    
        <Modal
            centered
            show={deleteModal}>
            <Modal.Header>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
          {
                deleteLoading ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                        <Spinner animation='border' color='green' />
                    </div>
                    :
                    <>
                        <Modal.Body>
                            Are you sure you want to Delete?
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={() => setdeleteModal(false)}
                                className='btn btn-danger'>
                                Close
                            </button>

                            <button
                             onClick={deleteAnnoucement}
                                className='btn btn-primary'>
                                Delete
                            </button>
                        </Modal.Footer>
                    </>
            }
        </Modal>
       {PremissionChecker(Ruser, "feed.list")  ?   <Feed/> : ''}
        {assignModal ?  <AssignUsers
                    permission={PremissionChecker(Ruser, "announcement.assign_user")}
                    getassignUrl={`${baseUrl}/mgmt/announcement/${selectedType}/assigned-users`}
                    postassignUrl={`${baseUrl}/mgmt/announcement/${selectedType}/assign-users`}
                    rotaId={selectedType}
                    visible={assignModal}
                    setModalVisibility={(bol) => setAssignModal(bol)}
                    refresh={()=>(console.log("abc"))}

                />:''}
    </>
  );
}

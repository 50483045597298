import { Card, CardContent, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
// import "./allholidays.css";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    DatePicker,
} from "@material-ui/pickers";
import { Spinner, Table } from "react-bootstrap";
import { payload } from "../AllHolidays/data.json";
// import { Tooltip } from '@material-ui/core';
import { baseUrl } from "../../../services/config";
import { toast } from "react-hot-toast";
import { GiPalmTree } from "react-icons/gi";
import { BsFillCircleFill, BsCircleHalf } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Avatar } from "@material-ui/core";
import { DatePicker as AntdDatePicker } from "antd";
import UsePagination from "../../components/UsePagination/UsePagination";
import set from "date-fns/fp/set/index.js";
import collect from "collect.js";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Drawer from "@material-ui/core/Drawer";
import { useParams } from 'react-router-dom'


const GroupHolidays = (props) => {
    const authtoken = localStorage.getItem("userToken");


    const [drawerModal, setdrawerModal] = useState(false);



    const params = useParams()

    const { id, reload } = props



    console.log('id is down', id)

    const [selectedgroupId, setSelectedgroupId] = useState(id)
    const [value, setValue] = useState();
    const [getDays, setGetDays] = useState([]);
    const [tempList, settempList] = useState([]);
    const [currenetSelectedDate, setcurrenetSelectedDate] = useState("");
    const [links, setLinks] = useState([]);
    const [holidaysList, setHolidaysList] = useState([]);
    const [dateIndexList, setdateIndexList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [leaveTypes, setLeaveTypes] = useState([]);

    const [searchText, setSearchText] = useState("");
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [selectedId, setSelectedId] = useState("");

    const [leaveDetails, setleaveDetails] = useState({});


    const [searchLeave, setsearchLeave] = useState('')
    const [searchRole, setsearchRole] = useState('')

    const [usersList, setUsersList] = useState([]);
    const [roles, setroles] = useState([]);
    const [groupList, setgroupList] = useState([])

    useEffect(() => {
        getInfo()
    }, [])



    const getInfo = () => {
        fetch(`${baseUrl}/mgmt/leave-groups`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authtoken}`,

            }
        }).then(res => res.json())
            .then((data) => {
                setgroupList(data.payload)
            }).catch(err => {
                console.log(err)
            })
    }







    useEffect(() => {
        getAllBrands();
        getAllRoles();
    }, []);

    const getAllBrands = () => {
        fetch(`${baseUrl}/mgmt/users`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((res) => res.json())

            .then((data) => {
                setUsersList(data.payload);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getAllRoles = () => {
        fetch(`${baseUrl}/mgmt/roles`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((res) => res.json())

            .then((data) => {
                setroles(data.payload);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [currentUserLeaves, setCurrentUserLeaves] = useState([]);

    useEffect(() => {
        let d = new Date();
        var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
        var curr_date = ("0" + d.getDate()).slice(-2);
        let enddate = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`;

        let url = `${baseUrl}/mgmt/leave-group/${selectedgroupId}/leave-calendar`;



        getLeavesTypes();
        getHolidaysCalendar(enddate, url);


        // if (reload || selectedgroupId) {


    }, []);

    const getLeavesTypes = () => {
        fetch(`${baseUrl}/mgmt/leave-types`,{
            method: "GET",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setLeaveTypes(data.payload);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getHolidaysCalendar = (apidate, url) => {

        console.log("ye call huya?");

        // let year = new Date(apidate).getFullYear();
        // let month = new Date(apidate).getMonth();

        // const date = new Date(year, month, 1);

        // let d = new Date();
        // var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
        // var curr_date = ("0" + d.getDate()).slice(-2);
        // let enddate = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`;

        // const customDateArray = [];
        // const customDateArray2 = [];

        // while (date.getMonth() === month) {
        //   customDateArray.push({
        //     date: new Date(date).getDate(),
        //     day: moment(date).format("dd"),
        //     leave: 0,
        //     fulldate: moment(date).format("YYYY-MM-DD"),
        //     leave: true,
        //   });
        //   date.setDate(date.getDate() + 1);
        //   customDateArray2.push(moment(date).format("YYYY-MM-DD"));
        // }

        let year = new Date().getFullYear();
        let month = new Date().getMonth();

        const date = new Date(year, month, 1);

        let d = new Date();
        var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
        var curr_date = ("0" + d.getDate()).slice(-2);
        let enddate = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`;

        const customDateArray = [];
        const customDateArray2 = [];

        while (date.getMonth() === month) {
            customDateArray.push({
                date: new Date(date).getDate(),
                day: moment(date).format("dd"),
                leave: 0,
                fulldate: moment(date).format("YYYY-MM-DD"),
                leave: true,
            });
            date.setDate(date.getDate() + 1);
            customDateArray2.push(moment(date).format("YYYY-MM-DD"));
        }
        console.log("arrayyyy ===>", customDateArray);
        setGetDays(customDateArray);
        setdateIndexList(customDateArray2);



        console.log("customDateArray", customDateArray)
        setGetDays(customDateArray);
        console.log(apidate, "this is api");
        setcurrenetSelectedDate(apidate);
        setLoading(true);
        const first = `${url}/${apidate}`;
        const onPaginate = url;

        fetch(url.includes("page") ? onPaginate : first, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    //@do not remove please
                    let newData = data.payload.data;
                    data.payload.data.map((userone, userIndex) => {
                        userone["thisLeaves"] = userone?.leaves?.reduce(function (
                            map,
                            obj,
                            i
                        ) {
                            map[i] = obj.day;
                            return Object.values(map);
                        },
                            {});
                        if (!userone.leaves || userone.leaves.length === 0) {
                            // Add 30 empty objects to "thisLeaves"
                            userone["thisLeaves"] = Array(30).fill({});
                        }
                        newData[userIndex] = userone;
                    });

                    setLinks(data.payload.links);
                    setHolidaysList(newData);
                    console.log(newData, "this is new hol");
                    setLoading(false);
                    console.log("my list", newData);
                } else if (!data.metadata.success) {
                    Object.values(data.metadata.message).map((msg) => {
                        toast.error(msg);
                    });
                    setLoading(false);
                } else {
                    toast.error("Something went wrong! 😔");
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };







    // const getStatus

    useEffect(() => {
        getDaysFunction()
    }, []);
    const getDaysFunction = () => {
        let year = new Date().getFullYear();
        let month = new Date().getMonth();

        const date = new Date(year, month, 1);

        let d = new Date();
        var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
        var curr_date = ("0" + d.getDate()).slice(-2);
        let enddate = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`;

        const customDateArray = [];
        const customDateArray2 = [];

        while (date.getMonth() === month) {
            customDateArray.push({
                date: new Date(date).getDate(),
                day: moment(date).format("dd"),
                leave: 0,
                fulldate: moment(date).format("YYYY-MM-DD"),
                leave: true,
            });
            date.setDate(date.getDate() + 1);
            customDateArray2.push(moment(date).format("YYYY-MM-DD"));
        }
        console.log("arrayyyy ===>", customDateArray);
        setGetDays(customDateArray);
        setdateIndexList(customDateArray2);
    }




    console.log("getDays", getDays)


    //get particular leaves according to user

    const getLeaves = (arr) => {
        let list = [];

        arr.map((item) => {
            list.push({
                status: item.status,
                start_date: item.start_date,
                end_date: item.end_date,
                date: getDatefrom(item.start_date),
                leaveType: item?.type?.name,
                duration: getDuration(item.start_date, item.end_date),
            });
        });
        return list;
    };

    //get durations from dates
    const getDuration = (d1, d2) => {
        let duration = "";
        const date1 = new Date(d1);
        const date2 = new Date(d2);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        duration = diffDays;

        return duration;
    };

    // get date from object when user request leave

    const getDatefrom = (date) => {
        let fdate = "";
        let d = new Date("2023-05-16");
        fdate = d.getDate();
        return fdate;
    };

    const getColor = (item) => {
        let bgColor = "";
        if (item.type.name == "Sickness") {
            bgColor = "red";
        } else if (item.type.name == "Annual leave") {
            bgColor = "purple";
        }

        return bgColor;
    };

    const onChangeDate = (date, dateString) => {
        console.log(date, dateString);
    };

    function checkandBalance(arr, name) {
        const { length } = arr;
        const id = length + 1;
        const found = arr.some((el) => el.day === name);

        // if (!found) arr.push({ id, day: name });
        return found;
    }

    let newData = payload;

    payload.map((userone, userIndex) => {
        userone["thisLeaves"] = userone?.leaves?.reduce(function (map, obj, i) {
            map[i] = obj.day;
            return Object.values(map);
        }, {});
        newData[userIndex] = userone;
    });

    const getFunc = (arr) => {
        var res = arr.reduce(function (map, obj, i) {
            map[i] = obj.day;
            return map;
        }, {});
        return Object.values(res);
    };

    console.log("get days?", getDays);

    // getFunc(payload[0].leaves)

    const getTypeForLeave = (arr, dd) => {
        return arr.find((item) => item.day == dd);
    };

    const onPaginate = (link) => {
        console.log(link.url);
        getDaysFunction()
        var date = link.url.split("/").pop();
        getHolidaysCalendar(date, link.url);

    };

    // const dataSearch = brands.filter((user) => {
    // });

    // let filteredArray = holidaysList.filter(function(outerObject) {
    //   outerObject.leaves = outerObject.leaves.filter(function(innerObject) {
    //     return innerObject?.type?.name == searchText;
    //   });
    //   return outerObject.leaves.length > 0;
    // });

    // console.log("filterrd ", filteredArray);

    // let filteredArray = holidaysList.filter(function (outerObject) {
    //   outerObject.items = outerObject.leaves.filter(function (innerObject) {
    //     return innerObject?.type?.name === searchText;
    //   });
    //   return holidaysList;
    // });

    // console.log('filteredArray',filteredArray);

    const onDayClick = (item) => {
        const holidaysCollection = collect(holidaysList);
        const sortedHolidays = holidaysCollection.sortBy(
            (user, key) => !user.thisLeaves.includes(item.fulldate)
        );
        setHolidaysList(sortedHolidays);
        setSelectedId(item.fulldate);
    };

    const dataSearchLast = holidaysList.map((item) => {
        item.leaves.filter((leave) => {
            if (searchText != "") {
                if (leave?.type?.name == searchText) {
                    console.log("found", item);
                    return item;
                }
            } else {
                console.log("No text found", item);
                return item;
            }
        });
    });

    // const filteredEmployees = holidaysList.filter(employee => {
    //   return employee.leaves.some(leave => leave.type.name === "Annual leave");
    // });

    function filterEmployees() {
        if (!searchText) {
            return holidaysList;
        } else {
            let array = [];
            holidaysList.map((employee, emplIndex) => {
                console.log("employee", employee);

                employee.leaves.find((leave, index) => {
                    if (leave?.type?.name == searchText) {
                        array.push({
                            ...employee,
                            ...holidaysList[emplIndex].leaves[index],
                        });
                    }
                });
            });

            console.log("datatatatatttattatta", array);
            return array;
        }
    }

    const onChangeType = (leaveType, roleId) => {

        setLoading(true);
        fetch(
            `${baseUrl}/mgmt/leave-group/${id}/leave-calendar/${currenetSelectedDate}?leave_type_id=${leaveType}&?role_id=${roleId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    //@do not remove please
                    let newData = data.payload.data;
                    data.payload.data.map((userone, userIndex) => {
                        userone["thisLeaves"] = userone?.leaves?.reduce(function (
                            map,
                            obj,
                            i
                        ) {
                            map[i] = obj.day;
                            return Object.values(map);
                        },
                            {});
                        newData[userIndex] = userone;
                    });

                    // data.payload.map((user) => {
                    //   customDateArray.map((date, i) => {
                    //     console.log(date.fulldate)
                    //     if(user.leaves_days.includes(date.fulldate)){
                    //       console.log('yes')
                    //     }else{
                    //       console.log('no')
                    //     }
                    //   })

                    // })

                    setLinks(data.payload.links);
                    setHolidaysList(newData);
                    setLoading(false);
                    console.log("my list", newData);
                } else if (!data.metadata.success) {
                    Object.values(data.metadata.message).map((msg) => {
                        toast.error(msg);
                    });
                    setLoading(false);
                } else {
                    toast.error("Something went wrong! 😔");
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const getLeaveDetails = (user) => {
        console.log(user);
        if (usersList.length > 0) {
            // const found = usersList.find(item => item.first_name == user.first_name && item.last_name == user.last_name)
            fetch(`${baseUrl}/mgmt/users/${user.id}/leave-applications`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                    if (data.metadata.success) {
                        setCurrentUserLeaves(data.payload);
                        setdrawerModal(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const clearFilter = () => {
        let url = `${baseUrl}/mgmt/leave-group/${selectedgroupId}/leave-calendar`;
        let d = new Date();
        var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
        var curr_date = ("0" + d.getDate()).slice(-2);
        let enddate = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`;
        setValue(new Date());
        getHolidaysCalendar(enddate, url);
    };

    const getDurationDays = (start_date, end_date) => {
        return (
            Math.round(
                moment
                    .duration(
                        moment(end_date, "YYYY-MM-DD").diff(
                            moment(start_date, "YYYY-MM-DD")
                        )
                    )
                    .asDays()
            ) || 1
        );
    };

    const getNumberofdays = (st, ed) => {
        var start_date = moment(st);
        var end_date = moment(ed);
        var num_days = end_date.diff(start_date, 'days');
        return Number(num_days) + 1
    }

    return (
        <>
            <Paper
                style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
            >
                <div className="row">
                    <div className="col-md-3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                views={["year", "month"]}
                                label="Year and Month"
                                // minDate={new Date("2012-03-01")}
                                // maxDate={new Date()}
                                value={value}
                                onChange={(dateee) => {
                                    setValue(dateee);
                                    console.log("date===>", dateee);
                                    let year = new Date(dateee).getFullYear();
                                    let month = new Date(dateee).getMonth();

                                    const date = new Date(year, month, 1);
                                    // get Data from api

                                    let d = new Date(dateee);
                                    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
                                    var curr_date = ("0" + d.getDate()).slice(-2);
                                    let ddd = `${new Date(
                                        d
                                    ).getFullYear()}-${curr_moth}-${curr_date}`;
                                    getHolidaysCalendar(ddd);

                                    const dates = [];

                                    while (date.getMonth() === month) {
                                        dates.push({
                                            date: new Date(date).getDate(),
                                            day: moment(date).format("dd"),
                                            leave: 0,
                                            fulldate: moment(date).format("YYYY-MM-DD"),
                                        });
                                        date.setDate(date.getDate() + 1);
                                    }

                                    console.log("arrayyyy ===>", dates);
                                    setGetDays(dates);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} helperText={null} />
                                )}
                            />
                        </MuiPickersUtilsProvider>
                    </div>

                    <div className="col-md-3">
                        <small className="form-text text-muted">
                            <b>Filter</b> by holiday Type
                        </small>
                        <select
                            className="form-control"
                            placeholder="Filter by Role"
                            name="type"
                            onChange={(e) => {
                                onChangeType(e.target.value, searchRole);
                                setsearchLeave(e.target.value)

                            }}
                            value={searchLeave}
                        >
                            <option value="">All</option>
                            {leaveTypes &&
                                leaveTypes.map((statu) => (
                                    <option key={statu.id} value={statu.id}>
                                        {statu.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <small className="form-text text-muted">
                            <b>Filter</b> by Roles
                        </small>
                        <select
                            className="form-control"
                            placeholder="Filter by Role"
                            name="type"
                            onChange={(e) => {
                                onChangeType(searchLeave, e.target.value);
                                setsearchRole(e.target.value)


                            }}
                            value={searchRole}
                        >
                            <option value="">All</option>
                            {roles &&
                                roles.map((rol) => (
                                    <option key={rol.id} value={rol.id}>
                                        {rol.name}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div className="col-md-3">
                        <small className="form-text text-muted">
                            <b>Next</b> Holiday Group
                        </small>
                        <select
                            onChange={(e) => {
                                let d = new Date();
                                var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
                                var curr_date = ("0" + d.getDate()).slice(-2);
                                let enddate = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`;

                                let url = `${baseUrl}/mgmt/leave-group/${e.target.value}/leave-calendar`;

                                setSelectedgroupId(e.target.value)
                                getHolidaysCalendar(enddate, url)
                            }}
                            className='form-control'
                            value={selectedgroupId}
                        >
                            <option value={''}>Select Group</option>
                            {
                                groupList.map(group => (
                                    <option value={group.id}>{group.name}</option>

                                ))
                            }
                        </select>
                    </div>

                    <div className="col-md-5 d-flex justify-content-end">
                        <div className="m-3">
                            <b>Annual leave:</b>
                            <GiPalmTree className="ml-2" size={25} color="green" />
                        </div>
                        <div className="m-3">
                            <b>Sickness:</b>
                            <BsFillCircleFill className="ml-2" size={25} color="#FF6771" />
                        </div>
                        <div className="m-3">
                            <b>Lateness:</b>
                            <BsCircleHalf className="ml-2" size={25} color="#FF6771" />
                        </div>
                    </div>

                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => clearFilter()}
                        className="col-md-2 mt-3"
                    >
                        <strong className="form-text text-muted">Clear filter</strong>
                    </div>
                </div>

                <div className="row mt-3">
                    <div
                        className="col-md-2"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <h3 style={{ color: "#C6C5C9" }}>Users</h3>
                    </div>
                    <div className="col-md-10 d-flex">
                        {getDays.map((dayo, i) => (
                            <div
                                onClick={() => onDayClick(dayo, i)}
                                style={{ cursor: "pointer" }}
                                className={`box ${dayo.fulldate == selectedId ? "boxOuter" : ""
                                    }`}
                            >
                                {/* <span className="">{dayo.day}</span> */}
                                <p style={{ marginBottom: 0 }}>{dayo.date}</p>
                                <b>{dayo.day}</b>
                                {/* <span className="tableDays text-black">{dayo.date}</span> */}
                            </div>
                        ))}
                    </div>
                </div>

                {loading ? (
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    holidaysList.map((user, i) => {
                        return (
                            <div className="row">
                                {/* {
                    user.leaves.length > 0 ? */}
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => getLeaveDetails(user)}
                                    className="col-md-2 d-flex align-items-center user-box"
                                >
                                    <h3>{user.first_name}</h3>
                                </div>
                                {/* : null

                  } */}
                                <div className="col-md-10 d-flex">
                                    {getDays.map((date, dateIndex) => {
                                        if (user.thisLeaves.length > 0) {
                                            if (user.thisLeaves.includes(date.fulldate)) {
                                                return (
                                                    <OverlayTrigger
                                                        key={date.id}
                                                        placement={"top"}
                                                        overlay={
                                                            <Tooltip id={`tooltip-${date.fulldate}`}>
                                                                <strong>
                                                                    {date.fulldate}{" "}
                                                                    {
                                                                        getTypeForLeave(user.leaves, date.fulldate)
                                                                            .type.name
                                                                    }
                                                                </strong>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div className="box">
                                                            <img
                                                                height={20}
                                                                width={20}
                                                                src={
                                                                    getTypeForLeave(user.leaves, date.fulldate)
                                                                        .type.icon_url
                                                                }
                                                            />
                                                        </div>
                                                    </OverlayTrigger>
                                                );
                                            } else {
                                                return <div className="box"></div>;
                                            }
                                        }
                                    })}
                                </div>
                            </div>
                        );
                    })
                )}
                {/* NOw we will check if item is already exits in array then show box otherwise show date */}

                <UsePagination links={links} onPaginate={onPaginate} />
            </Paper>
            <Drawer
                anchor={"right"}
                open={drawerModal}
                onClose={() => setdrawerModal(false)}
            >
                <div style={{ width: "400px" }}>
                    <div
                        className="row"
                        style={{ justifyContent: "flex-end", margin: 10 }}
                    >
                        {/* NOw we will check if item is already exits in array then show box otherwise show date */}
                        <div
                            onClick={() => setdrawerModal(false)}
                            className="offcanvas-close mt-n1 pr-5"
                        >
                            <a
                                href="#"
                                className="btn btn-xs btn-icon btn-light btn-hover-primary"
                                id="kt_quick_panel_close"
                            >
                                <i className="ki ki-close icon-xs text-muted"></i>
                            </a>
                        </div>
                    </div>

                    <div className="mt-5 text-center">
                        <span className="font-weight-bolder">Leave Details</span>
                    </div>
                    {currentUserLeaves &&
                        currentUserLeaves.map((leav) => (
                            <>
                                <div
                                    className="mt-2"
                                    style={{ display: "flex", justifyContent: "center" }}
                                >
                                    <Card className="allholiCard" variant="outlined">
                                        <CardContent
                                            style={{
                                                paddingTop: "0px",
                                                paddingLeft: "15px",
                                                paddingBottom: "5px",
                                                paddingRight: "0px",
                                            }}
                                            className="text-center"
                                        >
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-md-8 text-left pt-2">
                                                        <p>
                                                            {/* Duration: */}
                                                            <b>{getNumberofdays(leav.start_date, leav.end_date)} {getNumberofdays(leav.start_date, leav.end_date) == 1 ? 'day' : 'days'}</b>
                                                        </p>
                                                        <p style={{ marginBottom: "0" }}>
                                                            {leav?.type?.name}
                                                        </p>
                                                    </div>
                                                    <div className="col-md-4 text-center">
                                                        <p
                                                            className={
                                                                leav?.status == "PENDING"
                                                                    ? "bg-warning"
                                                                    : leav?.status == "REJECTED"
                                                                        ? "bg-danger"
                                                                        : "bg-primary"
                                                            }
                                                            style={{ marginBottom: "0", color: "white" }}
                                                        >
                                                            {leav?.status}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                                {/* <div className="row p-4">
                  <div className="col-md-6">
                    <label><strong>Name: {' '}</strong></label>

                  </div>
                  <div className="col-md-6">
                    <span>{leav?.type?.name}</span>
                  </div>
                </div> */}
                                {/* <div className="row p-4">
                  <div className="col-md-6">
                    <label><strong>Status: {' '}</strong></label>

                  </div>
                  <div className="col-md-6">
                    <span>{leav?.status}</span>
                  </div>
                </div> */}
                                {/* <hr /> */}
                            </>
                        ))}
                </div>
            </Drawer>
        </>
    );
};

export default GroupHolidays;

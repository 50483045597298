import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Select from "react-select";
import MultipleSelect from '../common/MultipleSelect/MultipleSelect';
import { PremissionChecker } from '../../../services/PremissionChecker';
import { useSelector } from 'react-redux';

const AssignRotaAndHoliday = ({open,title,role,handleClose,list,defaultValues,url,type}) => {
  const user = useSelector((state) => state.user.user);

const [isLoading,setisLoading]=useState(false)
const [selectedItems,setSelectedItems]=useState(defaultValues)
const authtoken = localStorage.getItem("userToken");
useEffect(() => {
    setSelectedItems(defaultValues)
}, [defaultValues])
console.log(selectedItems,"selectedItem")
    const saveData = () => {
        if(selectedItems==null || selectedItems.length==0){
          return  toast.error(`Please select  ${title}`)
        }
        const fdata = new FormData();
        if(type=='holiday'){
            for (let i = 0; i < selectedItems.length; i++) {
              fdata.append(`leave_group_ids[${i}]`, selectedItems[i].value);
            }
        }
        if(type=='rota'){
            for (let i = 0; i < selectedItems.length; i++) {
              fdata.append(`rota_ids[${i}]`, selectedItems[i].value);
            }
        }
      
        setisLoading(true);
        fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
          body:fdata,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.metadata.success) {
              toast.success(`${title} Assigned Successfully`);
              handleClose();
              setisLoading(false);
            } else if (!data.metadata.success) {
              setisLoading(false);
              toast.error('Something wents wronge');
            }
          })
          .catch((err) => {
            console.log(err);
            setisLoading(false);
          });
      };
  return (
    <>
      <Modal show={open} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Assign {title} to {role.name}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <div className="col-md-12 mt-2">
              <label>Select {title}</label>

              {/* <Select
                defaultValue={defaultValues}
                onChange={(e) => setSelectedItems(e)}
                isMulti
                options={list}
                className="basic-multi-select"
                classNamePrefix="select"
              /> */}

                <MultipleSelect
                defaultValue={defaultValues}
                    options={list}
                    handleChange={(e)=>{
                      console.log(e,"errr")
                      setSelectedItems(e)
                    }}
                    value={selectedItems}
                    multi={true}
                />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              onClick={handleClose}
              className="btn btn-danger"
            >
              Close
            </button>
            {
                isLoading ?
                <div style={{display:"flex",justifyContent:"center",width:"70px"}}>
                <Spinner animation='border' />
                </div>
                :
                type=='holiday' &&  PremissionChecker(user,"role.leave_groups_attach") || 
                type=='rota' &&  PremissionChecker(user,"role.rotas_attach")
                ?
                <button
                //   disabled={uploadLoading}
                  onClick={saveData}
                  className="btn btn-primary"
                >
                  Assign
                </button> : ''
            }
    
          </Modal.Footer>
        </Modal>
    </>
  )
}

export default AssignRotaAndHoliday
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { debounce } from 'lodash';
import {
    Input,
    Select,
    DatePickerField,
} from "../../../_metronic/_partials/controls";
import ReactSelect from 'react-select'
import { useState } from "react";
import { baseUrl } from "../../../services/config";
import Alert from 'react-bootstrap/Alert';

// Validation schema
const CustomerEditSchema = Yup.object().shape({

    name: Yup.string()
        .required("Role Name is required"),

});

export default function AddRole({
    saveCustomer,
    isEdit,
    customer,
    actionsLoading,
    onHide,
    errors
}) {


    const [selecetedPerm, setselecetedPerm] = useState([])
    const [loading, setloading] = useState(false)
    const [list, setList] = useState([])

    const authtoken = localStorage.getItem("userToken");

    useEffect(() => {
        getPermissions()
    }, [])

    const getPermissions = () => {
        setloading(true)
        fetch(`${baseUrl}/mgmt/permissions`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((res) => res.json())

            .then((data) => {
                setloading(false)
                let arr = []
                data.payload.forEach(element => {
                    arr.push({
                        label: element.name,
                        value: element.name
                    })
                });

                console.log("List", data.payload);

                setList(arr)


            })
            .catch((err) => {
                setloading(false)
                console.log(err);
            });
    };


    const onChangePerm = (perm) => {
        console.log(perm)
    }


    const handleSubmit = debounce((values, actions) => {
        // Your API call or form submission logic here
        saveCustomer(values)
    }, 500); // Adjust the delay time (in milliseconds) as needed



    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={customer}
                validationSchema={CustomerEditSchema}
                onSubmit={(values,) => {
                    handleSubmit(values);
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <>
                        <Modal.Body className="overlay overlay-block cursor-default">
                            {actionsLoading && (
                                <div className="overlay-layer bg-transparent">
                                    <div className="spinner spinner-lg spinner-success" />
                                </div>
                            )}
                            <Form onSubmit={handleSubmit} className="form form-label-right">
                                {
                                    errors ?
                                        errors.map(err => (
                                            <Alert variant={'danger'}>
                                                {err}
                                            </Alert>
                                        ))
                                        : null
                                }
                                <div className="form-group row">
                                    {/* First Name */}

                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <label>Enter Role Name</label>
                                        <Field
                                            name="name"
                                            component={Input}
                                            placeholder="Enter Role Name"
                                            maxLength={120}

                                        />

                                    </div>
                                </div>

                                <div>
                                    {/* <ReactSelect
                                        options={list}
                                        isMulti
                                        name="roles"
                                        onChange={e => onChangePerm(e)}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    /> */}
                                </div>

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-danger"
                            >
                                Cancel
                            </button>
                            <> </>
                            <button
                                disabled={actionsLoading}
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary"
                            >
                                {isEdit ? 'Update':'Save'}
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>

        </>
    );
}

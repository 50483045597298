import React, { useEffect } from "react";
import { useState } from "react";
import { Divider, Modal } from "antd";
import { FaFolderPlus } from "react-icons/fa";
import { AiFillFileAdd } from "react-icons/ai";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";
const FileFolderType = ({ onHide, open,folderOpner,fileOpner }) => {
  const user = useSelector((state) => state.user.user);

  return (
    <>
      <Modal
        title={`Add New`}
        centered
        className="my-custom-modal"
        open={open}
        okButtonProps={{ style: { display: "none" } }}
        okText={"Save"}
        onCancel={() => {
          onHide();
        }}
      >
        <div className="d-flex" style={{ justifyContent: "space-around" }}>
         
         
         <div style={{textAlign:"center"}}>
          {PremissionChecker(user, "folder.create") && 
          <>
           <FaFolderPlus onClick={folderOpner} style={{ fontSize: "6rem",cursor:"pointer" }} />
            <div style={{textAlign:"center"}}>
              <h6>Add New Folder</h6>
            </div>
          </>
          }
           
          </div>
          <div style={{textAlign:"center"}}>
            {PremissionChecker(user, "file.create") && 
            <>
             <AiFillFileAdd onClick={fileOpner} style={{ fontSize: "6rem",cursor:"pointer" }} />
            <div style={{textAlign:"center"}}>
              <h6>Add New File</h6>
            </div>
            </>
           }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FileFolderType;

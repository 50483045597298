import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { baseUrl } from '../../../services/config';
import { toast } from 'react-hot-toast';
import { PremissionChecker } from '../../../services/PremissionChecker';
import { useSelector } from 'react-redux';


const ResetModel = ({
    hideModel,
    visible,
    reload,
    userObj
}) => {
    const [isLoading, setisLoading] = React.useState(false);
    const [emailLoading,setEmailLoading]=useState(false)
    const authtoken = localStorage.getItem("userToken");
    const user = useSelector((state) => state.user.user);

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters long'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });




    const resetPassword = (values,resetForm) => {
        setisLoading(true)

        fetch(`${baseUrl}/mgmt/reset-password/${userObj.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authtoken}`,
            },
            body: JSON.stringify(values)
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    toast.success(data.metadata.message)
                    setisLoading(false)
                    hideModel()
                    resetForm()

                } else if (!data.metadata.success) {

                    // if (Array.isArray(data.metadata.message)) {
                    Object.values(data.metadata.message).map(msg => {
                        setisLoading(false)
                        toast.error(msg)
                    })
                    // } 

                }
            }).catch(err => {
                setisLoading(false)

                console.log(err);
            })

    }

    const sendEmail = () => {
        setEmailLoading(true)
        fetch(`${baseUrl}/mgmt/send-password-reset-email/${userObj.email}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
        setEmailLoading(false)
                    toast.success(data.metadata.message)
                    hideModel()

                } else if (!data.metadata.success) {
                    setEmailLoading(false)

                    // if (Array.isArray(data.metadata.message)) {
                    Object.values(data.metadata.message).map(msg => {
                        toast.error(msg)
                    })
                    // } 

                }
            }).catch(err => {
                setEmailLoading(false)
                console.log(err);
            })

    }


    return (
        <Modal show={visible} animation >
            <Modal.Header>
                <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ password: '', password_confirmation: '' }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        // Handle form submission here
                        resetPassword(values,resetForm)
                    }}
                >
                    {() => (
                        <Form>
                            {
                                isLoading ?
                                    <Spinner animation='border' />
                                    :
                                    <>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <Field placeholder="Enter Password" as={Input.Password} name="password" />
                                            <ErrorMessage name="password" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <Field  placeholder="Enter Confirm Password" as={Input.Password} name="password_confirmation" />
                                            <ErrorMessage name="password_confirmation" component="div" className="text-danger" />
                                        </div>
                                        {emailLoading ? 
                                         <div style={{display:"flex",justifyContent:"start"}}>
                                           <Spinner animation='border' variant='dark'/>
                                         </div> :
                                        <div className='mt-2 mb-2'>
                                                 {PremissionChecker(user, "user.password_reset_link") ? (
                                            <a onClick={sendEmail} style={{textDecoration:"underline"}}>Send reset password link</a>) : null }
                                        </div>}

                                        <div className='text-right'>
                                           
                                            <button type='button' className='btn btn-danger ml-2' onClick={hideModel}>
                                                Cancel
                                            </button>
                                            <button className='btn btn-primary ml-2' type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </>
                            }

                        </Form>
                    )}
                </Formik>
            </Modal.Body>

            {/* <Modal.Footer>
                <button onClick={resetPassword} className='btn btn-primary'>Reset</button>
                <button onClick={hideModel} className='btn btn-danger'>Cancel</button>
            </Modal.Footer> */}
        </Modal>
    )
}

export default ResetModel
import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { baseUrl } from "../../../../services/config";
import { toast, Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
const initialValues = {
  email: "",
};
function ForgotPassword(props) {
  const params = useParams()
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),

  });


  console.log(params)

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      // requestPassword(values.email)
      //   .then(() => setIsRequested(true))
      //   .catch(() => {
      //     setIsRequested(false);
      //     setSubmitting(false);
      //     setStatus(
      //       intl.formatMessage(
      //         { id: "AUTH.VALIDATION.NOT_FOUND" },
      //         { name: values.email }
      //       )
      //     );
      //   });
      fetch(`${baseUrl}/password/forget`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // 'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify(values),
      })
        .then((resp) => {
          resp.json().then((user) => {
            setSubmitting(false);
            console.log(user)
            if (user.metadata) {
              if (user.metadata.success) {
                setSubmitting(false);
                toast.success(user.metadata.message)
                resetForm()
                //  alert(user.metadata.message)
              } else if (!user.metadata.success) {
                setStatus(user.metadata.message);
              }
            }
          });
        })

        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <>
      <Toaster />
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset Password </h3>
            <div className="text-muted font-weight-bold">
              Enter your email to reset password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                placeholder="Email"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="invalid-feedback">{formik.errors.password}</div>
              ) : null}
            </div>


            {/* 

            <div className="form-group fv-plugins-icon-container">
            <input
                type="password"
                placeholder="Confirm Password"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "cPassword"
                )}`}
                name="cPassword"
                {...formik.getFieldProps("cPassword")}
              />
              {formik.touched.cPassword && formik.errors.cPassword ? (
                <div className="invalid-feedback">
                  {formik.errors.cPassword}
                </div>
              ) : null}
            </div> */}



            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Send Link
              </button>
              {/* <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link> */}
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));

import React from "react";
import {
  MixedWidget1,
  MixedWidget14,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  ListsWidget1,
  AdvanceTablesWidget2,
  AdvanceTablesWidget4,
  ListsWidget3,
  ListsWidget4,
  ListsWidget8,
} from "../widgets";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
export function Demo1Dashboard() {

  const Ruser = useSelector(state => state.user.user)

  let user;
  if (localStorage.getItem("userDetails")) {
    user = JSON.parse(localStorage.getItem("userDetails"));
  }

  const classes = useStyles();





  return (
    <>
         <h1>sdsadasda</h1>

      {
        Ruser?.role[0].name !="admin" && !Ruser?.email_verified?
          <>

<div>
  <h1>sdsadasda</h1>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Transition modal</h2>
            <p id="transition-modal-description">react-transition-group animates me.</p>
          </div>
        </Fade>
      </Modal>
    </div>
            {/* <div className="row">
              <div className="col-lg-6 col-xxl-4">
                <MixedWidget1 className="card-stretch gutter-b" />
              </div>
              <div className="col-lg-6 col-xxl-4">
                <ListsWidget9 className="card-stretch gutter-b" />
              </div>

              <div className="col-lg-6 col-xxl-4">
                <StatsWidget11
                  className="card-stretch card-stretch-half gutter-b"
                  symbolShape="circle"
                  baseColor="success"
                />
                <StatsWidget12 className="card-stretch card-stretch-half gutter-b" />
              </div>

              <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
                <ListsWidget1 className="card-stretch gutter-b" />
              </div>
              <div className="col-xxl-8 order-2 order-xxl-1">
                <AdvanceTablesWidget2 className="card-stretch gutter-b" />
              </div>
              <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                <ListsWidget3 className="card-stretch gutter-b" />
              </div>
              <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                <ListsWidget4 className="card-stretch gutter-b" />
              </div>
              <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                <ListsWidget8 className="card-stretch gutter-b" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <MixedWidget14 className="card-stretch gutter-b" />
              </div>
              <div className="col-lg-8">
                <AdvanceTablesWidget4 className="card-stretch gutter-b" />
              </div>
            </div> */}

          </>
          :
          <div>
         <h1>sdsadasda</h1>
            
          </div>
      }
    </>
  );
}

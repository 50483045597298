import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { baseUrl } from '../../../services/config'
import { useDispatch } from 'react-redux'

const EmailVerify = (props) => {
const [loading,setLoading]=useState(false)
const dispatch = useDispatch()
    // useEffect(() => {


    // }, [props.location.search]);

    const searchParams = new URLSearchParams(props.location.search);
    const expires = searchParams.get("expires");
    const signature = searchParams.get("signature");




    

    console.log("expires:", expires);
    console.log("signature:", signature);


    const token = localStorage.getItem('userToken')

    const params = useParams()


    const { id, hash } = params


    console.log(id)
    console.log(hash)



    useEffect(() => {
        verifyEmail()
    }, [])

    const logout = () => {
        localStorage.clear();
    
        dispatch({
          type: "ADD_USER",
          payload: {
            isAuthenticated: false,
            authtoken: null,
            user: null,
          },
        });
      }


    const verifyEmail = () => {

        let url = `${baseUrl}/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`

        console.log(url)

        fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    setLoading(false)
                    toast.success(data.metadata.message)
                    logout()
                }  else if (!data.metadata.success) {
                    if (toString.call(data.metadata.message) == "[object Array]") {
        
                      Object.values(data.metadata.message).map(msg => {
                        toast.error(msg)
                      })
                    } else {
                      toast.error(data.metadata.message)
                    }
                    setLoading(false)

        
                  }else {
                    toast.error('Something went wrong!')
                }
            })
            .catch(err => {
                console.log("error while verifying email...!", err)
            })



    }

    return (
<>
{ loading?    <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection:'column'
        }}>
            <span>Verifying your email please wait!</span>
            <Spinner animation='border' />
        </div>:''}
</>
    )
}



export default EmailVerify
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,

} from "react-bootstrap-table2-paginator";
import './styles.css'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../../app/modules/ECommerce/pages/customers/CustomersUIHelpers";
import { Pagination } from '../../../../src/_metronic/_partials/controls'
import { Modal, Spinner } from "react-bootstrap";
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../../modules/ECommerce/pages/customers/CustomersUIContext";
import { baseUrl } from "../../../services/config";
import { toast } from "react-hot-toast";
import { getSelectRow } from "../../../_metronic/_helpers";
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { CustomersGrouping } from "../../modules/ECommerce/pages/customers/customers-grouping/CustomersGrouping";
import GroupingTable from "./GroupingTable/GroupingTable";
import UpdateStatus from "../../components/UpdateStatus/UpdateStatus";
import { useHistory } from "react-router-dom";

export default function Orders() {

    const history = useHistory()
    const { ExportCSVButton } = CSVExport;

    const initialFilter = {
        pageNumber: 1,
        pageSize: 10,
    };

    const authtoken = localStorage.getItem("userToken");

    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [addModal, setaddModal] = useState(false);
    const [deleteModal, setdeleteModal] = useState(false);
    const [editModal, seteditModal] = useState(false);
    const [actionsLoading, setactionsLoading] = useState(false);
    const [brands, setBrands] = useState([])
    const [loading, setloading] = useState(false)
    const [editItem, setEditItem] = useState({})
    const customersUIContext = useCustomersUIContext();
    const [SelectedId, setSelectedId] = useState('')
    const [usernameSearch, setusernameSearch] = useState('')
    const [emailsearch, setemailsearch] = useState('')
    const [role_name, setrole_name] = useState('')
    const [selectedObj, setSelectedObj] = useState({})

    const [ids, setIds] = useState([])
    const [updateModal, setUpdateModal] = useState(false)
    useEffect(() => {
        getAllBrands()
    }, [])


    const [roles, setRoles] = useState([])

    useEffect(() => {
        getRoles()
    }, [])


    const getRoles = () => {
        fetch(`${baseUrl}/mgmt/roles`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authtoken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                // let arr = []

                // data.payload.forEach(element => {
                //     arr.push({
                //         id: element.id,
                //         label: element.name,
                //         value: element.name
                //     })
                // });
                setRoles(data.payload)
            }).catch(err => {
                console.log(err)
            })
    }

    const getAllBrands = () => {
        setloading(true)
        fetch(`${baseUrl}/mgmt/orders`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((res) => res.json())

            .then((data) => {
                setloading(false)
                console.log("brands List", data.payload);
                setBrands(data.payload)
            })
            .catch((err) => {
                setloading(false)
                console.log(err);
            });
    };



    const getClassOrder = (row) => {
        let classname = ''
        if (row == 'PENDING') {
            classname = 'info'
        }
        else if (row == 'COMPLETED') {
            classname = 'success'
        } else if (row == 'REJECTED') {
            classname = 'danger'
        }

        return classname
    }


    const columns = [

        {
            dataField: "user",
            text: "User Name",
            sort: true,
            formatter: (row, cell) => (
                <span>

                    {row?.first_name}   {row?.last_name}
                </span>
            )

            // sortCaret: sortCaret,
        },
        {
            dataField: "total_amount",
            text: "Total Amount",
            sort: true,
            // sortCaret: sortCaret,

        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            formatter: (row, cell) => (
                <span
                    className={`label label-lg label-light-${getClassOrder(row)} label-inline`}
                >
                    {row}
                </span>
            )
            // sortCaret: sortCaret,

        },

        {
            dataField: "placing_date",
            text: "Placing Date",
            sort: true,
            // sortCaret: sortCaret,

        },
        // {
        //     dataField: "contact",
        //     text: "Subrent-/purchase cost",
        //     sort: true,
        //     // sortCaret: sortCaret,

        // },
        // {
        //     dataField: "address",
        //     text: "Time(item/case/kit)",
        //     sort: true,
        //     // sortCaret: sortCaret,

        // },

        {
            dataField: "action",
            text: "Actions",
            formatter: columnFormatters.ActionsColumnFormatterOrder,
            formatExtraData: {
                ViewOrder: (item) => ViewOrderDetails(item),

            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
    ];


    const ViewOrderDetails = (item) => {

        history.push(`/view-order/${item.id}`)

    }


    const options = {
        custom: true,
        totalSize: brands?.length,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber,
    };



    const saveRole = values => {
        setactionsLoading(true)
        console.log(values)
        // console.log(values.employment_start_date.getDate())
        let d = new Date(values.employment_start_date)
        var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2)
        var curr_date = ("0" + (d.getDate())).slice(-2)
        let ddd = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`


        const fdata = {
            username: values.username,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            contact: values.contact,
            address: values.address,
            emergency_contact: values.emergency_contact,
            employment_start_date: ddd,
            role_id: values.role_name
        }


        console.log(fdata, "this is formdata")
        let url = editModal ? `mgmt/users/${editItem.id}?_method=PUT` : 'mgmt/users'

        fetch(`${baseUrl}/${url}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authtoken}`,
            },
            body: JSON.stringify(fdata)
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    setaddModal(false)
                    toast.success(data.metadata.message)
                    setactionsLoading(false)
                    getAllBrands()
                } else if (!data.metadata.success) {

                    Object.values(data.metadata.message).map(msg => {
                        toast.error(msg)
                    })
                    setactionsLoading(false)




                }

            }).catch(err => {
                console.log(err);
                setactionsLoading(false)

            })
    }





    //updating query params for pagination

    const updatingQueryParams = (page) => {
        console.log(page)
        setQueryParamsBase({
            pageNumber: page,
        });
    };


    const onPaginationSelectChange = () => {
        setQueryParamsBase({
            pageNumber: 1,
        });
    }

    //end   


    const init_values = {
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        contact: '',
        address: '',
        emergency_contact: '',
        employment_start_date: '',
        role_name: ''
    }





    const dataSearch = brands.filter((user) => {
        // let text = searchText.toLowerCase();
        // return searchText ? user?.topic?.toLowerCase().includes(text) || user?.section?.toLowerCase().includes(text) || user?.appellant_vs_respondent?.toLowerCase().includes(text) || user?.citations_ref?.toLowerCase().includes(text) : true;
        let text1 = usernameSearch.toLowerCase()
        const topicSearchData = text1 ? user?.username?.toLowerCase().includes(text1) : true;
        let text2 = emailsearch.toLowerCase()
        const appellantSearchData = text2 ? user?.email?.toLowerCase().includes(text2) : true;
        let text3 = role_name.toLowerCase()
        const sectionSearchData = text3 ? user?.status?.toLowerCase().includes(text3) : true;
        // let text4 = citationtext.toLowerCase()
        // const citationSearchData = text4 ? user?.citations_ref?.toLowerCase().includes(text4) : true;
        return topicSearchData && appellantSearchData && sectionSearchData
    });

    return (
        <>
            {loading ? <LinearProgress /> : null}
            <Card>

                <>

                    <CardHeader title="Orders">
                        <CardHeaderToolbar>
                            {/* <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    seteditModal(false)
                                    setaddModal(true)
                                }}
                            >
                                New Equipment
                            </button> */}
                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <div className="form-group row">
                            <div className="col-lg-2">
                                <small className="form-text text-muted">
                                    <b>Search</b> in Order No
                                </small>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="searchText"
                                    placeholder="Search by Order No"
                                    value={usernameSearch}
                                    onChange={(e) => {
                                        setusernameSearch(e.target.value);
                                    }}
                                />

                            </div>
                            {/* <div className="col-lg-2">
                                <small className="form-text text-muted">
                                    <b>Search</b> in order
                                </small>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="searchText"
                                    placeholder="Search by email"
                                    value={emailsearch}
                                    onChange={(e) => {
                                        setemailsearch(e.target.value);
                                    }}
                                />
                            </div> */}
                            <div className="col-lg-2">
                                <small className="form-text text-muted">
                                    <b>Filter</b> by Type
                                </small>
                                <select
                                    className="form-control"
                                    placeholder="Filter by Role"
                                    name="type"
                                    onChange={(e) => {
                                        setrole_name(e.target.value);
                                    }}
                                    value={role_name}
                                >
                                    <option value="">All</option>
                                    {
                                        ['PENDING', 'APPROVE', 'REJECTED'].map(role => (
                                            <option key={role} value={role}>{role}</option>
                                        ))
                                    }
                                </select>

                            </div>

                        </div>


                        {/* <CustomersFilter /> */}
                        {ids.length > 0 &&
                            <GroupingTable
                                list={ids}
                                onUpdateStatusDialog={() => setUpdateModal(true)}
                                openDeleteDialog={() => alert('workd')}
                            />}
                        {/* <CustomersTable /> */}
                        <ToolkitProvider
                            keyField="id"
                            data={dataSearch}
                            columns={columns}
                            exportCSV
                        >
                            {
                                props => (
                                    <PaginationProvider pagination={paginationFactory(options)}>
                                        {({ paginationProps, paginationTableProps }) => {
                                            return (
                                                <>
                                                    <Pagination
                                                        updatingQueryParams={updatingQueryParams}
                                                        onPaginationSelectChange={onPaginationSelectChange}
                                                        isLoading={loading}
                                                        paginationProps={paginationProps}
                                                    >
                                                        <MyExportCSV {...props.csvProps} />
                                                        <BootstrapTable
                                                            wrapperClasses="table-responsive"
                                                            bordered={false}
                                                            classes="table table-head-custom table-vertical-center overflow-hidden"
                                                            bootstrap4
                                                            keyField="id"
                                                            data={dataSearch}
                                                            columns={columns}
                                                            defaultSorted={uiHelpers.defaultSorted}
                                                            {...paginationTableProps}
                                                            {...props.baseProps}
                                                            filter={filterFactory()}
                                                            selectRow={getSelectRow({
                                                                entities: dataSearch,
                                                                ids,
                                                                setIds,
                                                            })}
                                                        ></BootstrapTable>

                                                    </Pagination>
                                                    {/* )} */}
                                                </>
                                            );
                                        }}
                                    </PaginationProvider>

                                )
                            }


                        </ToolkitProvider>
                    </CardBody>
                </>
            </Card>


            <UpdateStatus
                show={updateModal}
                onHide={() => setUpdateModal(false)}
                list={dataSearch}
                ids={ids}

            />


            {/* 
      <DeleteModal
        HideModal={() => setdeleteModal(false)}
        visible={deleteModal}
        // id={SelectedId}
        method="DELETE"
        reload={getAllBrands}
        url={`${baseUrl}/mgmt/users/${SelectedId}`}
      /> */}
        </>

    );
}



const MyExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <div className="d-flex justify-content-end">
            <button className="btn btn-success" onClick={handleClick}>Export CSV</button>
        </div>
    );
};

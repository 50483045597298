import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { baseUrl } from "../../../../services/config";
import { toast, Toaster } from "react-hot-toast";
import { useParams,useHistory } from "react-router-dom";
import {PiEyeClosedDuotone,PiEyeDuotone} from 'react-icons/pi'

const initialValues = {
    password: "",
    cPassword: "",
};
function ResetPassword(props) {
    const history=useHistory()
    const params = useParams()
    const { intl } = props;
    const [isRequested, setIsRequested] = useState(false);
    const ForgotPasswordSchema = Yup.object().shape({
        password: Yup.string().required("New Password is required"),
        cPassword: Yup.string()
            .required("Password confirmation is required")
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Password and Confirm Password didn't match"
                ),
            }),
    });
    const [showHidePassword,setShowHidePassword]=useState(false)
    const [showHidePassword2,setShowHidePassword2]=useState(false)


    console.log(params)

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ForgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting,resetForm }) => {
            // requestPassword(values.email)
            //   .then(() => setIsRequested(true))
            //   .catch(() => {
            //     setIsRequested(false);
            //     setSubmitting(false);
            //     setStatus(
            //       intl.formatMessage(
            //         { id: "AUTH.VALIDATION.NOT_FOUND" },
            //         { name: values.email }
            //       )
            //     );
            //   });

            const user = {
                token: params.token,
                email: params.email,
                password: values.password,
                password_confirmation: values.cPassword,

            };
            console.log(user)
            fetch(`${baseUrl}/password/reset`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    // 'X-Requested-With': 'XMLHttpRequest'
                },
                body: JSON.stringify(user),
            })
                .then((resp) => {
                    resp.json().then((user) => {
                        setSubmitting(false);
                        console.log(user)
                        if (user.metadata) {
                            if (user.metadata.success) {
                                setSubmitting(false);
                                resetForm()
                                toast.success(user.metadata.message)
                                 history.push('/auth/login')
                                //  alert(user.metadata.message)
                            } else if (!user.metadata.success) {
                                setStatus(user.metadata.message);
                            }
                        }
                    });
                })

                .catch((err) => {
                    console.log(err);
                });
        },
    });

    return (
        <>
            <Toaster />
            {isRequested && <Redirect to="/auth" />}
            {!isRequested && (
                <div className="login-form login-forgot" style={{ display: "block" }}>
                    <div className="text-center mb-10 mb-lg-20">
                        <h3 className="font-size-h1">Reset Password </h3>
                        <div className="text-muted font-weight-bold">
                            Enter your reset password and password
                        </div>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                    >
                        {formik.status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                <div className="alert-text font-weight-bold">
                                    {formik.status}
                                </div>
                            </div>
                        )}
                        <div className="form-group fv-plugins-icon-container">
                        <div className="d-flex">
                            <input
                                type={!showHidePassword? "password":'text'}
                                placeholder="New Password"
                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                    "password"
                                )}`}
                                name="password"
                                {...formik.getFieldProps("password")}
                            />
                              <div onClick={()=>setShowHidePassword(!showHidePassword)} className="eye-container">
           {!showHidePassword ? <PiEyeClosedDuotone style={{fontSize:"1.5rem"}}/> :
            <PiEyeDuotone style={{fontSize:"1.5rem"}}/>}
            </div>
            </div>

                            {formik.touched.password && formik.errors.password ? (
                                <div className="invalid-feedback">{formik.errors.password}</div>
                            ) : null}
                        </div>




                        <div className="form-group fv-plugins-icon-container">
                        <div className="d-flex">
                            <input
                                type={!showHidePassword2? "password":'text'}
                                placeholder="Confirm Password"
                                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                    "cPassword"
                                )}`}
                                name="cPassword"
                                {...formik.getFieldProps("cPassword")}
                            />
                            <div onClick={()=>setShowHidePassword2(!showHidePassword2)} className="eye-container">
           {!showHidePassword2 ? <PiEyeClosedDuotone style={{fontSize:"1.5rem"}}/> :
            <PiEyeDuotone style={{fontSize:"1.5rem"}}/>}
            </div>
            </div>
                            {formik.touched.cPassword && formik.errors.cPassword ? (
                                <div className="invalid-feedback">
                                    {formik.errors.cPassword}
                                </div>
                            ) : null}
                        </div>



                        <div className="form-group d-flex flex-wrap flex-center">
                            <button
                                id="kt_login_forgot_submit"
                                type="submit"
                                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                                disabled={formik.isSubmitting}
                            >
                                Reset Password
                            </button>
                            {/* <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link> */}
                        </div>
                    </form>
                </div>
            )}
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));

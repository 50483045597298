import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,

} from "react-bootstrap-table2-paginator";
// import './styles.css'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../../app/modules/ECommerce/pages/customers/CustomersUIHelpers";
import { Pagination } from '../../../../src/_metronic/_partials/controls'
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../../modules/ECommerce/pages/customers/CustomersUIContext";
import { baseUrl } from "../../../services/config";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";



export default function LeaveUsers() {

  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };

  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);


  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [addModal, setaddModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [actionsLoading, setactionsLoading] = useState(false);
  const [brands, setBrands] = useState([])
  const [loading, setloading] = useState(false)

  const [usernameSearch, setusernameSearch] = useState('')
  const [emailsearch, setemailsearch] = useState('')
  const [role_name, setrole_name] = useState('')
  const [selectedObj, setSelectedObj] = useState({})
  const [status, setStatus] = useState(false)
  const [restoreModel, setrestoreModel] = useState(false)

  useEffect(() => {
    getAllBrands()
  }, [])


  const [roles, setRoles] = useState([])

  useEffect(() => {
    getRoles()
  }, [])
  const history = useHistory();



  const getRoles = () => {
    fetch(`${baseUrl}/mgmt/roles`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authtoken}`,
      }
    }).then(res => res.json())
      .then((data) => {
        // let arr = []

        // data.payload.forEach(element => {
        //     arr.push({
        //         id: element.id,
        //         label: element.name,
        //         value: element.name
        //     })
        // });
        setRoles(data.payload)
      }).catch(err => {
        console.log(err)
      })
  }
  const dateConverter=(originalDateStr)=>{
    const date = new Date(originalDateStr);

const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
const year = date.getFullYear();

const formattedDate = `${day}-${month}-${year}`;

return formattedDate
  }

  function parseDate(dateStr) {
    let parts = dateStr.split("-");
    let day = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10) - 1; // Month is zero-based in Date object
    let year = parseInt(parts[2], 10);
    return new Date(year, month, day);
}
  const getAllBrands = () => {
    setloading(true)
    fetch(`${baseUrl}/mgmt/deleted-users`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false)
        console.log("user List", data);


        let tempArray = data.payload;
        tempArray.sort((a, b) => {
          return parseDate(b.employment_end_date) - parseDate(a.employment_end_date);
      });
      tempArray.map((item, index) => {
          item['employment_start_date']=dateConverter(item.employment_start_date)
          item['employment_end_date']=dateConverter(item.employment_end_date)
          item["sr_no"] = index + 1;
          tempArray[index] = item;
        });
      
        setBrands(tempArray)
      })
      .catch((err) => {
        setloading(false)
        console.log(err);
      });
  };

  const columns = [
    {
      dataField: "sr_no",
      text: "Sr.no",
      // sort: true,
      // sortCaret: sortCaret,


    },
    {
      dataField: "username",
      text: "Username",
      // sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "first_name",
      text: "Firstname",
      // sort: true,
      // sortCaret: sortCaret,

    },
    {
      dataField: "last_name",
      text: "Last Name",
      // sort: true,
      // sortCaret: sortCaret,

    },

    {
      dataField: "email",
      text: "Email",
      // sort: true,
      // sortCaret: sortCaret,

    },
    {
      dataField: "contact",
      text: "Contact number ",
      // sort: true,
      // sortCaret: sortCaret,

    },
    {
      dataField: "address",
      text: "Home address",
      // sort: true,
      // sortCaret: sortCaret,

    },
    {
      dataField: "emergency_contact",
      text: "Emergency contact info",
      // sort: true,
      // sortCaret: sortCaret,

    },
    {
      dataField: "employment_start_date",
      text: "Employment start date",
      // sort: true,
      // sortCaret: sortCaret,

    },
    {
      dataField: "employment_end_date",
      text: "Employment end date",
      // sort: true,
      // sortCaret: sortCaret,

    },
    {
      dataField: "role[0].name",
      text: "Role",
      // sort: true,
      // sortCaret: sortCaret,

    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.LeaveActionsColumnFormatter,
      formatExtraData: {
        user:user,
        openRestore: (item) => openRestoreModel(item),
        openDelete: (item) => openDeleteModel(item),
        goToViewHoliday: (item) => goToViewHolidayPage(item),
      },
      classes: "text-center",
      headerClasses: "text-center",
      style: {

        minWidth: "20px",
        display: 'flex'
      },
    },
  ];


  const openRestoreModel = (item) => {
    setSelectedObj(item)
    setrestoreModel(true)
  }

  const goToViewHolidayPage = (item) => {
    history.push(`/view-holiday/${item.id}`,item);
  };

  const openDeleteModel = (item) => {
    setSelectedObj(item)
    setdeleteModal(true)
  }



  //updating query params for pagination

  const updatingQueryParams = (page) => {
    console.log(page)
    setQueryParamsBase({
      pageNumber: page,
    });
  };


  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  }

  //end   





  const dataSearch = brands.filter((user) => {
    // let text = searchText.toLowerCase();
    // return searchText ? user?.topic?.toLowerCase().includes(text) || user?.section?.toLowerCase().includes(text) || user?.appellant_vs_respondent?.toLowerCase().includes(text) || user?.citations_ref?.toLowerCase().includes(text) : true;
    let text1 = usernameSearch.toLowerCase()
    const topicSearchData = text1 ? user?.username?.toLowerCase().includes(text1) : true;
    let text2 = emailsearch.toLowerCase()
    const appellantSearchData = text2 ? user?.email?.toLowerCase().includes(text2) : true;
    let text3 = role_name.toLowerCase()
    const sectionSearchData = text3 ? user?.role[0]?.name?.toLowerCase().includes(text3) : true;
    // let text4 = citationtext.toLowerCase()
    // const citationSearchData = text4 ? user?.citations_ref?.toLowerCase().includes(text4) : true;
    return topicSearchData && appellantSearchData && sectionSearchData && brands
  });



  console.log("after searched ", dataSearch)
  const options = {
    custom: true,
    totalSize: dataSearch?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };



  const reStoreUser = () => {
    fetch(`${baseUrl}/mgmt/restore-user/${selectedObj.id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authtoken}`,

      }
    }).then(res => res.json())
      .then((data) => {
        if (data.metadata.success) {
          toast.success(data.metadata.message)
          getAllBrands()
          setrestoreModel(false)
        } else if (!data.metadata.success) {
          toast.error(data.metadata.message)
        }
      }).catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Card>

        <>

          <CardHeader title="Leaver List">
            <CardHeaderToolbar>

            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search by username"
                  value={usernameSearch}
                  onChange={(e) => {
                    setusernameSearch(e.target.value);
    onPaginationSelectChange();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in username
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search by email"
                  value={emailsearch}
                  onChange={(e) => {
                    setemailsearch(e.target.value);
    onPaginationSelectChange();

                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in email
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Role"
                  name="type"
                  onChange={(e) => {
                    setrole_name(e.target.value);
    onPaginationSelectChange();

                  }}
                  value={role_name}
                >
                  <option value="">All</option>
                  {
                    roles.map(role => (
                      <option key={role.isEdit} value={role.name}>{role.name}</option>
                    ))
                  }
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Type
                </small>
              </div>

            </div>


            {/* <CustomersFilter />
            {customersUIProps.ids.length > 0 && <CustomersGrouping />}
            <CustomersTable /> */}
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <>
                    <Pagination
                      updatingQueryParams={updatingQueryParams}
                      onPaginationSelectChange={onPaginationSelectChange}
                      isLoading={loading}
                      paginationProps={paginationProps}
                    >
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        keyField="id"
                        data={dataSearch}
                        columns={columns}
                        defaultSorted={uiHelpers.defaultSorted}
                        {...paginationTableProps}
                        filter={filterFactory()}
                      ></BootstrapTable>
                    </Pagination>
                    {/* )} */}
                  </>
                );
              }}
            </PaginationProvider>
          </CardBody>
        </>
      </Card>


      <Modal centered animation onHide={() => setrestoreModel(false)} show={restoreModel}>
        <Modal.Header>
          <Modal.Title>Restore</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to restore this user?
        </Modal.Body>
        <Modal.Footer>
          <button onClick={reStoreUser} className="btn btn-primary">Yes</button>
          <button onClick={() => setrestoreModel(false)} className="btn btn-danger">No</button>
        </Modal.Footer>
      </Modal>


      <DeleteModal
        
        msg={'Are you sure you want to delete this user permanently?'}
        url={`${baseUrl}/mgmt/permanent-delete-user/${selectedObj.id}`}
        visible={deleteModal}
        HideModal={() => setdeleteModal(false)}
        method={'DELETE'}
        reload={getAllBrands}
        id={selectedObj.id}

      />
    </>

  );
}




const INITIAL_STATE = {
    rotaGroups: [],
};

const RotaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ROTA_GROUPS':
            return {
                ...state,
                rotaGroups: action.payload.rotaGroups,
            };
        default:
            return state;
    }
};

export default RotaReducer;
import React, { useEffect, useRef, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls'
import { Card as Carder, Skeleton, Tooltip } from 'antd';
import HolidayGroupCard from '../../components/HolidayGroupCard/HolidayGroupCard'
import { baseUrl } from '../../../services/config';
import { useQuery } from "react-query";
import { Spinner } from 'react-bootstrap';
import { Modal, Empty } from 'antd';
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-hot-toast';
import UpdateHolidayGroup from '../../components/UpdateHolidayGroup/UpdateHolidayGroup';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import AllHolidays from '../AllHolidays/AllHolidays';
import { PremissionChecker } from '../../../services/PremissionChecker';
import { useSelector } from 'react-redux';


const HolidayGroup = () => {
    const user = useSelector(state => state.user.user)

    const authtoken = localStorage.getItem("userToken");
    const [modal1Open, setModal1Open] = useState(false);
    const [selectedObject, setSelectedObject] = useState({})
    const [deleteModal, setdeleteModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [editModal, setEditModal] = useState(false);
  const   [currentId,setCurrentId]=useState("")
  const [haveError,setHaveError]=useState(false)
  const [trigger, setTrigger] = useState(false); 
  const [isLoading,setIsLoading]=useState(true)
  const [data,setdata]=useState([])
  const scrollRef=useRef(null)
    // init values
    const initialValues = {
        name: '',
    };

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleGroupsIdChange = (newGroupsId) => {
        setTrigger(prev => !prev); // Toggle trigger state to force useEffect to run again
        // You can set groupsId state here if needed
      };


    // validation schema 
    const LoginSchema = Yup.object().shape({
        name: Yup.string()
            .required('Group Name is Required'),
    });



    // @changing class according to error while input:focus and touched

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };


    const getColor = () => {
        let color = ''

        color = Math.floor(Math.random() * 16777215).toString(16)
        return color
    }





    // fetching data from api using react qeury

    // const { isLoading, error, data, refetch: getLeaveGroups, isError } = useQuery("getHolidayGroups", () =>
 
    // );

    useEffect(() => {
      
        getLeaveGroups()
    }, [])
    

  const getLeaveGroups=()=>{
    fetch(`${baseUrl}/mgmt/leave-groups`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${authtoken}`,
        }
    }).then((res) => res.json())
        .then((resp) => {
          if(resp.metadata){
          if(resp.metadata.success){
            if(user.role[0]?.name=="admin" && isLoading){
                setCurrentId(null)
            }else if(isLoading){
                handleGroupsIdChange()
                setCurrentId(resp.payload[0].id)
            }
          
            resp.payload.forEach((subs) => {
                subs.users.forEach((use) => {
                    use['color'] = getColor()
                })
            })
            setdata(resp.payload)
    setIsLoading(false)
          }else{
    setIsLoading(false)
          }
          }
        }).catch((err)=>(
    setIsLoading(false)
            
        ))
  }

    const { Meta } = Carder;

// if(haveError){
//     isLoading=false
// }

    // post data with formik 

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            enableLoading();
            fetch(`${baseUrl}/mgmt/leave-groups`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authtoken}`,
                },
                body: JSON.stringify(values)
            }).then(res => res.json())
                .then((data) => {
                    if (data.metadata.success) {
                        toast.success(data.metadata.message)
                        disableLoading()
                        getLeaveGroups()
                        setModal1Open(false)
                        resetForm()
                    } else if (!data.metadata.success) {
                        Object.values(data.metadata.message).map(msg => {
                            toast.error(msg)
                        })
                        disableLoading()

                    } else {
                        toast.error('unknown error!')
                        disableLoading()

                    }

                }).catch(err => {
                    disableLoading()

                    console.log(err)
                })


        },
    });




    // handling loading states
    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Spinner animation='border' />
            </div>
        )
    }


    // handling error 




    const onEditClick = item => {
        setSelectedObject(item)
        setEditModal(true)
    }
    const onDeleteClick = item => {
        setSelectedObject(item)
        setdeleteModal(true)
    }

    const callBackFun=(id)=>{
        handleGroupsIdChange()
        scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setCurrentId(id)
    }


    return (
        <>
        <div ref={scrollRef}></div>
            <AllHolidays groupsId={currentId} trigger={trigger}/>
            <Card>
                <CardHeader title="Holiday Groups">
                    <CardHeaderToolbar>
                        {PremissionChecker(user, "holiday_group.create") ?
                            <button
                                type="button"
                                className="btn btn-primary mx-2"
                                onClick={() => {
                                    setModal1Open(true)
                                }}
                            >
                                New Group
                            </button> : null}
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    {PremissionChecker(user, "holiday_group.list") ?
                        <div className='row d-flex justify-content-center'>
          {user.role[0]?.name=="admin"  &&                <Carder
          onClick={() => callBackFun(null)}
            hoverable
            style={{
                width: 270,
                margin: 10
            }}
           
            cover={
                <img
                    // onClick={() => gotToCalendar(`/group/${item.id}/${item.name}`)}
                    onClick={() => callBackFun(null)}
                    alt="example"
                    src="https://app.connecteam.com/images/categories/shift-scheduler/calendar-icon@2x.svg"
                />

            }
            actions={[
                // PremissionChecker(user,"leave_group.assigned_users")?
                // groupDropDown():null,
                // PremissionChecker(user,"leave_group.update")?
                // <EditOutlined className='text-primary' onClick={() => onEditClick(item)} key="edit" />:null,
                // PremissionChecker(user,"leave_group.delete")?<DeleteOutlined className='text-danger' onClick={() => onDeleteClick(item)} key="delete" />:null,
            ]}
            loading={loading}
        >
            <Skeleton loading={loading} avatar active>
                <div
                     onClick={() => callBackFun(null)}
                >
                    
                    <Meta
                        // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                        title={<Tooltip title={'Entire company'}>Entire company</Tooltip>}
                        // description="Job Scheduling"
                    />
                   
                </div>
            </Skeleton>

        </Carder>}
                            {
                                data && data.map((item, i) => (
                                    <HolidayGroupCard
                                    callBackFun={callBackFun}
                                        item={item}
                                        loading={isLoading}
                                        refresh={getLeaveGroups}
                                        onEditClick={(item) => onEditClick(item)}
                                        onDeleteClick={(item) => onDeleteClick(item)}
                                    />
                                ))
                            }
                        </div> :
                        <div className="notPermission">
                            <Empty description="Do not have permission" />
                        </div>
                    }

                    {/* <HolidayGroupCard
                        item={}

                    /> */}
                </CardBody>
            </Card>

            <Modal
                title={"Create New Holiday Group"}
                centered
                open={modal1Open}
                okText={"Create Group"}
                onOk={() => formik.handleSubmit()}
                confirmLoading={loading}
                onCancel={() => {
                    formik.resetForm()
                    setModal1Open(false)
                }}
            >
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Group Name"
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "name"
                            )}`}
                            name="name"
                            {...formik.getFieldProps("name")}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.name}</div>
                            </div>
                        ) : null}
                    </div>

                </form>

            </Modal>


            <UpdateHolidayGroup
                open={editModal}
                selectedObject={selectedObject}
                reload={getLeaveGroups}
                onHide={() => setEditModal(false)}
                name={selectedObject.name}
            />

            <DeleteModal
                visible={deleteModal}
                HideModal={() => setdeleteModal(false)}
                method={'DELETE'}
                url={`${baseUrl}/mgmt/leave-groups/${selectedObject.id}`}
                reload={getLeaveGroups}

            />
        </>
    )
}

export default HolidayGroup
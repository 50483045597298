import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import { getUserByToken } from "./authCrud";
import { baseUrl } from "../../../../services/config";

function AuthInit(props) {
  
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [checkSplash, setCheckSplash] = useState(true);
  const user = useSelector(state => state.user.user)
  // const { authToken } = useSelector(
  //   ({ auth }) => ({
  //     authToken: auth.authToken,
  //   }),
  //   shallowEqual
  // );

  const authToken = localStorage.getItem("userToken")

  // We should request user by authToken before rendering the application
  useEffect(() => {
    // Authorization head should be fulfilled in interceptor.


    // const gulraizRequest = () => {

      fetch(`${baseUrl}/mgmt/user-profile`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }).then(res => res.json())
        .then((data) => {
          if (data.metadata.success) {
            console.log("ya hy user",data.payload)
            dispatch({
              type: "ADD_USER",
              payload: {
                isAuthenticated: true,
                // authtoken: user.payload.token,
                user: data.payload,
              },
            });

            setCheckSplash(true)
          }
        }).catch(err => {
          console.log(err)
        })
    // }




    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          // const { data: user } = await getUserByToken();
          dispatch(props.fulfillUser(user));
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        if (checkSplash) {

          setShowSplashScreen(false);
        }
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      requestUser();
    } else {
      dispatch(props.fulfillUser(undefined));
      if(checkSplash){
        setShowSplashScreen(false);
      }
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);

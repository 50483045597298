import {Card as Carder, CardActions, CardContent, Typography } from '@material-ui/core';
import { Modal } from 'antd'
import { Formik, useFormik } from 'formik';
import React from 'react'
import { useState } from 'react';
import * as Yup from "yup";
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls'
import "./Staff.css"
import { Link } from 'react-router-dom';

const StaffMatrix = () => {
    const [loading, setLoading] = useState(false);
    const [modal1Open, setModal1Open] = useState(false);


    const LoginSchema = Yup.object().shape({
        name: Yup.string()
            .required('Group Name is Required'),
    });
    const enableLoading = () => {
        setLoading(true);
    };


    const initialValues = {
        name: '',
    };
    const disableLoading = () => {
        setLoading(false);
    };
    
    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            enableLoading();
            // fetch(`${baseUrl}/mgmt/rotas`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         Authorization: `Bearer ${authtoken}`,
            //     },
            //     body: JSON.stringify(values)
            // }).then(res => res.json())
            //     .then((data) => {
            //         if (data.metadata.success) {
            //             toast.success(data.metadata.message)
            //             disableLoading()
            //             getRotaGroups()
            //             setModal1Open(false)
            //             resetForm()
            //         } else if (!data.metadata.success) {
            //             Object.values(data.metadata.message).map(msg => {
            //                 toast.error(msg)
            //             })
            //             disableLoading()

            //         } else {
            //             toast.error('unknown error!')
            //             disableLoading()

            //         }

            //     }).catch(err => {
            //         disableLoading()

            //         console.log(err)
            //     })


        },
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

  return (
    <>
       <Card>
                <CardHeader title="Staff Matrix">
                    <CardHeaderToolbar>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                setModal1Open(true)
                            }}
                        >
                            New Group
                        </button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>

                   <div className='row'>
                   <div
       className='col-md-4'
      >
            <Carder  className="usersCard" variant="outlined">
              <CardContent className="d-flex">
              <Typography style={{color:"black",fontSize:"14px"}} color="textSecondary" gutterBottom>
                  <p className='title-price'>Name:</p>
                 <p>Cardmore Admore</p>
                </Typography>
              </CardContent>
              <CardActions
                style={{
                  justifyContent: 'space-around'
                }}
              >
                 <Link to="/view-matrix">
              <Button
                  title={'View'} size="small">
                  <VisibilityIcon style={{ color: "green" }} />
                </Button>
              </Link>
                <Button
                  title={'Edit'} size="small">
                  <EditIcon style={{ color: "blue" }} />
                </Button>
                <Button
                  title='Delete' size="small">
                  <DeleteOutlineIcon style={{ color: "red" }} />
                </Button>
              </CardActions>
            </Carder>
        
                        </div>
                        <div
       className='col-md-4'
      >
        <Carder  className="usersCard" variant="outlined">
              <CardContent className="d-flex">
              <Typography style={{color:"black",fontSize:"14px"}} color="textSecondary" gutterBottom>
                  <p className='title-price'>Name:</p>
                 <p>Farbideen jabezz</p>
                </Typography>
              </CardContent>
              <CardActions
                style={{
                  justifyContent: 'space-around'
                }}
              >
                <Link to="/view-matrix">
              <Button
                  title={'View'} size="small">
                  <VisibilityIcon style={{ color: "green" }} />
                </Button>
              </Link>
                <Button
                  title={'Edit'} size="small">
                  <EditIcon style={{ color: "blue" }} />
                </Button>
                <Button
                  title='Delete' size="small">
                  <DeleteOutlineIcon style={{ color: "red" }} />
                </Button>
              </CardActions>
            </Carder>
        
                        </div>
                        <div
       className='col-md-4'
      >
       <Carder  className="usersCard" variant="outlined">
              <CardContent className="d-flex">
              <Typography style={{color:"black",fontSize:"14px"}} color="textSecondary" gutterBottom>
                  <p className='title-price'>Name:</p>
                 <p>Former Chemore</p>
                </Typography>
              </CardContent>
              <CardActions
                style={{
                  justifyContent: 'space-around'
                }}
              >
              <Link to="/view-matrix">
              <Button
                  title={'View'} size="small">
                  <VisibilityIcon style={{ color: "green" }} />
                </Button>
              </Link>
                <Button
                  title={'Edit'} size="small">
                  <EditIcon style={{ color: "blue" }} />
                </Button>
                <Button
                  title='Delete' size="small">
                  <DeleteOutlineIcon style={{ color: "red" }} />
                </Button>
              </CardActions>
            </Carder>
        
                        </div>
                   </div>

              
                </CardBody>
            </Card>



            <Modal
                title={"Create new Group"}
                centered
                open={modal1Open}
                okText={"Create new Group"}
                onOk={() => formik.handleSubmit()}
                confirmLoading={loading}
                onCancel={() => {
                    setModal1Open(false)
                }}
            >
                <form
                    onSubmit={Formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Group Name"
                            type="text"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "name"
                            )}`}
                            name="name"
                            {...formik.getFieldProps("name")}
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.name}</div>
                            </div>
                        ) : null}
                    </div>

                </form>

            </Modal>
    </>
  )
}

export default StaffMatrix
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import BuildIcon from '@material-ui/icons/Build';
import Accessories from './Accessories';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../../services/config';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

export default function EquipmentDetails() {

 const params=useParams()
 const [loading,setloading]=useState(false)
 const [accData,setAccData]=useState([])
 const authtoken = localStorage.getItem("userToken");

 useEffect(() => {
     getAllAcc(params.id)
 }, [])
 
 
 const getAllAcc = (id) => {
     setloading(true)
     fetch(`${baseUrl}/mgmt/equipment/${id}/accessories`, {
         method: "GET",
         headers: {

             Authorization: `Bearer ${authtoken}`,
         },
     })
         .then((res) => res.json())

         .then((data) => {
             setloading(false)
             if(data.metadata){
                 if(data.metadata.success){
                    setAccData(data.payload)
                 }
             }
         })
         .catch((err) => {
             setloading(false)
             console.log(err);
         });
 };
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-force-tabpanel-${index}`}
                aria-labelledby={`scrollable-force-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }



    return (
        <>
            <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Tools" />
                    <Tab label="Accessories" />
                </Tabs>

            </Paper>
            <TabPanel value={value} index={0}>
                Tools
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Accessories id={params.id} accdata={accData} reload={getAllAcc}/>
            </TabPanel>
        </>
    );
}

// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { PremissionChecker } from "../../../../../../../services/PremissionChecker";

export function LeaveActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { attachPermissions, openEditCustomerDialog, openRestore, openDelete,goToViewHoliday,user }
) {


  //console.log('ye row', row)

  return (
    <>
      {/* <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Restore User</Tooltip>}
      > */}
           {PremissionChecker(user, "leaver.restore") ? (
        <a
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => openRestore(row)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG 
            title="Restore User" src={toAbsoluteUrl("/media/svg/icons/Text/Redo.svg")} />
          </span>
        </a>
         ): null} 
      {/* </OverlayTrigger> */}
      {/* <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Delete Permanently</Tooltip>}
      > */}
        {PremissionChecker(user, "leaver.delete") ? (
        <a
          className="btn btn-icon btn-light btn-hover-danger btn-sm ml-2"
          onClick={() => openDelete(row)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG 
            title="Delete Permanently" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
         ): null} 
        <>
        {PremissionChecker(user, "leave_application.list") ? (
      
            <a
              className="btn btn-icon btn-light btn-hover-info btn-sm ml-3"
              onClick={() => goToViewHoliday(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-info">
                <SVG
              title="View Holidays"
                src={toAbsoluteUrl("/media/svg/icons/Devices/Display2.svg")} />
              </span>
            </a>
          ): null} 
      </>
      {/* </OverlayTrigger> */}
    </>
  );
}

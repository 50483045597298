
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input, TextArea } from '../../../_metronic/_partials/controls';
import { Modal } from 'react-bootstrap';
import { baseUrl } from '../../../services/config';
import { toast } from 'react-hot-toast';
import DeleteIcon from '@material-ui/icons/Delete';
import { CloseCircleFilled, PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons'
import { Image } from 'antd';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const EditAnnoucment = ({
  onHide, reload, editItem

}) => {
  console.log(editItem)
  const [loading, setloading] = useState(false)
  const [image, setimage] = useState('')
  const [imgShow, setimgShow] = useState('')
  const [imgError, setimgError] = useState('')
  const [boxes, setBoxes] = useState([{ title: '', description: '', link: '', image: null,imagePreview:"" }]);
  const [deletedArray,setDeletedArray]=useState([])

  const authtoken = localStorage.getItem("userToken");

  const [images, setimages] = useState()


  const urlRegex = /^(https?|ftp):\/\/([A-Z0-9.-]+\.[A-Z]{2,})(:\d{1,5})?(\/[^\s]*)?$/i;

  const CustomerEditSchema = Yup.object().shape({
    title: Yup.string().when("type", {
      is: "2",
      then: Yup.string().required("Title is required"),
    }),
    description: Yup.string().notRequired("description is required").max(500, "You cannot enter more then 500 character").nullable(),
    // link: Yup.string()
    //   .matches(urlRegex, 'Invalid URL')
    //   .required("link is required"),
    type: Yup.string().required('Please select an option'),
  });
  useEffect(() => {
  if(editItem[0]?.type?.value==1){

    let array=[]

    editItem.map((item)=>{
        array.push({
          draggableId:item.id.toString(),
          id:item.id.toString(),
         title:item.title,
         description:item.description,
         link:item.link=="undefined"?"":item.link,
         image:"",
         imagePreview:item.image.url
        })
    })
    setBoxes(array)
//   for(let i=0; i<editItem.length; i++){
//   boxes[i].title=editItem[i].title
//   boxes[i].description=editItem[i].description
//   boxes[i].link=editItem[i].link
//  }
  }else{
    setimgShow(editItem[0].image.url)
  }
  }, [editItem])

  const editinitialValues = {
    // title: editItem.title,
    // description: editItem.description,
    // link: editItem.link,
    type: editItem[0]?.type?.value
  }

  const addinitialValues = {
     title: editItem[0].title,
    description: editItem[0].description,
    link: editItem[0].link,
    type: editItem[0]?.type?.value
  }


  const handleSubmit = (values) => {
//     let titleArray=[]
//     let desArray=[]
//     let linkArray=[]
//     let imageArray=[]
//  for(let i=0; i<boxes.length; i++){
//   titleArray[i]=boxes[i].title
//   desArray[i]=boxes[i].description
//   linkArray[i]=boxes[i].link
//   imageArray[i]=boxes[i].image
//  }
    const fdata = new FormData();
    // fdata.append("title", values.title);
    // fdata.append("description", values.description);
    // fdata.append("type", values.type);
   if(values.type==1){

  
    // for (let i = 0; i < boxes.length; i++) {
    //   if(boxes[i].title==""){
    //    toast.error(`Please Enter Title On ${i+1} Row`)
    //   }
    //   if(boxes[i].description==""){
    //    toast.error(`Please Enter Description On ${i+1} Row`)
    //   }
    //   if(boxes[i].image==null){
    //    toast.error(`Please Enter Image On ${i+1} Row`)
    //   }
    //   if(boxes[i].title=="" || boxes[i].description=="" || boxes[i].image==""){
    //     return
    //   }
    // }
    const checker=boxes.map((item)=>{
      if(item.title=='' || item.image==null){
        return true
      }
    })
    if(checker.includes(true)){
      toast.error('Title And Image Is Required')
      return
    }
    const updateObjects=  boxes.filter((item)=>{
      if(item.id){
        delete item.imagePreview;
        return item
      }
    })
  const newObjects=  boxes.filter((item)=>{
      if(!item.id){
        delete item.imagePreview;
        item['type']= values.type
        return item
      }
    })
console.log(newObjects,'program to warr gaya')
  

  
    setloading(true)
   
 for (let i = 0; i < updateObjects.length; i++) {
      for (let key of Object.keys(updateObjects[i])) {
        fdata.append(`update_announcement[${i}][${key}]`, updateObjects[i][key]);
      }
    }
 for (let i = 0; i < newObjects.length; i++) {
      for (let key of Object.keys(newObjects[i])) {
        fdata.append(`add_announcement[${i}][${key}]`, newObjects[i][key]);
      }
    }


    /////pahla wala
    // for (let i = 0; i < boxes.length; i++) {
    // //   fdata.append(`edit_ids[${i}]`,boxes[i].id);
    //   fdata.append(`title[${i}]`,boxes[i].title);
    //   fdata.append(`description[${i}]`,boxes[i].description);
    //   fdata.append(`link[${i}]`,boxes[i].link);
    //   fdata.append(`image[${i}]`,boxes[i].image);
    // }
    // for (let i = 0; i < editItem.length; i++) {
    //   fdata.append(`edit_ids[${i}]`,boxes[i].id);
    // }
    console.log(newObjects,'ab ya kia hy?')

   }else{
    const dummy=[
      {
        id:editItem[0].id,
        title:values.title,
        description:values.description,
        link:values.link,
        image:images || '',
      }
    ]
    for (let i = 0; i < dummy.length; i++) {
      for (let key of Object.keys(dummy[i])) {
        fdata.append(`update_announcement[${i}][${key}]`, dummy[i][key]);
      }
    }
    // fdata.append(`edit_ids[${i}]`,boxes[i].id);
    // fdata.append(`title[${0}]`,values.title);
    // fdata.append(`description[${0}]`,values.description);
    // fdata.append(`link[${0}]`,values.link);
    // fdata.append(`image[${0}]`,images || '');
   }



   let filterddeleteArr = deletedArray.filter(item=>item !== undefined)


   if(filterddeleteArr.length>0){
     for (let i = 0; i < filterddeleteArr.length; i++) {
      fdata.append(`delete_ids[${i}]`,filterddeleteArr[i]);
    }
   }else{
    fdata.append(`delete_ids[${0}]`,'');
   }
    
    // for (let i = 0; i < boxes.length; i++) {
    //   for (let key of Object.keys(boxes[i])) {
    //     fdata.append(`announcements[${i}][${key}]`, boxes[i][key]);
    //   }
    // }
 
 
    // if (values.type == '1') {
    //   for (let i = 0; i < images.length; i++) {
    //     console.log(i)
    //     fdata.append(`image[${i}]`, images[i])
    //   }
    // } else {
    //   fdata.append(`image[${0}]`, images[images.length - 1])
    // }



    for (var pair of fdata.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    const sliderUrl="mgmt/announcement/update"
    const simpleUrl=`/announcement/static/${editItem[0].id}/update`
    fetch(`${baseUrl}/mgmt/announcement/update`, {
      method: "POST",
      headers: {
        // "Content-Type":"multipart/form-data",
        Authorization: `Bearer ${authtoken}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false)
        if (data.metadata) {
          setloading(false)
          if (data.metadata.success) {
            if (boxes.length > 0) {
              let dummyArray = [];
              boxes.map((item, i) => {
                dummyArray.push({
                  title: item.title,
                  order: i + 1,
                });
              });
              console.log(dummyArray, "myIdzzz");
        
              const senddata = {
                announcements: dummyArray,
              };
              dragEndsApi(
                senddata,
                `${baseUrl}/mgmt/announcement/indexing`
              );
            }
            onHide()
            toast.success("Updated Successfully")
          } else if (!data.metadata.success) {
            if (toString.call(data.metadata.message) == "[object Object]") {
              const errorMessageArray = Object.values(
                data.metadata.message
              ).flatMap((messages) => messages);
              errorMessageArray.forEach((message) => toast.error(message));
            } else if (toString.call(data.metadata.message) == "[object Array]") {
              Object.values(data.metadata.message).map((msg) => {
                toast.error(msg);
              });
            } else {
              toast.error(data.metadata.message);
            }
          } else {
            toast.error(`Something went wrong`);
          }
        }
        console.log(data);
      })
      .catch((err) => {
        setloading(false)

        console.log("err is re", err);
      });
  }
  const options = [
    { value: '2', label: 'STATIC' },
    { value: '1', label: 'SLIDER' },
  ];




  const removeitem = (img, index) => {

    console.log(img);
    let arr = [...images]
    // let foundindex = arr.findIndex(img)
    let filterd = arr.filter((item, i) => item !== img)

    setimages(filterd)
  }



  console.log("selected", images);


  //multipule images codes

  const addInputBox = () => {
    setBoxes(prevBoxes => [...prevBoxes, { title: '', description: '', file: null,draggableId:Math.floor(10000 + Math.random() * 90000).toString() }]);
  };

  const hangleinputtitle = (index, value) => {
    const updatedBoxes = [...boxes];
    updatedBoxes[index].title = value;
    setBoxes(updatedBoxes);
  };

  const handleDesc = (index, value) => {
    const updatedBoxes = [...boxes];
    updatedBoxes[index].description = value;
    setBoxes(updatedBoxes);
  };
  const handleLink = (index, value) => {
    const updatedBoxes = [...boxes];
    updatedBoxes[index].link = value;
    setBoxes(updatedBoxes);
  };

  const handleFileChange = (index, file) => {
    const updatedBoxes = [...boxes];
    updatedBoxes[index].image = file;
    updatedBoxes[index].imagePreview = file;
    setBoxes(updatedBoxes);
  };

  const removeInputBox = (index,id) => {
    
    setDeletedArray(oldids => [...oldids, id]);
    setBoxes(prevBoxes => prevBoxes.filter((box, i) => i !== index));
  };
  console.log(deletedArray)


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    if (result.length > 0) {
      let dummyArray = [];
      result.map((item, i) => {
        dummyArray.push({
          id: item.id,
          order: i + 1,
        });
      });
      console.log(dummyArray, "myIdzzz");

      const senddata = {
        announcements: dummyArray,
      };
      // dragEndsApi(
      //   senddata,
      //   `${baseUrl}/mgmt/announcement/indexing`
      // );
    }
    return result;
  };

  const grid = 8;



  const getItemStyle = (isDragging, draggableStyle,index) => ({
    // display: "flex",
    width:"100%",
    minHeight:"47px",
    justifyContent: "space-between",
    userSelect: "none",
    // padding: grid,
    // marginBottom:index==items.length-1 ? "5px" : '',
    // margin: `0 0 ${grid}px 0`,
    // border:"1px solid #D8D8D8",
    // background: isDragging ? "lightgreen" :  "",
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "lightblue" : "",
    // padding: grid,
    // overflowX: "scroll",
    padding: 0,
    // width:
  });

  const dragEndsApi = (data, url) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        reload()
        if (data.metadata.success) {
          // toast.success(data.metadata.message);
          // getFolders();
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        } else {
          toast.error(`Something went wrong`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const updatedItems = reorder(boxes, result.source.index, result.destination.index);
    setBoxes(updatedItems);
  };



  return (
    <div className='container'>
      <Formik
        enableReinitialize={true}
        initialValues={addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          // console.log(values)
          handleSubmit(values);

        }}
      >
        {({ values, errors, touched, setFieldTouched, setFieldValue }) => (
          <Form>
            <Modal.Body className="overlay overlay-block cursor-default">
            <div className='col-md-12 mt-4'>
                <label htmlFor="type">Select an option:</label>
                <select
                  disabled={editItem ? true : false}
                  className="form-control"
                  name="type"
                  id="type"
                  defaultValue={editItem[0].type?.value}
                  onChange={(e) => {
                    setFieldValue('type', e.target.value)
                    setFieldTouched('type', false)
                  }

                  }
                >
                  <option value="">Select an option</option>
                  {options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.type && touched.type && (
                  <div className="error text-danger">{errors.type}</div>
                )}
              </div>
              {
                values.type == '2' &&
                <>
                  <div className='col-md-12 mt-2'>
                    <label className='text-dark'>Title</label>
                    <Field
                      component={Input}
                      placeholder="Title"
                      value={values.title}
                      name="title"
                    />
                  </div>
                  <div className='col-md-12 mt-2'>
                    <label className='text-dark'>Description</label>

                    <Field
                      component={TextArea}
                      placeholder="Description"
                      value={values.description}
                      name="description"
                    />
                  </div>
                  <div className='col-md-12 mt-2'>
                    <label className='text-dark'>Link</label>
                    <Field
                      component={Input}
                      placeholder="Link"
                      value={values.link}
                      name="link"
                    />
                  </div>
                </>
              }
             
              {values.type ?
                <div className='col-md-12 mt-2'>
                  {values.type == 2 ?
                    <>
                      <label className='text-dark'>Image</label>
                      <input
                      accept="image/png, image/jpeg"
                        // multiple={values.type == '1' ? true : false}
                        onChange={e => {
                          setimgError(false)
                          // let arr=[]
                          // arr.push(e.target.files[0])
                          // setimages(Object.values(e.target.files).concat(images))
                          setimages(e.target.files[0])
                          setimgShow(URL.createObjectURL(e.target.files[0]))
                        }} className='form-control' type='file'

                      // value={images}
                      />
                        {
                      imgShow?
                      <img src={imgShow} alt='img' height={100} width={100}/>:
                      ''
                    }

                    </>
                  
                    :
                    <>
                      <div>
                        <h2 className='mt-4' style={{ width: "100%", textAlign: "center" }}>Upload Images and Captions</h2>
                        <div className='yrrkiaanu'>
                          <div></div>
                        </div>
                           <div className='row mt-3'>
                          <div className='col-md-3'>
                            <label><b>Enter Title</b></label>
                          </div>
                          <div className='col-md-3'>
                            <label><b>Enter Description</b></label>
                          </div>
                          <div className='col-md-3'>
                          <label><b>Enter Link</b></label>
                          </div>
                        </div>
                        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                        {boxes.map((box, index) => (
                                 <Draggable key={box.id} draggableId={box.draggableId} index={index}>
                                 {(provided, snapshot) => (
                                   <div
                                     ref={provided.innerRef}
                                     {...provided.draggableProps}
                                     {...provided.dragHandleProps}
                                     style={getItemStyle(
                                       snapshot.isDragging,
                                       provided.draggableProps.style,
                                       index
                                     )}
           
                                   >
                          <div key={index} className='row mb-1 mt-1' style={{position:"relative"}}>
                            <div className='col-md-3'>
                              <input
                                className='form-control'
                                type="text"
                                value={box.title}
                                onChange={e => hangleinputtitle(index, e.target.value)}
                                placeholder="Title"
                              />
                            </div>
                            <div className='col-md-3'>
                              <input
                                className='form-control'
                                type="text"
                                value={box.description}
                                onChange={e => handleDesc(index, e.target.value)}
                                placeholder="Description"
                              />
                            </div>
                            <div className='col-md-3'>
                              <input
                                className='form-control'
                                type="text"
                                value={box.link}
                                onChange={e => handleLink(index, e.target.value)}
                                placeholder="Link"
                              />
                            </div>
                            <div className='col-md-3'>
                            <label htmlFor={`fileImage${index}`} className='btn btn-primary'>
                                 Choose Image
                              </label>
                              <input
                      accept="image/png, image/jpeg"
                                style={{ display: "none" }}
                                type="file"
                                id={`fileImage${index}`}
                                onChange={e => handleFileChange(index, e.target.files[0])}
                              />
                             {box.imagePreview? <span style={{marginLeft:"10px"}}><Image className='myPreviewImage' src={typeof box.imagePreview=="string"?box.imagePreview: URL.createObjectURL(box.imagePreview)} alt='img'/></span>:''}
                            </div>
                            <div className={'col-md-3 mt-2'} style={{ paddingLeft: "50px",position:"absolute",top:"3px",right:"-17%" }}>
                              {boxes.length > 1 && (
                                <MinusCircleFilled style={{ fontSize: "2rem", color: "red" }} onClick={() => removeInputBox(index,box.id)} />
                              )}
                              {boxes.length == index + 1 && (
                                <PlusCircleFilled style={{ fontSize: "2rem", marginLeft: "5px", color: "4BB543" }} onClick={addInputBox} />
                              )}


                            </div>
                          </div>
                        </div>
                      )}
                          </Draggable>
                        ))}

{provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
                      </div>
                    </>
                  }
                  {
                    imgError ?
                      <label className='text-danger'>Image is required</label> : null
                  }
                </div> : ''}
              {/* {
                imgShow ?
                  <img style={{ border: '2px solid black', padding: 10 }} src={imgShow} height={100} width={100} /> :
                  editItem ?
                    <img style={{ border: '2px solid black', padding: 10 }} src={editItem.image.url} height={100} width={100} /> : ''
              } */}




            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-danger btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                disabled={loading}
                type="submit"
                // onClick={() =>  handleSubmit(values)}
                className="btn btn-primary btn-elevate"
              >
                Update
              </button>
            </Modal.Footer>
          </Form>

        )}

      </Formik>




    </div>
  )
}

export default EditAnnoucment
export const timeArray = [
    { label: "12:00 am", value: "00:00:00" },
    { label: "0:30 am", value: "00:30:00" },
    { label: "1:00 am", value: "01:00:00" },
    { label: "1:30 am", value: "01:30:00" },
    { label: "2:00 am", value: "02:00:00" },
    { label: "2:30 am", value: "02:30:00" },
    { label: "3:00 am", value: "03:00:00" },
    { label: "3:30 am", value: "03:30:00" },
    { label: "4:00 am", value: "04:00:00" },
    { label: "4:30 am", value: "04:30:00" },
    { label: "5:00 am", value: "05:00:00" },
    { label: "5:30 am", value: "05:30:00" },
    { label: "6:00 am", value: "06:00:00" },
    { label: "6:30 am", value: "06:30:00" },
    { label: "7:00 am", value: "07:00:00" },
    { label: "7:30 am", value: "07:30:00" },
    { label: "8:00 am", value: "08:00:00" },
    { label: "8:30 am", value: "08:30:00" },
    { label: "9:00 am", value: "09:00:00" },
    { label: "9:30 am", value: "09:30:00" },
    { label: "10:00 am", value: "10:00:00" },
    { label: "10:30 am", value: "10:30:00" },
    { label: "11:00 am", value: "11:00:00" },
    { label: "11:30 am", value: "11:30:00" },
    { label: "12:00 pm", value: "12:00:00" },
    { label: "12:30 pm", value: "12:30:00" },
    { label: "1:00 pm", value: "13:00:00" },
    { label: "1:30 pm", value: "13:30:00" },
    { label: "2:00 pm", value: "14:00:00" },
    { label: "2:30 pm", value: "14:30:00" },
    { label: "3:00 pm", value: "15:00:00" },
    { label: "3:30 pm", value: "15:30:00" },
    { label: "4:00 pm", value: "16:00:00" },
    { label: "4:30 pm", value: "16:30:00" },
    { label: "5:00 pm", value: "17:00:00" },
    { label: "5:30 pm", value: "17:30:00" },
    { label: "6:00 pm", value: "18:00:00" },
    { label: "6:30 pm", value: "18:30:00" },
    { label: "7:00 pm", value: "19:00:00" },
    { label: "7:30 pm", value: "19:30:00" },
    { label: "8:00 pm", value: "20:00:00" },
    { label: "8:30 pm", value: "20:30:00" },
    { label: "9:00 pm", value: "21:00:00" },
    { label: "9:30 pm", value: "21:30:00" },
    { label: "10:00 pm", value: "22:00:00" },
    { label: "10:30 pm", value: "22:30:00" },
    { label: "11:00 pm", value: "23:00:00" },
    { label: "11:30 pm", value: "23:30:00" },
  ];
import React, { useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../../modules/ECommerce/pages/customers/CustomersUIContext";
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../modules/ECommerce/pages/customers/CustomersUIHelpers";
import { Pagination } from '../../../_metronic/_partials/controls'
import { Modal, Spinner } from "react-bootstrap";
import { HolidayTypeModel } from "./HolidayTypeModel";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { LinearProgress } from "@material-ui/core";
import { debounce } from "lodash";
import { baseUrl } from "../../../services/config";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { toast } from 'react-hot-toast'
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";

export function HolidayTypes() {

  const authtoken = localStorage.getItem("userToken");
  const [addModal, setaddModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [editModal, seteditModal] = useState(false);
  const [loading, setloading] = useState(false)
  const [list, setList] = useState([])
  const [selectedId, setSelectedId] = useState("")
  const [leaves, setleaves] = useState('')
const [entitlementModal,setEntitlementModal]=useState(false)
  const [leaveModal, setleaveModal] = useState(false)
  const [workingHours,setWorkingHours]=useState('')
  const [staffEntitlement,setStaffEntitlement]=useState('')


  ////for pagination
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };
  const user = useSelector(state => state.user.user)
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const customersUIContext = useCustomersUIContext();
  const [settingObj, setsettingObj] = useState({})

  useEffect(() => {
    getHoliDaysTypes();
    getEntitlement()
  }, []);
  const param = useParams()
  const getHoliDaysTypes = () => {
    setloading(true);
    fetch(`${baseUrl}/mgmt/leave-types`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${authtoken}`,
      // },
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false);

        console.log("List", data.payload);
        if (data.metadata.success) {
          if (data.payload) {
            let tempArray = data.payload;
            data.payload.map((item, index) => {
              item["sr_no"] = index + 1;
              tempArray[index] = item;
            });
            console.log(data.payload)
            setList(tempArray);
          }
        }

      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };


  const getEntitlement = () => {
    fetch(`${baseUrl}/mgmt/leave-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {

        console.log("List", data.payload);
        if (data.metadata.success) {
          if (data.payload) {
            setWorkingHours(data?.payload?.full_time_working_hours)
            setStaffEntitlement(data?.payload?.full_time_staff_entitlement)
          }
        }

      })
      .catch((err) => {

        console.log(err);
      });
  };

  const columns = [
    {
      dataField: 'sr_no',
      text: 'Sr.No',

    },
    {
      dataField: 'name',
      text: 'Name',

    },

    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.Leavetype,
      formatExtraData: {
        user: user,
        openEditCustomerDialog: (item) => openEdit(item),
        openDeleteCustomerDialog: (id) => {
          setSelectedId(id);
          setdeleteModal(true);
        },
        // openViewCustomerDialog:()=>history.push('/config-lists')
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];





  const openEdit = (item) => {
    setEditItem(item);
    seteditModal(true)
    setaddModal(true)
  }
  const options = {
    custom: true,
    totalSize: list?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };

  const updatingQueryParams = (page) => {
    console.log(page)
    console.log("this is chus2");
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  useEffect(() => {
    getSettings()
  }, [])


  const getSettings = () => {
    fetch(`${baseUrl}/asset/setting`)
      .then(res => res.json())
      .then(data => {
        setsettingObj(data.payload)
      })
      .catch(err => {
        console.log(err)
      })
  }



  const updateSettings = () => {
    setloading(true)
    const data = {
      allow_leaves: leaves
    }

    fetch(`${baseUrl}/mgmt/setting/update?_method=PUT`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {
        if (data.metadata.success) {
          toast.success(data.metadata.message)
          setaddModal(false)
          getSettings()
          setloading(false)
        }
        // setsettingObj(data.payload)
      })
      .catch(err => {
        console.log(err)
        setloading(false)

      })
  }

  const updateEntitlement=()=>{
    if(workingHours ==''){
     return toast.error('Please enter full time working hours')
    }
    if(staffEntitlement==''){
     return toast.error('Please enter full time staff entitlement')
    }
    setloading(true)
    const data = {
      full_time_staff_entitlement: staffEntitlement,
      full_time_working_hours:workingHours
    }

    fetch(`${baseUrl}/mgmt/leave-settings`, {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(data => {
        if (data.metadata.success) {
          toast.success('Updated Successfully')
          setEntitlementModal(false)
          getEntitlement()
          setloading(false)
        }
        // setsettingObj(data.payload)
      })
      .catch(err => {
        console.log(err)
        setloading(false)

      })
  }


  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Card>
        <CardHeader title="Holidays Settings">
          <CardHeaderToolbar>
        {PremissionChecker(user, "leave_type.holiday_entitlement") ?   <button
                  type="button"
                  className="btn btn-primary mx-2"
                  onClick={() => {
                   setEntitlementModal(true)
                  }}
                >
                  Holiday Entitlement
                </button>:null}
            {
              PremissionChecker(user, "leave_type.create") ?
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    seteditModal(false)
                    setaddModal(true)
                  }}
                >
                  New Type
                </button>
                :
                null
            }
              
            {/* <button
              type="button"
              className="btn btn-primary ml-2"
              onClick={() => {
                setleaveModal(true)
            
              }}
            >
              Annual Leave
            </button> */}
          </CardHeaderToolbar>
        </CardHeader>

        <CardBody>

          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  updatingQueryParams={updatingQueryParams}
                  onPaginationSelectChange={onPaginationSelectChange}
                  isLoading={loading}
                  paginationProps={paginationProps}
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    keyField='id'
                    data={list}
                    columns={columns}
                    defaultSorted={uiHelpers.defaultSorted}
                    {...paginationTableProps}
                  />
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
      <Modal
        show={addModal}
      >
        <Modal.Header><h4>
          {editModal ? 'Edit Holiday Setting' : 'Holiday Setting'}
        </h4></Modal.Header>
        <HolidayTypeModel
          editItem={editModal ? editItem : ""}
          reload={getHoliDaysTypes}
          id={param.id}
          isEdit={editModal ? true : false}
          saveCustomer={values => console.log(values)}
          onHide={() => setaddModal(false)}
        />
      </Modal>
      <DeleteModal
        method={'DELETE'}
        HideModal={() => setdeleteModal(false)}
        visible={deleteModal}
        title="Delete Holiday Setting"
        url={`${baseUrl}/mgmt/leave-types/${selectedId}`}
        reload={getHoliDaysTypes}
      />

      <Modal
        onHide={() => setleaveModal(false)}
        show={leaveModal}>
        <Modal.Header>
          <Modal.Title>Update Leaves</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className='form-control'
            placeholder={`Type leaves`}
            defaultValue={settingObj.leaves}

            onChange={(e) => setleaves(e.target.value)}
          />


        </Modal.Body>
        <Modal.Footer>
          {
            loading ?
              <Spinner color='green' animation='border' />
              : <>

                <button
                  onClick={() => setleaveModal(false)}
                  className='btn btn-danger'>
                  Cancel
                </button>

                <button
                  onClick={() => updateSettings()}
                  className='btn btn-primary'>
                  Update
                </button>

              </>
          }
        </Modal.Footer>
      </Modal>







      <Modal
        onHide={() => setEntitlementModal(false)}
        show={entitlementModal}>
        <Modal.Header>
          <Modal.Title>Holiday Entitlement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
           <div className="col-md-12">
            <label><b>Full Time Working Hours</b></label>
            <input
            type="number"
            className='form-control'
            placeholder={`Enter Full Time Working Hours`}
            defaultValue={workingHours}

            onChange={(e) => setWorkingHours(e.target.value)}
          />
           </div>
           <div className="col-md-12 mt-5">
            <label><b>Full Time Staff Entitlement</b></label>
            <input
            type="number"
            className='form-control'
            placeholder={`Enter Full Time Staff Entitlement`}
            defaultValue={staffEntitlement}
            onChange={(e) => setStaffEntitlement(e.target.value)}
          />
           </div>
        </div>
         


        </Modal.Body>
        <Modal.Footer>
          {
            loading ?
              <Spinner color='green' animation='border' />
              : <>

                <button
                  onClick={() => setEntitlementModal(false)}
                  className='btn btn-danger'>
                  Cancel
                </button>

                <button
                  onClick={() => updateEntitlement()}
                  className='btn btn-primary'>
                  Update
                </button>

              </>
          }
        </Modal.Footer>
      </Modal>

    </>

  );
}

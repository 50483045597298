import React, { useEffect, useState } from "react";
import "./accessoires.css";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Spin, Tooltip } from "antd";
import { baseUrl } from "../../../services/config";
import { toast } from "react-hot-toast";
import { Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#333",
    },
  }));
const Accessories = ({ id, accdata,reload }) => {
  console.log("=====", accdata);
  const authtoken = localStorage.getItem("userToken");
const [uploadLoading,setuploadLoading]=useState(false)
  const uploadImage = (e,thisID) => {
    setuploadLoading(true)
    let formdata = new FormData();

    formdata.append("image", e.target.files[0]);

    fetch(`${baseUrl}/mgmt/accessories/${thisID}?_method=PUT`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: formdata,
    })
      .then((res) => res.json())
      .then((data) => {
    setuploadLoading(false)
        if (data.metadata.success) {
          console.log(data);
          toast.success(data.metadata.message);
          reload(id)
        } else if (!data.metadata.success) {
          Object.values(data.metadata.message).map((msg) => {
            toast.error(msg);
          });
        } else {
          toast.error(`Something went wrong :(`);
        }
      })
      .catch((err) => {
        console.log(err);
    setuploadLoading(false)
      });
  };
  const classes = useStyles();
  return (
    <>
      {uploadLoading ? (
            <Backdrop
              style={{ backgroundColor: "rgb(0, 0, 255)" }}
              className={classes.backdrop}
              open={uploadLoading}
            >
              {/* <Space size="middle"> */}
                <Spin tip="uploading" size="large" />
              {/* </Space> */}
            </Backdrop>
          ) : (
            ""
          )}
      <div className="row">
        {accdata.length > 0 ? (
          accdata.map((item) => (
            <div className="col-md-4">
              <div class="containerr">
                <img className="cardimg" src={item.image.url} alt="" />
                <div></div>
                <div class="overlayss"></div>
                <div class="button">
                  <Tooltip placement="top" title="Update Image">
                    {/* <Button style={{height:"50px",width:"50px"}} icon={<UploadOutlined />}></Button> */}
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          onChange={(e)=>uploadImage(e,item.id)}
                          className="fileuploader"
                          type="file"
                          accept="image/x-png,image/gif,image/jpeg"
                        />
                        <span className="upload-button">
                          <i class="fas fa-arrow-up"></i>
                        </span>
                      </label>
                    </div>
                  </Tooltip>
                </div>
                <h4 className="cardtextacc">
                  {item?.name.length > 33
                    ? item?.name.substr(0, 33) + "..."
                    : item?.name}
                </h4>
              </div>
            </div>
          ))
        ) : (
          <div className="noaccfound">
            <h4> No Accessories yet</h4>
          </div>
        )}
      </div>
    </>
  );
};

export default Accessories;

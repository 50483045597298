
const INITIAL_STATE = {
    show: false,
};

const ButtonReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SHOW_BUTTON':
            return {
                ...state,
                show: action.payload.show,
            };
        default:
            return state;
    }
};

export default ButtonReducer;
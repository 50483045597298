import React, { useEffect, useMemo, useState } from "react";
import './styles.css'
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { baseUrl } from "../../../services/config";
import EquipmentCard from "../../components/EquipmentCard/EquipmentCard";
import UsePagination from "../../components/Pagination/UsePagination";
import { Pagination } from 'antd';

export default function Equipments() {


    const authtoken = localStorage.getItem("userToken");

    const [brands, setBrands] = useState([])
    const [links, setLinks] = useState([])
    const [loading, setloading] = useState(false)

    const [usernameSearch, setusernameSearch] = useState('')

    const [ids, setIds] = useState([])

    useEffect(() => {
        getAllBrands(`${baseUrl}/mgmt/equipment`)
    }, [])


    const getAllBrands = (url) => {
        setloading(true)
        fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((res) => res.json())

            .then((data) => {
                setloading(false)
                setBrands(data.payload.data)
                setLinks(data.payload.links)
            })
            .catch((err) => {
                setloading(false)
                console.log(err);
            });
    };


    const dataSearch = brands.filter((user) => {
        let text1 = usernameSearch.toLowerCase()
        const topicSearchData = text1 ? user?.name?.toLowerCase().includes(text1) : true;
        return topicSearchData
    });
    const onPaginate = (link) => {
        getAllBrands(link.url)
    }

    return (
        <>
            {loading ? <LinearProgress /> : null}
            <Card>

                <>

                    <CardHeader title="Equipments">
                        <CardHeaderToolbar>

                        </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody>
                        <div className="form-group row">
                            <div className="col-lg-2">
                                <small className="form-text text-muted">
                                    <b>Search</b> by name
                                </small>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="searchText"
                                    placeholder="Search by name"
                                    value={usernameSearch}
                                    onChange={(e) => {
                                        setusernameSearch(e.target.value);
                                    }}
                                />

                            </div>


                        </div>
                        <div className="row">
                            {
                                dataSearch.map((item, i) => {
                                    return <div key={i} className="col-md-4"><EquipmentCard reload={()=>getAllBrands(`${baseUrl}/mgmt/equipment`)} item={item} /></div>
                                })
                            }

                        </div>
                    </CardBody>
                </>
            </Card>
            <UsePagination
                links={links}
                onPaginate={onPaginate}
            />

        </>

    );
}


// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { PremissionChecker } from "../../../../../../../services/PremissionChecker";
import { useSelector } from "react-redux";

import { OverlayTrigger, Tooltip } from "react-bootstrap";




export function UserFormatter(
  cellContent,
  row,
  rowIndex,
  { attachPermissions, openEditCustomerDialog, goToViewHoliday, openDeleteCustomerDialog, user, resetPassword, gotopermissionmodal,goToApplyLeave }
) {



  console.log('console krwa dain', user);
  // const user = useSelector(state => state.user.user)


  return (
    <>
      {PremissionChecker(user, "user.reset") ? (
        // <OverlayTrigger
        //   overlay={<Tooltip id="products-edit-tooltip">Reset Password</Tooltip>}
        // >
          <a
            // title="Reset Password"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => resetPassword(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
               title="Reset Password"
                src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
              />
            </span>
          </a>
        // </OverlayTrigger>
      ) : null}

      {/* {PremissionChecker(user, "work_pattern.list") ? (

        <OverlayTrigger
          overlay={<Tooltip id="products-edit-tooltip">Add</Tooltip>}
        >
          <a
            title="add"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => gotopermissionmodal(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")}
              />
            </span>
          </a>
        </OverlayTrigger>
      ) : null} */}


      {PremissionChecker(user, "user.update") ? (
        // <OverlayTrigger
        //   overlay={<Tooltip id="products-edit-tooltip">Edit User</Tooltip>}
        // >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => openEditCustomerDialog(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
            title="Edit User"
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </a>
        // </OverlayTrigger>
      ) : null}
      <> </>
      {PremissionChecker(user, "user.delete") ? (
        // <OverlayTrigger
        //   overlay={<Tooltip id="products-edit-tooltip">Delete User</Tooltip>}
        // >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => openDeleteCustomerDialog(row.id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG 
            title="Leaver"
              src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </a>
        // </OverlayTrigger>
      ) : null}
      <>
        {/* {PremissionChecker(user, "leave_application.show") ? ( */}
          {/* <OverlayTrigger
            overlay={<Tooltip id="products-edit-tooltip">View Holidays</Tooltip>}
          > */}
          {PremissionChecker(user, "leave_application.list") ? (
            <a
              className="btn btn-icon btn-light btn-hover-info btn-sm ml-3"
              onClick={() => goToViewHoliday(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-info">
                <SVG
              title="View Holidays"
                src={toAbsoluteUrl("/media/svg/icons/Devices/Display2.svg")} />
              </span>
            </a>
              ) : null}
            {PremissionChecker(user, "leave_application.apply_leave_application") ? (
            <a
              className="btn btn-icon btn-light btn-hover-info btn-sm ml-3 mr-2"
              onClick={() => goToApplyLeave(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-info">
                <SVG
              title="Apply Leave"
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")} />
              </span>
            </a>
            ) : null}
          {/* </OverlayTrigger> */}
        {/* ) : null} */}
      </>
    </>
  );
}

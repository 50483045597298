import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import { baseUrl } from '../../../services/config'
import { Button, message, Space } from 'antd';
import { SendNotifaction } from '../../../services/SendNotifaction';
const DeleteShift = ({
    HideModal,
    visible,
    shifts,
    reload,
    url,
    method,
    msg,
    title,
    button,
    start_date,
    end_date,
    users,
    openOverlap
}) => {
    const authToken = localStorage.getItem("userToken");
    const [loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();

    const warning = (res) => {
        messageApi.open({
            type: 'warning',
            content: `${res}`,
        });
    };
    const cancelSub = (e) => {
        e.preventDefault()
        setLoading(true)
        const fdata = new FormData();
        for (let i = 0; i < shifts.length; i++) {
            fdata.append(`shift_ids[${i}]`, shifts[i].id);
        }
        fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
                start_date: start_date,
                end_date: end_date
            }),
        }).then(res => res.json())
            .then((data) => {
                if (data.metadata.success) {
                    reload()
                    if(title=='Publish'){
                        const idArray = [];
                        shifts.map((shi)=>{
                            if (!idArray.includes(shi.user_id)) {
                                idArray.push(shi.user_id);
                              } else {
                              }
                        })
                        
                        idArray.map((id)=>{
                            SendNotifaction(
                                "Publish",
                               `Your Shifts is published from  ${start_date} to ${end_date}`,
                                  id,
                                "",
                              );
                        })
                    
                    }
                    toast.success(data.metadata.message)
                    setLoading(false)
                    HideModal()
                } else if (!data.metadata.success) {
                    setLoading(false)
                    HideModal()

                    if (toString.call(data.metadata.message) == "[object Object]") {
                        const errorMessageArray = Object.values(
                          data.metadata.message
                        ).flatMap((messages) => messages);
                        errorMessageArray.forEach((message) =>{
                            if(message=="Sorry you can not publish shifts, because there are some overlapping shifts."){
                                reload()
                                openOverlap()
                              }
                            toast.error(message)});
                      } else if (toString.call(data.metadata.message) == "[object Array]") {
                        Object.values(data.metadata.message).map((msg) => {
                            if(msg=="Sorry you can not publish shifts, because there are some overlapping shifts."){
                                reload()
                                openOverlap()
                              }
                          toast.error(msg);
                        });
                      } else {
                        if(data.metadata.message=="Sorry you can not publish shifts, because there are some overlapping shifts."){
                            reload()
                            openOverlap()
                          }
                        toast.error(data.metadata.message);
                      }
                } else {
                    setLoading(false)
                    toast.error('Unknown error :(')
                }
            }).catch(err => {
                setLoading(false)
            })
    }



    return (
        <>
            {contextHolder}
            <Modal
                centered
                show={visible}>
                <Modal.Header>
                    <Modal.Title>{title ? title : 'Delete'}</Modal.Title>
                </Modal.Header>
                {
                    loading ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                            <Spinner animation='border' color='green' />
                        </div>
                        :
                        <>
                            <Modal.Body>
                                {
                                    msg ? msg : 'Are you sure you want to Delete?'
                                }

                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    onClick={HideModal}
                                    className='btn btn-danger'>
                                    Cancel
                                </button>

                                <button
                                    onClick={cancelSub}
                                    className='btn btn-primary'>
                                    {button ? button : 'Delete'}
                                </button>
                            </Modal.Footer>
                        </>
                }
            </Modal>
        </>
    )
}

export default DeleteShift
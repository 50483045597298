
const INITIAL_STATE = {
    edges: [],
    roles: [],
};

const RoleReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ROLES':
            return {
                ...state,
                edges: action.payload.edges,
                roles: action.payload.roles,
            };
        default:
            return state;
    }
};

export default RoleReducer;
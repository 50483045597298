import React from 'react'
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls'
import './style.css'
import {useHistory,useLocation} from 'react-router-dom'
import { Empty } from 'antd'
import {
    PlusOutlined,
    ArrowRightOutlined,
    CloseOutlined,
    CheckOutlined,
  } from "@ant-design/icons";

const ViewDvla = () => {
    const location = useLocation();
    const data = location.state;
    console.log(data,"heher")
  return (
    <Card>

    <>

      <CardHeader title={`Endorsements`}>

      </CardHeader>
      <CardBody>
       
   <div className='row'>
 {
    data?.endorsements.length>0 ?
    data?.endorsements.map((item)=>(
        <div className='col-md-4'>
        <div class="cardEndor">
        <div class="ribbon-pop">{item.code}</div>
        <span
          className={`label label-lg label-light-danger label-inline mypoints`}
        >
          {item.points}
        </span>
       <div className='row'>
        <div className='col-md-12'>
        <div className='d-flex pcontainer'>
            <p>Conviction Date:- &nbsp;</p>
            <p><b>{item.conviction_date}</b></p>
        </div>
        </div>
        <div className='col-md-12'>
        <div className='d-flex pcontainer'>
            <p>Offence Date:- &nbsp;</p>
            <p><b>{item.offence_date}</b></p>
        </div>
        </div>
        <div className='col-md-12'>
        <div className='d-flex pcontainer'>
            <p>Disqualify:- &nbsp;</p>
            <p><b>
            {item.is_disqualify =='true' ? (
                                  <CheckOutlined
                                    style={{
                                      position: "relative",
                                      color: "green",
                                      bottom: "3px",
                                    }}
                                  />
                                ) : (
                                  <CloseOutlined
                                    style={{
                                      position: "relative",
                                      color: "red",
                                      bottom: "3px",
                                    }}
                                  />
                                )}
                </b></p>
        </div>
        </div>
       {item.is_disqualify=='true' &&  <div className='col-md-12'>
        <div className='d-flex pcontainer'>
            <p>Disqualify End Date:- &nbsp;</p>
            <p><b>{item.disqualify_end_date}</b></p>
        </div>
        </div>}

       </div>
        </div>
      </div>
    ))

  
  :
  <div className='col-md-12'>
    <Empty/>
  </div>
 }
   </div>

      </CardBody>
    </>
  </Card>
  )
}

export default ViewDvla
import React from "react";
import { useField, useFormikContext } from "formik";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["ant-input"]; // Use Ant Design's input class

  if (touched && errors) {
    classes.push("ant-input-invalid");
  }

  if (touched && !errors) {
    classes.push("ant-input-valid");
  }

  return classes.join(" ");
};

export function AntDatePicker({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);

  return (
    <>
      {/* {props.label && <label>{props.label}</label>} */}
      <DatePicker
        autoComplete="off" // Ant Design's DatePicker does not use 'autocomplete'
        format="DD/MM/YYYY" // Ant Design format prop
        placeholder={field.placeholderText}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        value={field.value ? dayjs(field.value) : null} // Use dayjs to handle date values
        onChange={(val) => {
          console.log("new", val);
          setFieldValue(field.name, val ? val.format("YYYY-MM-DD") : null); // Format the value to match your form's expected format
        }}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="ant-form-explain text-danger">
          {errors[field.name].toString()}
        </div>
      ) : (
        <div className="ant-form-explain">
          {/* Please enter <b>{props.label}</b> in 'DD/MM/YYYY' format */}
        </div>
      )}
    </>
  );
}

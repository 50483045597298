import React from 'react'
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import { useState } from 'react';
import { baseUrl } from '../../../services/config';
import { Modal } from 'antd';
import { toast } from 'react-hot-toast';
import { Input } from '../../../_metronic/_partials/controls';
import { useEffect } from 'react';


const UpdateStaffModal = ({
    selectedObject,
    open,
    onHide,
    reload,
    name
}) => {

    const [loading, setLoading] = useState(false);
    const [icon, setIcon] = useState(null);
    const [iconPreview, setIconPreview] = useState(null); // New state for preview

    const authtoken = localStorage.getItem("userToken");

    useEffect(() => {
        formik.setValues({
            name: name
        });
        if (selectedObject?.icon?.url) {
            setIconPreview(selectedObject.icon.url);
        }else{
            setIconPreview(null);
            setIcon(null)
        }
    }, [name, selectedObject]);

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const initialValues = {
        name: name,
    };

    const LoginSchema = Yup.object().shape({
        name: Yup.string()
            .required('Group Name is Required'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            enableLoading();
            let formData = new FormData();
            formData.append('name', values.name);
            if (icon) {
                formData.append('icon', icon);
            }
            fetch(`${baseUrl}/mgmt/staff-areas/${selectedObject.id}?_method=PUT`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                },
                body: formData
            }).then(res => res.json())
                .then((data) => {
                    if (data.metadata.success) {
                        toast.success(data.metadata.message);
                        disableLoading();
                        reload();
                        onHide();
                        resetForm();
                        setIcon(null);
                        setIconPreview(null);
                document.getElementById('icon').value = ''
                    } else if (!data.metadata.success) {
                        Object.values(data.metadata.message).map(msg => {
                            toast.error(msg);
                        });
                        disableLoading();
                    } else {
                        toast.error('unknown error!');
                        disableLoading();
                    }
                }).catch(err => {
                    disableLoading();
                    console.log(err);
                });
        },
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    return (
        <Modal
            title={`Update ${selectedObject.name} Area`}
            centered
            open={open}
            okText={"Update"}
            onOk={() => formik.handleSubmit()}
            cancelButtonProps={{ style: { backgroundColor: "red", color: "white", border: "none" } }}
            confirmLoading={loading}
            onCancel={() => {
                onHide();
                document.getElementById('icon').value = ''
                setIcon(null);
        setIconPreview(null);
            }}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Group Name"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "name"
                        )}`}
                        name="name"
                        {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.name}</div>
                        </div>
                    ) : null}
                </div>
                <div className='col-md-12 col-sm-12 col-lg-12'>
                    <div className="form-group">
                        <label htmlFor="link"><b>Icon</b></label>
                        <input
                            id="icon"
                            name="icon"
                            type="file"
                            accept="image/*"
                            className='form-control'
                            onChange={(event) => {
                                const file = event.target.files[0];
                                setIcon(file);
                                setIconPreview(URL.createObjectURL(file)); // Set preview
                            }}
                        />
                    </div>
                </div>
                {iconPreview && (
                    <div className='col-md-12'>
                        <img src={iconPreview} alt='folderIcons' style={{ height: "100px" }} />
                    </div>
                )}
            </form>
        </Modal>
    )
}

export default UpdateStaffModal;

import { Avatar, Card as Carder, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls'
import './Clock.css'
import { ClockCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../services/config';
import { Spinner, Table } from 'react-bootstrap';
import { Checkbox, DatePicker } from 'antd';
import { toast } from "react-hot-toast";
import moment from "moment";


const { RangePicker } = DatePicker;

const ClockInOut = () => {

  const authtoken = localStorage.getItem("userToken");
  const [isLoading, setisLoading] = useState(false)
  const [shiftDate, setShiftDate] = useState("")

  const [searchDate, setsearchDate] = useState([])

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });
  const classes = useStyles();
  const getColor = () => {
    let color = ''

    color = Math.floor(Math.random() * 16777215).toString(16)
    return color
  }

  const [usersList, setUsersList] = useState([])


  useEffect(() => {
    getUsers(`${baseUrl}/mgmt/attendance-report`)
  }, [])




  const getUsers = (url) => {

    setisLoading(true)
    fetch(url, {
      headers: {
        Authorization: `Bearer ${authtoken}`
      }
    }).then(res => res.json())
      .then((data) => {
        if(data.metadata.success){
          let newUsers = data.payload.data
          data.payload.data.map((userone, userIndex) => {
            userone["color"] = Math.floor(Math.random() * 16777215).toString(16);
            userone["checked"] = false;
            newUsers[userIndex] = userone;
          });
          setUsersList(newUsers)
          setisLoading(false)
          console.log("description", newUsers)
        }else if(!data.metadata.success){
            Object.values(data.metadata.message).map(msg=>{
              toast.error(msg)
            })
          setisLoading(false)

        }


      }).catch(err => {
        console.log(err)
        setisLoading(false)

      })
  }



  const getHourseToString = (sT, eT) => {

    const sTime = moment(sT);
    const eTime = moment(eT);

    const duration = moment.duration(eTime.diff(sTime));
    const formattedDuration = moment
      .utc(duration.asMilliseconds())
      .format("HH:mm [hours]");
    return formattedDuration;
  };



  const getOverTime = (totalHours, regularHours) => {


    let result = 0
    const regularDuration = moment.duration(regularHours);
    const totalDuration = moment.duration(totalHours);

    const overtimeDuration = totalDuration.subtract(regularDuration);
    const overtimeHours = overtimeDuration.asHours();

    result = overtimeHours
    return result
  }

  
  const dateFiltration = (date, dateString) => {
    const sd1 = dateString[0]
    const ed1 = dateString[1]
    let url = `${baseUrl}/mgmt/attendance-report?start_date=${sd1}&end_date=${ed1}`
    getUsers(url)
  };


  return (
    <>
      <Card>
        <CardHeader title="Clock in/out">
          <CardHeaderToolbar>

          </CardHeaderToolbar>
        </CardHeader>

        <CardBody>
          <div className='row'>
            <div className='col-md-3'>
              <label><strong>Filter</strong></label>
              <RangePicker  onChange={dateFiltration} />
            </div>
          </div>
          {isLoading && <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}

          >
            <Spinner animation='border' />
          </div>}

          <div id="tableboot">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  {/*<th>*/}
                  {/*  <Checkbox ></Checkbox> */}
                  {/*</th>*/}
                  <th className="text-start">First name</th>
                  <th className="text-start">Job</th>
                  <th className="text-start">Clock in</th>
                  <th className="text-start">Clock out</th>
                  <th className="text-start">Total Hours</th>
                  <th className="text-start">Regulrar Hours</th>
                  <th className="text-start">Overtime</th>
                  <th className="text-start">Paid time off</th>
                </tr>
              </thead>

              <tbody>
                {
                  usersList && usersList.map((user) => (

                    <tr key={user.id} id="clockinrow">
                      {/*<td className='starttworows' style={{ textAlign: "start", }}>*/}
                      {/*  <Checkbox ></Checkbox>*/}
                      {/*</td>*/}
                      <td
                        style={{ color: "black" }}
                        className="text-start d-flex starttworows"
                      >
                        <Avatar style={{ backgroundColor: `#${user?.color}`, height: "30px", width: "30px" }} alt={user?.first_name?.toUpperCase()} src="#" />
                        <p style={{ margin: "5px", fontSize: "1.2rem" }}>{user.first_name}</p>
                      </td>
                      <td
                        style={{ color: "black" }}

                      >
                        {
                          user.rota_shifts.map((shift) => (
                            <div key={shift.id}>
                              <span style={{textAlign:'justify'}}>{shift.title}</span>
                              {/* {
                                shift.attendance.map(atten => (
                                  <>
                                    <span>{atten.action} at {atten.time}</span>
                                    <span>{atten.action} at {atten.time}</span>
                                  </>
                                ))
                              } */}

                            </div>
                          ))
                        }
                      </td>
                      <td
                        style={{ color: "black",textAlign:'justify' }}

                      >
                        {
                          user.rota_shifts.map((shift) => (
                            <div key={shift.id}>
                              {
                                shift.full_day ?
                                  'All day'
                                  :
                                  shift.attendance[0]?.action + " " + shift.attendance[0]?.time
                              }
                              {/* {
                                shift.attendance.map(atten => (
                                  <>
                                    <span>{atten.action} at {atten.time}</span>

                                  </>
                                ))
                              } */}

                            </div>
                          ))
                        }

                      </td>

                      <td
                          style={{ color: "black",textAlign:'justify' }}

                      >
                        {
                          user.rota_shifts.map((shift) => (
                            <div key={shift.id}>
                              {
                                shift.full_day ?
                                  'All day'
                                  :
                                  shift.attendance[1]?.action + " " + shift.attendance[1]?.time
                              }
                            </div>
                          ))
                        }

                      </td>
                      <td
                          style={{ color: "black",textAlign:'justify' }}

                      >
                        {
                          user.rota_shifts.map((shift) => (
                            <div key={shift.id}>
                              {
                                shift.full_day ?
                                  'All day'
                                  :
                                  getHourseToString(`${shift.date}T${shift.attendance[0]?.time}`, `${shift.date}T${shift.attendance[1]?.time}`)

                              }
                            </div>
                          ))
                        }


                      </td>
                      <td
                          style={{ color: "black",textAlign:'justify' }}

                      >
                        {
                          user.rota_shifts.map((shift) => (
                            <div key={shift.id}>
                              {
                                shift.full_day ?
                                  'All day'
                                  :
                                  getHourseToString(`${shift.date}T${shift.start_time}`, `${shift.date}T${shift.end_time}`)

                              }
                            </div>
                          ))
                        }

                      </td>
                      <td
                          style={{ color: "black",textAlign:'justify' }}

                      >
                        {
                          user.rota_shifts.map((shift) => (
                            <div key={shift.id}>
                              {
                                shift.full_day ?
                                  'All day'
                                  :
                                  getOverTime(getHourseToString(`${shift.date}T${shift.attendance[0]?.time}`, `${shift.date}T${shift.attendance[1]?.time}`), getHourseToString(`${shift.date}T${shift.start_time}`, `${shift.date}T${shift.end_time}`))
                              }
                            </div>
                          ))
                        }

                      </td>
                      <td
                          style={{ color: "black",textAlign:'justify' }}

                      >
                        -

                      </td>




                    </tr>
                  ))
                }

              </tbody>
            </Table>
          </div>

          {/* <div className='row'>



            {
              usersList && usersList.map((user) => (
                <div key={user.id} className='col-md-4 mt-3'>
                  <Link to={`/In-Out-details/${user.id}/${user.username}`}>
                    <Carder className="usersCard" variant="outlined">
                      <CardContent className='text-center'>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <Avatar style={{ backgroundColor: `#${getColor()}`, height: "60px", width: "66px" }} alt={"A"} src="#" />
                        </div>

                        <h5 style={{ paddingTop: "10px" }}>
                          {user.username}
                        </h5>
                        <div className='d-flex justify-content-center'>
                          <ClockCircleOutlined style={{ marginTop: "3px", marginRight: "3px" }} />
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Clock In/Out
                          </Typography>
                        </div>
                      </CardContent>

                    </Carder>
                  </Link>
                </div>
              ))

            }

          </div> */}
        </CardBody>
      </Card>
    </>
  )
}

export default ClockInOut

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./myDashboard.css";
import { MailOutlined } from "@ant-design/icons";
import { baseUrl } from "../../../services/config";
import { useEffect } from "react";
import { UserSwitchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import AllPendingLeaves from "../AllPendingLeaves/AllPendingLeaves";
import TodayShifts from "../../components/TodayShifts/TodayShifts";
import { timeArray } from "../../components/RotaCalendarMobi/utilts";
import Slider from "react-slick";
import { Paper } from "@material-ui/core";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { Empty, Spin } from "antd";
const MyDashboard = () => {
  const [users, setUsers] = useState(0);
  const [holidayGroups, setHolidayGroups] = useState(0);
  const [groupList, setGroupList] = useState(0);
  const [allRotaGroups, setAllRotaGroups] = useState([]);
  const [departmentList, setDepartmentList] = useState(0);
  const [currentActive, setCurrentActive] = useState(0);
  const [pendingList, setPendingList] = useState(0);
  const [allTodayShifts, setAllTodayShifts] = useState([]);
  const [allHoliDays, setAllHoliDays] = useState([]);
  const [holidayShifts, setHolidayShifts] = useState(undefined);
  const [loadingForShifts, setLoadingForShifts] = useState(false);
  const [loading, setLoading] = useState(false);
  const Ruser = useSelector((state) => state.user.user);
  const [modal, setModal] = useState(true);
  let user;
  if (localStorage.getItem("userDetails")) {
    user = JSON.parse(localStorage.getItem("userDetails"));
  }
  function openMailbox() {
    window.open(`https://${Ruser.email}`, "_blank");
  }
  const authtoken = localStorage.getItem("userToken");

  useEffect(() => {
    getDashboardData();
    getRotaGroups();
  }, []);
  const getRotaGroups = () => {
    fetch(`${baseUrl}/mgmt/rotas`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data);
          setAllRotaGroups(data.payload);
          getRotaShifts(data.payload[0].id);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };
  const getRotaShifts = (id) => {
    setLoadingForShifts(true);
    fetch(`${baseUrl}/mgmt/today-rota/${id}/shifts`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) =>
        res.json().then((data) => {
          console.log(data, "here is the shifters");
          if (data.metadata) {
            if (data.metadata.success) {
              if (data.payload.shifts_data.length > 0) {
                converter(data?.payload?.shifts_data);
                setLoadingForShifts(false);
              }
              if (data.payload.holiday_data.length > 0) {
                data.payload.holiday_data.forEach((item) => {
                  item["color"] = Math.floor(Math.random() * 16777215).toString(
                    16
                  );
                });
                setAllHoliDays(data.payload.holiday_data);
                setLoadingForShifts(false);
              }
            } else {
              setLoadingForShifts(false);
            }
          }
        })
      )
      .catch((err) => {
        setLoadingForShifts(false);
        console.log(err);
      });
  };

  const getDashboardData = () => {
    setLoading(true);
    fetch(`${baseUrl}/mgmt/dashboard-analytics`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // converter(data?.payload?.today_shifts);
        setUsers(data?.payload?.total_users);
        setHolidayGroups(data?.payload?.total_holiday_groups);
        setGroupList(data?.payload?.total_rota_groups);
        setDepartmentList(data?.payload?.total_departments);
        setPendingList(data?.payload?.total_pending_leaves);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  function removeSeconds(timeStr) {
    const response = timeArray.find((item) => item.value == timeStr);
    if (response == undefined) {
      return timeStr;
    } else {
      return response.label.toUpperCase();
    }
    //  return timeStr.substring(0, 5);
  }
  const converter = (arr) => {
    if (arr.length > 0) {
      arr.sort((a, b) => b.hide_time - a.hide_time);
      const responseGroups = [];
      const groupMap = {};
      arr.forEach((item) => {
        const { shift_title, start_time, hide_time, holiday } = item;
        const groupKey = holiday
          ? "Holiday"
          : `${shift_title}_${start_time}_${hide_time}`;

        if (!groupMap[groupKey]) {
          const groupTitle = holiday ? "Holiday" : shift_title;
          groupMap[groupKey] = {
            isHoliday: holiday,
            title: groupTitle,
            time: removeSeconds(start_time),
            hide_time: hide_time,
            users: [],
          };
          responseGroups.push(groupMap[groupKey]);
        }
        groupMap[groupKey].users.push({
          ...item,
          color: Math.floor(Math.random() * 16777215).toString(16),
        });
      });
      const allShifts = responseGroups.filter((item) => !item.isHoliday);
      const allHolidayShifts = responseGroups.find((item) => item.isHoliday);
      if (allHolidayShifts) {
        allHolidayShifts.users.sort((a, b) => a.hide_time - b.hide_time);
      }
      console.log(allShifts, "making dumps ===>>>>>>>>");
      console.log(allHolidayShifts, "making dumps ===>>>>>>>>");
      allShifts.sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return a.value - b.value;
      });
      setAllTodayShifts(allShifts);
      setHolidayShifts(allHolidayShifts);
    }
  };

  const handlePrevious = () => {
    if (currentActive === 0 || loadingForShifts) {
      return;
    }
    setAllTodayShifts([]);
    setHolidayShifts(undefined);
    getRotaShifts(allRotaGroups[currentActive - 1].id);
    setCurrentActive(currentActive - 1);
  };
  const handleNext = () => {
    if (currentActive === allRotaGroups.length - 1 || loadingForShifts) {
      return;
    }
    setAllTodayShifts([]);
    setHolidayShifts(undefined);
    getRotaShifts(allRotaGroups[currentActive + 1].id);
    setCurrentActive(currentActive + 1);
  };
  return (
    <>
      {Ruser?.role[0].name != "admin" && !Ruser?.email_verified ? (
        <>
          <div>
            <Modal
              centered
              animation={true}
              show={modal}
              onHide={() => setModal(false)}
            >
              <Modal.Body className="overlay overlay-block cursor-default alertmodalEmail">
                <div className="modalbackground"></div>
                <div className="mainContainerofmail">
                  <div className="mailicon">
                    <MailOutlined style={{ fontSize: "5rem" }} />
                  </div>
                  <div className="text-center">
                    <h4 className="mainheadingMails">
                      Please Verify Your Email Address
                    </h4>
                    <p>
                      We noticed your email has not been verified. Verifying
                      your email is an essential step to activate your account
                      and enable various features.
                    </p>
                  </div>
                  <div className="pt-2">
                    <p>
                      The <b>Email</b> of your account is <b>{Ruser.email}</b>
                    </p>
                  </div>
                  <div>
                    <button onClick={openMailbox} className="verfybutton">
                      VERIFY YOUR EMAIL ADDRESS
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </>
      ) : (
        <div>
          <div id="">
            <div class="align-items-stretch d-flex justify-content-between counter-cardss">
              <div class="c-dashboardInfo">
                <Link
                  to={Ruser?.role[0].name == "admin" ? "/users" : "/dashboard"}
                >
                  <div class="wrapder">
                    {/* <div>
           <UserSwitchOutlined/>
           </div> */}
                    <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                      Total Users
                    </h4>
                    <svg
                      class="MuiSvgIcon-root-19 counterSvg"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                    </svg>
                    <div>
                      <span class="hind-font caption-12 c-dashboardInfo__count">
                        <CountUp end={users} duration={2} />
                      </span>
                    </div>
                  </div>
                </Link>
              </div>

              <div class="c-dashboardInfo">
                <Link
                  to={
                    Ruser?.role[0].name == "admin"
                      ? "/holidaygroups"
                      : "/dashboard"
                  }
                >
                  <div class="wrapder">
                    <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                      Holiday Groups
                    </h4>
                    <svg
                      class="MuiSvgIcon-root-19 counterSvg"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                    </svg>
                    <div>
                      <span class="hind-font caption-12 c-dashboardInfo__count">
                        <CountUp end={holidayGroups} duration={2} />
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="c-dashboardInfo">
                <Link
                  to={
                    Ruser?.role[0].name == "admin"
                      ? "/rota-groups"
                      : "/dashboard"
                  }
                >
                  <div class="wrapder">
                    <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                      Rota Planners
                    </h4>
                    <svg
                      class="MuiSvgIcon-root-19 counterSvg"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                    </svg>
                    <div>
                      <span class="hind-font caption-12 c-dashboardInfo__count">
                        <CountUp end={groupList} duration={2} />
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="c-dashboardInfo">
                <Link
                  to={
                    Ruser?.role[0].name == "admin" ? "/staff-area" : "dashboard"
                  }
                >
                  <div class="wrapder">
                    <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                      Departments
                    </h4>
                    <svg
                      class="MuiSvgIcon-root-19 counterSvg"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                    </svg>
                    <div>
                      <span class="hind-font caption-12 c-dashboardInfo__count">
                        <CountUp end={departmentList} duration={2} />
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
              <div class="c-dashboardInfo">
                <Link
                  to={
                    Ruser?.role[0].name == "admin"
                      ? "/holidaygroups"
                      : "/dashboard"
                  }
                >
                  <div class="wrapder">
                    <h4 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                      Pending Leaves
                    </h4>
                    <svg
                      class="MuiSvgIcon-root-19 counterSvg"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                    </svg>
                    <div>
                      <span class="hind-font caption-12 c-dashboardInfo__count">
                        <CountUp end={pendingList} duration={2} />
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="pt-5">
              <div className="row">
                <div className="col-md-8">
                  {allRotaGroups.length > 0 && (
                    <div className="c-dashboardInfo">
                      <div class="wrapder-rota">
                        <div className="rotas-slides">
                          <div className="textSlides">
                            <FaArrowLeft
                              className={
                                currentActive === 0 ? "slideDisable" : ""
                              }
                              onClick={handlePrevious}
                            />
                            <p>{allRotaGroups[currentActive]?.name}</p>
                            <FaArrowRight
                              className={
                                currentActive === allRotaGroups.length - 1
                                  ? "slideDisable"
                                  : ""
                              }
                              onClick={handleNext}
                            />
                          </div>
                        </div>
                        {loadingForShifts ? (
                          <div className="todays_loading">
                            <Spin />
                          </div>
                        ) : allTodayShifts.length > 0 ||
                          holidayShifts != undefined ||
                          allHoliDays.length > 0 ? (
                          <div className="row">
                            {allTodayShifts?.map((item) => (
                              <TodayShifts
                                item={item}
                                signleHolidays={false}
                                removeSeconds={removeSeconds}
                              />
                            ))}
                            {holidayShifts != undefined ||
                            allHoliDays.length > 0 ? (
                              <TodayShifts
                                item={holidayShifts}
                                signleHolidays={true}
                                allHoliDays={allHoliDays}
                                removeSeconds={removeSeconds}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <div className="todays_loading">
                            <Empty />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4">
                  <AllPendingLeaves
                  isDashboard={true}
                    reloader={() => {
                      console.log("nothing");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyDashboard;

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { baseUrl } from "../../../../services/config";
import { SendNotifaction } from "../../../../services/SendNotifaction";

export function StatusDropdown({ userId, statusList, item, reload, leaveApps, updateStatus, index }) {

    const authtoken = localStorage.getItem("userToken");

    // const [statusList, setStatusList] = useState([]);
    const [buttonLoading, setbuttonLoading] = useState(false);



    console.log("item in user", item);
   
    const CustomerStatusCssClasses = ["info", "success", "danger", ""];

    const onStatusChange = (status) => {
        setbuttonLoading(true)
        fetch(`${baseUrl}/mgmt/leave-applications/${item.id}?_method=PUT`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authtoken}`,
            },
            body: JSON.stringify({ status: status.value }),

        })
            .then((resp) => {
                resp.json().then((res) => {
                    setbuttonLoading(false)
                    if (res.metadata) {
                        if (res.metadata.success) {
                            toast.success(res.metadata.message);
                            // getLeaveDetails()
                            // updateArray(status)
                            // SendNotifaction('Holiday', `Your holiday ${status.name}`, userId)
                            reload()
                            setbuttonLoading(false)
                            let found = statusList.find(item => item.value == status.value)
                            console.log(found);
                            const extraData = {
                                type: 'update_leave_status',
                                userId: userId,
                                // leave_id:
                            }
                            SendNotifaction('Leave Status Updated', `Your Leave updated to ${found.name}`, userId, '', extraData)
                        }
                        else if (!res.metadata.success) {
                            setbuttonLoading(false)
                            if (toString.call(res.metadata.message) == "[object Object]") {
                              const errorMessageArray = Object.values(
                                res.metadata.message
                              ).flatMap((messages) => messages);
                              errorMessageArray.forEach((message) => toast.error(message));
                            } else if (toString.call(res.metadata.message) == "[object Array]") {
                              Object.values(res.metadata.message).map((msg) => {
                                toast.error(msg);
                              });
                            } else {
                              toast.error(res.metadata.message);
                            }
                          } else {
                            setbuttonLoading(false);
                            toast.error(`Something went wrong`);
                          }
                    }
                });
            }).catch(err => {
                console.log(err)
            })
    }




    const updateArray = (status) => {
        let arr = [...leaveApps]
        leaveApps[index].status = status.name
        updateStatus(arr)

    }










    return (<>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover">
            <li className="navi-header font-weight-bold py-5">
                <span className="font-size-lg">Update Status:</span>
                <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right"
                    title="Update user application status"></i>
            </li>
            <li className="navi-separator mb-3 opacity-70"></li>
            {
                buttonLoading ?
                    <div
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    ><Spinner animation="border" /></div>
                    :

                    statusList && statusList.map((sta, i) => (
                        <li
                            onClick={() => onStatusChange(sta)}
                            style={{ cursor: 'pointer' }} className="navi-item">
                            <div className="navi-link">
                                <span className="navi-text">
                                    <span className={`label label-xl label-inline label-light-${CustomerStatusCssClasses[i]}`}>{sta.name}</span>
                                </span>
                            </div>
                        </li>

                    ))
            }
            {/* <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-text">
                        <span className="label label-xl label-inline label-light-danger">Partner</span>
                    </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-text">
                        <span className="label label-xl label-inline label-light-warning">Suplier</span>
                    </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-text">
                        <span className="label label-xl label-inline label-light-primary">Member</span>
                    </span>
                </a>
            </li>
            <li className="navi-item">
                <a href="#" className="navi-link">
                    <span className="navi-text">
                        <span className="label label-xl label-inline label-light-dark">Staff</span>
                    </span>
                </a>
            </li> */}
            {/* <li className="navi-separator mt-3 opacity-70"></li> */}
            {/* <li className="navi-footer pt-5 pb-4">
                <a className="btn btn-clean font-weight-bold btn-sm" href="#">
                    <i className="ki ki-plus icon-sm"></i>
                    Add new
                </a>
            </li> */}
        </ul>
        {/*end::Navigation*/}
    </>);
}


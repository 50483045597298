import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-hot-toast';
import { baseUrl } from '../../../services/config'
import { Button, message, Space } from 'antd';
const DeleteDraftModal = ({
    HideModal,
    visible,
    id,
    reload,
    url1,
    url2,
    method,
    msg,
    isIMove,
    isDraft,
    moveAllShiftsToDraftOnly,
    mainLoading
}) => {
    const authToken = localStorage.getItem("userToken");
    const [loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();

    const warning = (res) => {
        messageApi.open({
          type: 'warning',
          content: `${res}`,
        });
      };
    const cancelSub = (e,isDraft) => {
        e.preventDefault()
    const url=    isDraft ? url2 : url1
        setLoading(true)
        fetch(url, {
            method,
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                if(isIMove){
                    toast.success("Draft shifts deleted successfully.")
                    setLoading(false)
                    HideModal()
                    reload()
                }else{
                    if (data.metadata.success) {
                        toast.success(data.metadata.message)
                        setLoading(false)
                        HideModal()
                        reload()
                    } else if (!data.metadata.success) {
                        setLoading(false)
                        toast.error(data.metadata.message)
                    } else {
                        setLoading(false)
                        toast.error('Unknown error :(')
                    }
                }        
                
            }).catch(err => {
                setLoading(false)
            })
    }



    return (
        <>
        {contextHolder}
        <Modal
        style={{opacity:mainLoading ? 0.3:1}}
          onHide={HideModal}
            centered
            show={visible}>
            <Modal.Header>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            {
                loading ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                        <Spinner animation='border' color='green' />
                    </div>
                    :
                    <>
                        <Modal.Body>
                            {
                                msg ? msg : 'Are you sure you want to Delete?'
                            }

                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={HideModal}
                                className='btn btn-danger'>
                                Close
                            </button>

                            <button
                                onClick={(e)=>{
                                    if (isDraft == false) {
                                        moveAllShiftsToDraftOnly()
                                          .then((success) => {
                                            if (success) {
                                                cancelSub(e,true)
                                            } else {
                                            }
                                          })
                                          .catch((error) => {
                                          });
                                      }else{
                                        cancelSub(e,true)
                                      }
                                }
                                }
                                className='btn btn-primary'>
                                Delete
                            </button>
                        </Modal.Footer>
                    </>
            }
        </Modal>
        </>
    )
}

export default DeleteDraftModal
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls'
import { HolidayTypeModel } from '../HolidayTypes/HolidayTypeModel'
import { Modal, Table } from 'react-bootstrap'
import { baseUrl } from '../../../services/config'
import './styles.css'
import GroupHolidays from '../GroupHolidays/GroupHolidays'
const ViewHolidayGroup = () => {

    const params = useParams()

    // console.log(params)

    const { id, groupname } = params


    const authtoken = localStorage.getItem("userToken");
    const [addModal, setaddModal] = useState(false);
    const [deleteModal, setdeleteModal] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [editModal, seteditModal] = useState(false);
    const [loading, setloading] = useState(false)
    const [groupList, setgroupList] = useState([])

    const [selectedId, setSelectedId] = useState('')


    useEffect(() => {
        getInfo()
    }, [])



    const getInfo = () => {
        fetch(`${baseUrl}/mgmt/leave-groups`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authtoken}`,

            }
        }).then(res => res.json())
            .then((data) => {
                setgroupList(data.payload)
            }).catch(err => {
                console.log(err)
            })
    }




    return (
        <>
            {/* 1st group */}
            <div>
                <GroupHolidays reload={true} groupname={groupname} id={id} />

                {/* <div className='col-md-6'>
                    <label>Select Next Group</label>
                    <select
                        onChange={(e) => setSelectedId(e.target.value)}
                        className='form-control'
                    >
                        <option value={''}>Select Group</option>
                        {
                            groupList.map(group => (

                                group.id !== id &&
                                <option value={group.id}>{group.name}</option>


                            ))
                        }
                    </select>
                </div> */}
                {/* {selectedId && (
                    <GroupHolidays reload={selectedId !== id} groupname={groupname} id={selectedId} />
                )} */}


            </div>
        </>
    )
}

export default ViewHolidayGroup
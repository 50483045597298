import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import "./styles.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../../app/modules/ECommerce/pages/customers/CustomersUIHelpers";
import { Pagination } from "../../../../src/_metronic/_partials/controls";
import { Modal, Spinner } from "react-bootstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../../modules/ECommerce/pages/customers/CustomersUIContext";
import { baseUrl } from "../../../services/config";
import AddUser from "./AddUser";
import { toast } from "react-hot-toast";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ResetModel from "../../components/ResetModel/ResetModel";
import { Checkbox, Divider } from "antd";
import { values } from "lodash";
import DeleteUser from "../../components/DeleteUser/DeleteUser";
import "./styles.css";
export default function UsersList() {
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };

  // temp
  const user = useSelector((state) => state.user.user);
  console.log(user);
  // end
  const authtoken = localStorage.getItem("userToken");

  const history = useHistory();
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [addModal, setaddModal] = useState(false);
  const [addpermissionModal, setaddpermissionModal] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [actionsLoading, setactionsLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [loading, setloading] = useState(false);
  const [editItem, setEditItem] = useState({});
  const customersUIContext = useCustomersUIContext();
  const [SelectedId, setSelectedId] = useState("");
  const [selectedIdForPattren, setSelectedIdForPattren] = useState("");
  const [usernameSearch, setusernameSearch] = useState("");
  const [emailsearch, setemailsearch] = useState("");
  const [role_name, setrole_name] = useState("");
  const [selectedObj, setSelectedObj] = useState({});
  const [status, setStatus] = useState(false);
  const [resetModel, setresetModel] = useState(false);
  const [list, setList] = useState([]);
  const [myRoleIndexArray, setMyRoleIndexArray] = useState([]);
  const [workingHours, setWorkingHours] = useState("");

  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const resetstate = () => {
    setWorkingHours("");
    setSelectedDays("");
    setSelectedDays2("");
  };

  const [weekDays, setWeekDays] = useState(daysOfWeek);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedDays2, setSelectedDays2] = useState([]);
  const [workPattern, setWorkPattern] = useState([]);
  const [dayValues, setDayValues] = useState({});
  const [dayValues2, setDayValues2] = useState({});

  const handleCheckboxChange = (dayName) => {
    console.log(dayName);
    if (selectedDays.includes(dayName)) {
      setSelectedDays(selectedDays.filter((day) => day !== dayName));
      setDayValues({ ...dayValues, [dayName]: 0 });
    } else {
      setSelectedDays([...selectedDays, dayName]);
      setDayValues({ ...dayValues, [dayName]: 1 });
    }
  };
  const handleCheckboxChange2 = (dayName) => {
    console.log(dayName);
    if (selectedDays2.includes(dayName)) {
      setSelectedDays2(selectedDays2.filter((day) => day !== dayName));
      setDayValues({ ...dayValues, [dayName]: 0 });
    } else {
      setSelectedDays2([...selectedDays2, dayName]);
      setDayValues({ ...dayValues, [dayName]: 1 });
    }
  };

  useEffect(() => {
    getAllBrands();
  }, []);

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getRoles();
  }, []);

  const attachSubmit = () => {};

  const getRoles = () => {
    fetch(`${baseUrl}/mgmt/roles`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // let arr = []

        // data.payload.forEach(element => {
        //     arr.push({
        //         id: element.id,
        //         label: element.name,
        //         value: element.name
        //     })
        // });
        setRoles(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const dateConverter = (originalDateStr) => {
    if (originalDateStr) {
      const date = new Date(originalDateStr);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const year = date.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;

      return formattedDate;
    }
  };
  const getAllBrands = () => {
    setloading(true);
    fetch(`${baseUrl}/mgmt/users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false);
        console.log("user List", data);
        const WorkPatterns = data.payload.map((user) => user.work_pattern);
        console.log(WorkPatterns);
        setWorkPattern(WorkPatterns);

        const tempArray = data.payload.map((item, index) => ({
          ...item,
          sr_no: index + 1,
          work_pattern: item.work_pattern,
          // employment_start_date: dateConverter(item.employment_start_date),
          // employment_end_date: dateConverter(item.employment_end_date),
        }));
        setBrands(tempArray);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
  console.log(workPattern);
  const columns = [
    {
      dataField: "sr_no",
      text: "Sr.no",
      // sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "username",
      text: "Username",
      // sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "first_name",
      text: "Firstname",
      // sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      // sort: true,
      // sortCaret: sortCaret,
    },

    {
      dataField: "email",
      text: "Email",
      // sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "contact",
      text: "Contact number ",
      // sort: true,
      // sortCaret: sortCaret,
    },
    // {
    //   dataField: "address",
    //   text: "Home address",
    //   sort: true,

    // },
    // {
    //   dataField: "emergency_contact",
    //   text: "Emergency contact info",
    //   // sort: true,
    //   // sortCaret: sortCaret,
    // },
    // {
    //   dataField: "employment_start_date",
    //   text: "Employment start date",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },
    {
      dataField: "role[0].name",
      text: "Role",
      // sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "action",
      text: "Actions",

      formatter: columnFormatters.UserFormatter,
      formatExtraData: {
        user: user,

        openEditCustomerDialog: (item) => openEdit(item),
        goToViewHoliday: (item) => goToViewHolidayPage(item),
        goToApplyLeave: (item) => goToApplyLeave(item),
        openDeleteCustomerDialog: (id) => {
          setdeleteModal(true);
          setSelectedId(id);
        },
        resetPassword: (item) => resetPasswordModel(item),
        // gotopermissionmodal: (row) => {
        //   const weekdaysWithOneValue = weekDays.filter(weekday => row.work_pattern[weekday] === 1);
        //   console.log("index", weekdaysWithOneValue)
        //   console.log("selected user", row)
        //   setSelectedDays(weekdaysWithOneValue)
        //   setWorkingHours(row?.work_pattern?.total_weekly_working_hours)
        //   setWorkPattern(row)
        //   setaddpermissionModal(true);
        //   setSelectedIdForPattren(row.id);
        //   // setEditItem(item);

        // },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "257px",
      },
    },
  ];

  const getPermissions = () => {
    const dayValues = {
      monday: selectedDays.includes("monday") ? 1 : 0,
      tuesday: selectedDays.includes("tuesday") ? 1 : 0,
      wednesday: selectedDays.includes("wednesday") ? 1 : 0,
      thursday: selectedDays.includes("thursday") ? 1 : 0,
      friday: selectedDays.includes("friday") ? 1 : 0,
      saturday: selectedDays.includes("saturday") ? 1 : 0,
      sunday: selectedDays.includes("sunday") ? 1 : 0,
    };
    const fdata = {
      user_id: selectedIdForPattren,
      total_weekly_working_hours: workingHours,
      ...dayValues,
    };
    setloading(true);
    fetch(`${baseUrl}/mgmt/update-user-work-pattern/${selectedIdForPattren}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(fdata),
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false);
        toast.success(data.metadata.message);
        setaddpermissionModal(false);
        setSelectedIdForPattren("");
        resetstate();
        getAllBrands();
        console.log("data", data.payload);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };
//   function convertDate(dateString) {
//     // Split the input date string into an array [DD, MM, YYYY]
//     const dateParts = dateString.split('-');
    
//     // Rearrange the array elements to [YYYY, MM, DD]
//     const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    
//     return formattedDate;
// }
  const openEdit = (item) => {
    // item.employment_start_date=convertDate(item.employment_start_date)
    console.log("item user side", item);
    setEditItem(item);
    const weekdaysWithOneValue = weekDays.filter(
      (weekday) => item.work_pattern[weekday] == "1"
    );
    const weekdaysWithOneValue2 = weekDays.filter(
      (weekday) => item.leave_pattern[weekday] == "1"
    );
    setSelectedDays(weekdaysWithOneValue);
    setSelectedDays2(weekdaysWithOneValue2);
    setWorkingHours(item?.work_pattern?.total_weekly_working_hours);
    seteditModal(true);
    setaddModal(true);
  };

  const pattnerOpner = () => {
    if (!editModal) {
      setWorkingHours("");
    }
    // setSelectedDays([])
    setaddpermissionModal(true);
  };

  const resetPasswordModel = (item) => {
    setSelectedObj(item);
    setresetModel(true);
  };

  const goToViewHolidayPage = (item) => {
    history.push(`/view-holiday/${item.id}`, item);
  };
  const goToApplyLeave = (item) => {
    history.push(`/apply-user-leaves/${item.id}`, item);
  };

  const saveRole = (values) => {
    setactionsLoading(true);

    const dayValues2 = {
      monday: selectedDays.includes("monday") ? 1 : 0,
      tuesday: selectedDays.includes("tuesday") ? 1 : 0,
      wednesday: selectedDays.includes("wednesday") ? 1 : 0,
      thursday: selectedDays.includes("thursday") ? 1 : 0,
      friday: selectedDays.includes("friday") ? 1 : 0,
      saturday: selectedDays.includes("saturday") ? 1 : 0,
      sunday: selectedDays.includes("sunday") ? 1 : 0,
      leave_pattern_monday: selectedDays2.includes("monday") ? 1 : 0,
      leave_pattern_tuesday: selectedDays2.includes("tuesday") ? 1 : 0,
      leave_pattern_wednesday: selectedDays2.includes("wednesday") ? 1 : 0,
      leave_pattern_thursday: selectedDays2.includes("thursday") ? 1 : 0,
      leave_pattern_friday: selectedDays2.includes("friday") ? 1 : 0,
      leave_pattern_saturday: selectedDays2.includes("saturday") ? 1 : 0,
      leave_pattern_sunday: selectedDays2.includes("sunday") ? 1 : 0,
    };

    console.log(values);
    // console.log(values.employment_start_date.getDate())
    let d = new Date(values.employment_start_date);
    var curr_moth = ("0" + (d.getMonth() + 1)).slice(-2);
    var curr_date = ("0" + d.getDate()).slice(-2);
    let ddd = `${new Date(d).getFullYear()}-${curr_moth}-${curr_date}`;

    let d2 = new Date(values.date_of_birth);
    var curr_moth2 = ("0" + (d2.getMonth() + 1)).slice(-2);
    var curr_date2 = ("0" + d2.getDate()).slice(-2);
    let ddd2 = `${new Date(d2).getFullYear()}-${curr_moth2}-${curr_date2}`;

    const fdata = {
      username: values.username,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      contact: values.contact,
      address: values.address,
      emergency_contact: values.emergency_contact,
      emergency_contact_name: values.emergency_contact_name,
      employment_start_date: ddd,
      role_id: values.role_name,
      dob: ddd2,
      ttc_check: status ? 1 : 0,
      town: values.town,
      postal_code: values.postal_code,
      nin: values.nin,
      // country: values.country,
      // driving_licence_number: values.driving_licence_number,
      // enquiring_companies: values.enquiring_companies,
      // request_purpose: values.request_purpose,
      // fair_processing_declaration: values.fair_processing_declaration,
      // dvla_consent_start_date: values.dvla_consent_start_date,
      // dvla_consent_end_date: values.dvla_consent_end_date,
      password: values.password,
      total_weekly_working_hours: workingHours,
      ...dayValues2,
    };

    console.log(fdata, "this is formdata");
    let url = editModal
      ? `mgmt/users/${editItem.id}?_method=PUT`
      : "mgmt/users";

    fetch(`${baseUrl}/${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(fdata),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setaddModal(false);
          setSelectedDays([]);
          setSelectedDays2([]);
          toast.success(data.metadata.message);
          setactionsLoading(false);
          getAllBrands();
          resetstate();
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
          setactionsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setactionsLoading(false);
      });
  };
  console.log(editItem);
  console.log(selectedDays);

  //updating query params for pagination

  const updatingQueryParams = (page) => {
    console.log(page);
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };

  //end

  const init_values = {
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    address: "",
    emergency_contact: "",
    employment_start_date: "",
    role_name: "",
  };

  const dataSearch = brands.filter((user) => {
    // let text = searchText.toLowerCase();
    // return searchText ? user?.topic?.toLowerCase().includes(text) || user?.section?.toLowerCase().includes(text) || user?.appellant_vs_respondent?.toLowerCase().includes(text) || user?.citations_ref?.toLowerCase().includes(text) : true;
    let text1 = usernameSearch.toLowerCase();
    const topicSearchData = text1
      ? user?.username?.toLowerCase().includes(text1)
      : true;
    let text2 = emailsearch.toLowerCase();
    const appellantSearchData = text2
      ? user?.email?.toLowerCase().includes(text2)
      : true;
    let text3 = role_name.toLowerCase();
    const sectionSearchData = text3
      ? user?.role[0]?.name?.toLowerCase().includes(text3)
      : true;
    // let text4 = citationtext.toLowerCase()
    // const citationSearchData = text4 ? user?.citations_ref?.toLowerCase().includes(text4) : true;
    return (
      topicSearchData && appellantSearchData && sectionSearchData && brands
    );
  });

  const options = {
    custom: true,
    totalSize: dataSearch?.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };
  console.log(list);
  const rowClasses = (row, rowIndex) => {
    return rowIndex % 2 === 0 ? "light-row" : "dark-row";
  };
  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Card>
        <>
          <CardHeader title="Users List">
            <CardHeaderToolbar>
              {PremissionChecker(user, "user.create") ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    seteditModal(false);
                    resetstate();
                    setaddModal(true);
                  }}
                >
                  New User
                </button>
              ) : null}
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <div className="form-group row">
              <div className="col-lg-3">
                <div className="user_list_label_text">
                  <p>Username</p>
                </div>
                <input
                  type="text"
                  className="form-control shadow-light"
                  name="searchText"
                  placeholder="Search by username"
                  value={usernameSearch}
                  onChange={(e) => {
                    setusernameSearch(e.target.value);
                    onPaginationSelectChange();
                  }}
                />
              </div>
              <div className="col-lg-3">
                <div className="user_list_label_text">
                  <p>Email</p>
                </div>
                <input
                  type="text"
                  className="form-control shadow-light"
                  name="searchText"
                  placeholder="Search by email"
                  value={emailsearch}
                  onChange={(e) => {
                    setemailsearch(e.target.value);
                    onPaginationSelectChange();
                  }}
                />
              </div>
              <div className="col-lg-3">
                <div className="user_list_label_text ">
                  <p>Type</p>
                </div>
                <select
                  className="form-control shadow-light"
                  placeholder="Filter by Role"
                  name="type"
                  onChange={(e) => {
                    setrole_name(e.target.value);
                    onPaginationSelectChange();
                  }}
                  value={role_name}
                >
                  <option value="">All</option>
                  {roles.map((role) => (
                    <option key={role.isEdit} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* <CustomersFilter />
            {customersUIProps.ids.length > 0 && <CustomersGrouping />}
            <CustomersTable /> */}
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <>
                    <Pagination
                      updatingQueryParams={updatingQueryParams}
                      onPaginationSelectChange={onPaginationSelectChange}
                      isLoading={loading}
                      paginationProps={paginationProps}
                    >
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table my_table_header_custom table-vertical-center overflow-hidden"
                        bootstrap4
                        keyField="id"
                        rowClasses={rowClasses}
                        headerClasses="black-header"
                        data={dataSearch}
                        columns={columns}
                        defaultSorted={uiHelpers.defaultSorted}
                        {...paginationTableProps}
                        filter={filterFactory()}
                      ></BootstrapTable>
                    </Pagination>
                    {/* )} */}
                  </>
                );
              }}
            </PaginationProvider>
          </CardBody>
        </>
      </Card>
      <Modal size="lg" show={addModal} onHide={() => setaddModal(true)}>
        <Modal.Header>
          <h4>{editModal ? "Edit User" : "Add New User"}</h4>
        </Modal.Header>
        <AddUser
          customer={init_values}
          editItem={editModal ? editItem : ""}
          isEdit={editModal ? true : false}
          saveCustomer={(values) => saveRole(values)}
          status={status}
          setStatus={(bol) => setStatus(bol)}
          onHide={() => setaddModal(false)}
          // onChangeRole={(val) => setrole_name(val)}
          actionsLoading={actionsLoading}
          pattrenOpner={pattnerOpner}
        />
      </Modal>
      <DeleteUser
        HideModal={() => setdeleteModal(false)}
        visible={deleteModal}
        id={SelectedId}
        method="GET"
        reload={getAllBrands}
        // url={`${baseUrl}/mgmt/users/${SelectedId}`}
      />

      <ResetModel
        hideModel={() => setresetModel(false)}
        userObj={selectedObj}
        visible={resetModel}
        reload={getAllBrands}
      />
      <Modal size="md" show={addpermissionModal}>
        <Modal.Header>
        <Modal.Title>Work pattern</Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <label class="form-label">Weekly Working Hours</label>
          <input
            type="number"
            class="form-control"
            name="workingHours"
            value={workingHours}
            onChange={(e) => setWorkingHours(e.target.value)}
          />

          <Divider style={{ marginBottom: "0px" }} />
          <div>
            <>
              <span>
                {weekDays.map((dayName, index) => (
                  <label
                    key={index}
                    style={{
                      display: "inline-block",
                      width: "33%",
                      boxSizing: "border-box",
                      padding: "5px",
                    }}
                  >
                    <Checkbox
                      style={{ paddingRight: "30px", paddingTop: "5px" }}
                      className="givingspace"
                      name="checkbox"
                      checked={selectedDays.includes(dayName)}
                      onChange={() => handleCheckboxChange(dayName)}
                    >
                      {dayName.toLocaleUpperCase()}
                    </Checkbox>
                  </label>
                ))}
              </span>
            </>
          </div>
          <Divider/>
          <h4>Availability Holidays</h4>
          <Divider/>

          <div>
            <>
              <span>
                {weekDays.map((dayName, index) => (
                  <label
                    key={index}
                    style={{
                      display: "inline-block",
                      width: "33%",
                      boxSizing: "border-box",
                      padding: "5px",
                    }}
                  >
                    <Checkbox
                      style={{ paddingRight: "30px", paddingTop: "5px" }}
                      className="givingspace"
                      name="checkbox"
                      checked={selectedDays2.includes(dayName)}
                      onChange={() => handleCheckboxChange2(dayName)}
                    >
                      {dayName.toLocaleUpperCase()}
                    </Checkbox>
                  </label>
                ))}
              </span>
            </>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={() => {
              if (editModal) {
                const weekdaysWithOneValue = weekDays.filter(
                  (weekday) => editItem.work_pattern[weekday] == "1"
                );
                const weekdaysWithOneValue2 = weekDays.filter(
                  (weekday) => editItem.leave_pattern[weekday] == "1"
                );
                setSelectedDays(weekdaysWithOneValue);
                setSelectedDays2(weekdaysWithOneValue2);
                setWorkingHours(
                  editItem?.work_pattern?.total_weekly_working_hours
                );
              } else {
                setSelectedDays([]);
                setSelectedDays2([]);
                setWorkingHours("");
              }
              setSelectedIdForPattren("");
              setaddpermissionModal(false);
            }}
            className="btn btn-danger"
          >
            Cancel
          </button>

          {editModal ? (
            // PremissionChecker(user, "work_pattern.update") ? (
            <button
              disabled={loading}
              onClick={() => {
                if (selectedIdForPattren) {
                  getPermissions();
                } else {
                  setaddpermissionModal(false);
                }
              }}
              className="btn btn-primary"
            >
              {selectedIdForPattren ? "Submit" : "Save"}
            </button>
          ) : (
            // ) : null
            // PremissionChecker(user, "work_pattern.create") ? (
            <button
              disabled={loading}
              onClick={() => {
                if (selectedIdForPattren) {
                  getPermissions();
                } else {
                  setaddpermissionModal(false);
                }
              }}
              className="btn btn-primary"
            >
              {selectedIdForPattren ? "Submit" : "Save"}
            </button>
          )
          // ) : null
          }

          {/* {PremissionChecker(user, "work_pattern.create") ? (
            <button
              disabled={loading}
              onClick={() => {
                if (selectedIdForPattren) {
                  getPermissions()
                } else {
                  setaddpermissionModal(false)
                }
              }
              } className="btn btn-primary">
              {selectedIdForPattren ? "Submit" : "Save"}
            </button>
          ) : null} */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

// const selectRow = {
//   mode: 'checkbox',
//   clickToSelect: true,
//   selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
//     <label className="checkbox checkbox-single">
//       <input

//         type="checkbox"
//         ref={(input) => {
//           if (input) input.indeterminate = indeterminate;
//         }}
//         {...rest}
//       />
//       <span />
//     </label>
//   ),
//   selectionRenderer: ({ mode, ...rest }) => (
//     <input type={mode} {...rest} />
//   )
// };

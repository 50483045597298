import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ProductStatusCssClasses } from '../../modules/ECommerce/pages/products/ProductsUIHelpers';

const UpdateStatus = ({
    list,
    ids,
    show,
    onHide
}) => {



    const selectedProducts = () => {
        const _products = [];
        ids.forEach((id) => {
          const product = list.find((el) => el.id === id);
          if (product) {
            _products.push(product);
          }
        });
        return _products;
      };


    const [isLoading, setisLoading] = useState(false)
    const [status, setStatus] = useState(0);


    const updateStatus = () => {
        // // server request for updateing product by ids
        // dispatch(actions.updateProductsStatus(productsUIProps.ids, status)).then(
        //   () => {
        //     // refresh list after deletion
        //     dispatch(actions.fetchProducts(productsUIProps.queryParams)).then(
        //       () => {
        //         // clear selections list
        //         productsUIProps.setIds([]);
        //         // closing delete modal
        //         onHide();
        //       }
        //     );
        //   }
        // );
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Status has been updated for selected orders
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
                {isLoading && (
                    <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-warning" />
                    </div>
                )}
                <div className="list-timeline list-timeline-skin-light padding-30">
                    <div className="list-timeline-items">
                        {selectedProducts().map((product) => (
                            <div className="list-timeline-item mb-3" key={product.id}>
                                <span className="list-timeline-text">
                                    <span
                                        className={`label label-lg label-light-${ProductStatusCssClasses[product.status]
                                            } label-inline`}
                                        style={{ width: "60px" }}
                                    >
                                        ID: {product.id}
                                    </span>{" "}
                                    <span className="ml-5">
                                        {product.user.first_name},
                                    </span>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="form">
                <div className="form-group">
                    <select
                        className={`form-control ${ProductStatusCssClasses[status]}`}
                        value={status}
                        onChange={(e) => setStatus(+e.target.value)}
                    >
                        <option value="0">Approve</option>
                        <option value="1">Rejected</option>
                    </select>
                </div>
                <div className="form-group">
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={updateStatus}
                        className="btn btn-primary btn-elevate"
                    >
                        Update Status
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdateStatus
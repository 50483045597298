import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './deleteRecure.css'
const DeleteShift = ({open,close,handleDelete, mainLoading = false,muLoading =false}) => {


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
     opacity: mainLoading ? 0.3 : 1,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
     opacity: mainLoading ? 0.3 : 1,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 0, 0),
    width:280,
    borderRadius:"20px"

  },
}));
const classes = useStyles();



  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={()=>close()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <p style={{textAlign:"center"}} id="transition-modal-title"><b>Are You Sure You Want To Delete This Shift?</b></p>
           <div>
           {
            muLoading ?

           <div style={{display:"flex",justifyContent:"center"}}>
           <div class="loader-button-me"></div> 
           </div>:
           <div>
             <button onClick={()=>handleDelete()} className='deleteRecureButton'>Delete This</button> 
           </div>
           }
           </div>
           <div>
           <button onClick={()=>close()} className='deleteRecureButton2'><b>Cancel</b></button>
           </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

export default DeleteShift
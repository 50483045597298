import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { baseUrl } from '../../../services/config';
import * as Yup from "yup";
// import { Input, TextArea } from "../../../_metronic/_partials/controls";
import { Input } from "./../../././../_metronic/_partials/controls";
import {TextArea} from "./../../././../_metronic/_partials/controls/forms/TextArea"
// import firebase, { database } from "firebase";
import { toast } from "react-hot-toast";
// import { serverKey } from "../../../utils/constants";

export default function AddFeed({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  editItem,
  reload,
  users,
  currentUserData
}) {
  const authtoken = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [imageShow, setImageShow] = useState("");
  const [videoShow, setVideoShow] = useState("");
  const [imageEror,setimageError]=useState(false)   
  const [thumnail,setThumbnail]=useState()
  console.log(editItem,"edits")
  const logo = `https://cdn-icons-png.flaticon.com/512/3772/3772243.png`;
  const CustomerEditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    // description: Yup.string().required("Description is required"),
  });
  const editinitialValues = {
    title: editItem.title,
    // description: editItem.description,
  };
  const addinitialValues = {
    title: "",
    // description: "",
  };

  const handleSubmit = (values) => {
    if(!editItem){
      if(image==""){
        setimageError(true)
        return
      }
    }
    const fdata = new FormData();
fdata.append(`title`,values.title);
// fdata.append(`description`,values.description);
if(image){
  fdata.append('file',image);
}
if(thumnail){
  fdata.append('thumbnail',thumnail);
}


    setLoading(true)
    const addUrl='mgmt/feeds'
    const editUrl=`mgmt/feeds/${editItem.id}?_method=PUT`
    fetch(`${baseUrl}/${editItem?editUrl:addUrl}`, {
      method: 'POST',
      
      headers: {
        // 'Accept': 'application/json, text/plain, */*',
        // 'Content-Type': 'application/json',
         Authorization: `Bearer ${authtoken}`,
      },
      body: fdata
    }).then(res => res.json())
      .then((data) => {
        console.log(data)
        setLoading(false)

        if (data.metadata.success) {
          toast.success(data.metadata.message)
          setLoading(false)
          reload()
          onHide()
        } else if (!data.metadata.success) {
          Object.values(data.metadata.success).map((msg) => {
            toast.error(msg)
          setLoading(false)
          })
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      })
  }
 const  previewFunc=async(file)=>{
  const splitter=file?.type.split('/')
  if(splitter[0]=="image"){
    setImageShow(URL.createObjectURL(file));
    setVideoShow("")
  }else{
    const cover = await getVideoCover(file, 1.5);
    // print out the result image blob
    const myFile = new File([cover], "thumbnail", { type: cover.type });
    console.log(myFile, "this is f");
    setThumbnail(myFile)
    setVideoShow(URL.createObjectURL(file));
    setImageShow("")
  }
 }
 function getVideoCover(file, seekTo = 0.0) {
  console.log("getting video cover for file: ", file);
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener("error", (ex) => {
      reject("error when loading video file", ex);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        console.log("video is now paused at %ss.", seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.75 /* quality */
        );
      });
    });
  });
}
console.log(image)
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={editItem != "" ? editinitialValues : addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          // editItem ? editHandler(values) :
           handleSubmit(values);
        }}
      >
        {({ values }) => (
          <>
            <Form className="form form-label-right">
              <Modal.Body className="overlay overlay-block cursor-default pt-0 pb-0">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label>Enter Title</label>
                    <Field
                      component={Input}
                      placeholder="Enter title"
                      value={values.title}
                      name="title"
                    />
                  </div>
                </div>

                {/* <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label>Enter Description</label>
                    <Field
                      style={{ height: "8rem" }}
                      value={values.description}
                      name="description"
                       component={TextArea}
                      placeholder="Enter description"
                    />
                  </div>
                </div> */}

                <div className="form-group row" style={{marginBottom:"0px"}}>
                {imageShow?(
                  <img style={{ height: 135, width: 200,marginTop:"20px" }} src={imageShow} value={image}/>

                   ):
                   videoShow=='' &&  editItem?.file?.type.split('/')[0]=="image"?
                   <img
                       style={{ height: 150, width: 150 }}
                      src={ editItem.file?.url}
                  />:
                  null

                        }          
                {videoShow?(
                  <video src={videoShow} controls height={150} width={200}/>

                   ):
                   imageShow=='' &&  editItem?.file?.type.split('/')[0]=="video"?
                   <video  src={ editItem.file?.url} controls height={150} width={200}/>
                   :null
                        }          
            <label
                    htmlFor="fileImage"
                    style={{
                      width: 121,
                      margin: 20,
                      padding: 10,
                      borderRadius: 5,
                      backgroundColor: "#E6E9EC",
                      cursor: "pointer",
                    }}
                  >
                   Click To Upload
                    <img loading={"lazy"} style={{ width: 100 }} src={logo} />
                 
                  </label>
             
                

                  <input
                    style={{ display: "none" }}
                    type="file"
                    id={`fileImage`}
                    accept="image/*, video/*"
                    onChange={(e) => {
                      setimageError(false)
                      previewFunc(e.target.files[0])
                      setImage(e.target.files[0]);
                    }}
                  />

                  {/* <input type={'file'} onChange={(e) => setImage(e.target.files[0])} /> */}
                </div>
                {imageEror?
                <lable className="text-danger">Image/Video is required</lable>:
                null  
                }
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-danger btn-elevate"
                >
                  Cancel
                </button>
              
                {loading ?
                <div style={{width:"65px",textAlign:"center"}}>
                <Spinner animation="border" />
                                    </div>:
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-primary btn-elevate"

                >
                  {editItem ? 'Update':'Save'}
                </button>}
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

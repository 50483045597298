// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";
 const dummy=[
  {
    id:"1",
    userName:"admin123",
    firstName:"dimmi",
    lastName:"gon",
    email:"fc@gmail.com",
    contact_number:304034343,
    home_adress:"job bolger 12",
    contact_info:"koriander.com",
    start_date:"15 August",
    role:"Super admin"

  },
  {
    id:"2",
    userName:"subroso",
    firstName:"kim",
    lastName:"admin",
    email:"ntmt@gmail.com",
    contact_number:3043445,
    home_adress:"ab block 33",
    contact_info:"kimmi.com",
    start_date:"14 Nov",
    role:"Super admin"

  },
  {
    id:"3",
    userName:"cook",
    firstName:"cookie",
    lastName:"parser",
    email:"fc@gmail.com",
    contact_number:30434344,
    home_adress:"kori ntv 34",
    contact_info:"tb.com",
    start_date:"17 Dec",
    role:"Super admin"

  },
  {
    id:"4",
    userName:"admin123",
    firstName:"super",
    lastName:"admin",
    email:"abc@gmail.com",
    contact_number:304034343,
    home_adress:"ab block 33",
    contact_info:"sdkmask.com",
    start_date:"14 August",
    role:"Super admin"

  },
  {
    id:"5",
    userName:"admin123",
    firstName:"super",
    lastName:"admin",
    email:"abc@gmail.com",
    contact_number:304034343,
    home_adress:"ab block 33",
    contact_info:"sdkmask.com",
    start_date:"14 August",
    role:"Super admin"

  },
  {
    id:"6",
    userName:"Root",
    firstName:"tipal",
    lastName:"Danadar",
    email:"jim@gmail.com",
    contact_number:30405543,
    home_adress:"barsalona 33",
    contact_info:"simmi.com",
    start_date:"5 Jan",
    role:"Super admin"

  }
 ]
export function CustomersTable() {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
    };
  }, [customersUIContext]);

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.customers }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    customersUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "userName",
      text: "Username",
      sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "firstName",
      text: "Firstname",
      sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "lastName",
      text: "Sirname",
      sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "contact_number",
      text: "Contact number ",
      sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "home_adress",
      text: "Home address",
      sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "contact_info",
      text: "Emergency contact info",
      sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "start_date",
      text: "Employment start date",
      sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      // sortCaret: sortCaret,
      headerSortingClasses,
    },
    // {
    //   dataField: "gender",
    //   text: "Gender",
    //   sort: false,
    //   sortCaret: sortCaret,
    // },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.StatusColumnFormatter,
    //   headerSortingClasses,
    // },
    // {
    //   dataField: "type",
    //   text: "Type",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.TypeColumnFormatter,
    // },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
        openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : dummy}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: customersUIProps.ids,
                  setIds: customersUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={dummy} />
                <NoRecordsFoundMessage entities={dummy} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

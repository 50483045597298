import { Paper } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { baseUrl } from '../../../services/config'



const OrderDetail = () => {

    const { id } = useParams()
    const authtoken = localStorage.getItem("userToken");

    const [isLoading, setIsloading] = useState(false)
    const [orderDetails, setOrderDetails] = useState({})


    useEffect(() => {
        getDetails()
    }, [])



    const getDetails = () => {
        setIsloading(true)
        fetch(`${baseUrl}/mgmt/orders/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authtoken}`,
            }
        }).then(res => res.json())
            .then((data) => {
                console.log(data,"this is data");
                setOrderDetails(data.payload)
                setIsloading(false)
            }).catch(err => {
                console.log(err)
                setIsloading(false)

            })
    }

    return (
        <div>
            <Paper className='p-4'>
                {
                    isLoading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Spinner animation='border' />
                    </div>
                }

                <div className='d-flex justify-content-end'>
                    <div className='text-info p-2'>
                        <strong>Order Placed on:</strong> {orderDetails?.date_placed}
                    </div>
                </div>
                <div>
                    <h2 style={{ padding: '33px' }}>Shoot Details</h2>
                    <div className='row m-3'>
                        <div className='col-md-6 d-flex' >
                            <div className='col-md-6'>
                                <strong>Shoot Title</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.shoot_title}
                            </div>
                        </div>
                        <div className='col-md-6 d-flex'>
                            <div className='col-md-6'>
                                <strong>Status</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.status}
                            </div>
                        </div>
                    </div>
                    <div className='row m-3'>
                        <div className='col-md-6  d-flex' >
                            <div className='col-md-6'>
                                <strong>Shoot Start Date</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.shoot_start_date}
                            </div>
                        </div>
                        <div className='col-md-6  d-flex'>
                            <div className='col-md-6'>
                                <strong>Shoot end Date</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.shoot_end_date}
                            </div>
                        </div>
                    </div>
                </div>
          
                <hr />
                {/* billing details */}
                <div>
                    <h2 style={{ padding: '33px' }}>Billing Details</h2>
                    <div className='row m-3'>
                        <div className='col-md-6 d-flex' >
                            <div className='col-md-6'>
                                <strong>Billing Address</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.billing_address}
                            </div>
                        </div>
                        <div className='col-md-6 d-flex'>
                            <div className='col-md-6'>
                                <strong>Billing City</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.billing_city}
                            </div>
                        </div>
                    </div>
                    <div className='row m-3'>
                        <div className='col-md-6  d-flex' >
                            <div className='col-md-6'>
                                <strong>Billing Company</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.billing_company}
                            </div>
                        </div>
                        <div className='col-md-6  d-flex'>
                            <div className='col-md-6'>
                                <strong>Billing Country</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.billing_country}
                            </div>
                        </div>
                    </div>
                    <div className='row m-3'>
                        <div className='col-md-6  d-flex' >
                            <div className='col-md-6'>
                                <strong>Billing Email</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.billing_email}
                            </div>
                        </div>
                        <div className='col-md-6  d-flex'>
                            <div className='col-md-6'>
                                <strong>Billing City</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.billing_city}
                            </div>
                        </div>
                    </div>
                    <div className='row m-3'>
                        <div className='col-md-6  d-flex' >
                            <div className='col-md-6'>
                                <strong>Billing Invoice Ref</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.billing_invoice_ref}
                            </div>
                        </div>
                        <div className='col-md-6  d-flex'>
                            <div className='col-md-6'>
                                <strong>Billing Zip Code</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.billing_zip_code}
                            </div>
                        </div>
                    </div>
                    {/* <div className='row m-3'>
                        <div className='col-md-6  d-flex' >
                            <div className='col-md-6'>
                                <strong>Billing Address</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.billing_address}
                            </div>
                        </div>
                        <div className='col-md-6  d-flex'>
                            <div className='col-md-6'>
                                <strong>Billing City</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.billing_city
                                }
                            </div>
                        </div>
                    </div> */}
                </div>
                <hr />

                <div>
                    <h2 style={{ padding: '33px' }}>Contact Details</h2>
                    <div className='row m-3'>
                        <div className='col-md-6 d-flex' >
                            <div className='col-md-6'>
                                <strong>Contact Email</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.contact_email}
                            </div>
                        </div>
                        <div className='col-md-6 d-flex'>
                            <div className='col-md-6'>
                                <strong>Contact First Name</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.contact_first_name}
                            </div>
                        </div>
                    </div>
                    <div className='row m-3'>
                        <div className='col-md-6  d-flex' >
                            <div className='col-md-6'>
                                <strong>Contact Last Name</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.contact_last_name}
                            </div>
                        </div>
                        <div className='col-md-6  d-flex'>
                            <div className='col-md-6'>
                                <strong>Contact Phone</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.contact_phone}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div>
                    <h2 style={{ padding: '33px' }}>Delivery Details</h2>
                    <div className='row m-3'>
                        <div className='col-md-6 d-flex' >
                            <div className='col-md-6'>
                                <strong>Delivery Address</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.delivery_address}
                            </div>
                        </div>
                        <div className='col-md-6 d-flex'>
                            <div className='col-md-6'>
                                <strong>Delivery City</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.delivery_city}
                            </div>
                        </div>
                    </div>
                    <div className='row m-3'>
                        <div className='col-md-6  d-flex' >
                            <div className='col-md-6'>
                                <strong>Delivery State</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.delivery_state}
                            </div>
                        </div>
                        <div className='col-md-6  d-flex'>
                            <div className='col-md-6'>
                                <strong>Delivery Street Address</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.delivery_street_address}
                            </div>
                        </div>
                    </div>
                    <div className='row m-3'>
                        <div className='col-md-6  d-flex' >
                            <div className='col-md-6'>
                                <strong>Delivery United Kingdom</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.delivery_united_kingdom}
                            </div>
                        </div>
                        <div className='col-md-6  d-flex'>
                            <div className='col-md-6'>
                                <strong>Delivery Zip Code</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.delivery_zip_code}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div>
                    <h2 style={{ padding: '33px' }}>Transport Details</h2>
                    <div className='row m-3'>
                        <div className='col-md-6 d-flex' >
                            <div className='col-md-6'>
                                <strong>Transport Collection Date</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.transport_collection_date}
                            </div>
                        </div>
                        <div className='col-md-6 d-flex'>
                            <div className='col-md-6'>
                                <strong>Transport Collection</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.transport_collection_time}
                            </div>
                        </div>
                    </div>
                    <div className='row m-3'>
                        <div className='col-md-6  d-flex' >
                            <div className='col-md-6'>
                                <strong>Transport Delivery Date</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.transport_delivery_date}
                            </div>
                        </div>
                        <div className='col-md-6  d-flex'>
                            <div className='col-md-6'>
                                <strong>Transport Delivery Date</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.transport_delivery_time}
                            </div>
                        </div>
                    </div>
                    <div className='row m-3'>
                        <div className='col-md-6  d-flex' >
                            <div className='col-md-6'>
                                <strong>Transport Type</strong>
                            </div>
                            <div className='col-md-6'>
                                {orderDetails.transport_type}
                            </div>
                        </div>
                        {/* <div className='col-md-6  d-flex'>
                            <div className='col-md-6'>
                                <strong>Delivery Zip Code</strong>

                            </div>
                            <div className='col-md-6'>
                                {orderDetails.delivery_zip_code}
                            </div>
                        </div> */}
                    </div>
                </div>
             
                <div>
                    <h2 style={{ padding: '33px' }}>Equipments</h2>
                    {
                        orderDetails?.equipment && orderDetails?.equipment.map((equ) => (
                            <div className='row m-3'>
                                <div className='col-md-6 d-flex m-2'>
                                    <div className='col-md-6'>
                                        <strong>Name</strong>
                                    </div>
                                    <div className='col-md-6'>
                                        {equ.name}
                                    </div>
                                </div>
                                {/* <div className='col-md-6 d-flex m-2 '>
                                    <div className='col-md-6'>
                                        <strong>Order Price</strong>

                                    </div>
                                    <div className='col-md-6'>
                                        {equ.order_price}
                                    </div>
                                </div> */}
                                {/* <div className='col-md-6 d-flex m-2'>
                                    <div className='col-md-6'>
                                        <strong>Order Total Amount</strong>

                                    </div>
                                    <div className='col-md-6'>
                                        {equ.order_total_amount}
                                    </div>
                                </div> */}
                                {/* <div className='col-md-6 d-flex m-2'>
                                    <div className='col-md-6'>
                                        <strong>Rental Cost</strong>

                                    </div>
                                    <div className='col-md-6'>
                                        {equ.rental_cost}
                                    </div>
                                </div> */}
                                <div className='col-md-6 d-flex m-2'>
                                    <div className='col-md-6'>
                                        <strong>Order Quantity</strong>

                                    </div>
                                    <div className='col-md-6'>
                                        {equ.order_quantity}
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>
                <div>
                    <h2 style={{ padding: '33px' }}>Accessories</h2>
                    {
                        orderDetails?.accessories && orderDetails?.accessories.map((acc) => (
                            <div className='row m-3'>
                                <div className='col-md-6 d-flex m-2'>
                                    <div className='col-md-6'>
                                        <strong>Name</strong>
                                    </div>
                                    <div className='col-md-6'>
                                        {acc.name}
                                    </div>
                                </div>
                       
                                <div className='col-md-6 d-flex m-2'>
                                    <div className='col-md-6'>
                                        <strong>Order Quantity</strong>

                                    </div>
                                    <div className='col-md-6'>
                                        {acc.order_quantity}
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>

            </Paper>

        </div>
    )
}

export default OrderDetail
import { InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./custommodal.css";
const CustomModal = ({ open, close }) => {
  const [seletedValue, setSelectedValue] = useState("");
  const [daily, setDaily] = useState("");
  const [week,setWeek]=useState("")
  const [monthDays,setMonthdays]=useState([])
  const [collectMonthDays,setCollectMonthdays]=useState([])
  const [collectWeeks,setCollectWeeks]=useState([])
  const options = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
  ];
  const weekly = [
    { label: "M", value: "monday" },
    { label: "T", value: "tuesday" },
    { label: "W", value: "wednesday" },
    { label: "T", value: "thusday" },
    { label: "F", value: "friday" },
    { label: "S", value: "saturday" },
    { label: "S", value: "sunday" },
  ];

  useEffect(()=>{
    const first31NumbersArray = [];

    for (let i = 1; i <= 31; i++) {
      const numberObject = {
        label: i.toString(),
        value: i.toString()
      };
      first31NumbersArray.push(numberObject);
    }
    setMonthdays(first31NumbersArray)
  },[])

  const handleWeekClick=(resultent)=>{
    if(collectWeeks.includes(resultent)){
      const remover=  collectWeeks.filter((item)=>item != resultent)
      setCollectWeeks(remover)
      }else{
        setCollectWeeks((prev)=>[...prev,resultent])
      }
  }
  const handleMonthClick=(resultent)=>{
      if(collectMonthDays.includes(resultent)){
      const remover=  collectMonthDays.filter((item)=>item != resultent)
      setCollectMonthdays(remover)
      }else{
        setCollectMonthdays((prev)=>[...prev,resultent])
      }
  }

  function numberToOrdinal(number) {
    if (typeof number !== 'number' || isNaN(number)) {
      return 'Invalid input';
    }
  
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;
  
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return number + 'th';
    }
  
    switch (lastDigit) {
      case 1:
        return number + 'st';
      case 2:
        return number + 'nd';
      case 3:
        return number + 'rd';
      default:
        return number + 'th';
    }
  }
  return (
    <>
      <Modal
        open={open}
        onCancel={close}
        onOk={close}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { backgroundColor: "#151515" } }}
      >
        <div className="row">
          <div className="col-md-12">
            <label>
              <b>Frequency</b>
            </label>
            <Select
              onChange={(e) => setSelectedValue(e.value)}
              options={options}
            />
          </div>
          {seletedValue == "daily" && (
            <div className="col-md-12 mt-2">
              <label>
                <b>Every</b>
              </label>
              <InputNumber
                style={{ width: "100%" }}
                onChange={(e) => setDaily(e)}
              />

              
            </div>
          )}

          {
            seletedValue == "weekly" &&
            <div className="col-md-12 mt-2">
            <label>
              <b>Weekly</b>
            </label>
            <table>
              {" "}
              <tr className="weeklyrow">
                {weekly.map((item) => (
                  <>
                    <td style={{backgroundColor:collectWeeks.includes(item.value) &&"gray",color:collectWeeks.includes(item.value) && "white"}} onClick={()=>handleWeekClick(item.value)}>{item.label}</td>
                  </>
                ))}
              </tr>
            </table>
          </div>
          }


{
            seletedValue == "monthly" &&
<>
            <div className="col-md-12 mt-2">
            <label>
              <b>Everys Month</b>
            </label>
            <InputNumber
              style={{ width: "100%" }}
            />

            
          </div>
            <div className="col-md-12 mt-2">
            <label>
              <b>Select Month Days</b>
            </label>
              <div>
              <div className="monthlyrow">
                {monthDays.length>0  && monthDays.map((item,index) => (
                  <>
                    <div style={{backgroundColor:collectMonthDays.includes(item.value) &&"gray",color:collectMonthDays.includes(item.value) && "white"}} onClick={()=>handleMonthClick(item.value)}>{item.label}</div>
                  </>
                ))}
              </div>
              <div style={{display:"flex",flexWrap:"wrap"}}>
                {
                collectMonthDays.length>0 &&  collectMonthDays.map((num,i)=>(
                      <p style={{margin:0}}>{i==0 && "Every "}{numberToOrdinal(Number(num))} {i+1==collectMonthDays.length?'':","}</p>
                      ))
                    }
                    </div>
              </div>
             
          </div>
          </>
          }
        </div>
      </Modal>
    </>
  );
};

export default CustomModal;

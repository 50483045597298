import React from "react";

import Icon, { HeartFilled } from "@ant-design/icons";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
// import FeedCard from './FeedCard'
import './feedsView.css'
import { Divider } from "antd";
import { Avatar } from "@material-ui/core";
import {BsFillArrowLeftCircleFill} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import { FaLongArrowAltLeft } from "react-icons/fa";

function View(props) {
  const data = props?.location?.state?.item;
  console.log(data)
  const dateChnage=(inputDate)=>{
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear() % 100; 
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedYear = year.toString().padStart(2, "0");
    
    const convertedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;
    
   return convertedDate
    
  }
  return (
    <>
  
    <div style={{width:"100%",display:"flex",justifyContent:"center",position:"relative"}}>
      {/* <div style={{position:"absolute",left:"0px"}}>
    
      </div> */}
      <div className="card" style={{width:"85%"}}>
        <>
        <div className="cardHeader22">
      <Link to={'/announcement'}>
                        <FaLongArrowAltLeft style={{fontSize:"2.5rem",color:"black",marginRight:"20px",textDecoration:"none"}}/>
                        {/* <button className="btn btn-primary">Go Back</button> */}
                        </Link>
      </div>
          <div className="">
            <div
            style={{display:"inline-block",textAlign:"center",height:"500px",width:"100%"}}
              // width={100}
              //  style={{ position: "relative" }}
            >
             { data?.file?.type.split('/')[0]=="image"?
              <img
                src={data?.file?.url}
                alt=""
                style={{ height: "100%",maxWidth:"100%" }}
              />:
              <video  src={ data.file?.url} controls style={{width:"100%"}}/>
              }
            </div>

            <div style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"-40px"}}>

            <div
              className="card text-dark bg-light mb-3  top-50 start-80 translate-middle"
              style={{width:"80%"}}
            >
              <div className="d-flex justify-content-between align-items-center" style={{paddingTop:"15px",paddingLeft:"30px",paddingRight:"30px"}}>
                <h3 className="card-title mb-0">{data?.title}</h3>
                <button
                  type="button"
                  className="btn btn-outline-light border  fs-3"
                >
                  {/* <HeartFilled /> */}
                  <strong>
                    <span style={{fontSize:"20px",paddingRight:"5px"}}>{data?.likes}</span>
                    <HeartFilled style={{ color: "red", fontSize: "25px",position:"relative",bottom:"3px" }} />{" "}
                  </strong>
                </button>
              </div>
              <div className="card-body pt-0">
                {/* <p className="card-text" style={{ fontSize: "17px" }}>
                 {data?.description}
                </p> */}
                <div>
                  <Divider/>
                  <div className="commentsHeading">
                  <h2>Comments</h2>
                  <div className="linnerss"></div>
                  </div>
                 {
                  data.comments &&
                   data.comments.map((item)=>(
                  <>
                    <div className="comment-card border bg-white position-relative p-3" style={{borderTopRightRadius:"10px"}}>
                    <div className="d-flex align-items-start bg-white p-3">
                      <Avatar style={{marginRight:"5px",backgroundColor:`${item.color}`}} src="#" alt={item.user.toUpperCase()}/>
                      <div>
                        <h3 className="username" style={{paddingLeft:"2px"}}>{item.user}</h3>
                        <p
                          className="comment-text"
                          style={{ fontSize: "16px",margin:"0px",fontWeight:"lighter",paddingLeft:"3px" }}
                        >
                          {item.comment}
                        </p>
                        <span className="timestamp position-absolute bottom-0 end-0"style={{right:"0px",backgroundColor:"lightgray",color:"white",padding:"0px 8px"}}>
                          Posted on {dateChnage(item.time_stamp)}
                        </span>
                      </div>
                    </div>
                  </div>
                    <hr />
                  </>
                   ))
                 }
                </div>
              </div>
            </div>
            </div>

          </div>
        </>
    </div>
    </div>
    </>
    
  );
}

export default View;

import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,

} from "react-bootstrap-table2-paginator";
import './styles.css'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../../app/modules/ECommerce/pages/customers/CustomersUIHelpers";
import { Pagination } from '../../../../src/_metronic/_partials/controls'
import { Modal, Spinner } from "react-bootstrap";
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../../modules/ECommerce/pages/customers/CustomersUIContext";
import { baseUrl } from "../../../services/config";
import toast from "react-hot-toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useHistory } from 'react-router-dom'
import { SendNotifaction } from "../../../services/SendNotifaction";
import { useSelector } from "react-redux";
import { PremissionChecker } from "../../../services/PremissionChecker";


export default function Dvla() {

  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };

  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [addModal, setaddModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [actionsLoading, setactionsLoading] = useState(false);
  const [brands, setBrands] = useState([])
  const [loading, setloading] = useState(false)
  const [editItem, setEditItem] = useState({})
  const customersUIContext = useCustomersUIContext();
  const [selectedId, setSelectedId] = useState('')
  const [usernameSearch, setusernameSearch] = useState('')
  const [emailsearch, setemailsearch] = useState('')
  const [role_name, setrole_name] = useState('')
  const [confirmModal, setconfirmModel] = useState(false)
  const [selectedObj, setSelectedObj] = useState({})

  useEffect(() => {
    getAllBrands()
  }, [])

  const history = useHistory()
  const [roles, setRoles] = useState([])

  useEffect(() => {
    getRoles()
  }, [])



  const getRoles = () => {
    fetch(`${baseUrl}/mgmt/roles`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authtoken}`,
      }
    }).then(res => res.json())
      .then((data) => {
        // let arr = []

        // data.payload.forEach(element => {
        //     arr.push({
        //         id: element.id,
        //         label: element.name,
        //         value: element.name
        //     })
        // });
        setRoles(data.payload)
      }).catch(err => {
        console.log(err)
      })
  }

  const getAllBrands = () => {
    setloading(true)
    fetch(`${baseUrl}/mgmt/users-with-license-check`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {
        setloading(false)

        const result = data.payload.map((item, i) => {
          item['sr'] = i + 1
          return item
        }

        )
        setBrands(result)
      })
      .catch((err) => {
        setloading(false)
        console.log(err);
      });
  };

  const columns = [
    {
      dataField: "sr",
      text: "sr.no",
      // sort: true,
      // sortCaret: sortCaret,


    },
    {
      dataField: "user.username",
      text: "Username",
      // sort: true,
      // sortCaret: sortCaret,
    },
    // {
    //   dataField: "first_name",
    //   text: "Firstname",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },
    // {
    //   dataField: "last_name",
    //   text: "Last Name",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },

    {
      dataField: "user.email",
      text: "Email",
      // sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "licence_check.total_points",
      text: "Total Points",
      // sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "user.ttc_verified",
      text: "TTC Verified",
      formatter: (row, cell) => (
        <span
          className={`label label-lg label-light-${getClassOrder(row)} label-inline`}
        >
          {row ? "Yes" : "No"}
        </span>
      )

    },
    {
      dataField: "licence_check",
      text: "Endorsements",
      formatter: (row, cell) => (
        PremissionChecker(user,"dvla.endorsements")  ?
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => history.push('/view-dvla', row)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              title="View Endorsements"
              src={toAbsoluteUrl("/media/svg/icons/Devices/Display2.svg")}
            />
          </span>
        </a>
        : ''
      ),
    },
    // {
    //   dataField: "contact",
    //   text: "Contact number ",
    // },
    // {
    //   dataField: "address",
    //   text: "Home address",
    // },
    // {
    //   dataField: "emergency_contact",
    //   text: "Emergency contact info",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },
    // {
    //   dataField: "employment_start_date",
    //   text: "Employment start date",
    //   sort: true,
    //   // sortCaret: sortCaret,

    // },
    // {
    //   dataField: "role[0].name",
    //   text: "Role",
    // },
    {
      dataField: "Status",
      text: "Approve",
      formatter: columnFormatters.toggleFormatter,
      formatExtraData: {
        user: user,
        onStatusChange: (row) => {
          console.log(row)
          setSelectedId(row)
          setconfirmModel(true)
          // setdeleteModal(true)
        },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];


  const openEdit = (item) => {
    setEditItem(item)
    seteditModal(true)
    setaddModal(true)
  }


  const options = {
    custom: true,
    totalSize: brands.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };



  const getClassOrder = (row) => {
    let classname = ''
    if (row) {
      classname = 'success'
    } else if (!row) {
      classname = 'danger'
    }

    return classname
  }




  //updating query params for pagination

  const updatingQueryParams = (page) => {
    console.log(page)
    setQueryParamsBase({
      pageNumber: page,
    });
  };


  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  }

  //end   

  const dataSearch = brands.filter((user) => {
    // let text = searchText.toLowerCase();
    // return searchText ? user?.topic?.toLowerCase().includes(text) || user?.section?.toLowerCase().includes(text) || user?.appellant_vs_respondent?.toLowerCase().includes(text) || user?.citations_ref?.toLowerCase().includes(text) : true;
    let text1 = usernameSearch.toLowerCase()
    const topicSearchData = text1 ? user?.username?.toLowerCase().includes(text1) : true;
    let text2 = emailsearch.toLowerCase()
    const appellantSearchData = text2 ? user?.email?.toLowerCase().includes(text2) : true;
    // let text3 = role_name.toLowerCase()
    // const sectionSearchData = text3 ? user?.role[0]?.name?.toLowerCase().includes(text3) : true;
    // let text4 = citationtext.toLowerCase()
    // const citationSearchData = text4 ? user?.citations_ref?.toLowerCase().includes(text4) : true;
    return topicSearchData && appellantSearchData
  });



  const handleChangeStatus = () => {
    setButtonLoading(true)
    fetch(`${baseUrl}/mgmt/change-dvla-status/${selectedId.user?.id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())

      .then((data) => {

        if (data.metadata) {
          setButtonLoading(false)
          if (data.metadata.success) {

            let status = data.payload.status == 1 ? 'Approve' : 'Rejected'
            SendNotifaction('DVLA Alert', `Permit to Drive ${status}`, selectedId.user?.id)
            setconfirmModel(false)
            getAllBrands()
            toast.success(data.metadata.message)
          }
        }
      })
      .catch((err) => {
        setButtonLoading(false)
        console.log(err);
      });
  }

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Card>
        <>
          <CardHeader title="Dvla">
          </CardHeader>
          <CardBody>
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search by username"
                  value={usernameSearch}
                  onChange={(e) => {
                    setusernameSearch(e.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in username
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search by email"
                  value={emailsearch}
                  onChange={(e) => {
                    setemailsearch(e.target.value);
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in email
                </small>
              </div>
              {/* <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Role"
                  name="type"
                  onChange={(e) => {
                    setrole_name(e.target.value);
                  }}
                  value={role_name}
                >
                  <option value="">All</option>
                  {
                    roles.map(role => (
                      <option key={role.isEdit} value={role.name}>{role.name}</option>
                    ))
                  }
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Type
                </small>
              </div> */}

            </div>
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <>
                    <Pagination
                      updatingQueryParams={updatingQueryParams}
                      onPaginationSelectChange={onPaginationSelectChange}
                      isLoading={loading}
                      paginationProps={paginationProps}
                    >
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        keyField="id"
                        data={dataSearch}
                        columns={columns}
                        defaultSorted={uiHelpers.defaultSorted}
                        {...paginationTableProps}
                        filter={filterFactory()}
                      ></BootstrapTable>
                    </Pagination>
                    {/* )} */}
                  </>
                );
              }}
            </PaginationProvider>
          </CardBody>
        </>
      </Card>
      <Modal
        show={confirmModal}
        centered
        animation
      >
        <Modal.Header>
          <Modal.Title>{selectedId.status == 1 ? 'Disapprove' : 'Approve'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to <b>{selectedId.status == 1 ? 'disapprove' : 'approve'}</b> this?</p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setconfirmModel(false)} className="btn btn-light">Cancel</button>
          <button disabled={buttonLoading} onClick={() => handleChangeStatus()} className="btn btn-primary">{selectedId.status == 1 ? 'Disapprove' : 'Approve'}</button>
        </Modal.Footer>
      </Modal>

    </>

  );
}



/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { PremissionChecker } from "../../../../../services/PremissionChecker";
import { useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {


  const Ruser = useSelector(state => state.user.user)

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };



  let user;
  if (localStorage.getItem("userDetails")) {
    user = JSON.parse(localStorage.getItem("userDetails"));
  }



  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>




        {Ruser?.role[0].name != "admin" && !Ruser?.email_verified ?
          null :
          <>

            {/* <li
                className={`menu-item ${getMenuItemActive("/leave-settings", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/leave-settings">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} />
                  </span>
                  <span className="menu-text">Settings</span>
                </NavLink>
              </li> */}
            {/* end:: section */}

            {/* Error Pages */}
            {PremissionChecker(Ruser, "user.list") || PremissionChecker(Ruser, "role.list") || PremissionChecker(Ruser, "leaver.list") ?
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/error",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/user-managment">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")}
                    />
                  </span>
                  <span className="menu-text">User Managment</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li className="menu-item  menu-item-parent" aria-haspopup="true">
                      <span className="menu-link">
                        <span className="menu-text">User Managment</span>
                      </span>
                    </li>



                    {
                      PremissionChecker(Ruser, "user.list") ?
                        <li
                          className={`menu-item ${getMenuItemActive("/users")}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to="/users">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Users</span>
                          </NavLink>
                        </li>
                        :
                        null
                    }




                    {
                      PremissionChecker(Ruser, "role.list") ?
                        <li
                          className={`menu-item ${getMenuItemActive("/view-hierarchy")}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to="/view-hierarchy">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Roles</span>
                          </NavLink>
                        </li>
                        :
                        null
                    }
                    { PremissionChecker(Ruser, "leaver.list") ?
                      <li
                        className={`menu-item ${getMenuItemActive("/leave-users")}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/leave-users">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Leaver</span>
                        </NavLink>
                      </li>
                      : null}
                    {/* <li
                      className={`menu-item ${getMenuItemActive("/permissions")}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/permissions">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Permissions</span>
                      </NavLink>
                    </li> */}




                  </ul>
                </div>
              </li> : null}


           {PremissionChecker(Ruser, "holiday_group.list") || PremissionChecker(Ruser, "leave_calendar.show") || PremissionChecker(Ruser, "leave_application.list") || PremissionChecker(Ruser, "leave_type.list") ? <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/error",
                true)}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/leaveholidays">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")}
                  />
                </span>
                <span className="menu-text">Holiday</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {/* <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Rota Planner</span>
                </span>
              </li> */}
                  {/* {

                  PremissionChecker(Ruser, "leave_application.list") ?
                    <li
                      className={`menu-item ${getMenuItemActive("/leaveholidays")}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/leaveholidays">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Holiday Planner</span>
                      </NavLink>
                    </li> : null
                    
                    } */}
                  {/* {Ruser?.role[0].name == "admin" ?
                    <li
                      className={`menu-item ${getMenuItemActive("/allHolidays")}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/allHolidays">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Holidays</span>
                      </NavLink>
                    </li>
                    : null} */}

            
                
                {PremissionChecker(Ruser, "holiday_group.list") || PremissionChecker(Ruser, "leave_calendar.show") || PremissionChecker(Ruser, "leave_application.list") ?    <li
                      className={`menu-item ${getMenuItemActive("/holidaygroups")}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/holidaygroups">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Holiday Management</span>
                      </NavLink>
                    </li> : ''}
                    
                    {/* } */}
                 {PremissionChecker(Ruser, "leave_type.list") ?   <li
                    className={`menu-item ${getMenuItemActive("/holidaytypes")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/holidaytypes">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Holiday Settings</span>
                    </NavLink>
                  </li> : ''}
                </ul>
              </div>
            </li> : ''}

            {/* @@Rota Planner links */}

            {
              PremissionChecker(Ruser, "rota.list") ?

                <li
                  className={`menu-item ${getMenuItemActive("/rota-groups")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/rota-groups">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                    </span>
                    <span className="menu-text">Rota Planner</span>
                  </NavLink>
                </li>
                :
                null
            }

            {/* @clock in out links */}
            {Ruser?.role[0].name == "admin" ?
              <li
                className={`menu-item ${getMenuItemActive("/In-Out")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/In-Out">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Clock in/out</span>
                </NavLink>
              </li>
              : null}



            {/* @DVLA Drivers section*/}

            {
              PremissionChecker(Ruser, "dvla.list") ?

              <li
                className={`menu-item ${getMenuItemActive("/dvla")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/dvla">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">DVLA Drivers</span>
                </NavLink>
              </li>

              : null}
            {/* @staff Announcement */}

             {
              PremissionChecker(Ruser, "announcement.list") || PremissionChecker(Ruser, "feed.list") ?
             <li
                className={`menu-item ${getMenuItemActive("/announcement")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/announcement">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Staff Announcement </span>
                </NavLink>
              </li>
: ''}

            {/* @orders  */}
            {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/error",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/user-managment">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")}
                    />
                  </span>
                  <span className="menu-text">Orders</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li className="menu-item  menu-item-parent" aria-haspopup="true">
                      <span className="menu-link">
                        <span className="menu-text">Orders</span>
                      </span>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive("/manage-orders")}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/manage-orders">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">App Orders</span>
                      </NavLink>
                    </li>
                    <li
                      className={`menu-item ${getMenuItemActive("/users")}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/users">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Web Orders</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li> */}


            {/* end::Menu Nav */}


            {/* <li
                className={`menu-item ${getMenuItemActive("/equipments", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/equipments">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Equipments</span>
                </NavLink>
              </li> */}
            {Ruser?.role[0].name == "admin" ?
              <li
                className={`menu-item ${getMenuItemActive("/staff-matrix", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/staff-matrix">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                  </span>
                  <span className="menu-text">Staff Matrix</span>
                </NavLink>
              </li>
              : null}
            {
              PremissionChecker(Ruser, "staff_area.list") || PremissionChecker(Ruser,'department.list') ?
                <li
                  className={`menu-item ${getMenuItemActive("/staff-area", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/staff-area">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                    </span>
                    <span className="menu-text">Staff Area</span>
                  </NavLink>
                </li>
                :
                null
            }
          </>}
        {/* {
          PremissionChecker(Ruser, "department.list") ?
            <li
              className={`menu-item ${getMenuItemActive("/department", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/department">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                </span>
                <span className="menu-text">Departments</span>
              </NavLink>
            </li>
            :
            null
        } */}
        {/* {
          PremissionChecker(Ruser, "feed.list") ?
            <li
              className={`menu-item ${getMenuItemActive("/feed", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/feed">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
                </span>
                <span className="menu-text">Feeds</span>
              </NavLink>
            </li>
            :
            null
        } */}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

import React from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {Tabs, Tooltip } from "antd";
import { Spinner, Table } from "react-bootstrap";
import {
  PlusOutlined,
  FolderOpenOutlined,
  SettingOutlined,
  UploadOutlined,
  FileOutlined,
  DeleteOutlined,
  FolderOutlined,
  RightOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  UserSwitchOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./styles.css";
import { useEffect } from "react";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import AddFolderModel from "../../components/AddFolderModel/AddFolderModel";
import { baseUrl } from "../../../services/config";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import UploadFileModel from "../../components/UploadFileModal/UploadFileModel";
import DocViewer, {
  DocViewerRenderers,
  PDFRenderer,
  PNGRenderer,
} from "@cyntler/react-doc-viewer";
import pdfFile from "./sample.pdf";
import { PremissionChecker } from "../../../services/PremissionChecker";
import { useSelector } from "react-redux";
import EditFileModal from "../../components/UploadFileModal/EditFileModal";
import AssignedModal from "../../components/UploadFileModal/AssignedModal";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import AssignedModal2 from "../../components/UploadFileModal/AssignedModal2";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import FileFolderType from "../../components/FileFolderType/FileFolderType";
import FileIcon from "../../asserts/simpleFile.png";
import './tree.css'
// import { DndProvider } from "react-dnd";

// import {
//   Tree,
//   MultiBackend,
//   getBackendOptions
// } from "@minoru/react-dnd-treeview";
// import { CustomNode } from "./CustomNode.jsx";

// // import { theme } from "./theme.js";
// import { Placeholder } from "./Placeholder.jsx";
// import styles from "./App.module.css";
// import SampleData from "./sample_data.json";
// import { CssBaseline, ThemeProvider } from '@material-ui/core';
// import { CustomDragPreview } from "./CustomDragPreview.jsx";
import { Tree } from 'antd';

const x = 3;
const y = 2;
const z = 1;
const defaultData = [];

const generateData = (_level, _preKey, _tns) => {
  const preKey = _preKey || '0';
  const tns = _tns || defaultData;
  const children = [];
  for (let i = 0; i < x; i++) {
    const key = `${preKey}-${i}`;
    tns.push({
      title: key,
      key,
    });
    if (i < y) {
      children.push(key);
    }
  }
  if (_level < 0) {
    return tns;
  }
  const level = _level - 1;
  children.forEach((key, index) => {
    tns[index].children = [];
    return generateData(level, key, tns[index].children);
  });
};

generateData(z);

const { TabPane } = Tabs;
const ViewArea = () => {
  const user = useSelector((state) => state.user.user);

  const params = useParams();
  const tabRef = useRef();
  const { id, groupname } = params;
  const authtoken = localStorage.getItem("userToken");

  const [folderModel, setfolderModel] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const fileInputRef = useRef(null);
  const [folderList, setFolderList] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [selectedObject2, setSelectedObject2] = useState({});
  const [deleteModal, setdeleteModal] = useState(false);
  const [uploadModel, setUploadModel] = useState(false);
  const [editFileModel, setEditFileModel] = useState(false);
  const [usersList, setusersList] = useState([]);
  const [selectedFile, setselectedFile] = useState({});
  const [activeKey, setActiveKey] = useState("1");
  const [fileDeletetruer, setFileDeletetruer] = useState(false);
  const [assignedModal, setAssignedModal] = useState(false);
  const [assignedModal2, setAssignedModal2] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const [myDragList, setMyDraglist] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [savePositionLoading,setSavePositionLoading]=useState(false)
  const [onlyForFirstTime,setOnlyForFirstTime]=useState(true)
  const [showButton,setShowButton]=useState(false)
const arr = [
  {
      key:"39",
      id: 39,
      isFolder:true,
      category_name: "FOLDER",
      category_value: 2,
      title: "Folder 1",
      created_at: "2024-07-12T05:10:38.000000Z",
      type: null,
      link: null,
      html_tags: null,
      order: null,
      icon_id: 444,
      icon_name: "blob",
      icon_type: "image/png",
      icon_url: "https://api.shl.film/storage/550a141f12de6341fba65b0ad0433500/blob",
      file: null,
  },
  {
      key:"40",
      id: 40,
      isFolder:false,
      category_name: "FILE",
      category_value: 1,
      title: "file 1",
      created_at: "2024-07-12T05:11:10.000000Z",
      type: "file",
      link: null,
      html_tags: null,
      order: null,
      icon_id: 446,
      icon_name: "blob",
      icon_type: "image/png",
      icon_url: "https://api.shl.film/storage/1a5b1e4daae265b790965a275b53ae50/blob",
      file_id: 445,
      file_name: "dummy",
      file_type: "application/pdf",
      file_url: "https://api.shl.film/storage/67f7fb873eaf29526a11a9b7ac33bfac/dummy.pdf",
  },
  {
      key:"41",
      id: 41,
      isFolder:false,
      category_name: "FILE",
      category_value: 1,
      title: "file2",
      created_at: "2024-07-12T05:11:25.000000Z",
      type: "link",
      link: "sdsadsad",
      html_tags: null,
      order: null,
      icon_id: 447,
      icon_name: "blob",
      icon_type: "image/png",
      icon_url: "https://api.shl.film/storage/9a96876e2f8f3dc4f3cf45f02c61c0c1/blob",
      file: null,
  },
  {
      key:"42",
      id: 42,
      isFolder:false,
      category_name: "FILE",
      category_value: 1,
      title: "file3",
      created_at: "2024-07-12T05:11:43.000000Z",
      type: "page_builder",
      link: null,
      html_tags: "<p>sdsagfsdfsd</p>",
      order: null,
      icon_id: 448,
      icon_name: "blob",
      icon_type: "image/png",
      icon_url: "https://api.shl.film/storage/9b70e8fe62e40c570a322f1b0b659098/blob",
      file: null,
  }
];






  const [gData, setGData] = useState([]);
const [orignalArray,setOrignalArray]=useState([])
  // const [expandedKeys] = useState(['0-0', '0-0-0', '0-0-0-0']);
  useEffect(() => {
    getFolders();
    getUsers();
    setOnlyForFirstTime(false)
  }, []);

  const getUsers = () => {
    fetch(`${baseUrl}/mgmt/staff-areas/${id}/assigned-users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setusersList(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(gData,"ended Response")

  const getFolders = async () => {
    if(!onlyForFirstTime){
      updatePositions2()
    }
    setShowButton(false)
    console.log("iss ka issue hy");
    try {
      const response = await fetch(
        `${baseUrl}/mgmt/staff-area/${id}/staff-area-items-get-positions`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );
      const data = await response.json();
     if(data.metadata){
      if(data.metadata.success){
        setGData(data.payload.result)
        setOrignalArray(data.payload.result)
      }
     }
    } catch (err) {
      console.log(err);
    }
  };







  const onDragEnter = (info) => {
  };
  const onDrop = (info) => {
    console.log(info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1
  
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          const result = loop(data[i].children, key, callback);
          if (result) {
            return result;
          }
        }
      }
    };
  
    const data = [...gData];
  
    // Find drop item
    let dropItem;
    loop(data, dropKey, (item) => {
      dropItem = item;
      return item; // To exit the loop early
    });
  
    // Check if drop item has isFolder false
    if (dropItem && dropItem.isFolder === false && !info.dropToGap) {
      console.log('Cannot drop here as the target is not a folder');
      return;
    }
  
    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
      return item; // To exit the loop early
    });
  
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
        return item; // To exit the loop early
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
        return _item; // To exit the loop early
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i, 0, dragObj);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i + 1, 0, dragObj);
      }
    }
    setShowButton(true)
    setGData(data);
  };
  
 

  const fileEdit = (item) => {
    setSelectedObject(item);
    setEditFileModel(true);
  };
  const onFileClick=(item)=>{
    setselectedFile(item);
    setActiveKey("2");
  }
  const onEditClick = (item) => {
     setSelectedObject(item);
      setIsEdit(true);
      setCurrentCategory("");
      setfolderModel(true);
  };

  const fileDelete = (item) => {
    setSelectedObject(item);
    setdeleteModal(true);
  };
  const openUploadModel = (item) => {
    setSelectedObject(item);
    setUploadModel(true);
    setCurrentCategory("");
  };



  const updatePositions = () => {
    setSavePositionLoading(true)
    fetch(`${baseUrl}/mgmt/staff-area-items-set-positions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({positions_data:gData}),
    })
      .then((res) => res.json())
      .then((data) => {
    setSavePositionLoading(false)
        if (data.metadata.success) {
          toast.success(data.metadata.message);
          setShowButton(false)
          // getFolders();
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        } else {
          toast.error(`Something went wrong`);
        }
      })
      .catch((err) => {
    setSavePositionLoading(false)
        console.log(err);
      });
  };


  const updatePositions2 = () => {
    // setSavePositionLoading(true)
    fetch(`${baseUrl}/mgmt/staff-area-items-set-positions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify({positions_data:gData}),
    })
      .then((res) => res.json())
      .then((data) => {
    // setSavePositionLoading(false)
        if (data.metadata.success) {
          // toast.success(data.metadata.message);
          setShowButton(false)
          // getFolders();
        } else if (!data.metadata.success) {
          if (toString.call(data.metadata.message) == "[object Object]") {
            const errorMessageArray = Object.values(
              data.metadata.message
            ).flatMap((messages) => messages);
            errorMessageArray.forEach((message) => toast.error(message));
          } else if (toString.call(data.metadata.message) == "[object Array]") {
            Object.values(data.metadata.message).map((msg) => {
              toast.error(msg);
            });
          } else {
            toast.error(data.metadata.message);
          }
        } else {
          toast.error(`Something went wrong`);
        }
      })
      .catch((err) => {
    setSavePositionLoading(false)
        console.log(err);
      });
  };

  const renderTitle = (nodeData) => {
  const isOpen=!expandedKeys.includes(nodeData.key)
    return (
      !nodeData.isFolder ?
      <div
      style={{
        backgroundColor: "#e0e0e0" ,
      }}
      className={`file`}
    >
      <div  className="row" style={{ marginTop: "3px" }}>
        <div  className="col-md-8 d-flex">
          {/* <div style={{ fontSize: "15px" }}>{1}:</div> */}
          <div className="mr-2" style={{ WebkitWritingMode: "vertical-lr" }}>
            <FileTextOutlined className="area_icons folder_fileIcons" />
          </div>
          <div
            className="text-container-area"
            style={{ fontSize: "15px" }}
            onClick={()=>onFileClick(nodeData)}
          >
            <Tooltip title={nodeData.title} placement="top">
              {nodeData.title}
            </Tooltip>
          </div>
        </div>
        <div className="col-md-4 d-flex">
          {PremissionChecker(user, "staff_area.assign_user") && PremissionChecker(user, "file.assign_user") ? (
            <span
              onClick={() => {
                setSelectedObject(nodeData);
                setAssignedModal(true);
              }}
              style={{ WebkitWritingMode: "vertical-lr", fontSize: "1.3rem",marginLeft:"8px" }}
              className="icon"
            >
              <UserSwitchOutlined size={50} style={{ color: "orange" }} />
            </span>
           ) : (
            ""
          )}

          {PremissionChecker(user, "file.update") && (
            <span
              title="Edit File"
              onClick={() => fileEdit(nodeData)}
              style={{ WebkitWritingMode: "vertical-lr" }}
              className="icon ml-3"
            >
              <EditOutlined className="plusicon" style={{ color: "black" }} />
            </span>
          )}

          {PremissionChecker(user, "file.delete") && (
            <span
              title="Delete File"
              onClick={() => fileDelete(nodeData)}
              style={{ WebkitWritingMode: "vertical-lr" }}
              className="icon ml-3"
            >
              <DeleteOutlined
                className="plusicon2"
                style={{ color: "red",marginLeft:"10px" }}
              />
            </span>
            )}
        </div>
      </div>
    </div>

      : 

      <div>
      {
        // files && files.length == 0 ?
        //     <div className='folder'>
        //         <span>Empty</span>
        //     </div>
        //     :

        <>
          <div
            className="folder"
            style={{
              background: !isOpen && "black",
              color: !isOpen && "white",
            }}
          >
            {/* <div style={{ WebkitWritingMode: 'vertical-lr' }}><RightOutlined /></div> */}
            <div
             onClick={() => handleExpand(nodeData.key)}
              className="col-md-8 d-flex"
              style={{ paddingLeft: 0 }}
            >
              <div
                className="mr-2"
                style={{ WebkitWritingMode: "vertical-lr" }}
              >
                {/* {category?.value == "2" ? (
                  !isOpen ? ( */}
                    <FolderOpenOutlined className="area_icons folder_fileIcons" />
                {/* //   ) : (
                //     <FolderOutlined className="area_icons folder_fileIcons" />
                //   )
                // ) : (
                //   <FileTextOutlined className="area_icons folder_fileIcons" />
                // )} */}
              </div>
              <div
                className="text-container-area"
                style={{ fontSize: "15px" }}
              >
                <Tooltip title={nodeData.title} placement="top">
                  {nodeData.title}
                </Tooltip>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
            {PremissionChecker(user, "staff_area.assign_user") && PremissionChecker(user, "folder.assign_user") && <div
                onClick={() => {    
                    setSelectedObject2(nodeData);
                    setAssignedModal2(true);
                }}
                style={{ WebkitWritingMode: "vertical-lr" }}
                className={`icon ml-2 ${!isOpen && "hideIt"}`}
              >
                <UserSwitchOutlined
                  size={50}
                  style={{ color: "orange" }}
                  className="area_icons"
                />
             
              </div>}
        
                { PremissionChecker(user, "folder.update") && <div
                  title="Edit Folder"
                  onClick={() => onEditClick(nodeData)}
                  style={{ WebkitWritingMode: "vertical-lr" }}
                  className={`icon ml-2 ${!isOpen && "hideIt"}`}
                >
                  <EditOutlined
                    className="plusicon"
                    style={{ color: "black" }}
                  />
                </div>}
           
               {PremissionChecker(user, "folder.delete") && <div
                  title="Delete Folder"
                  onClick={() => fileDelete(nodeData)}
                  style={{ WebkitWritingMode: "vertical-lr" }}
                  className={`icon ml-2 ${!isOpen && "hideIt"}`}
                >
                  <DeleteOutlined
                    className="plusicon2"
                    style={{ color: "red" }}
                  />
                </div>}
              
              {PremissionChecker(user, "file.create") && (
                <div
                  title={`Upload File in ${nodeData.title}`}
                  onClick={() => {
                      openUploadModel(nodeData);
                  }}
                  style={{ WebkitWritingMode: "vertical-lr" }}
                  className={`icon ml-2 ${!isOpen && "hideIt"}`}
                >
                  <UploadOutlined
                    className="plusicon2"
                    style={{
                      color: "orange",
                      // opacity: category?.value == "2" ? 1 : -1,
                    }}
                  />
                </div>
               )}
              <div
                onClick={() => handleExpand(nodeData.key)}
                className="mr-2"
                style={{ WebkitWritingMode: "vertical-lr" }}
              >
                {
                  !isOpen ? (
                    <DownOutlined className="area_icons" />
                  ) : (
                    <RightOutlined className="area_icons" />
                  )
                }
              </div>
            </div>
          </div>
        </>
      }
    </div>
    );
  };

  const handleExpand = (key) => {
    setExpandedKeys((prevExpandedKeys) => {
      if (prevExpandedKeys.includes(key)) {
        return prevExpandedKeys.filter((k) => k !== key);
      } else {
        return [...prevExpandedKeys, key];
      }
    });
  };





  const onChange = (key) => {
    setActiveKey(key);
  };

  const Statistics = () => {
    return (
      <div>
        <Table responsive>
          <thead className="viewAreaTable">
            <tr id="tbl">
              <th className="text-center">No #</th>
              <th>Name</th>
              <th>Email</th>
            </tr>
          </thead>

          <tbody>
            {!!usersList &&
              usersList.map((user, index) => (
                <tr
                  id="tbl"
                  className={index % 2 == 0 ? "whiteColorRow" : "lightColorRow"}
                >
                  <td style={{ color: "black" }} className="text-center">
                    {index + 1}
                  </td>
                  <td style={{ color: "black" }} className="text_align_start">
                    {user?.username}
                  </td>
                  <td style={{ color: "black" }} className="text_align_start">
                    {user?.email}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {/* <h1>aaa</h1> */}
        {/* <Table columns={columns} dataSource={usersList} /> */}
      </div>
    );
  };

  const PreviewTab = () => {
    let arr = [selectedFile];

    // let newarr = arr.map(li=>{
    //     uri:URL.createObjectURL(li)
    // })

    console.log("selectedFile", selectedFile);

    return (
      <div style={{ backgroundColor: "#e9f4ff" }}>
        {selectedFile.type == "page_builder" || selectedFile.type == "link" ? (
          <>
            <h4 className="text-center">{selectedFile.title}</h4>
            <div class="iframe-container" style={{ overflowY: "scroll" }}>
              <div class="iframe-content">
                {selectedFile.html_tags ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: selectedFile.html_tags }}
                  ></div>
                ) : (
                  <iframe
                    title={selectedFile.title}
                    src={selectedFile.link}
                  ></iframe>
                )}
              </div>
            </div>
          </>
        ) : selectedFile.type == "file" ? (
          <div>
            {selectedFile?.file?.type == "application/msword" ||
            selectedFile?.file?.type ==
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            selectedFile?.file?.type == "text/csv" ||
            selectedFile?.file?.type == "application/vnd.ms-excel" ||
            selectedFile?.file?.type ==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
              <div>
                <object
                  data={`https://docs.google.com/gview?url=${encodeURIComponent(
                    selectedFile?.file?.url
                  )}&embedded=true`}
                  title="Document Viewer"
                  width="100%"
                  height="600px"
                />

                {/* <a href={selectedFile?.file?.url}>Not Supported file <b>Download</b> </a> */}
              </div>
            ) : (
              // selectedFile?.file?.type == 'application/msword' ||  selectedFile?.file?.type == 'text/csv'
              //     ?
              //     <iframe src={`https://docs.google.com/gview?url=${encodeURIComponent(selectedFile?.file?.url)}&embedded=true`} title="Document Viewer" width="100%" height="600px" />
              //     :

              <object
                height="500"
                width="100%"
                type={selectedFile?.file?.type}
                // sandbox="allow-same-origin allow-scripts allow-forms"
                data={selectedFile?.file?.url}
              ></object>
            )}
            {/* <DocViewer
                                // requestHeaders={{
                                //     Authorization: `Bearer ${authtoken}`,

                                // }}
                                // config={{
                                //     header: {
                                //         Authorization: `Bearer ${authtoken}`,

                                //     }
                                // }}
                                prefetchMethod='GET'
                                requestHeaders={{
                                    Authorization: `Bearer ${authtoken}`,

                                }}
                                pluginRenderers={DocViewerRenderers}
                                documents={arr.map((li) => ({
                                    uri: li?.file?.url,
                                    fileName: li.title,
                                }))} /> */}
          </div>
        ) : null}
      </div>
    );
  };
 




  const reloader = () => {
    setselectedFile("");
    getFolders();
  };

  const folderOpner = () => {
    setCurrentCategory("folder");
    setIsEdit(false);
    setfolderModel(true);
  };

  const fileOpner = () => {
    setCurrentCategory("file");
    setUploadModel(true);
  };

  return (
    <>
      <Card>
        <CardHeader className="view_area_header" title={groupname}>
          <CardHeaderToolbar>
            <Link to={"/staff-area"}>
              <BsFillArrowLeftCircleFill
                style={{
                  top: "26px",
                  left: "35px",
                  position: "absolute",
                  fontSize: "2.5rem",
                  color: "black",
                  marginRight: "20px",
                  textDecoration: "none",
                }}
              />
            </Link>
            {PremissionChecker(user, "folder.create") ||
            PremissionChecker(user, "file.create") ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setTypeModal(true);
                }}
              >
                Add New
              </button>
            ) : null}
             { savePositionLoading ? 
           <div style={{width:"100px",display:"grid",placeContent:"center"}}>
  <Spinner animation="border" variant="dark"/>

           </div> : 
             showButton && <button
                type="button"
                className="btn btn-primary mx-2"
                onClick={updatePositions}
              >
                Save Positions
              </button>}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div
              style={{
                paddingLeft: 0,
                borderRight: "1px solid black",
              }}
              className="col-md-5"
            >
              {/* {PremissionChecker(user, "folder.list") && (
                <div className="tree-view">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {myDragList.map((item, index) => (
                            <Draggable
                              isDragDisabled={
                                !item.isOpen && item.files.length > 0
                              }
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  {(item.category?.value == "1" &&
                                    PremissionChecker(user, "file.list")) ||
                                  (item.category?.value == "2" &&
                                    PremissionChecker(user, "folder.list")) ? (
                                    <Folder
                                      isDrageable={snapshot.isDragging}
                                      index={index}
                                      key={index}
                                      item={item}
                                    />
                                  ) : null}
                               
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              )} */}
                 <Tree
      className="draggable-tree"
      defaultExpandedKeys={expandedKeys}
      draggable
      blockNode
      onDragEnter={onDragEnter}
      onDrop={onDrop}
      treeData={gData}
      titleRender={renderTitle}
      onExpand={(keys) => setExpandedKeys(keys)}
      expandedKeys={expandedKeys}
    />
            </div>
            <div className="col-md-7">
              <Tabs
                activeKey={activeKey}
                itemRef={tabRef}
                defaultActiveKey="1"
                onChange={onChange}
              >
                <TabPane tab="Users" key="1">
                  <Statistics />
                </TabPane>
                <TabPane tab="Preview" key="2">
                  <PreviewTab />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </CardBody>
      </Card>
      <FileFolderType
        folderOpner={folderOpner}
        fileOpner={fileOpner}
        open={typeModal}
        onHide={() => {
          setCurrentCategory("");
          setTypeModal(false);
        }}
      />
      <AddFolderModel
        isEdit={isEdit}
        selectedObject={selectedObject}
        open={folderModel}
        title={selectedObject.title}
        hidePreviousModal={() => {
          setCurrentCategory("");
          setTypeModal(false);
        }}
        onHide={() => {
          setCurrentCategory("");
          setfolderModel(false);
        }}
        areaId={id}
        reload={reloader}
      />

      <DeleteModal
        visible={deleteModal}
        HideModal={() => setdeleteModal(false)}
        method={"DELETE"}
        url={
         `${baseUrl}/mgmt/staff-area-items/${selectedObject.id}`
        }
        reload={reloader}
      />
      <UploadFileModel
        currentCategory={currentCategory}
        isEdit={false}
        hidePreviousModal={() => {
          setCurrentCategory("");
          setTypeModal(false);
        }}
        closeModel={() => {
          setUploadModel(false);
        }}
        visible={uploadModel}
        id={selectedObject.id}
        reload={getFolders}
        areaId={id}
      />
      <EditFileModal
        currentCategory={currentCategory}
        closeModel={() => {
          setCurrentCategory("");
          setEditFileModel(false);
        }}
        visible={editFileModel}
        item={selectedObject}
        reload={getFolders}
      />
      {assignedModal && (
        <AssignedModal
          closeModel={() => setAssignedModal(false)}
          visible={assignedModal}
          users={usersList}
          reload={getFolders}
          item={selectedObject}
        />
      )}
      {assignedModal2 && (
        <AssignedModal2
          currentCategory={currentCategory}
          closeModel={() => {
            setCurrentCategory("");
            setAssignedModal2(false);
          }}
          visible={assignedModal2}
          users={usersList}
          reload={getFolders}
          item={selectedObject2}
        />
      )}
    </>
  );
};

export default ViewArea;

import React, { useEffect, useRef } from 'react'
import {Divider, Drawer
} from 'antd';
import {MailOutlined,PhoneOutlined,HomeOutlined} from '@ant-design/icons'
import { Avatar, Chip } from '@material-ui/core';
import './profileDrawer.css'

const UserProfileDrawer = ({
    hideDrawer,
    drawerVisible,
    user
}) => {


    return (
        <>
          <Drawer
            // title={currentDate}
            width={420}
            onClose={() =>{ 
                hideDrawer()}}
            open={drawerVisible}
            bodyStyle={{
                paddingBottom: 50,
            }}
            // extra={
            //     <Space>
            //         <h3 className='text-center'>{`Edit:${viewTitle?.length > 32
            //             ? viewTitle?.substr(0, 32) + "..."
            //             : viewTitle}`}</h3>
            //     </Space>
            // }
            
            >

        
<div className='profileimageandname'>
<Avatar
                  style={{ backgroundColor: `#${user?.color}` }}
                  alt={user?.name?.toUpperCase()}
                  src={user?.image !=null ? user?.image?.url :   "#"}
                  className='profileDrawerIma'
                />
                <p>{user?.name}</p>
</div>
 <div className='otherDetailofprofile'>
  <div>
  <MailOutlined />
    <p>{user?.email}</p>
  </div>
  <div>
  <PhoneOutlined />
    <p>{user?.contact}</p>
  </div>
  <div>
  <HomeOutlined />
    <p style={{marginBottom:0}}>{user?.address}</p>
  </div>
 </div>
 <Divider/>
<div className='profileHeadingContainer'>
 <h3><b>Work Pattern</b></h3>
 <div></div>
</div>
<div>
<Chip className={user.work_pattern?.monday==1? 'tagerrs triggerTrue' : 'tagerrs ' } label="Monday" />
<Chip className={user.work_pattern?.tuesday==1? 'tagerrs triggerTrue' : 'tagerrs ' }  label="Tuesday" />
<Chip className={user.work_pattern?.wednesday==1? 'tagerrs triggerTrue' : 'tagerrs ' } label="Wednesday" />
<Chip className={user.work_pattern?.thursday==1? 'tagerrs triggerTrue' : 'tagerrs' } label="Thursday" />
<Chip className={user.work_pattern?.friday==1? 'tagerrs triggerTrue' : 'tagerrs' } label="Friday" />
<Chip className={user.work_pattern?.saturday==1? 'tagerrs triggerTrue' : 'tagerrs' } label="Saturday" />
<Chip className={user.work_pattern?.sunday==1? 'tagerrs triggerTrue' : 'tagerrs' } label="Sunday" />
</div>

        </Drawer>
          

        </>

      
    )
}

export default UserProfileDrawer